/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";

import { Card, CardHeader, CardBody, Row, Col, Input, FormGroup } from "reactstrap";

// add new codes
import { useHistory } from 'react-router-dom';
import dataTypes from "views/diary/dataType.js";

import "assets/css/coach.css";

// end

let axios = require('axios');
let Pusher = require('pusher-js');
let URL_API = "https://xs458763.xsrv.jp/api/";
let channel = null;


let g_messages = [];
let flag = true;
function ChatRoom() {

  let session = JSON.parse(window.sessionStorage.getItem('sessions'));
  if(!session){
    window.location = '/';
  }

  const history = useHistory();

  const [player, setPlayer] = useState(JSON.parse(window.sessionStorage.getItem('player')));
  const [messages, setMessages] = useState([]);
  const [msg, setMsg] = useState('');
  const [uFlag, setUFlag] = useState(0);
  const [more, setMore] = useState(0);

  const toPage = (e, pname) => {
    history.push('../coach/'+pname);
  }

  const onShowAllmsg = (e) => {
    axios.get(URL_API+"getAllMessages", {
      params: {
        coach_id : session['id'],
        player_id : player['id']
      }
    })
    .then(function (response) {
      setMessages(response.data.list);
      g_messages = response.data.list;
      setMore(response.data.more);
      setUFlag(!flag);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
  }

  const onChangeMsg = (e) => {
    setMsg(e.target.value);
  }

  const onSetShowFlag = (e) => {
    axios.get(URL_API+"setShowFlag", {
      params: {
        coach_id : session['id'],
        player_id : player['id'],
        f:1
      }
    })
    .then(function (response) {
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
  }

  const onSendMsg = (e) => {
    axios.get(URL_API+"setMessages", {
      params: {
        coach_id : session['id'],
        player_id : player['id'],
        content : msg,
        f:1
      }
    })
    .then(function (response) {
      // setUFlag(uFlag+1);
      setMsg('');
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
  }

  const render1 = (item, idx) => {
    return (
      <div key={idx} className="col-12 px-0 mx-0 d-flex justify-content-end align-items-center">
        <span className="send_msg px-3 py-2 mb-1" dangerouslySetInnerHTML={{__html: item.content}} />
      </div>
    );
  }

  const onShowData = (e, id, item) => { 
    console.log(item);
    window.sessionStorage.setItem('msg_id', item.id);
    onSetShowFlag(e);
    history.push('../coach/preparationview/'+id);
  }

  const onShowData1 = (e, id, item) => {     
    console.log(item); 
    window.sessionStorage.setItem('msg_id', item.id);
    axios.get(URL_API+"getPreparaAfterAPI", {
      params: {
        id: id, 
      }
    })
    .then(function (response) {
      window.sessionStorage.setItem('selTables', JSON.stringify(response.data[0]));
      history.push('../coach/tableview/'+id);
    });     
  }

  const render2 = (item, idx) => {
    let s = !item.showId ? '' : item.showId;
    let p = s.split(':');
    // console.log(p);
    return (
      <div key={idx} className="col-12 px-0 mx-0 d-flex justify-content-start align-items-center">
        <span className="receive_msg px-3 py-2 mb-1">
          <span className="" dangerouslySetInnerHTML={{__html: item.content}} />
          {
            item.sFlag == 2 && (
              <i className="fas fa-check float-right ml-1" style={{color:'rgb(3, 115, 202)'}}></i>
            )
          }
          {
            (p[0] == 'p' && p[1]) && (
              <i className="pl-1 fas fa-file float-right" onClick={(e) => onShowData(e, p[1], item)}></i>
            )
          }
          {
            (p[0] == 't' && p[1]) && (
              <i className="pl-1 fas fa-file float-right" onClick={(e) => onShowData1(e, p[1], item)}></i>
            )
          }
        </span>        
      </div>
    );
  }

  const renderMsgs = (item, idx) => {
    if(item.sender_id == session.id){
      return render1(item, idx);
    }else{
      return render2(item, idx);
    }
  }

  useEffect(() => {
    if(channel)
      channel.unbind();
    Pusher.logToConsole = true;
    var pusher = new Pusher('6ecccbbacc37f7103bdb', {
        cluster: 'eu'
    });

    channel = pusher.subscribe('my-'+session.id+'-'+player.id);
    channel.bind('my-event', function(data) {
      console.log(data, g_messages);
      // setUFlag((c) => c+1);
      setUFlag(!flag);
      let msgs = g_messages;
      msgs = [ ...msgs, {
          id : data['id'],
          sFlag : data['sFlag'],
          sender_id : data['sender_id'],
          receiver_id : data['receiver_id'],
          content : data['data']['content'],
          showId : !data['data']['pid'] ? 0 : data['data']['pid']
        }];
      // msgs.push(
      //   {
      //     id : data['id'],
      //     sFlag : data['sFlag'],
      //     sender_id : data['sender_id'],
      //     receiver_id : data['receiver_id'],
      //     content : data['data']['content'],
      //     showId : !data['data']['pid'] ? 0 : data['data']['pid']
      //   }
      // );
      setMessages(msgs);
      g_messages = msgs;
      // console.log(uFlag);
      setUFlag(uFlag+1);
      // console.log(data, g_messages);
    });

    axios.get(URL_API+"getMessages", {
      params: {
        coach_id : session['id'],
        player_id : player['id']
      }
    })
    .then(function (response) {
      // let d = JSON.parse(response);
      // window.sessionStorage.setItem('players', JSON.stringify(response.data));
      setMessages(response.data.list);
      g_messages = response.data.list;
      setMore(response.data.more);
      // console.log(response);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 

    return () => {
      // console.log(channel);
      channel.unbind();
    }

  }, []);

  return (
    <>      
      <div className="content coach_content px-0 pb-0">
        <div className="selected_player">          
          <Row className="col-12 py-2 px-0 mx-0 row">
            <div className="col-2 d-flex align-items-center">
              <i className="fas fa-chevron-left" style={{fontSize:'20px'}} onClick={(event) => history.push('../coach/chatMembers')}></i>
            </div>
            <div className="col-10 d-flex align-items-center justify-content-end">
              <div className="d-flex align-items-center px-0" onClick={(e) => history.push('../coach/chatMembers')}>
                <span style={{width:'100%', whiteSpace:'pre-wrap'}}>{player.name}</span>
              </div>
              <div className="text-center col-2 px-0" onClick={(e) => history.push('../coach/chatMembers')}>
                <img className="s_player_icon" src={(player.profile_photo_path ? "https://xs458763.xsrv.jp/uploads/"+player.profile_photo_path : require("assets/img/mike.jpg").default)}/>
              </div>
            </div>
          </Row>
        </div>
        {/*<div className={"col-12 d-flex justify-content-center align-items-center " + more}>
                  <div className="text-center moreBtn" onClick={onShowAllmsg}>
                    メッセージをもっと見る
                  </div>
                </div>*/}
        <Card className="mb-0 col-12 px-1">
          <CardBody className="px-0 py-0">
            <div className={"col-12 px-0 py-2 px-2 " + (more == 'moreShow' ? 'msg_list1' : 'msg_list')}>
              {
                messages && (
                  messages.map((item, idx) => 
                    renderMsgs(item, idx)
                  )
                )
              }
            </div>
            <div className="row px-0 mx-0 align-items-center border-top" style={{height:'60px', marginBottom:'10px', paddingTop:'5px'}}>
              <div className="col-11 px-1 d-flex align-items-center">
                <input type="text" className="col-12 p-2" value={msg} onFocus={(e) => onSetShowFlag(e)} onChange={onChangeMsg} style={{borderRadius:'10px',resize: 'none', border:'1px solid #999'}} />
              </div>
              <div className="col-1 px-1 d-flex align-items-center justify-content-center" >
                <i className="fa fa-paper-plane" onClick={onSendMsg} style={{fontSize:'24px', color:'rgb(3, 115, 202)'}}></i>
              </div>
            </div>
          </CardBody>
        </Card>

      </div>
    </>
  );
}

export default ChatRoom;
