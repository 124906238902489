/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from "reactstrap";

import routes from "routes.js";
import routesCoach from "routesCoach.js";
import noSidebarCoachRoutes from "noSidebarCoachRoutes.js";
import noSidebarRoutes from "noSidebarRoutes.js";

import { useHistory } from 'react-router-dom';

let axios = require('axios');
// let Pusher = require('pusher-js');
// let channel5 = null;
let URL_API = "https://xs458763.xsrv.jp/api/";

function Header(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();
  const [msgCnt, setMsgCnt] = React.useState(0);
  const history = useHistory();

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }

  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };
  const getBrand = () => {
    // let brandName = "Default Brand";
    let brandName="";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    noSidebarRoutes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    routesCoach.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    noSidebarCoachRoutes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  const onToMessagePage = (e) => {
    if(se['userkind'] == 'coach')
      history.push('/coach/chatMembers');
    else
      history.push('/admin/members');
  }
  React.useEffect(() => {
    // if(channel5){
    //   channel5.unbind();
    //   channel5 = null;
    // }

    // Pusher.logToConsole = true;
    // var pusher = new Pusher('6ecccbbacc37f7103bdb', {
    //     cluster: 'eu'
    // });
    // // console.log('my-receive-msg-'+se['id']);
    // channel5 = pusher.subscribe('my-'+se['id']);
    // channel5.bind('my-event', function(data) {
    //   // console.log(data);
    //   setMsgCnt(data['data']['cnt']);
    // });
    window.addEventListener("resize", updateColor.bind(this));
    // axios.get(URL_API+"getMCnt", {
    //   params: {
    //     id : se['id']
    //   }
    // })
    // .then(function (response) {
      
    // }).catch(
    //   error => {
    //     if(error.response){
    //       console.log(error.response.data);
    //     };
    //   }
    // ); 
    // return () => {
    //   channel5.unbind();
    // }
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);
  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        props.location.pathname.indexOf("full-screen-maps") !== -1
          ? "dark"
          : color
      }
      expand="lg"
      className={
        props.location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top NavBg "
          : "navbar-absolute fixed-top NavBg "
      }
    >
      <Container fluid className="d-flex justify-content-between mr-2">
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" style={{background:'white'}}/>
              <span className="navbar-toggler-bar bar2" style={{background:'white'}} />
              <span className="navbar-toggler-bar bar3" style={{background:'white'}} />
            </button>
          </div>
          <NavbarBrand>{getBrand()}</NavbarBrand>
        </div>
        {/*<div>
                  <i className="fa fa-envelope" aria-hidden="true" style={{color:'#d1d3e2', 'fontSize':'20px'}} onClick={(e) => onToMessagePage(e)}></i>
                  {
                    msgCnt > 0 && (
                      <span className="msgCnt">{msgCnt}</span>)
                  }
                </div>*/}
      </Container>
    </Navbar>
  );
}

export default Header;
