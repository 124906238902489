/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";
import DatePicker from 'react-mobile-datepicker';
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

// add new codes
import { useHistory } from 'react-router-dom';

import "assets/css/diary.css";
// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
function lastday(y,m){
  return  new Date(y, m +1, 0).getDate();
}
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
function Evaluation() {

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }  
  
  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

  var date = new Date();  
  // const [aPlayers, setAPlayers] =useState([]);
  if(!window.sessionStorage.getItem('today'))
    window.sessionStorage.setItem('today', date);
  const [todayVDate, setTodayVDate] =  useState(month[date.getMonth()]+" "+date.getFullYear());
  const [today, setToday] =  useState(date);
  const [sToday, setSToday] =  useState(date);

  const [datas, setDatas] = useState([]);
  const [dateConfig, setDateConfig] = useState({
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: 'MM',
        caption: 'Mon',
        step: 1,
    }
  });

  const [modalIsOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  // const [isShowCmt, setIsShowCmt] = useState('');
  // const [isEditCmt, setIsEditCmt] = useState(false);
  // const [players, setPlayers] = useState(JSON.parse(window.sessionStorage.getItem('players')));
  const coach = JSON.parse(window.sessionStorage.getItem("coach"));
  const history = useHistory();

  const setStateDatas = (d_datas) => {
    let datas = [];
    let days = lastday(today.getFullYear(), today.getMonth());
    for(var i=1;i<=days;i++){
      let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
      let d = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+i);
      if(!isValidDate(d)){
        d = new Date();
        setToday(d);
        sessionStorage.setItem("today", d);
      }
      if(d_datas[key]){
        datas.push({'dtitle':i+' '+ weekday[d.getDay()], w:d.getDay(), key:key, v:d_datas[key]});
      }
      else
        datas.push({'dtitle':i+' '+ weekday[d.getDay()], w:d.getDay(), key:key, v:{}});
    }
    setDatas(datas);
  } 

  function openModal(f, fname) {
    setIsOpen(true);
  }

  const selectDate = (time) => {
    setToday(time);
    setSToday(time);
    setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
    window.sessionStorage.setItem('today', time);
    setIsOpen(false);
    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  const renderStarData = (item, num) => {
    
    // console.log(item);
    let dArr = [];
    for(let i=1;i<=num;i++){
      dArr.push({n:i, sel:(i<=item ? 'gold':'noGold')});
    }
    // setRenderData(rd);
    return dArr;
  }

  const renderItem = (data, item) => {
    // console.log(p, k);

    if(data.v['item_value']){
      let item_value = JSON.parse(data.v['item_value']);
      if(item_value && (typeof item_value == 'object') && item_value != {}){
        item_value[item.id] = isNaN(Number(item_value[item.id])) ? 0 : item_value[item.id];
        return (
          renderStarData(item_value[item.id], 5).map((sItem, i) => 
            <i key={i} className={'fa fa-star p-0 '+ sItem.sel} ></i>
          )
        )
      }
    }else{
      return (
      <>
        <i className={'fa fa-star p-0 noGold'}></i>
        <i className={'fa fa-star p-0 noGold'}></i>
        <i className={'fa fa-star p-0 noGold'}></i>
        <i className={'fa fa-star p-0 noGold'}></i>
        <i className={'fa fa-star p-0 noGold'}></i>
      </>
      )
    }
  }

  const formatCheckData2 = (data) => {
    let comment = "";
    if(data.v && data.v != {} && data.v['comment']){
      comment = data.v['comment'];
    }
    return (
      <>
        <div className=' col-12 cmtText'>{comment}</div>        
      </>
    )
  }

  const formatCheckData1 = (data) => {

      return items.map((item, index) => (
        <div key={index} className="d-flex py-1">
          <div className="col-7 pl-0 pr-1">{item.title}</div>
          <div className="col-5 px-0 d-flex justify-content-between">
            {
              renderItem(data, item)
            }
          </div>
        </div>
      ))
  }

  const renderItemData1 = (data, idx) => {
    return (
      <div key={idx} className="border-bottom">        
        <div className="py-1 col-12 px-0">
          <div className={"col-12 px-1 d-flex align-items-center font-weight-bolder " + (data.w==0 ? 'sun' : (data.w == 6 ? 'sat' : ''))}>
            {data.dtitle}
          </div>
          <Col className="col-12 px-1 evalItems">                      
            {
              formatCheckData1(data)
            }             
          </Col>
        </div>
          {
            formatCheckData2(data)
          }  
      </div>
    )
  }

  useEffect(() => {    
    // add new codes diaryData202201
    if(window.sessionStorage.getItem('EvaluationDatas') && window.sessionStorage.getItem('EvaluationDatas') != 'undefined')
      setStateDatas(JSON.parse(window.sessionStorage.getItem('EvaluationDatas'))); 

    axios.get(URL_API+"getEvalItemAPI", {
      params: {
        c_id: coach['id'],
      }
    })
    .then(function (response) {  
      window.sessionStorage.setItem('evalItems', JSON.stringify(response.data));

      setItems(response.data);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 

    axios.get(URL_API+"getEvalDatasAPI", {
      params: {
        seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-01', 
        p_id: se['id'],
      }
    })
    .then(function (response) {  
      // window.sessionStorage.setItem('EvaluationDatas', JSON.stringify(response.data));
      console.log(response.data);
      setStateDatas(response.data);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 

  }, [today]);
  
  // console.log(renderData);
  return (
    <>
      <DatePicker
        dateConfig={dateConfig}
        value={sToday}
        isOpen={modalIsOpen}
        onSelect={selectDate}
        onCancel={handleCancel} />

      <div className="content px-0 pb-0">
        <Row className="px-0 mx-0">
          <Col md="12" className="px-0">
            <Card className="mb-0">
            <CardBody className="seldate" id='seldate' style={{color:'white'}}>
                <Row>
                  <Col className="col-12 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ym')} >
                    {todayVDate}
                  </Col>
                </Row>
            </CardBody>

            <CardBody className="px-2 attend_list">              
              {
                datas.map((data, index) => (
                    renderItemData1(data, index)
              ))}
            </CardBody>
            </Card>
          </Col>          
        </Row>
      </div>
    </>
  );
}

export default Evaluation;