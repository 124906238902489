import React, { Component } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import "assets/css/chart.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import dataTypes from "views/diary/dataType.js";


let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}
function getMaxV(item){
	for(let i=0;i<dataTypes.basic.length;i++){
		if(dataTypes.basic[i].id == item.data_type)
			return dataTypes.basic[i].max;
	} 
	return 5;
}
function DiaryChart3(){

	let session = JSON.parse(window.sessionStorage.getItem('sessions'));
	if(!session)
		window.location = "/";
	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const [flag, setFlag] 						= useState(0);
	const [startNumber, setStartNumber]         = useState(0);
    const [endNumber, setEndNumber]             = useState(0);
	const [categories, setCategories] 			= useState([]);
	const [data, setData] 						= useState([]);
	const [data2, setData2] 					= useState([]);
	const [todayDate, setTodayDate] 			= useState();
	const [seriesName, setSeriesName] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['title'] + "("+JSON.parse(sessionStorage.getItem('selectedItem'))['i_unit']+")" || '');
	const [fname, setFName] 					= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['id'] || 0);
	const [chartType, setChartType] 			= useState('line');
	const [chart_logo, setChart_logo] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['i_icon'] || '');
	const [maxNum, setMaxNum] 					= useState(getMaxV(JSON.parse(sessionStorage.getItem('selectedItem'))) || 5);
	const tooltipEnabled  						= false;
	const [showDate, setShowDate] 				= useState();
	const [xData, setXData] 					= useState(1);
	// const [yData, setYData] 					= useState(0);
	// const [showInputV, setShowInputV] 			= useState('hide');
	// const [showDataV, setShowDataV] 			= useState('show');
	// const [showInputBtn, setShowInputBtn] 		= useState('hide');
	// const [showInputBtnf, setShowInputBtnf] 	= useState(false);
	const [isModal, setIsModal] 				= useState('hide');
	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [modalIsOpen, setIsOpen] 				= React.useState(false);
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	// const [getKey, setGetKey] = useState((new Date(sessionStorage.getItem('today'))).getFullYear()+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));
	const [dateConfig, setDateConfig] = useState({
                    'year': {
                        format: 'YYYY',
                        caption: 'Year',
                        step: 1,
                    },
                    'month': {
                        format: 'MM',
                        caption: 'Mon',
                        step: 1,
                    }
                });

	const history = useHistory();

	const openModal = (f) => {
	    setIsOpen(true);
	    setIsModal('_hide');
	    if(f == 'ymd'){
	      setDateConfig({
	            'year': {
	                format: 'YYYY',
	                caption: 'Year',
	                step: 1,
	            },
	            'month': {
	                format: 'MM',
	                caption: 'Mon',
	                step: 1,
	            }
	        });
	    }
	}	

	const selectDate = (time) => {
	    setToday(time);
	    setTodayDate(time.getDate());
	    setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	    setIsOpen(false);
	    // setGetKey(time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	}

	const handleCancel = () => {
	    setIsOpen(false);
	}

	const handleClick = (chart) => {
		var x = chart.point.x;
		var y = chart.point.y;
		// console.log(x);
		// setYData(data[x]);
		setStartNumber(data[x]);
		setEndNumber(data2[x]);
		// if(flag == 0){
			let catg = categories[x];
			let md = catg.split('/');
			// let mon = today.getMonth() + 1;
			const td = new Date(today.getFullYear()+"-"+md[0]+"-"+md[1]);
			let day = td.getDay();
			setShowDate(md[0]+' / '+md[1] + ' ' + weekday[day]);

		let key = today.getFullYear()+"-"+("0"+md[0]).slice(-2)+"-"+("0" + md[1]).slice(-2);
		let ds = JSON.parse(window.sessionStorage.getItem('diaryData'));

		if(ds[key] && ds[key][fname]){
			setStartNumber(ds[key][fname].start);
			setEndNumber(ds[key][fname].end);
		}else{
			setStartNumber(0);
			setEndNumber(0);
		}
		// }else{
			// setShowDate(month[categories[x]-1]);
		// }
		setXData(x);
		setIsModal('show');
	};

	const noHandleClick = (chart) => {
		setIsModal('_hide');
	}

	const onShowChart = (f) => {
		setIsModal('hide');	
		setFlag(f);
	}

	const onSwitchChart = (c) => {
		setChartType(c);
		setIsModal('_hide');
	}

	const onChangeNumber = (event, i) => {
		let s = startNumber;
		let e = endNumber
		if(i==1){
		  	setStartNumber(event.target.value);
		  	s = event.target.value;
		}else{
		  	setEndNumber(event.target.value);
		  	e = event.target.value;
		}
		let catg = categories[xData];
		let md = catg.split('/');
		let setDataDate = today.getFullYear()+'-'+md[0]+'-' + md[1];
		let session = window.sessionStorage.getItem('sessions'); 
		let se = JSON.parse(session);
		let vv = {start:s,end:e};
		axios.get(URL_API+"setDiaryItemData", {
	      params: {
	        seldate: setDataDate, 
	        user: se['id'],
	        item_id: fname,
	        v:vv
	      }
	    })
	    .then(function (response) {
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
	  		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
	      	setStateDatas(response.data.data);
	    }).catch(
	      error => {
	        if(error.response){
	          console.log(error.response.data);
	        };
	      }
	    ); 
	}

	const onBeforeData = (e) => {
		onGetData(xData-1);
	}

	const onNextData = (e) => {
		onGetData(xData+1);
	}

	const onGetData = (i) => {
		if(data[i] != undefined){
			// setYData(data[i]);
			setStartNumber(data[i]);
			setEndNumber(data2[i]);
			let catg = categories[i];
			let md = catg.split('/');
			// let mon = today.getMonth() + 1;
			const td = new Date(today.getFullYear()+"-"+md[0]+"-"+md[1]);
			let day = td.getDay();
			setShowDate(md[0]+' / '+md[1] + ' ' + weekday[day]);
			setXData(i);
		}
	}

	const showList = (e) => {
		if(session.userkind == 'coach')
			history.push('../coach/diaryList3');
		else
	    	history.push('../admin/diaryList3');
	};

	const toPage = (e, pname) => {
		if(session.userkind == 'coach' && pname == 'diary'){
			history.push('../coach/'+pname);
			return;
		}
		history.push('../admin/'+pname);
	}

	const setStateDatas = (d_datas) => {
		var categs = [];
		var dats   = [];
		var dats2  = [];
		let y = today.getFullYear();
		let m =today.getMonth();
		// console.log(flag, fname);
		if(flag == 0){
			let m =today.getMonth();
			let days = lastday(y, m);
			if(d_datas == null){
				for(var i=1;i<=days;i++){
					categs.push((today.getMonth()+1)+'/'+i);
					dats.push(0);
					dats2.push(0);
				}
			}else{
				for(var i=1;i<=days;i++){
					let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
					categs.push((today.getMonth()+1)+'/'+i);
					// console.log(key, d_datas[key]);
					if(d_datas[key]){
						if(d_datas[key][fname]){
							let s = d_datas[key][fname].start;
							if(s)
								dats.push(isNaN(Number(s)) ? 0 : Number(s));
							else
								dats.push(0);
							let e = d_datas[key][fname].end;
							if(e)
								dats2.push(isNaN(Number(e)) ? 0 : Number(e));
							else
								dats2.push(0);
						}else{
							dats.push(0);
							dats2.push(0);
						}
					}else{
						dats.push(0);
						dats2.push(0);
					}
				}
			}
		}else if(flag == 1){
			let mon = m + 1;
			let m1 = mon % 3 == 0 ? mon - 2 : ( mon % 3 == 1 ? mon : mon - 1);
			let m2 = m1 + 1;
			let m3 = m1 + 2;
			let ms = [m1, m2, m3];
			if(d_datas == null){
				for(var i = 0; i<ms.length;i++){
					let days = lastday(y, ms[i]-1);
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						dats.push(0);
						dats2.push(0);
					}
				}
			}else{
				for(var i = 0; i<ms.length;i++){
					let days = lastday(y, ms[i]-1);
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						let key = today.getFullYear()+"-"+("0"+ms[i]).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							if(d_datas[key][fname]){
								let s = d_datas[key][fname].start;
								if(s)
									dats.push(isNaN(Number(s)) ? 0 : Number(s));
								else
									dats.push(0);
								let e = d_datas[key][fname].end;
								if(e)
									dats2.push(isNaN(Number(e)) ? 0 : Number(e));
								else
									dats2.push(0);
							}else{
								dats.push(0);
								dats2.push(0);
							}
						}else{
							dats.push(0);
							dats2.push(0);
						}
					}
				}
			}
		}else{
			if(d_datas == null){
				for(var i = 0; i<12;i++){
					let days = lastday(y, i);
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						dats.push(0);
						dats2.push(0);
					}
				}
			}else{
				for(var i=0;i<12;i++){
					let days = lastday(y, i);
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						let key = today.getFullYear()+"-"+("0"+(i+1)).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							if(d_datas[key][fname]){
								let s = d_datas[key][fname].start;
								if(s)
									dats.push(isNaN(Number(s)) ? 0 : Number(s));
								else
									dats.push(0);
								let e = d_datas[key][fname].end;
								if(e)
									dats2.push(isNaN(Number(e)) ? 0 : Number(e));
								else
									dats2.push(0);
							}else{
								dats.push(0);
								dats2.push(0);
							}
						}else{
							dats.push(0);
							dats2.push(0);
						}
					}
				}
			}
		}
		// console.log(categs, dats);
		setCategories(categs);
		setData(dats);
		setData2(dats2);
	}

	const chartOptions = {
		xAxis: {
  			categories: categories,
  			title:'',
  			labels:{
  				enabled:false
  			}
  		},
  		yAxis:{
  			title:""
  		},
  		series: [
  			{
	          	type: chartType,
	          	name: '始める',
	          	color: '#0071ce',
	          	data: data,
	        },
	        {
	          	type: chartType,
	          	name: '終わり',
	          	color: '#ff671b',
	          	data: data2,
	        }
		],
		credits: {
    		enabled: false
  		},
		tooltip: {
	        shared: true,
	        useHTML: true,
	        headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
	        pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
	            '<td style="text-align: right"><b>{point.y}</b></td></tr>',
	        footerFormat: '</table>',
	        enabled: tooltipEnabled,
	    },
		title:{
			text:''
		},
		plotOptions:{
			series : {
				point: {
		          events: {
		            click: handleClick.bind(this),
		          },
		        },
			},
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},
		},
		chart:{
			events:{
				click:noHandleClick.bind(this)
			}
		}
	}

	useEffect(() => {
		// console.log(typeof today);
		// let session = window.sessionStorage.getItem('sessions'); 
	 //    let se = JSON.parse(session);
	    if(!session){
	      	window.location = '/';
	    }

	    if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
	        setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	    }

	    let p = session;
	   	if(session.userkind == 'coach'){
			p = JSON.parse(window.sessionStorage.getItem('player')); 
			if(!p){
			  p = JSON.parse(window.sessionStorage.getItem('players'))[0];
			}
		}
	   	axios.get(URL_API+"getDiaryAPI", {
			params: {
				seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
				user: p['id'],
			}
	    })
	    .then(function (response) {		      
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
	      	setStateDatas(response.data.data);
	    }).catch(
	      	error => {
	        	if(error.response){
	          		console.log(error.response.data);
	        	};
	      	}
	    );


  	}, [flag, today]);
	return (
		<>
			<DatePicker
		        dateConfig={dateConfig}
		        value={today}
		        isOpen={modalIsOpen}
		        onSelect={selectDate}
		        onCancel={handleCancel} />	
			<div className="content px-0">
        		<Row className="px-0 mx-0">
          			<Col md="12" className="px-0">
            			<Card>
            				<CardBody className="seldate" id='seldate' style={{color:'white'}}>
					            <Row>
					            	<Col className="col-2 d-flex justify-content-center align-items-center">
					            		<i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toPage(event, 'diary')}></i>
					            	</Col>
					              	<Col className="col-8 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ymd')} >
					                	{todayVDate}
					             	</Col>
					             	<Col className="col-2 d-flex justify-content-center">
					             	</Col>
					            </Row>
					        </CardBody>   
							<div className="chart_body">
								<div className="row col-12 px-0 mx-0 py-1 justify-content-between align-items-center">
									<div className="col-9 px-0 d-flex align-items-center">
										<div className="d-flex col-3 px-0 justify-content-center align-items-center">
											<img src={chart_logo} className="chart_list_logo"/>
										</div>
										<div className="col-9 px-0 justify-content-start align-items-center">
											{seriesName}
										</div>
									</div>
									<div className="d-flex col-3 px-0 justify-content-center align-items-center chartList">
										<i className="fas fa-chart-line" style={{marginRight:'5px'}}></i>
          								<i className="fas fa-list" onClick={(event) => showList(event)}></i>
									</div>
								</div>
							</div>
				      		<div>
				        		<HighchartsReact
				          			highcharts={Highcharts}
				          			options={chartOptions}
				          			handleClick={handleClick}
				        		/>

				      			<div className={isModal + ' py-3 px-2 showDataDiv'}>
									<div className={'show_body'}>						
										<div className={'m-header mb-1'}>
											<div>{showDate}</div>
										</div>
										<div className="m-body row col-12 px-0 mx-0 align-items-center">
											<i className={"col-2 fas fa-caret-left fa-fw fa-2x pointer"} onClick={(event) => onBeforeData(event)}></i>
											<div className="col-8 row px-0 mx-0">
												<div className="col-12 d-flex px-1">
									                <label className="col-3 px-1 m-0 d-flex align-items-center">A : </label>
									                {
									                	session.userkind == 'player' ? (
									                		<input className="col-9 px-1" type="number" id="sValue" value={startNumber} onChange={(event) => onChangeNumber(event, 1)}/>) : startNumber
									            	}
									              </div>
									              <div className="col-12 d-flex px-1 mt-1">
									                <label className="col-3 px-1 m-0 d-flex align-items-center">B : </label>
									                {
									                	session.userkind == 'player' ? (
									                		<input className="col-9 px-1" type="number" id="eValue" value={endNumber} onChange={(event) => onChangeNumber(event, 2)}/>) : endNumber
									                }
									              </div>
											</div>
											<i className="col-2 fas fa-caret-right fa-fw fa-2x pointer" onClick={(event) => onNextData(event)}></i>
										</div>
										<div className="d-flex justify-content-center mt-2">
											<span><i className="fas fa-angle-down fa-fw fa-2x pointer" onClick={(event) => noHandleClick(event)}></i></span>
										</div>
									</div>		
								</div>
				      		</div>
				      		<div>      			
								<div className="d-flex justify-content-between align-items-center">
									<div className="ml-2">
									
									</div>
									<div className="d-flex">
										<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='line'?'active':'')} onClick={() => onSwitchChart('line')}><i className="fas fa-chart-line fa-fw fa-2x pointer"></i></span>
										<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='column'?'active':'')} onClick={() => onSwitchChart('column')}><i className="fas fa-chart-bar fa-fw fa-2x pointer"></i></span>
									</div>
								</div>
				      		</div>
				      		<div>
				      			<Row className="mx-0">
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==0?'active':'')} onClick={() => onShowChart(0)}>
				      					月
				      				</Col>
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==1?'active':'')} onClick={() => onShowChart(1)}>
				      					3か月
				      				</Col>
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==2?'active':'')} onClick={() => onShowChart(2)}>
				      					年
				      				</Col>
				      			</Row>
				      		</div>
				      	</Card>
				    </Col>
				</Row>
			</div>
      	</>
    )
}

export default DiaryChart3;