/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import { useHistory } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";



var ps;

function Sidebar(props) {
  // console.log(props);
  let session = window.sessionStorage.getItem("sessions");
  let se = JSON.parse(session);
  if(!se)
    window.location = '/';

  const history = useHistory();
  
  const sidebar = React.useRef();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  const toPage = (e) => {
    e.preventDefault();
    // history.push('../admin/diaryChart');
    let session = window.sessionStorage.getItem("sessions");
    let se = JSON.parse(session);
    if(!se)
      window.location = '/';
    let topUrl = se.userkind == 'player' ? '/admin/user-page' : (se.userkind == 'coach' ? '/coach/user' : '');
    window.location = topUrl;
  }
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a
          href="#"
          onClick={(e) => toPage(e)}
          className="simple-text logo-mini"
        >
          <div className="logo-img">
            <img id="userLogo" src={props.uLog} alt="react-logo" style={{borderRadius:'50%'}}/>
          </div>
        </a>
        <a
          href="#"
          onClick={(e) => toPage(e)}
          className="simple-text logo-normal"
          id="userName"
        >
          {props.uName}
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes.map((prop, key) => {
            return (
              <li
                className={
                  activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                }
                key={key}
              >
                <NavLink
                  to={prop.layout + prop.path}
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </NavLink>
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
