import React, { Component } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import "assets/css/chart.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import technicalImg from "assets/img/self/technical.png";
import graphImg from "assets/img/self/graph.png";
import listImg from "assets/img/self/list.png";



let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}

function initDiaryStorage(tDate){
  // var date = new Date();
  // console.log(tDate);
  let key = tDate.getFullYear()+("0"+(tDate.getMonth()+1)).slice(-2)+("0" + tDate.getDate()).slice(-2);
  if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
    let diaryData = JSON.parse(window.sessionStorage.getItem('diaryData'));
    if(!diaryData[key]){
      diaryData[key] = {
        abdominal: 0,
        eat1: 0,
        eat2: 0,
        eat3: 0,
        mental1: 0,
        mental2: 0,
        mental3: 0,
        physical1: 0,
        physical2: 0,
        physical3: 0,
        pushup: 0,
        sleepend: "00:00",
        sleepstart: "00:00",
        spine: 0,
        stretch: 0,
        studyend: "00:00",
        studystart: "00:00",
        tactical1: 0,
        tactical2: 0,
        tactical3: 0,
        technical1: 0,
        technical2: 0,
        technical3: 0,
        todayDate: ""
      };
      window.sessionStorage.setItem('diaryData', JSON.stringify(diaryData));
    }
    return diaryData;
  }else{
    let diaryData = {};
    diaryData[key] = {
      abdominal: 0,
      eat1: 0,
      eat2: 0,
      eat3: 0,
      mental1: 0,
      mental2: 0,
      mental3: 0,
      physical1: 0,
      physical2: 0,
      physical3: 0,
      pushup: 0,
      sleepend: "00:00",
      sleepstart: "00:00",
      spine: 0,
      stretch: 0,
      studyend: "00:00",
      studystart: "00:00",
      tactical1: 0,
      tactical2: 0,
      tactical3: 0,
      technical1: 0,
      technical2: 0,
      technical3: 0,
      todayDate: ""
    };
    window.sessionStorage.setItem('diaryData', JSON.stringify(diaryData));
    return diaryData;
  }
}

function getDateTime(d){
	var h = Math.floor(d / 3600000);
	var m = Math.floor((d / 3600000 - h) * 60);
	// console.log(("0"+(h)).slice(-2)+":"+("0" + m).slice(-2), m);
	return ("0"+(h)).slice(-2)+":"+("0" + m).slice(-2);
}

function TimeChart(){

	const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
	const [flag, setFlag] 						= useState(0);
	const [categories, setCategories] 			= useState([]);
	const [data, setData] 						= useState([]);
	const [data2, setData2] 					= useState([]);
	const [todayDate, setTodayDate] 			= useState();
	const [seriesName, setSeriesName] 			= useState('');
	const [fname, setFName] 					= useState(sessionStorage.getItem('chart_field_name') || 'technical1');
	const [fname2, setFName2]					= useState(sessionStorage.getItem('chart_field2_name') || 'technical2');
	const [chartType, setChartType] 			= useState('line');
	const [chart_logo, setChart_logo] 			= useState(sessionStorage.getItem('chart_intro_img') || technicalImg);
	// const [tooltipEnabled, setTooltipEnabled] 	= useState(false);

	const [showDate, setShowDate] 				= useState();

	const [xData, setXData] 					= useState(1);
	const [yData, setYData] 					= useState('NO DATA');
	const [showInputV, setShowInputV] 			= useState('hide');
	const [showDataV, setShowDataV] 			= useState('show');
	const [showInputBtn, setShowInputBtn] 		= useState('show');
	const [showInputBtnf, setShowInputBtnf] 	= useState(false);
	const [isModal, setIsModal] 				= useState('hide');
	const [modalIsOpen, setIsOpen] 				= React.useState(false);
	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	const [sToday, setSToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [timeFname, setTimeFname] 			= useState('studystart');
	const [dateType, setDateType] = useState('ymd');
	const [dateConfig, setDateConfig] = useState({
                    'year': {
                        format: 'YYYY',
                        caption: 'Year',
                        step: 1,
                    },
                    'month': {
                        format: 'MM',
                        caption: 'Mon',
                        step: 1,
                    },
                    'date': {
                        format: 'DD',
                        caption: 'Day',
                        step: 1,
                    }
                });

	const [vData1, setVData1] 					= useState('00:00');
	const [vData2, setVData2] 					= useState('00:00');

	const [vData, setVData] 					= useState('NO DATA');

	const [getKey, setGetKey] = useState((new Date(sessionStorage.getItem('today'))).getFullYear()+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));

	const history = useHistory();	

	const setStateDatas = (d_datas) => {
		// setDiaryData(d_datas);

		var categs = [];
		var datas = [];
		var datas2 = [];
		let y = today.getFullYear();
		let m =today.getMonth();
		if(flag == 0){
			let days = lastday(y, m);
			if(d_datas == null){
				for(var i=1;i<=days;i++){
					categs.push(i);
					datas.push(0);
					datas2.push(0);
				}
			}else{
				for(var i=1;i<=days;i++){
					let key = today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + i).slice(-2);
					categs.push((today.getMonth()+1)+'/'+i);
					if(d_datas[key]){
						let d = d_datas[key][fname].split(':');
						datas.push(Number(d[0]) * 60 * 60 *1000+ Number(d[1]) *  60 *1000);

						let d2 = d_datas[key][fname2].split(':');
						datas2.push(Number(d2[0]) * 60 * 60 *1000+ Number(d2[1]) *  60 *1000);
					}else{
						datas.push(0);
						datas2.push(0);
					}
				}
			}
		}else if(flag == 1){
			let mon = m + 1;
			let m1 = mon % 3 == 0 ? mon - 2 : ( mon % 3 == 1 ? mon : mon - 1);
			let m2 = m1 + 1;
			let m3 = m1 + 2;
			let ms = [m1, m2, m3];
			if(d_datas == null){
				for(var i = 0; i<ms.length;i++){
					let days = lastday(y, ms[i]-1);
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						datas.push(0);
						datas2.push(0);
					}
				}
			}else{
				for(var i = 0; i<ms.length;i++){
					let days = lastday(y, ms[i]-1);
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						let key = today.getFullYear()+("0"+ms[i]).slice(-2)+("0" + j).slice(-2);
						if(d_datas[key]){
							let d = d_datas[key][fname].split(':');
							datas.push(Number(d[0]) * 60 * 60 *1000+ Number(d[1]) *  60 *1000);

							let d2 = d_datas[key][fname2].split(':');
							datas2.push(Number(d2[0]) * 60 * 60 *1000+ Number(d2[1]) *  60 *1000);
						}else{
							datas.push(0);
							datas2.push(0);
						}
					}
				}
			}
		}else{
			if(d_datas == null){
				for(var i = 0; i<12;i++){
					let days = lastday(y, i);
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						datas.push(0);
						datas2.push(0);
					}
				}
			}else{
				for(var i=0;i<12;i++){
					let days = lastday(y, i);
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						let key = today.getFullYear()+("0"+(i+1)).slice(-2)+("0" + j).slice(-2);
						if(d_datas[key]){
							let d = d_datas[key][fname].split(':');
							datas.push(Number(d[0]) * 60 * 60 *1000+ Number(d[1]) *  60 *1000);

							let d2 = d_datas[key][fname2].split(':');
							datas2.push(Number(d2[0]) * 60 * 60 *1000+ Number(d2[1]) *  60 *1000);
						}else{
							datas.push(0);
							datas2.push(0);
						}
					}
				}
			}
		}

		setCategories(categs);
		setData(datas);
		setData2(datas2);
	}	

	const handleClick = (chart) => {
		var x = chart.point.x;
		var y = chart.point.y;
		// console.log(data[x], data2[x], data, data2);

		var startD = getDateTime(data[x]);
		var endD = getDateTime(data2[x]);
		// console.log(startD+" - "+endD);
		setVData(startD+" - "+endD);	
		let catg = categories[x];
		let md = catg.split('/');	
		const td = new Date(today.getFullYear()+"-"+md[0]+"-"+md[1]);
		let day = td.getDay();
		setVData1(startD);
		setVData2(endD);
		setShowDate(md[0]+' / '+md[1] + ' ' + weekday[day]);

		// if(flag == 0){
		// 	const d = new Date();
		// 	let mon = d.getMonth() + 1;

		// 	const td = new Date(d.getFullYear()+"-"+mon+"-"+categories[x]);
		// 	let day = td.getDay();

		// 	setShowDate(mon+' / '+categories[x] + ' ' + weekday[day]);
		// 	setVData1(startD);
		// 	setVData2(endD);
		// }else{
		// 	setShowDate(month[categories[x]-1]);
		// }
		setXData(x);
		setIsModal('show');
	};

	const noHandleClick = (chart) => {
		// setIsModal('hide');		
		// setShowInputV('hide');
		// setShowDataV('show');
		// setShowInputBtnf(false);
		setIsModal('_hide');		
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
	}

	const onShowChart = (f) => {
		setIsModal('hide');		
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
		setFlag(f);
		if(f == 0)
			setShowInputBtn('show');
		else
			setShowInputBtn('hide');
	}

	const onSwitchChart = (c) => {
		setChartType(c);
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
		noHandleClick();
	}

	const onInputData = (e, f) => {
		if(f){
			setShowInputV('show');
			setShowDataV('hide');
			e.target.focus();
		}else{
			setShowInputV('hide');
			setShowDataV('show');
		}
		setShowInputBtnf(f);
	}

	const onChangeData = (e, f_name, n) => {
		if(n==1)
			setVData1(e.target.value);
		else
			setVData2(e.target.value);

		// console.log(categories[xData]);
		let session = window.sessionStorage.getItem('sessions'); 
		let d = categories[xData].split('/');
		let setDataDate = today.getFullYear()+'-'+d[0]+'-' + d[1];
		let se = JSON.parse(session);
		axios.get(URL_API+"setChartData", {
	      	params: {
	        	fname: f_name, 
	        	v:e.target.value,
	        	seldate: setDataDate,
	        	flag:flag,
	        	user: se['id'],
	      	}
	    })
	    .then(function (response) {
	    	// setCategories(response.data.categories);
	    	// setData(response.data.data);	   
	    	let ds = JSON.parse(window.sessionStorage.getItem('diaryData'));
	    	let key = today.getFullYear()+("0"+d[0]).slice(-2)+("0" + d[1]).slice(-2);
	    	// console.log(key, f_name);
	    	// ds[key][fname] = e.target.value;
	    	if(!ds[key])
	    		ds = initDiaryStorage(new Date(today.getFullYear()+'-'+d[0]+'-' + d[1]));
	    	ds[key][f_name] = e.target.value;
	    	window.sessionStorage.setItem('diaryData', JSON.stringify(ds));
	   		setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   		setVData(vData1+' - '+vData2);
	    }); 
	}

	const setDiaryDataStorage = (f_name, v, setFuncName) => {
		// console.log(f_name, v, setFuncName);
		let catg = categories[xData];
		let md = catg.split('/');
		let session = window.sessionStorage.getItem('sessions'); 
		let setDataDate = today.getFullYear()+'-'+(md[0])+'-' + md[1];
		console.log(f_name, v, setFuncName);
		let se = JSON.parse(session);
		axios.get(URL_API+"setChartData", {
	      	params: {
	        	fname: f_name, 
	        	v:v,
	        	seldate: setDataDate,
	        	flag:flag,
	        	user: se['id'],
	      	}
	    })
	    .then(function (response) {
	    	// setCategories(response.data.categories);
	    	// setData(response.data.data);	   
	    	let ds = JSON.parse(window.sessionStorage.getItem('diaryData'));
	    	// let key = today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + todayDate).slice(-2);
	    	// console.log(key, f_name);
	    	let catg = categories[xData];
			let md = catg.split('/');
			let key = today.getFullYear()+("0"+md[0]).slice(-2)+("0" + md[1]).slice(-2);
	    	// ds[key][fname] = e.target.value;
	    	if(!ds[key])
	    		ds = initDiaryStorage(new Date(today.getFullYear()+'-'+md[0]+'-' + md[1]));
	    	ds[key][f_name] = v;
	    	window.sessionStorage.setItem('diaryData', JSON.stringify(ds));
	   		setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	    }); 
    }

	const onBeforeData = (e) => {
		onGetData(xData-1);
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
	}

	const onNextData = (e) => {
		onGetData(xData+1);
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
	}

	const onGetData = (i) => {
		if(data[i] != undefined){
			var startD = getDateTime(data[i]);
			var endD = getDateTime(data2[i]);
			setVData(startD+" - "+endD);
			setVData1(startD);
			setVData2(endD);
			// setYData(data[i] == 0 ? 'NO DATA' : data[i]);
			let catg = categories[i];
			let md = catg.split('/');
			// let mon = today.getMonth() + 1;
			const td = new Date(today.getFullYear()+"-"+md[0]+"-"+md[1]);
			let day = td.getDay();
			setShowDate(md[0]+' / '+md[1] + ' ' + weekday[day]);
			setXData(i);
			setTodayDate(i);
		}
	}

	const showChart1 = (title, fname1, fname2, img, e) => {
	    sessionStorage.setItem("chart_title", title);
	    sessionStorage.setItem("chart_field_name", fname1);
	    sessionStorage.setItem("chart_field2_name", fname2);
	    sessionStorage.setItem("chart_intro_img", img);
	    sessionStorage.setItem("today", today);
	    history.push('../admin/timeChart');
	  };

	const showList1 = (title, fname, fname2, img, e) => {
		// console.log(title, fname);
	    sessionStorage.setItem("chart_title", title);
	    sessionStorage.setItem("chart_field_name", fname);
	    sessionStorage.setItem("chart_field2_name", fname2);
	    sessionStorage.setItem("chart_intro_img", img);
	    sessionStorage.setItem("today", today);
	    // console.log(today);
	    history.push('../admin/timeList');
	};
	function openModal(f, fname, da) {
		noHandleClick();
	    setIsOpen(true);
	    setDateType(f);
	    if(f == 'ymd'){
	      setDateConfig({
	                    'year': {
	                        format: 'YYYY',
	                        caption: 'Year',
	                        step: 1,
	                    },
	                    'month': {
	                        format: 'MM',
	                        caption: 'Mon',
	                        step: 1,
	                    },
	                    'date': {
	                        format: 'DD',
	                        caption: 'Day',
	                        step: 1,
	                    }
	                });
	    }else{
	      setTimeFname(fname);
	      var t = da.split(':');
	      // console.log(eval(fname), t);
	      var tod = sToday;
	      tod.setHours(t[0]);
	      tod.setMinutes(t[1]);
	      var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
	      // console.log(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
	      setSToday(tods);
	      setDateConfig({
	        'hour': {
	          format: 'hh',
	          caption: 'Hour',
	          step: 1,
	        },
	        'minute': {
	          format: 'mm',
	          caption: 'Min',
	          step: 1,
	        },
	      });
	    }
	}

	// const selectDate = (time) => {
	//     setToday(time);
	//     setTodayDate(time.getDate());
	//     setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	//     setIsOpen(false);
	//     // setGetKey(time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	//     // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	// }

	const selectDate = (time) => {
	    if(dateType == 'ymd'){
	      setToday(time);
	      setSToday(time);
	      setTodayDate(time.getDate());
	      setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	      setGetKey(time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	    }else{
	      var h = time.getHours();
	      var m = time.getMinutes();
	      // console.log(timeFname, h, m);
	      var t = ("0"+h).slice(-2)+":"+("0" + m).slice(-2);
	      var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t);
	      // console.log(dateConfig);
	      setSToday(tods);
	      if(timeFname == 'studystart'){
	        setDiaryDataStorage(timeFname, t, 'setStudystart');
	        setVData1(t);
	      }else if(timeFname == 'studyend'){
	        setDiaryDataStorage(timeFname, t, 'setStudyend');
	        setVData2(t);
	      }else if(timeFname == 'sleepstart'){
	        setDiaryDataStorage(timeFname, t, 'setSleepstart');
	        setVData1(t);
	      }else if(timeFname == 'sleepend'){
	        setDiaryDataStorage(timeFname, t, 'setSleepend');
	        setVData2(t);
	      }
	      setVData(vData1+' - '+vData2);
	    }
	    setIsOpen(false);
	    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	  }

	const handleCancel = () => {
	    setIsOpen(false);
	}

	const chartOptions = {
		xAxis: {
  			categories: categories,
  			title:'',
  			labels:{
  				enabled:false
  			}
  		},
  		yAxis: {
  			title:"",
		    type: 'datetime',
		    dateTimeLabelFormats: {
		      minute: '%H:%S',
		    }
		  },
  		// yAxis:{
  		// 	title:"",
  		// 	labels: {
	   //        formatter: function () {
	   //          return `${this.value.toString().replace('.', ':')}`;
	   //        },
	   //      },
  		// },
  		series: [
  			{
	          	type: chartType,
	          	name: '始める',
	          	color: '#0071ce',
	          	data: data,
	        },
	        {
	          	type: chartType,
	          	name: '終わり',
	          	color: '#ff671b',
	          	data: data2,
	        },
      		// { 
      		// 	type: chartType,
      		// 	name: seriesName,
        // 		data: data        		
      		// }
		],
		credits: {
    		enabled: false
  		},
		tooltip: {
	        shared: true,
	        useHTML: true,
	        dateTimeLabelFormats:{
	        	minute:'%H:%M'
	        },
	        formatter: function() {

                var s = '';
                s += '<b>' + Highcharts.dateFormat('%H:%M', this.y) + '</b>';

                return s;
            },
	        // headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
	        // pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
	        //     '<td style="text-align: right"><b>{new Date(point.y)}</b></td></tr>',
	        // footerFormat: '</table>',
	        enabled: false
	    },
		title:{
			text:''
		},
		plotOptions:{
			series : {
				marker: {
		            symbol: 'circle',
		          },
				point: {
		          events: {
		            click: handleClick.bind(this),
		          },
		        },
			},
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},

		}
	}

	useEffect(() => {
		// console.log(typeof today);
		let session = window.sessionStorage.getItem('sessions'); 
	    let se = JSON.parse(session);
	    if(!se){
	      	window.location = '/';
	    }

	    // if(JSON.parse(window.sessionStorage.getItem('categories')) != null){
	    	
	    // }

	   	var chart_title = sessionStorage.getItem('chart_title');
	   	if(chart_title != null)
	   		setSeriesName(chart_title);
	   	else
	   		setSeriesName('');

	   	var chart_field_name = sessionStorage.getItem('chart_field_name');
	   	if(chart_field_name != null)
	   		setFName(chart_field_name);
	   	else
	   		setFName('technical1');

	   	var chart_intro_img = sessionStorage.getItem('chart_intro_img');
	   	if(chart_intro_img != null)
	   		setChart_logo(chart_intro_img);
	   	else
	   		setChart_logo(technicalImg);

	   	if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
			setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   	}
   		axios.get(URL_API+"getDiaryAPI", {
			params: {
				seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
				user: se['id'],
			}
	    })
	    .then(function (response) {		      
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data));
	      	setStateDatas(response.data);
	    }).catch(
	      	error => {
	        	if(error.response){
	          		console.log(error.response.data);
	        	};
	      	}
	    );

	    // axios.get(URL_API+"getChartData", {
	    //   	params: {
	    //     	fname: fname, 
	    //     	seldate: today.toISOString().slice(0, 10),
	    //     	flag:flag,
	    //     	user: se['id'],
	    //   	}
	    // })
	    // .then(function (response) {
	    // 	setCategories(response.data.categories);
	    // 	setData(response.data.data);
	    // }); 

  	}, [flag, today]);
	return (
		<>		
			<DatePicker
		        dateConfig={dateConfig}
		        value={sToday}
		        isOpen={modalIsOpen}
		        onSelect={selectDate}
		        onCancel={handleCancel} />	
			<div className="content">
        		<Row>
          			<Col md="12">
            			<Card>
            				<CardBody className="seldate" id='seldate' onClick={() => openModal('ymd', '', '')} >
					            <Row>
					              	<Col className="col-12 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}}>
					                	{todayVDate}
					             	</Col>
					            </Row>
					        </CardBody>
							<div className="chart_body">
								<div className="d-flex justify-content-between align-items-center">
									<div className="ml-2 d-flex align-items-center">
										<img src={chart_logo} className="mr-2"/> {seriesName}
									</div>
									<div className="d-flex mr-2">
										<img src={graphImg} width="35px" style={{marginRight:'5px'}} onClick={(event) => showChart1(seriesName, fname, fname2, chart_logo, event)}/>
										<img src={listImg} width="35px" onClick={(event) => showList1(seriesName, fname, fname2, chart_logo, event)}/>
									</div>
								</div>
							</div>
				      		<div>
				        		<HighchartsReact
				          			highcharts={Highcharts}
				          			options={chartOptions}
				          			handleClick={handleClick}
				        		/>
				        		<div className={isModal + ' py-3 px-2'} id="showDateDiv">
									<div className={'show_body'}>						
										<div className={'m-header mb-1'}>
											<div>{showDate}</div>
											<div className={'pos_abs pointer'} onClick={(event) => onInputData(event, !showInputBtnf)}>input</div>
										</div>
										<div className={'m-body'}>
											<span><i className={"fas fa-caret-left fa-fw fa-2x pointer"} onClick={(event) => onBeforeData(event)}></i></span>
											<div className={showDataV}>{vData}</div>
											<div className={showInputV}>
												<input type="text" style={{width:'120px', textAlign:'center'}} onClick={() => openModal('hm', fname, vData1)} readOnly="readonly" value={vData1} />     
                    							<input type="text" style={{width:'120px', textAlign:'center'}} onClick={() => openModal('hm', fname2, vData2)} readOnly="readonly" value={vData2} />
											</div>
											<span><i className={"fas fa-caret-right fa-fw fa-2x pointer"} onClick={(event) => onNextData(event)}></i></span>
										</div>
										<div className="d-flex justify-content-center">
											<span><i className="fas fa-angle-down fa-fw fa-2x pointer" onClick={(event) => noHandleClick(event)}></i></span>
										</div>
									</div>		
								</div>
				      			
				      		</div>
				      		<div>      			
								<div className="d-flex justify-content-between align-items-center">
									<div className="ml-2">
									</div>
									<div className="d-flex">
										<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='line'?'active':'')} onClick={() => onSwitchChart('line')}><i className="fas fa-chart-line fa-fw fa-2x pointer"></i></span>
										<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='column'?'active':'')} onClick={() => onSwitchChart('column')}><i className="fas fa-chart-bar fa-fw fa-2x pointer"></i></span>
									</div>
								</div>
				      		</div>
				      		<div>
				      			<Row className="mx-0">
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==0?'active':'')} onClick={() => onShowChart(0)}>
				      					月
				      				</Col>
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==1?'active':'')} onClick={() => onShowChart(1)}>
				      					3か月
				      				</Col>
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==2?'active':'')} onClick={() => onShowChart(2)}>
				      					年
				      				</Col>
				      			</Row>
				      		</div>
				      	</Card>
				    </Col>
				</Row>
			</div>
      	</>
    )
}

export default TimeChart;