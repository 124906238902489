/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// add new code 
import DiaryChart from "views/diary/DiaryChart.js";
import DiaryList from "views/diary/DiaryList.js";
import TimeList from "views/diary/TimeList.js";
import TimeChart from "views/diary/TimeChart.js";
import DiarySList from "views/diary/DiarySList.js";
import Setting from "views/diary/Setting.js";
import NewItem from "views/diary/NewItem.js";
import SelectIcon from "views/diary/SelectIcon.js";
import SelectDays from "views/diary/SelectDays.js";
import SelectType from "views/diary/SelectType.js";
import EditItem from "views/diary/EditItem.js";
import DiaryChart3 from "views/diary/DiaryChart3.js";
import DiaryList3 from "views/diary/DiaryList3.js";
import DiaryChart4 from "views/diary/DiaryChart4.js";
import DiaryList4 from "views/diary/DiaryList4.js";
import DiaryChart5 from "views/diary/DiaryChart5.js";
import DiaryList5 from "views/diary/DiaryList5.js";
import DiaryChart6 from "views/diary/DiaryChart6.js";
import DiaryList6 from "views/diary/DiaryList6.js";
import DiaryChart7 from "views/diary/DiaryChart7.js";
import DiaryList7 from "views/diary/DiaryList7.js";
import DiaryChart8 from "views/diary/DiaryChart8.js";
import DiaryList8 from "views/diary/DiaryList8.js";

import DiaryChart9 from "views/diary/DiaryChart9.js";
import DiaryList9 from "views/diary/DiaryList9.js";
import DiaryChart10 from "views/diary/DiaryChart10.js";
import DiaryList10 from "views/diary/DiaryList10.js";
import DiaryChart11 from "views/diary/DiaryChart11.js";
import ApList from "views/ApList.js";
import ChatRoom from "views/ChatRoom.js";
// end
var noSidebarRoutes = [ 
  {
    path: "/diaryChart",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart,
    layout: "/admin",
  },
  {
    path: "/diaryList",
    name: "日記リスト",
    icon: "nc-icon nc-share-66",
    component: DiaryList,
    layout: "/admin",
  },
  {
    path: "/timeChart",
    name: "日記リスト",
    icon: "nc-icon nc-share-66",
    component: TimeChart,
    layout: "/admin",
  },
  {
    path: "/timeList",
    name: "日記リスト",
    icon: "nc-icon nc-share-66",
    component: TimeList,
    layout: "/admin",
  },
  {
    path: "/diarySList",
    name: "日記リスト",
    icon: "nc-icon nc-share-66",
    component: DiarySList,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "設定",
    icon: "nc-icon nc-share-66",
    component: Setting,
    layout: "/admin",
  },
  {
    path: "/newItem",
    name: "New Item",
    icon: "nc-icon nc-share-66",
    component: NewItem,
    layout: "/admin",
  },
  {
    path: "/selectIcon",
    name: "New Item",
    icon: "nc-icon nc-share-66",
    component: SelectIcon,
    layout: "/admin",
  },
  {
    path: "/selectDays",
    name: "Select days",
    icon: "nc-icon nc-share-66",
    component: SelectDays,
    layout: "/admin",
  },
  {
    path: "/selectType",
    name: "Select a Data Type",
    icon: "nc-icon nc-share-66",
    component: SelectType,
    layout: "/admin",
  },
  {
    path: "/editItem",
    name: "Edit Item",
    icon: "nc-icon nc-share-66",
    component: EditItem,
    layout: "/admin",
  },
  {
    path: "/diaryChart3",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart3,
    layout: "/admin",
  },
  {
    path: "/diaryList3",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList3,
    layout: "/admin",
  },
  {
    path: "/diaryChart4",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart4,
    layout: "/admin",
  },
  {
    path: "/diaryList4",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList4,
    layout: "/admin",
  },
  {
    path: "/diaryChart5",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart5,
    layout: "/admin",
  },
  {
    path: "/diaryList5",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList5,
    layout: "/admin",
  },
  {
    path: "/diaryChart6",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart6,
    layout: "/admin",
  },
  {
    path: "/diaryList6",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList6,
    layout: "/admin",
  },
  {
    path: "/diaryChart7",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart7,
    layout: "/admin",
  },
  {
    path: "/diaryList7",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList7,
    layout: "/admin",
  },
  {
    path: "/diaryChart8",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart8,
    layout: "/admin",
  },
  {
    path: "/diaryList8",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList8,
    layout: "/admin",
  },
  {
    path: "/diaryChart9",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart9,
    layout: "/admin",
  },
  {
    path: "/diaryList9",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList9,
    layout: "/admin",
  },
  {
    path: "/diaryChart10",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart10,
    layout: "/admin",
  },
  {
    path: "/diaryList10",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList10,
    layout: "/admin",
  },
  {
    path: "/diaryChart11",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart11,
    layout: "/admin",
  }, 
  {
    path: "/apList",
    name: "格言集",
    icon: "nc-icon nc-bullet-list-67",
    component: ApList,
    layout: "/admin",
  },
  {
    path: "/chatRoom",
    name: "チャット",
    icon: "nc-icon nc-chat-33",
    component: ChatRoom,
    layout: "/admin",
  },  
];
export default noSidebarRoutes;
