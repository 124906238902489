import React, { Component } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import "assets/css/chart.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y, m){
	return  new Date(y, m +1, 0).getDate();
}

function getDateTime(d){
	var h = Math.floor(d / 3600000);
	var m = Math.floor((d / 3600000 - h) * 60);
	var s = Math.round(((d / 3600000 - h) * 60 - m) * 60);
	// console.log(("0"+(h)).slice(-2)+":"+("0" + m).slice(-2), m);
	return ("0"+(h)).slice(-2)+":"+("0" + m).slice(-2)+"`"+("0" + s).slice(-2);
}

function checkTimeNum(t, f){
    if(Number(t) > f)
      return f;
    else
      return Number(t);
}

function DiaryChart8(){

	let session = JSON.parse(window.sessionStorage.getItem('sessions'));
	if(!session)
		window.location = '/';
	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const [flag, setFlag] 						= useState(0);
	const [categories, setCategories] 			= useState([]);
	const [data, setData] 						= useState([]);
	const [data2, setData2] 					= useState([]);
	const [todayDate, setTodayDate] 			= useState();
	const [seriesName, setSeriesName] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['title'] + "("+JSON.parse(sessionStorage.getItem('selectedItem'))['i_unit']+")" || '');
	const [fname, setFName] 					= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['id'] || 0);

	const [chartType, setChartType] 			= useState('line');
	const [chart_logo, setChart_logo] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['i_icon'] || '');

	const [showDate, setShowDate] 				= useState();

	const [xData, setXData] 					= useState(1);
	const [yData, setYData] 					= useState('NO DATA');
	const [isModal, setIsModal] 				= useState('hide');
	const [modalIsOpen, setIsOpen] 				= React.useState(false);
	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	const [sToday, setSToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [timeFname, setTimeFname] 			= useState('start');
	const [dateType, setDateType] 				= useState('ymd');
	const [dateConfig, setDateConfig] 			= useState({
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        },
        'month': {
            format: 'MM',
            caption: 'Mon',
            step: 1,
        }
    });

	const [vData1, setVData1] 					= useState('00:00`00');
	const [vData2, setVData2] 					= useState('00:00`00');

	const [getKey, setGetKey] = useState((new Date(sessionStorage.getItem('today'))).getFullYear()+'-'+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+'-'+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));

	const history = useHistory();	

	const setStateDatas = (d_datas) => {

		var categs = [];
		var datas = [];
		var datas2 = [];
		let y = today.getFullYear();
		let m = today.getMonth();
		if(flag == 0){
			let days = lastday(y, m);
			if(d_datas == null){
				for(var i=1;i<=days;i++){
					categs.push(i);
					datas.push(0);
					datas2.push(0);
				}
			}else{
				for(var i=1;i<=days;i++){
					let key = today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2);
					categs.push((today.getMonth()+1)+'/'+i);
					if(d_datas[key] && d_datas[key][fname]){
						// let d = d_datas[key][fname].start.split(':');
						let d = [];
						if(d_datas[key][fname].start){
							d = d_datas[key][fname].start.split(':');
						}
						if(isNaN(Number(d[0])))
							d[0] = 0;
						if(isNaN(Number(d[1])))
							d[1] = 0;
						if(isNaN(Number(d[2])))
							d[2] = 0;

						d[0] = checkTimeNum(d[0], 23);
					    d[1] = checkTimeNum(d[1], 59);
					    d[2] = checkTimeNum(d[2], 59);
						datas.push(Number(d[0]) * 60 * 60 *1000 + Number(d[1]) *  60 *1000 + Number(d[2]) * 1000);

						let d2 = [];
						if(d_datas[key][fname].end){
							d2 = d_datas[key][fname].end.split(':');
						}
						if(isNaN(Number(d2[0])))
							d2[0] = 0;
						if(isNaN(Number(d2[1])))
							d2[1] = 0;
						if(isNaN(Number(d2[2])))
							d2[2] = 0;

						d2[0] = checkTimeNum(d2[0], 23);
					    d2[1] = checkTimeNum(d2[1], 59);
					    d2[2] = checkTimeNum(d2[2], 59);
						datas2.push(Number(d2[0]) * 60 * 60 *1000 + Number(d2[1]) *  60 *1000 + Number(d2[2]) * 1000);
					}else{
						datas.push(0);
						datas2.push(0);
					}
				}
			}
		}else if(flag == 1){
			let mon = m + 1;
			let m1 = mon % 3 == 0 ? mon - 2 : ( mon % 3 == 1 ? mon : mon - 1);
			let m2 = m1 + 1;
			let m3 = m1 + 2;
			let ms = [m1, m2, m3];
			if(d_datas == null){
				for(var i = 0; i<ms.length;i++){
					let days = lastday(y, ms[i]-1);
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						datas.push(0);
						datas2.push(0);
					}
				}
			}else{
				for(var i = 0; i<ms.length;i++){
					let days = lastday(y, ms[i]-1);
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						let key = today.getFullYear()+'-'+("0"+ms[i]).slice(-2)+'-'+("0" + j).slice(-2);
						if(d_datas[key] && d_datas[key][fname]){
							let d = [];
							if(d_datas[key][fname].start){
								d = d_datas[key][fname].start.split(':');
							}
							if(isNaN(Number(d[0])))
								d[0] = 0;
							if(isNaN(Number(d[1])))
								d[1] = 0;
							if(isNaN(Number(d[2])))
								d[2] = 0;

							d[0] = checkTimeNum(d[0], 23);
						    d[1] = checkTimeNum(d[1], 59);
						    d[2] = checkTimeNum(d[2], 59);
							datas.push(Number(d[0]) * 60 * 60 *1000 + Number(d[1]) *  60 *1000 + Number(d[2]) * 1000);

							// let d2 = d_datas[key][fname].end.split(':');
							let d2 = [];
							if(d_datas[key][fname].end){
								d2 = d_datas[key][fname].end.split(':');
							}
							if(isNaN(Number(d2[0])))
								d2[0] = 0;
							if(isNaN(Number(d2[1])))
								d2[1] = 0;
							if(isNaN(Number(d2[2])))
								d2[2] = 0;

							d2[0] = checkTimeNum(d2[0], 23);
						    d2[1] = checkTimeNum(d2[1], 59);
						    d2[2] = checkTimeNum(d2[2], 59);
							datas2.push(Number(d2[0]) * 60 * 60 *1000 + Number(d2[1]) *  60 *1000 + Number(d2[2]) * 1000);
						}else{
							datas.push(0);
							datas2.push(0);
						}
					}
				}
			}
		}else{
			if(d_datas == null){
				for(var i = 0; i<12;i++){
					let days = lastday(y, i);
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						datas.push(0);
						datas2.push(0);
					}
				}
			}else{
				for(var i=0;i<12;i++){
					let days = lastday(y, i);
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						let key = today.getFullYear()+'-'+("0"+(i+1)).slice(-2)+'-'+("0" + j).slice(-2);
						if(d_datas[key] && d_datas[key][fname]){
							let d = [];
							if(d_datas[key][fname].start){
								d = d_datas[key][fname].start.split(':');
							}
							if(isNaN(Number(d[0])))
								d[0] = 0;
							if(isNaN(Number(d[1])))
								d[1] = 0;
							if(isNaN(Number(d[2])))
								d[2] = 0;

							d[0] = checkTimeNum(d[0], 23);
						    d[1] = checkTimeNum(d[1], 59);
						    d[2] = checkTimeNum(d[2], 59);
							datas.push(Number(d[0]) * 60 * 60 *1000 + Number(d[1]) *  60 *1000 + Number(d[2]) * 1000);

							let d2 = [];
							if(d_datas[key][fname].end){
								d2 = d_datas[key][fname].end.split(':');
							}
							if(isNaN(Number(d2[0])))
								d2[0] = 0;
							if(isNaN(Number(d2[1])))
								d2[1] = 0;
							if(isNaN(Number(d2[2])))
								d2[2] = 0;

							d2[0] = checkTimeNum(d2[0], 23);
						    d2[1] = checkTimeNum(d2[1], 59);
						    d2[2] = checkTimeNum(d2[2], 59);
							datas2.push(Number(d2[0]) * 60 * 60 *1000 + Number(d2[1]) *  60 *1000 + Number(d2[2]) * 1000);
						}else{
							datas.push(0);
							datas2.push(0);
						}
					}
				}
			}
		}

		setCategories(categs);
		setData(datas);
		setData2(datas2);
	}	

	const handleClick = (chart) => {
		var x = chart.point.x;
		var y = chart.point.y;

		// console.log(data, data2);

		let catg = categories[x];
		let md = catg.split('/');	
		const td = new Date(today.getFullYear()+"-"+md[0]+"-"+md[1]);
		let day = td.getDay();

		let key = today.getFullYear()+'-'+("0"+md[0]).slice(-2)+'-'+("0" + md[1]).slice(-2);
		let dd = JSON.parse(window.sessionStorage.getItem('diaryData'));
		if(dd[key] && dd[key][fname]){
			let st = dd[key][fname].start.split(':');
			setVData1(st[0]+":"+st[1]+"`"+st[2]);
			let e = dd[key][fname].end.split(':');
			setVData2(e[0]+":"+e[1]+"`"+e[2]);
		}else{
			setVData1('00:00`00');
			setVData2('00:00`00');
		}

		setShowDate(md[0]+' / '+md[1] + ' ' + weekday[day]);

		setXData(x);
		setIsModal('show');
	};

	const noHandleClick = (chart) => {
		setIsModal('_hide');
	}

	const onShowChart = (f) => {
		setIsModal('hide');
		setFlag(f);
	}

	const onSwitchChart = (c) => {
		setChartType(c);
		noHandleClick();
	}

	const onBeforeData = (e) => {
		onGetData(xData-1);
	}

	const onNextData = (e) => {
		onGetData(xData+1);
	}

	const onGetData = (i) => {
		if(data[i] != undefined){

			let catg = categories[i];
			let md = catg.split('/');

			const td = new Date(today.getFullYear()+"-"+md[0]+"-"+md[1]);
			let day = td.getDay();
			setShowDate(md[0]+' / '+md[1] + ' ' + weekday[day]);
			
			let key = today.getFullYear()+'-'+("0"+md[0]).slice(-2)+'-'+("0" + md[1]).slice(-2);
			let dd = JSON.parse(window.sessionStorage.getItem('diaryData'));
			
			if(dd[key] && dd[key][fname]){
				let ts = [0,0,0];
				if(dd[key][fname].start)
					ts = dd[key][fname].start.split(':');
				if(isNaN(Number(ts[0])))
					ts[0] = 0;
				if(isNaN(Number(ts[1])))
					ts[1] = 0;
				if(isNaN(Number(ts[2])))
					ts[2] = 0;

				ts[0] = checkTimeNum(ts[0], 23);
			    ts[1] = checkTimeNum(ts[1], 59);
			    ts[2] = checkTimeNum(ts[2], 59);
				setVData1(("0"+ts[0]).slice(-2)+":"+("0" + ts[1]).slice(-2)+"`"+("0" + ts[2]).slice(-2));
				let te = [0,0,0];
				if(dd[key][fname].end)
					te = dd[key][fname].end.split(':');
				if(isNaN(Number(te[0])))
					te[0] = 0;
				if(isNaN(Number(te[1])))
					te[1] = 0;
				if(isNaN(Number(te[2])))
					te[2] = 0;

				te[0] = checkTimeNum(te[0], 23);
			    te[1] = checkTimeNum(te[1], 59);
			    te[2] = checkTimeNum(te[2], 59);
				setVData2(("0"+te[0]).slice(-2)+":"+("0" + te[1]).slice(-2)+"`"+("0" + te[2]).slice(-2));
			}else{
				setVData1('00:00`00');
				setVData2('00:00`00');
			}

			// var startD = getDateTime(data[i]);
			// var endD = getDateTime(data2[i]);

			// setVData1(startD);
			// setVData2(endD);

			setXData(i);
			setTodayDate(i);
		}
	}

	const showList1 = (e) => {
	    sessionStorage.setItem("today", today);
	    if(session.userkind == 'coach')
	    	history.push('../coach/diaryList8');
	    else
	    	history.push('../admin/diaryList8');
	};
	function openModal(f, fk, da) {	    
	    if(f == 'ymd'){
	    	setIsOpen(true);
	    	setDateType(f);
	      	setDateConfig({
	            'year': {
	                format: 'YYYY',
	                caption: 'Year',
	                step: 1,
	            },
	            'month': {
	                format: 'MM',
	                caption: 'Mon',
	                step: 1,
	            }
	        });
	    }else{
	    	if(session.userkind == 'coach')
	    		return;
	    	setIsOpen(true);
	    	setDateType(f);
	    	setTimeFname(fk);
	    	console.log(da);
	      	var t = da.split(':');
	      	var s = t[1].split('`');

	      	if(isNaN(Number(t[0])))
				t[0] = 0;
			if(isNaN(Number(s[0])))
				s[0] = 0;
			if(isNaN(Number(s[1])))
				s[1] = 0;

			t[0] = checkTimeNum(t[0], 23);
		    s[0] = checkTimeNum(s[0], 59);
		    s[1] = checkTimeNum(s[1], 59);

	      	var tod = sToday;
	      	tod.setHours(t[0]);
	      	tod.setMinutes(s[0]);
	      	tod.setSeconds(s[1]);
	      	// var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+s[0]+":"+s[1]);
	      	console.log(tod);
	      	setSToday(tod);
	      	setDateConfig({
	        	'hour': {
	          		format: 'hh',
	          		caption: 'Hour',
	          		step: 1,
	        	},
	        	'minute': {
	          		format: 'mm',
	          		caption: 'Min',
	          		step: 1,
	        	},
		        'second': {
		          	format: 'ss',
		          	caption: 'Sec',
		          	step: 1,
		        }
	      	});
	    }
	}

	const selectDate = (time) => {
	    if(dateType == 'ymd'){
	      setToday(time);
	      setSToday(time);
	      setTodayDate(time.getDate());
	      setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	      setGetKey(time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	    }else{
	      	var h = time.getHours();
	      	var m = time.getMinutes();
	      	var s = time.getSeconds();

	      	var t = ("0"+h).slice(-2)+":"+("0" + m).slice(-2)+":"+("0" + s).slice(-2);

	      	var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t);

	      	setSToday(tods);

	    	// let session = window.sessionStorage.getItem('sessions'); 
	    	// let se = JSON.parse(session);

	      	let catg = categories[xData];
			let md = catg.split('/');	
			const setDataDate = today.getFullYear()+"-"+md[0]+"-"+md[1];

			let dd = JSON.parse(window.sessionStorage.getItem('diaryData'));
			let key = today.getFullYear()+'-'+("0"+md[0]).slice(-2)+'-'+("0" + md[1]).slice(-2);

			let vv = {start:'00:00:00', end:'00:00:00'};

			if(dd[key] && dd[key][fname]){
				if(timeFname == 'start'){
					vv['end'] = dd[key][fname]['end'];
					vv['start'] = t;
				}else{
					vv['end'] = t;
					vv['start'] = dd[key][fname]['start'];
				}
			}else{
				if(timeFname == 'start'){
					vv['start'] = t;
				}else{
					vv['end'] = t;
				}
			}
			let ts = ("0"+h).slice(-2)+":"+("0" + m).slice(-2)+"`"+("0" + s).slice(-2);
			if(timeFname == 'start'){
				setVData1(ts);
			}else{
				setVData2(ts);
			}

			axios.get(URL_API+"setDiaryItemData", {
		      params: {
		        seldate: setDataDate, 
		        user: session['id'],
		        item_id: fname,
		        v:vv
		      }
		    })
		    .then(function (response) {
		      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
		  		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
		      	setStateDatas(response.data.data);
		    }).catch(
		      error => {
		        if(error.response){
		          console.log(error.response.data);
		        };
		      }
		    ); 

	    }
	    setIsOpen(false);
	  }

	const handleCancel = () => {
	    setIsOpen(false);
	}

	const toPage = (e, pname) => {
		if(session.userkind == 'coach' && pname == 'diary'){
			history.push('../coach/'+pname);
			return;
		}
		history.push('../admin/'+pname);
	}

	const chartOptions = {
		xAxis: {
  			categories: categories,
  			title:'',
  			labels:{
  				enabled:false
  			}
  		},
  		yAxis: {
  			title:"",
		    type: 'datetime',
		    dateTimeLabelFormats: {
		      minute: '%H:%M:%S',
		    }
		  },
  		series: [
  			{
	          	type: chartType,
	          	name: '始める',
	          	color: '#0071ce',
	          	data: data,
	        },
	        {
	          	type: chartType,
	          	name: '終わり',
	          	color: '#ff671b',
	          	data: data2,
	        },
		],
		credits: {
    		enabled: false
  		},
		tooltip: {
	        shared: true,
	        useHTML: true,
	        dateTimeLabelFormats:{
	        	minute:'%H:%M:%S'
	        },
	        formatter: function() {

                var s = '';
                s += '<b>' + Highcharts.dateFormat('%H:%M:%S', this.y) + '</b>';

                return s;
            },
	        enabled: false
	    },
		title:{
			text:''
		},
		plotOptions:{
			series : {
				marker: {
		            symbol: 'circle',
		          },
				point: {
		          events: {
		            click: handleClick.bind(this),
		          },
		        },
			},
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},

		}
	}

	useEffect(() => {
		// console.log(typeof today);
		if(!session){
	      	window.location = '/';
	    }

	   	if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
			setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   	}

	   	let p = session;
	   	if(session.userkind == 'coach'){
			p = JSON.parse(window.sessionStorage.getItem('player')); 
			if(!p){
			  p = JSON.parse(window.sessionStorage.getItem('players'))[0];
			}
		}
   		axios.get(URL_API+"getDiaryAPI", {
			params: {
				seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
				user: p['id'],
			}
	    })
	    .then(function (response) {		      
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
      		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
	      	setStateDatas(response.data.data);
	    }).catch(
	      	error => {
	        	if(error.response){
	          		console.log(error.response.data);
	        	};
	      	}
	    );
  	}, [flag, today]);
	return (
		<>		
			<DatePicker
		        dateConfig={dateConfig}
		        value={sToday}
		        isOpen={modalIsOpen}
		        onSelect={selectDate}
		        onCancel={handleCancel} />	
			<div className="content px-0">
        		<Row className="px-0 mx-0">
          			<Col md="12" className="px-0">
            			<Card>
            				<CardBody className="seldate" id='seldate' style={{color:'white'}}>
					            <Row>
					            	<Col className="col-2 d-flex justify-content-center align-items-center">
					            		<i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toPage(event, 'diary')}></i>
					            	</Col>
					              	<Col className="col-8 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ymd', '', '')} >
					                	{todayVDate}
					             	</Col>
					             	<Col className="col-2 d-flex justify-content-center">
					             	</Col>
					            </Row>
					        </CardBody>
							<div className="chart_body">
								<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center">
									<div className="col-9 px-0 d-flex align-items-center">
										<div className="d-flex col-3 px-0 justify-content-center align-items-center">
											<img src={chart_logo} className="chart_list_logo"/>
										</div>
										<div className="col-9 px-0 justify-content-start align-items-center">
											{seriesName}
										</div>
									</div>
									<div className="d-flex col-3 px-0 justify-content-center align-items-center chartList">
										<i className="fas fa-chart-line" style={{marginRight:'5px'}}></i>
          								<i className="fas fa-list" onClick={(event) => showList1(event)}></i>
									</div>
								</div>
							</div>
				      		<div>
				        		<HighchartsReact
				          			highcharts={Highcharts}
				          			options={chartOptions}
				          			handleClick={handleClick}
				        		/>
				        		<div className={isModal + ' py-3 px-2'} id="showDateDiv">
									<div className={'show_body'}>						
										<div className={'m-header mb-1'}>
											<div>{showDate}</div>
										</div>
										<div className={'m-body row px-0 mx-0'}>
											<span className="col-2 px-0"><i className={"fas fa-caret-left fa-fw fa-2x pointer"} onClick={(event) => onBeforeData(event)}></i></span>
											<div className="col-8 px-0">
												<div className="col-12 px-0 row mx-0">
													<label className="col-3 px-0 m-0 d-flex align-items-center justify-content-center">A : </label>
													{
														session.userkind == 'player' ? (<span className="col-9 px-0 m-0 d-flex align-items-center" onClick={() => openModal('hm', 'start', vData1)}>{vData1}</span>) : vData1
													}													
												</div>
												<div className="col-12 px-0 row mx-0">  
													<label className="col-3 px-0 m-0 d-flex align-items-center justify-content-center">B : </label>
													{
														session.userkind == 'player' ? (<span className="col-9 px-0 m-0 d-flex align-items-center" onClick={() => openModal('hm', 'end', vData2)}>{vData2}</span>) : vData2
													}
	                    						</div>
	                    					</div>
											<span className="col-2 px-0"><i className={"fas fa-caret-right fa-fw fa-2x pointer"} onClick={(event) => onNextData(event)}></i></span>
										</div>
										<div className="d-flex justify-content-center">
											<span><i className="fas fa-angle-down fa-fw fa-2x pointer" onClick={(event) => noHandleClick(event)}></i></span>
										</div>
									</div>		
								</div>				      			
				      		</div>
				      		<div>      			
								<div className="d-flex justify-content-between align-items-center">
									<div className="ml-2">
									</div>
									<div className="d-flex">
										<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='line'?'active':'')} onClick={() => onSwitchChart('line')}><i className="fas fa-chart-line fa-fw fa-2x pointer"></i></span>
										<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='column'?'active':'')} onClick={() => onSwitchChart('column')}><i className="fas fa-chart-bar fa-fw fa-2x pointer"></i></span>
									</div>
								</div>
				      		</div>
				      		<div>
				      			<Row className="mx-0">
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==0?'active':'')} onClick={() => onShowChart(0)}>
				      					月
				      				</Col>
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==1?'active':'')} onClick={() => onShowChart(1)}>
				      					3か月
				      				</Col>
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==2?'active':'')} onClick={() => onShowChart(2)}>
				      					年
				      				</Col>
				      			</Row>
				      		</div>
				      	</Card>
				    </Col>
				</Row>
			</div>
      	</>
    )
}

export default DiaryChart8;