/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { useHistory } from 'react-router-dom';

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routesCoach.js";
import noSidebarCoachRoutes from  "noSidebarCoachRoutes.js";

import logo from "assets/img/mike.jpg";

var ps;
let Pusher = require('pusher-js');
let channel1 = null;
let notificationAlert;
function Dashboard(props) {
  let backgroundColor = React.useState("black");
  let activeColor = React.useState("info"); 

  const mainPanel = React.useRef();
  const location = useLocation();
  const history = useHistory();
  notificationAlert = React.useRef(); 

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }

  const uName = JSON.parse(window.sessionStorage.getItem('sessions'))['name'] || 'Nonaka';
  const [userLogo, setUserLogo] = React.useState(JSON.parse(window.sessionStorage.getItem('sessions'))['profile_photo_path'] == '' || JSON.parse(window.sessionStorage.getItem('sessions'))['profile_photo_path'] == null ? logo : "https://xs458763.xsrv.jp/uploads/" + JSON.parse(window.sessionStorage.getItem('sessions'))['profile_photo_path']);
  const [msgPlayerId, setMsgPlayerId] = useState(0);

  const onShowMsg = (e) => {
    let plys = JSON.parse(window.sessionStorage.getItem('players'));
    for(let i=0;i<plys.length;i++){
      if(plys[i].id == msgPlayerId)
        window.sessionStorage.setItem('player', JSON.stringify(plys[i]));
    }
    history.push('/coach/chatRoom');
  }

  React.useEffect(() => {

    ////notification
    if(channel1){
      channel1.unbind();
      channel1 = null;
    }
    Pusher.logToConsole = true;
    var pusher = new Pusher('6ecccbbacc37f7103bdb', {
        cluster: 'eu'
    });
    // console.log('my-receive-msg-'+se['id']);
    channel1 = pusher.subscribe('my-receive-msg-'+se['id']);
    channel1.bind('my-event', function(data) {
      setMsgPlayerId(data['sender_id']);
      // console.log(data);
      if(window.location.href.indexOf('coach/chatRoom') == -1){
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>
                <b>メッセージが来ました。</b>
              </div>
            </div>
          ),
          type: 'warning',
          icon: "nc-icon nc-bell-55",
          autoDismiss: 7
        };
        if(notificationAlert.current)
          notificationAlert.current.notificationAlert(options);
      }
    });

    ////notification

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
      channel1.unbind();
    };
  });
  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    
  }, [location]);
  
  return (
    <div className="wrapper">
      <NotificationAlert ref={notificationAlert}  className="col-12" onClick={(e) => onShowMsg(e)}/>
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
        uLog = {userLogo}
        uName = {uName}
      />
      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar {...props} />
        <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
          {noSidebarCoachRoutes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
        </Switch>
      </div>
      
    </div>
  );
}

export default Dashboard;
