import React, { Component } from 'react';
// import DatePicker from 'react-mobile-datepicker';
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useState, useEffect } from "react";
import Modal from 'react-modal';
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import dataTypes from "views/diary/dataType.js";

import "assets/css/chart.css";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0.25rem',
    width:'85%',
    borderRadius:'15px',
    fontWeight:'bold'
  },
};

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}
// function getMaxV(item){
// 	for(let i=0;i<dataTypes.basic.length;i++){
// 		if(dataTypes.basic[i].id == item.data_type)
// 			return dataTypes.basic[i].max;
// 	} 
// 	return 5;
// }
function Diary3(props){

	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	// const [flag, setFlag] 						= useState(0);
	const flag			 						= props.flag;
	const [startNumber, setStartNumber]         = useState(0);
    const [endNumber, setEndNumber]             = useState(0);
	const [categories, setCategories] 			= useState([]);
	const [data, setData] 						= useState([]);
	const [data2, setData2] 					= useState([]);
	const [todayDate, setTodayDate] 			= useState();
	const [seriesName, setSeriesName] 			= useState(props.sItem['title'] || '');
	const [fname, setFName] 					= useState(props.sItem['id'] || 0);
	const [chartType, setChartType] 			= useState('line');
	const [chart_logo, setChart_logo] 			= useState(props.sItem['i_icon'] || '');
	// const [maxNum, setMaxNum] 					= useState(getMaxV(JSON.parse(sessionStorage.getItem('selectedItem'))) || 5);
	// const tooltipEnabled  						= false;

	const today									= props.today;

	const [modalIsOpen, setIsOpen] 	= useState(false);
	const [fromTo, setFromTo]		= useState('');
	const [cnts, setCnts]			= useState(0);

	const [aAve, setAAve]			= useState(0);
	const [aMin, setAMin]			= useState(0);
	const [aMax, setAMax]			= useState(0);
	const [aMinDay, setAMinDay]		= useState('');
	const [aMaxDay, setAMaxDay]		= useState('');

	const [bAve, setBAve]			= useState(0);
	const [bMin, setBMin]			= useState(0);
	const [bMax, setBMax]			= useState(0);
	const [bMinDay, setBMinDay]		= useState('');
	const [bMaxDay, setBMaxDay]		= useState('');

	const [tAve, setTAve]			= useState(0);
	const [tMin, setTMin]			= useState(0);
	const [tMax, setTMax]			= useState(0);
	const [tMinDay, setTMinDay]		= useState('');
	const [tMaxDay, setTMaxDay]		= useState('');
	
	const handleClick = (chart) => {
		setIsOpen(true);
	};

	const onSwitchChart = (c) => {
		setChartType(c);
	}

	function afterOpenModal() {

  	}

  	function closeModal() {
    	setIsOpen(false);
  	}

	let cnt = 0;

	let asum = 0;
	let aave = 0;
	let amin = 0;
	let amax = 0;
	let admin = '';
	let admax = '';

	let bsum = 0;
	let bave = 0;
	let bmin = 0;
	let bmax = 0;
	let bdmin = '';
	let bdmax = '';

	let tsum = 0;
	let tave = 0;
	let tmin = 0;
	let tmax = 0;
	let tdmin = '';
	let tdmax = '';

	const setValues = (ymd, v) =>{
		// console.log(ymd, v, props.sItem['id']);
		cnt += 1;
		let start = 0;
		let end = 0;
		if(v.start)
			start = isNaN(Number(v.start)) ? 0 : Number(v.start);
		if(v.end)
			end = isNaN(Number(v.end)) ? 0 : Number(v.end);

		// console.log(start, end);

		let dates = ymd.split('-');

		asum += start;
		aave = (asum / cnt).toFixed(2);
		if(amin == 0){
			amin = start;
			admin = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(amin > start){
				amin = start;
				admin = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
		if(amax == 0){
			amax = start;
			admax = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(amax < start){
				amax = start;
				admax = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}

		bsum += end;
		bave = (bsum / cnt).toFixed(2);
		if(bmin == 0){
			bmin = end;
			bdmin = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(bmin > end){
				bmin = end;
				bdmin = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
		if(bmax == 0){
			bmax = end;
			bdmax = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(bmax < end){
				bmax = end;
				bdmax = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}

		let av = end - start;
		tsum += av;
		tave = (tsum / cnt).toFixed(2);
		if(tmin == 0){
			tmin = av;
			tdmin = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(tmin > av){
				tmin = av;
				tdmin = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
		if(tmax == 0){
			tmax = av;
			tdmax = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(tmax < av){
				tmax = av;
				tdmax = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
	}

	const setStateDatas = (d_datas) => {
		var categs = [];
		var dats   = [];
		var dats2  = [];
		let y = today.getFullYear();
		let m =today.getMonth();
		// console.log(flag, fname);
		if(flag == 0){
			let m =today.getMonth();
			let days = lastday(y, m);
			setFromTo(month[m]+'.01,'+y+' - '+month[m]+'.'+days+','+y+" ("+days+"days)");
			if(d_datas == null){
				for(var i=1;i<=days;i++){
					categs.push((today.getMonth()+1)+'/'+i);
					dats.push(0);
					dats2.push(0);
				}
			}else{
				for(var i=1;i<=days;i++){
					let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
					categs.push((today.getMonth()+1)+'/'+i);
					// console.log(key, d_datas[key]);
					if(d_datas[key]){
						if(d_datas[key][fname]){
							if(d_datas[key][fname].start){
								let z = d_datas[key][fname].start;
								dats.push(isNaN(Number(z)) ? 0 : Number(z));
							}else
								dats.push(0);

							if(d_datas[key][fname].end){
								let z = d_datas[key][fname].end;
								dats2.push(isNaN(Number(z)) ? 0 : Number(z));
							}else
								dats2.push(0);

							setValues(key, d_datas[key][fname]);
						}else{
							dats.push(0);
							dats2.push(0);
						}
					}else{
						dats.push(0);
						dats2.push(0);
					}
				}
			}
		}else if(flag == 1){
			let mon = m + 1;
			let m1 = mon % 3 == 0 ? mon - 2 : ( mon % 3 == 1 ? mon : mon - 1);
			let m2 = m1 + 1;
			let m3 = m1 + 2;
			let ms = [m1, m2, m3];
			let sdays = 0;
			let days = 0;
			if(d_datas == null){
				for(var i = 0; i<ms.length;i++){
					days = lastday(y, ms[i]-1);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						dats.push(0);
						dats2.push(0);
					}
				}
			}else{
				for(var i = 0; i<ms.length;i++){
					days = lastday(y, ms[i]-1);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						let key = today.getFullYear()+"-"+("0"+ms[i]).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							if(d_datas[key][fname]){
								if(d_datas[key][fname].start){
									let z = d_datas[key][fname].start;
									dats.push(isNaN(Number(z)) ? 0 : Number(z));
								}else
									dats.push(0);

								if(d_datas[key][fname].end){
									let z = d_datas[key][fname].end;
									dats2.push(isNaN(Number(z)) ? 0 : Number(z));
								}else
									dats2.push(0);
								setValues(key, d_datas[key][fname]);
							}else{
								dats.push(0);
								dats2.push(0);
							}
						}else{
							dats.push(0);
							dats2.push(0);
						}
					}
				}
			}
			setFromTo(month[m1-1]+'.01,'+y+'-'+month[m3-1]+'.'+days+','+y+" ("+sdays+"days)");
		}else{
			let sdays = 0;
			if(d_datas == null){
				for(var i = 0; i<12;i++){
					let days = lastday(y, i);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						dats.push(0);
						dats2.push(0);
					}
				}
			}else{
				for(var i=0;i<12;i++){
					let days = lastday(y, i);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						let key = today.getFullYear()+"-"+("0"+(i+1)).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							if(d_datas[key][fname]){
								if(d_datas[key][fname].start){
									let z = d_datas[key][fname].start;
									dats.push(isNaN(Number(z)) ? 0 : Number(z));
								}else
									dats.push(0);

								if(d_datas[key][fname].end){
									let z = d_datas[key][fname].end;
									dats2.push(isNaN(Number(z)) ? 0 : Number(z));
								}else
									dats2.push(0);
								setValues(key, d_datas[key][fname]);
							}else{
								dats.push(0);
								dats2.push(0);
							}
						}else{
							dats.push(0);
							dats2.push(0);
						}
					}
				}
			}
			setFromTo(month[0]+'.01,'+y+'-'+month[11]+'.31,'+y+" ("+sdays+"days)");
		}
		setCategories(categs);
		setData(dats);
		setData2(dats2);

		setCnts(cnt);

		setAAve(aave);
		setAMin(amin);
		setAMax(amax);
		setAMinDay(admin);
		setAMaxDay(admax);

		setBAve(bave);
		setBMin(bmin);
		setBMax(bmax);
		setBMinDay(bdmin);
		setBMaxDay(bdmax);
		
		setTAve(tave);
		setTMin(tmin);
		setTMax(tmax);
		setTMinDay(tdmin);
		setTMaxDay(tdmax);

	}

	const chartOptions = {
		xAxis: {
  			categories: categories,
  			title:'',
  			labels:{
  				enabled:false
  			}
  		},
  		yAxis:{
  			title:""
  		},
  		series: [
  			{
	          	type: chartType,
	          	name: '始める',
	          	color: '#0071ce',
	          	data: data,
	        },
	        {
	          	type: chartType,
	          	name: '終わり',
	          	color: '#ff671b',
	          	data: data2,
	        }
		],
		credits: {
    		enabled: false
  		},
		tooltip: {
	        enabled: false,
	    },
		title:{
			text:''
		},
		plotOptions:{
			series : {
				point: {
		          events: {
		            click: handleClick.bind(this),
		          },
		        },
			},
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},
		},
		chart:{
			events:{
				click:handleClick.bind(this)
			},
			height:120
		}
	}

	useEffect(() => {
	    if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
	        setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	    }
  	}, [flag, today]);
	return (
		<>
			<Modal
		        isOpen={modalIsOpen}
		        onAfterOpen={afterOpenModal}
		        onRequestClose={closeModal}
		        style={customStyles}
		        contentLabel="Example Modal">
	        	<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center border-bottom">
	        		<div className="col-3 px-0 d-flex align-items-center justify-content-center">
	        			<img src={chart_logo} className="chart_list_logo"/>
	        		</div>
					<div className="col-6 px-0 d-flex align-items-center justify-content-center">
						{seriesName}
					</div>
					<div className="col-3 d-flex">
					</div>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-center align-items-center border-bottom">
					{
						fromTo
					}
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Count</label>
					<span>A : {cnts} B : {cnts}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">

				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">(A) Ave</label>
					<span>{aAve}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">(A) Min : {aMinDay}</label>
					<span>{aMin}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">(A) Max : {aMaxDay}</label>
					<span>{aMax}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">

				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">(B) Ave</label>
					<span>{bAve}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">(B) Min : {bMinDay}</label>
					<span>{bMin}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">(B) Max : {bMaxDay}</label>
					<span>{bMax}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">

				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">(B-A) Ave</label>
					<span>{tAve}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">(B-A) Min : {tMinDay}</label>
					<span>{tMin}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">(B-A) Max : {tMaxDay}</label>
					<span>{tMax}</span>
				</div>
		    </Modal>
			<div className="chart_body">
				<div className="row col-12 px-0 mx-0 py-1 justify-content-between align-items-center">
					<div className="col-8 px-0 d-flex align-items-center">
						<div className="d-flex col-3 px-0 justify-content-center align-items-center">
							<img src={chart_logo} className="chart_list_logo"/>
						</div>
						<div className="col-9 px-0 justify-content-start align-items-center">
							{seriesName}
						</div>
					</div>
					<div className="d-flex col-4">
						<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='line'?'active':'')} onClick={() => onSwitchChart('line')}><i className="fas fa-chart-line fa-fw fa-2x pointer"></i></span>
						<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='column'?'active':'')} onClick={() => onSwitchChart('column')}><i className="fas fa-chart-bar fa-fw fa-2x pointer"></i></span>
					</div>
				</div>
			</div>
      		<div style={{borderBottom:'1px solid #000'}}>
        		<HighchartsReact
          			highcharts={Highcharts}
          			options={chartOptions}
          			handleClick={handleClick}
        		/>
      		</div>
      	</>
    )
}

export default Diary3;