/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";

import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";
// import * as ic from "assets/img/self/icon";

// add new codes
import { useHistory } from 'react-router-dom';

// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function SelectIcon() {

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }

  const history = useHistory();

  const icon_imgs = JSON.parse(window.sessionStorage.getItem('s_icons'));

  const [sIcon, setSIcon] = useState(JSON.parse(window.sessionStorage.getItem('s_Icon')) || icon_imgs[0]);
  const editFlag = window.sessionStorage.getItem('editFlag') || 0;

  const toItem = (event) => {
    if(editFlag == 0)
      toPage(event, 'newItem');
    else
      toPage(event, 'editItem');
  }

  const toPage = (e, pname) => {
    history.push('../admin/'+pname);
  }

  const onSelectIcon = (e, item) => {
    setSIcon(item);
    window.sessionStorage.setItem('s_Icon', JSON.stringify(item));
    if(editFlag == 0)
      toPage(e, 'newItem');
    else
      toPage(e, 'editItem');
  }

  return (
    <>      
      <div className="content px-0 pb-0">
        <div className="titleDiv">
          <Card className="mb-0">
            <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
              <div className="d-flex col-12 px-2">
                <div className="col-2 col-lg-1 d-flex align-items-center px-0">
                  <i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toItem(event)}></i>
                </div>
                <div className="col-8 col-lg-10 text-center px-0 d-flex align-items-center justify-content-center head_title">
                    アイコンを選択
                </div>
                <div className="col-2 col-lg-1 px-0">
                </div>
              </div>
            </CardBody>
            <CardBody className="px-1 diary_list py-0">
              <div className="col-12 px-0 row mx-0 icon_list">
              {
                icon_imgs.map((item, idx)=>
                  <div key={idx} className={'col-2 p-2 d-flex justify-content-center align-items-center ' + (sIcon.name == item.name ? 'i_selected' : '')}>
                    <img src={item.data.default} onClick={(event) => onSelectIcon(event, item)}/>
                  </div>
                )
              }
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default SelectIcon;
