import React, { Component } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import "assets/css/chart.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}

function getDateTime(d){
	var h = Math.floor(d / 3600000);
	var m = Math.floor((d / 3600000 - h) * 60);
	var s = Math.round(((d / 3600000 - h) * 60 - m) * 60);
	// console.log(("0"+(h)).slice(-2)+":"+("0" + m).slice(-2), m);
	return ("0"+(h)).slice(-2)+":"+("0" + m).slice(-2)+"`"+("0" + s).slice(-2);
}

function checkTimeNum(t, f){
    if(Number(t) > f)
      return f;
    else
      return Number(t);
}

function DiaryChart6(){

	let session = JSON.parse(window.sessionStorage.getItem('sessions'));
	if(!session)
		window.location = "/";
	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const [flag, setFlag] 						= useState(0);
	const [categories, setCategories] 			= useState([]);
	const [data, setData] 						= useState([]);
	const [todayDate, setTodayDate] 			= useState();
	const [seriesName, setSeriesName] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['title'] + "("+JSON.parse(sessionStorage.getItem('selectedItem'))['i_unit']+")" || '');
	const [fname, setFName] 					= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['id'] || 0);
	const [chartType, setChartType] 			= useState('line');
	const [chart_logo, setChart_logo] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['i_icon'] || '');

	const [showDate, setShowDate] 				= useState();

	const [xData, setXData] 					= useState(1);
	const [yData, setYData] 					= useState('NO DATA');
	const [isModal, setIsModal] 				= useState('hide');
	const [modalIsOpen, setIsOpen] 				= React.useState(false);
	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	const [sToday, setSToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());

	const [dateType, setDateType] = useState('ymd');
	const [dateConfig, setDateConfig] = useState({
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        },
        'month': {
            format: 'MM',
            caption: 'Mon',
            step: 1,
        }
    });

	const [vData, setVData] 					= useState('NO DATA');

	const [getKey, setGetKey] = useState((new Date(sessionStorage.getItem('today'))).getFullYear()+"-"+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+"-"+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));

	const history = useHistory();	

	const setStateDatas = (d_datas) => {
		// setDiaryData(d_datas);

		var categs = [];
		var datas = [];
		let y = today.getFullYear();
		let m =today.getMonth();
		if(flag == 0){
			let days = lastday(y, m);
			if(d_datas == null){
				for(var i=1;i<=days;i++){
					categs.push(i);
					datas.push(0);
				}
			}else{
				for(var i=1;i<=days;i++){
					let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
					categs.push((today.getMonth()+1)+'/'+i);

					if(d_datas[key] && d_datas[key][fname]){
						// let d = d_datas[key][fname].start.split(':');
						// console.log(d, Number(d[0]) * 60 * 60 *1000, Number(d[1]) *  60 *1000);
						let d = [];
						if(d_datas[key][fname].start){
							d = d_datas[key][fname].start.split(':');
						}
						if(isNaN(Number(d[0])))
							d[0] = 0;
						if(isNaN(Number(d[1])))
							d[1] = 0;
						if(isNaN(Number(d[2])))
							d[2] = 0;

						d[0] = checkTimeNum(d[0], 23);
					    d[1] = checkTimeNum(d[1], 59);
					    d[2] = checkTimeNum(d[2], 59);
						datas.push(Number(d[0]) * 60 * 60 *1000 + Number(d[1]) *  60 *1000 + Number(d[2]) * 1000);
					}else{
						datas.push(0);
					}
				}
			}
		}else if(flag == 1){
			let mon = m + 1;
			let m1 = mon % 3 == 0 ? mon - 2 : ( mon % 3 == 1 ? mon : mon - 1);
			let m2 = m1 + 1;
			let m3 = m1 + 2;
			let ms = [m1, m2, m3];
			if(d_datas == null){
				for(var i = 0; i<ms.length;i++){
					let days = lastday(y, ms[i]-1);
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						datas.push(0);
					}
				}
			}else{
				for(var i = 0; i<ms.length;i++){
					let days = lastday(y, ms[i]-1);
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						let key = today.getFullYear()+"-"+("0"+ms[i]).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key] && d_datas[key][fname]){
							// let d = d_datas[key][fname].start.split(':');
							let d = [];
							if(d_datas[key][fname].start){
								d = d_datas[key][fname].start.split(':');
							}
							if(isNaN(Number(d[0])))
								d[0] = 0;
							if(isNaN(Number(d[1])))
								d[1] = 0;
							if(isNaN(Number(d[2])))
								d[2] = 0;

							d[0] = checkTimeNum(d[0], 23);
						    d[1] = checkTimeNum(d[1], 59);
						    d[2] = checkTimeNum(d[2], 59);
							datas.push(Number(d[0]) * 60 * 60 *1000+ Number(d[1]) *  60 *1000 + Number(d[2]) * 1000);
						}else{
							datas.push(0);
						}
					}
				}
			}
		}else{
			if(d_datas == null){
				for(var i = 0; i<12;i++){
					let days = lastday(y, i);
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						datas.push(0);
					}
				}
			}else{
				for(var i=0;i<12;i++){
					let days = lastday(y, i);
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						let key = today.getFullYear()+"-"+("0"+(i+1)).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key] && d_datas[key][fname]){
							let d = [];
							if(d_datas[key][fname].start){
								d = d_datas[key][fname].start.split(':');
							}
							if(isNaN(Number(d[0])))
								d[0] = 0;
							if(isNaN(Number(d[1])))
								d[1] = 0;
							if(isNaN(Number(d[2])))
								d[2] = 0;

							d[0] = checkTimeNum(d[0], 23);
						    d[1] = checkTimeNum(d[1], 59);
						    d[2] = checkTimeNum(d[2], 59);
							datas.push(Number(d[0]) * 60 * 60 *1000+ Number(d[1]) *  60 *1000 + Number(d[2]) * 1000);
						}else{
							datas.push(0);
						}
					}
				}
			}
		}
		// console.log(datas);
		setCategories(categs);
		setData(datas);
		// setData2(datas2);
	}	

	const handleClick = (chart) => {
		var x = chart.point.x;
		var y = chart.point.y;
		console.log(data[x], data);

		var startD = getDateTime(data[x]);

		setVData(startD);	
		let catg = categories[x];
		let md = catg.split('/');	
		if(isNaN(Number(md[0])))
			md[0] = 0;
		if(isNaN(Number(md[1])))
			md[1] = 0;
		
		md[0] = checkTimeNum(md[0], 12);
		let days = lastday(today.getFullYear(), md[0]-1);
	    md[1] = checkTimeNum(md[1], days);
		const td = new Date(today.getFullYear()+"-"+md[0]+"-"+md[1]);
		let day = td.getDay();
		// setVData1(startD);
		setShowDate(md[0]+' / '+md[1] + ' ' + weekday[day]);

		setXData(x);
		setIsModal('show');
	};

	const noHandleClick = (chart) => {
		setIsModal('_hide');
	}

	const onShowChart = (f) => {
		setIsModal('hide');
		setFlag(f);
	}

	const onSwitchChart = (c) => {
		setChartType(c);
		noHandleClick();
	}

	const onBeforeData = (e) => {
		onGetData(xData-1);
	}

	const onNextData = (e) => {
		onGetData(xData+1);
	}

	const onGetData = (i) => {
		if(data[i] != undefined){
			var startD = getDateTime(data[i]);
			// var endD = getDateTime(data2[i]);
			setVData(startD);
			// setVData1(startD);

			let catg = categories[i];
			let md = catg.split('/');

			const td = new Date(today.getFullYear()+"-"+md[0]+"-"+md[1]);
			let day = td.getDay();
			setShowDate(md[0]+' / '+md[1] + ' ' + weekday[day]);
			setXData(i);
			setTodayDate(i);
		}
	}

	const showList = (e) => {
	    sessionStorage.setItem("today", today);
	    if(session.userkind == 'coach')
	    	history.push('../coach/diaryList6');
	    else
	    	history.push('../admin/diaryList6');
	};
	function openModal(f, fname, da) {
		// noHandleClick();
	    setIsOpen(true);
	    setDateType(f);
	    if(f == 'ymd'){
	      	setDateConfig({
	            'year': {
	                format: 'YYYY',
	                caption: 'Year',
	                step: 1,
	            },
	            'month': {
	                format: 'MM',
	                caption: 'Mon',
	                step: 1,
	            }
	        });
	    }else{
	      // setTimeFname(fname);
	      // console.log(da);
	      var t = da.split(':');
	      var s = t[1].split('`');
	      // console.log(eval(fname), t);
	      var tod = sToday;
	      // console.log(sToday.getDate());
	      if(isNaN(Number(t[0])))
				t[0] = 0;
			if(isNaN(Number(s[0])))
				s[0] = 0;
			if(isNaN(Number(s[1])))
				s[1] = 0;

			t[0] = checkTimeNum(t[0], 23);
		    s[0] = checkTimeNum(s[0], 59);
		    s[1] = checkTimeNum(s[1], 59);
	      tod.setHours(t[0]);
	      tod.setMinutes(s[0]);
	      tod.setSeconds(s[1]);
	      // var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+s[0]+":"+s[1]);
	      // console.log(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+s[0]+":"+s[1]);
	      setSToday(tod);
	      setDateConfig({
	        'hour': {
	          format: 'hh',
	          caption: 'Hour',
	          step: 1,
	        },
	        'minute': {
	          format: 'mm',
	          caption: 'Min',
	          step: 1,
	        },
	        'second': {
	          format: 'ss',
	          caption: 'Sec',
	          step: 1,
	        }
	      });
	    }
	}

	const selectDate = (time) => {
	    if(dateType == 'ymd'){
	      setToday(time);
	      setSToday(time);
	      setTodayDate(time.getDate());
	      setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	      setGetKey(time.getFullYear()+"-"+("0"+(time.getMonth()+1)).slice(-2)+"-"+("0" + time.getDate()).slice(-2));
	    }else{
	      	var h = time.getHours();
	      	var m = time.getMinutes();
	      	var s = time.getSeconds();
	      	// console.log(timeFname, h, m);
	      	var t = ("0"+h).slice(-2)+":"+("0" + m).slice(-2)+":"+("0" + s).slice(-2);
	      	var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t);
	      	// console.log(dateConfig);
	      	setSToday(tods);	      
	      	setVData(("0"+h).slice(-2)+":"+("0" + m).slice(-2)+"`"+("0" + s).slice(-2));

	       	// let session = window.sessionStorage.getItem('sessions'); 
	    	// let se = JSON.parse(session);

	      	let catg = categories[xData];
			let md = catg.split('/');	
			const setDataDate = today.getFullYear()+"-"+md[0]+"-"+md[1];
			let vv = {start:t};


			axios.get(URL_API+"setDiaryItemData", {
		      params: {
		        seldate: setDataDate, 
		        user: session['id'],
		        item_id: fname,
		        v:vv
		      }
		    })
		    .then(function (response) {
		      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
		  		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
		      	setStateDatas(response.data.data);
		    }).catch(
		      error => {
		        if(error.response){
		          console.log(error.response.data);
		        };
		      }
		    ); 

	    }
	    setIsOpen(false);
	    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	  }

	const handleCancel = () => {
	    setIsOpen(false);
	}

	const toPage = (e, pname) => {
		if(session.userkind == 'coach' && pname == 'diary'){
			history.push('../coach/'+pname);
			return;
		}
		history.push('../admin/'+pname);
	}

	const chartOptions = {
		xAxis: {
  			categories: categories,
  			title:'',
  			labels:{
  				enabled:false
  			}
  		},
  		yAxis: {
  			title:"",
		    type: 'datetime',
		    dateTimeLabelFormats: {
		      second: '%H:%M`%S',
		    }
		  },
  		series: [
  			{
	          	type: chartType,
	          	name: '始める',
	          	color: '#0071ce',
	          	data: data,
	        }
		],
		credits: {
    		enabled: false
  		},
		tooltip: {
	        shared: true,
	        useHTML: true,
	        dateTimeLabelFormats:{
	        	second:'%H:%M`%S'
	        },
	        formatter: function() {

                var s = '';
                s += '<b>' + Highcharts.dateFormat('%H:%M`%S', this.y) + '</b>';

                return s;
            },
	        // headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
	        // pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
	        //     '<td style="text-align: right"><b>{new Date(point.y)}</b></td></tr>',
	        // footerFormat: '</table>',
	        enabled: false
	    },
		title:{
			text:''
		},
		plotOptions:{
			series : {
				marker: {
		            symbol: 'circle',
		          },
				point: {
		          events: {
		            click: handleClick.bind(this),
		          },
		        },
			},
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},

		}
	}

	useEffect(() => {
		// console.log(typeof today);
		if(!session){
	      	window.location = '/';
	    }

	   	if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
			setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   	}
	   	let p = session;
	   	if(session.userkind == 'coach'){
			p = JSON.parse(window.sessionStorage.getItem('player')); 
			if(!p){
			  p = JSON.parse(window.sessionStorage.getItem('players'))[0];
			}
		}
   		axios.get(URL_API+"getDiaryAPI", {
			params: {
				seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
				user: p['id'],
			}
	    })
	    .then(function (response) {		      
	      	// window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
      		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
	      	setStateDatas(response.data.data);
	    }).catch(
	      	error => {
	        	if(error.response){
	          		console.log(error.response.data);
	        	};
	      	}
	    );
  	}, [flag, today]);
	return (
		<>		
			<DatePicker
		        dateConfig={dateConfig}
		        value={sToday}
		        isOpen={modalIsOpen}
		        onSelect={selectDate}
		        onCancel={handleCancel} />	
			<div className="content px-0">
        		<Row className="px-0 mx-0">
          			<Col md="12" className="px-0">
            			<Card>
            				<CardBody className="seldate" id='seldate' style={{color:'white'}}>
					            <Row>
					            	<Col className="col-2 d-flex justify-content-center align-items-center">
					            		<i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toPage(event, 'diary')}></i>
					            	</Col>
					              	<Col className="col-8 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ymd', '', '')} >
					                	{todayVDate}
					             	</Col>
					             	<Col className="col-2 d-flex justify-content-center">
					             	</Col>
					            </Row>
					        </CardBody>
							<div className="chart_body">
								<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center">
									<div className="col-9 px-0 d-flex align-items-center">
										<div className="d-flex col-3 px-0 justify-content-center align-items-center">
											<img src={chart_logo} className="chart_list_logo"/>
										</div>
										<div className="col-9 px-0 justify-content-start align-items-center">
											{seriesName}
										</div>
									</div>
									<div className="d-flex col-3 px-0 justify-content-center align-items-center chartList">
										<i className="fas fa-chart-line" style={{marginRight:'5px'}}></i>
          								<i className="fas fa-list" onClick={(event) => showList(event)}></i>
									</div>
								</div>
							</div>
				      		<div>
				        		<HighchartsReact
				          			highcharts={Highcharts}
				          			options={chartOptions}
				          			handleClick={handleClick}
				        		/>
				        		<div className={isModal + ' py-3 px-2'} id="showDateDiv">
									<div className={'show_body'}>						
										<div className={'m-header mb-1'}>
											<div>{showDate}</div>
										</div>
										<div className={'m-body'}>
											<span><i className={"fas fa-caret-left fa-fw fa-2x pointer"} onClick={(event) => onBeforeData(event)}></i></span>
											{
												session.userkind == 'player' ? (<div onClick={() => openModal('hm', fname, vData)}>{vData}</div>) : vData
											}											
											<span><i className={"fas fa-caret-right fa-fw fa-2x pointer"} onClick={(event) => onNextData(event)}></i></span>
										</div>
										<div className="d-flex justify-content-center">
											<span><i className="fas fa-angle-down fa-fw fa-2x pointer" onClick={(event) => noHandleClick(event)}></i></span>
										</div>
									</div>		
								</div>
				      			
				      		</div>
				      		<div>      			
								<div className="d-flex justify-content-between align-items-center">
									<div className="ml-2">
									</div>
									<div className="d-flex">
										<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='line'?'active':'')} onClick={() => onSwitchChart('line')}><i className="fas fa-chart-line fa-fw fa-2x pointer"></i></span>
										<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='column'?'active':'')} onClick={() => onSwitchChart('column')}><i className="fas fa-chart-bar fa-fw fa-2x pointer"></i></span>
									</div>
								</div>
				      		</div>
				      		<div>
				      			<Row className="mx-0">
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==0?'active':'')} onClick={() => onShowChart(0)}>
				      					月
				      				</Col>
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==1?'active':'')} onClick={() => onShowChart(1)}>
				      					3か月
				      				</Col>
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==2?'active':'')} onClick={() => onShowChart(2)}>
				      					年
				      				</Col>
				      			</Row>
				      		</div>
				      	</Card>
				    </Col>
				</Row>
			</div>
      	</>
    )
}

export default DiaryChart6;