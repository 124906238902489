/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
// import "assets/css/ToggleSwitch.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import 'react-notifications/lib/notifications.css';
import 'react-notifications-component/dist/theme.css'
import 'animate.css';


import AdminLayout from "layouts/Admin.js";
import UserLayout from "layouts/User.js";
import SubLayout from "layouts/Sub.js";
import CoachLayout from "layouts/Coach.js";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/coach" render={(props) => <CoachLayout {...props} />} />
      {/* <Redirect to="/admin/dashboard" /> */}
      <Route path="/user" render={(props) => <UserLayout {...props} />} />
      <Route path="/sub" render={(props) => <SubLayout {...props} />} />
      <Redirect to="/user/login" />      
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();

reportWebVitals();