/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useHistory } from 'react-router-dom';

import { Radar, RadarChart, PolarGrid, 
  PolarAngleAxis, PolarRadiusAxis } from 'recharts';

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

let session = {}; 
let se;

function Redarchart() {

  session = window.sessionStorage.getItem('sessions'); 
  se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }
  
  const history = useHistory(); 

  let data = [
    { name: '技術', x: 3 },
    { name: 'メンタル', x: 4 },
    { name: 'フィジカル', x: 4 },
    { name: '戦術', x: 5 },
    { name: '攻撃力', x: 3 },
    { name: '守備カ', x: 4 },
  ];
  const [power, setPower] = useState(data); 
  const [total, setTotal] = useState(0);
  const [mark, setMark] = useState(0);
  const [uFlag, setUFlag] = useState(true);

  useEffect(() => {

    let p = JSON.parse(window.sessionStorage.getItem('power'));
    if(Array.isArray(p)){
      if(p.length > 0){

        setPower(p); 
        let t = p[0]['val1'] + p[0]['val2'] + p[0]['val3'] +p[0]['val4'] +p[0]['val5']+p[0]['val6'];        
        setTotal(t);
        setMark(Math.round(t / 6));

      }
    }

    let player = se;
    if(se.userkind == 'coach'){
      player = JSON.parse(window.sessionStorage.getItem('player')); 
      if(!player){
        player = JSON.parse(window.sessionStorage.getItem('players'))[0];
        window.sessionStorage.setItem('player', JSON.stringify(player));   
      }
    }else if(se.userkind == 'player'){
      player = se;
    }
    
    // console.log(player);
    axios.get(URL_API+"rederchart", {
      params: {
        kind: 1,
        user: player['id'], 
      }
    })
    .then(function (response) {
      if(Array.isArray(response.data)){
        if(response.data.length > 0){
          data[0]['x'] = response.data[0]['val1'];
          data[1]['x'] = response.data[0]['val2'];
          data[2]['x'] = response.data[0]['val3'];
          data[3]['x'] = response.data[0]['val4'];
          data[4]['x'] = response.data[0]['val5'];
          data[5]['x'] = response.data[0]['val6'];

          let t = response.data[0]['val1'] + response.data[0]['val2'] + response.data[0]['val3'] +response.data[0]['val4'] +response.data[0]['val5']+response.data[0]['val6'];
          setPower(data);
          setTotal(t);
          setMark(Math.round(t / 6));
        }
      }

    });

  }, []);

  const redarchart = (e, kind) => {

    history.push('../sub/redarchart');

  }

  const save = (e) =>{  
   
    window.sessionStorage.setItem("power", JSON.stringify(power));

    axios.get(URL_API+"rederchartSave", {
      params: {
        val1: power[0]['x'],
        val2: power[1]['x'],
        val3: power[2]['x'],
        val4: power[3]['x'],
        val5: power[4]['x'],
        val6: power[5]['x'],
        kind: 1,
        user: se['id'], 
      }
    },{ 
      xsrfHeaderName: 'X-CSRF-Token',
      withCredentials: true
    })
    .then(function (response) {
      let t = power[0]['x']+power[1]['x']+power[2]['x']+power[3]['x']+power[4]['x']+power[5]['x'];
      //setPower(data);
      setTotal(t);
      setMark(Math.round(t / 6));
    });
  }

  const handleOnChange = (e, kind, val) => {
    if(se.userkind != 'player')
      return;
    let data = power;    
    data[kind]['x'] = val;  
    setPower(data);
    setUFlag(!uFlag);    
  }

  const profileUpdateFunc = (e) => {
    history.push('../admin/analysis');
  }

  const profileUpdateFunc1 = (e) => {
    history.push('../coach/analysis');
  }

  return (
    <>
      <div className="content coach-user-content" style={{padding:'20px'}}>
       
        <Row>
          <Col onClick={(e) => redarchart(1)} style={{textAlign:'center', border:'1px solid #CCC', padding:'5px', margin:'5px', borderRadius:'20px'}}>
            <h6>総合力</h6>
            <div className="d-flex justify-content-center col-12">
            <RadarChart height={350} width={350} style={{fontSize:'10px'}} 
              outerRadius="80%" data={power}>
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis domain={[0, 5]}/>
              <Radar dataKey="x" stroke="green" angle={1}
                  fill="green" fillOpacity={0.5} max={5} />
            </RadarChart>
            </div>
            <h6 style={{textAlign:'right', paddingRight:'10px', color:'rgb(48 81 239)'}}>合計 : {total}   評価 : {mark}</h6>
          </Col>          
        </Row>

        <Row style={{textAlign:'center', padding:'5px'}}>
          <Col className="col-3 px-1" style={{display:'flex', justifyContent:'start', alignItems:'center'}}>技術</Col>
          <Col className="col-9">
            <Row style={{border:'1px solid #10896d'}}>
              <Col className={power[0]['x'] === 0 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 0, 0)}>0</Col>
              <Col className={power[0]['x'] === 1 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 0, 1)}>1</Col>
              <Col className={power[0]['x'] === 2 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 0, 2)}>2</Col>
              <Col className={power[0]['x'] === 3 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 0, 3)}>3</Col>
              <Col className={power[0]['x'] === 4 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 0, 4)}>4</Col>
              <Col className={power[0]['x'] === 5 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 0, 5)}>5</Col>
            </Row>
          </Col>
        </Row>
        <Row style={{textAlign:'center', padding:'5px'}}>
          <Col className="col-3 px-1" style={{display:'flex', justifyContent:'start', alignItems:'center'}}>メンタル</Col>
          <Col className="col-9">
            <Row style={{border:'1px solid #10896d'}}>
              <Col className={power[1]['x'] === 0 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 1, 0)}>0</Col>
              <Col className={power[1]['x'] === 1 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 1, 1)}>1</Col>
              <Col className={power[1]['x'] === 2 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 1, 2)}>2</Col>
              <Col className={power[1]['x'] === 3 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 1, 3)}>3</Col>
              <Col className={power[1]['x'] === 4 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 1, 4)}>4</Col>
              <Col className={power[1]['x'] === 5 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 1, 5)}>5</Col>
            </Row>
          </Col>
        </Row>
        <Row style={{textAlign:'center', padding:'5px'}}>
          <Col className="col-3 px-1" style={{display:'flex', justifyContent:'start', alignItems:'center'}}>フィジカル</Col>
          <Col className="col-9">
            <Row style={{border:'1px solid #10896d'}}>
              <Col className={power[2]['x'] === 0 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 2, 0)}>0</Col>
              <Col className={power[2]['x'] === 1 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 2, 1)}>1</Col>
              <Col className={power[2]['x'] === 2 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 2, 2)}>2</Col>
              <Col className={power[2]['x'] === 3 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 2, 3)}>3</Col>
              <Col className={power[2]['x'] === 4 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 2, 4)}>4</Col>
              <Col className={power[2]['x'] === 5 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 2, 5)}>5</Col>
            </Row>
          </Col>
        </Row>
        <Row style={{textAlign:'center', padding:'5px'}}>
          <Col className="col-3 px-1" style={{display:'flex', justifyContent:'start', alignItems:'center'}}>戦術</Col>
          <Col className="col-9">
            <Row style={{border:'1px solid #10896d'}}>
              <Col className={power[3]['x'] === 0 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 3, 0)}>0</Col>
              <Col className={power[3]['x'] === 1 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 3, 1)}>1</Col>
              <Col className={power[3]['x'] === 2 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 3, 2)}>2</Col>
              <Col className={power[3]['x'] === 3 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 3, 3)}>3</Col>
              <Col className={power[3]['x'] === 4 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 3, 4)}>4</Col>
              <Col className={power[3]['x'] === 5 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 3, 5)}>5</Col>
            </Row>
          </Col>
        </Row>
        <Row style={{textAlign:'center', padding:'5px'}}>
          <Col className="col-3 px-1" style={{display:'flex', justifyContent:'start', alignItems:'center'}}>攻撃力</Col>
          <Col className="col-9">
            <Row style={{border:'1px solid #10896d'}}>
              <Col className={power[4]['x'] === 0 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 4, 0)}>0</Col>
              <Col className={power[4]['x'] === 1 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 4, 1)}>1</Col>
              <Col className={power[4]['x'] === 2 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 4, 2)}>2</Col>
              <Col className={power[4]['x'] === 3 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 4, 3)}>3</Col>
              <Col className={power[4]['x'] === 4 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 4, 4)}>4</Col>
              <Col className={power[4]['x'] === 5 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 4, 5)}>5</Col>
            </Row>
          </Col>
        </Row>
        <Row style={{textAlign:'center', padding:'5px'}}>
          <Col className="col-3 px-1" style={{display:'flex', justifyContent:'start', alignItems:'center'}}>守備カ</Col>
          <Col className="col-9">
            <Row style={{border:'1px solid #10896d'}}>
              <Col className={power[5]['x'] === 0 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 5, 0)}>0</Col>
              <Col className={power[5]['x'] === 1 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 5, 1)}>1</Col>
              <Col className={power[5]['x'] === 2 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 5, 2)}>2</Col>
              <Col className={power[5]['x'] === 3 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 5, 3)}>3</Col>
              <Col className={power[5]['x'] === 4 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 5, 4)}>4</Col>
              <Col className={power[5]['x'] === 5 ? "rederchatvalsel" : "rederchatval"} onClick={(e) => handleOnChange(e, 5, 5)}>5</Col>
            </Row>
          </Col>
        </Row>


        <Row>     
          {
            se.userkind == 'player' && (    
              <>    
                <Col className="col-6">
                  <input type="button" name="user" value="保存" className="btn-round btn btn-danger" onClick={save}/>
                </Col>  
                <Col className="col-6">
                  <input type="button" name="user" value="自己分析画面" className="btn-round btn btn-primary" onClick={profileUpdateFunc}/>
                </Col>
              </>
            )
          }
          {            
            se.userkind != 'player' && (
              <Col className="col-12">
                <input type="button" name="user" value="自己分析画面" className="btn-round btn btn-primary" onClick={profileUpdateFunc1}/>
              </Col>
            )
          }                                  
        </Row>
      </div>
    </>
  );
}

export default Redarchart;
