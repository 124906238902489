/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

import {  
  Card,  
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let target = {}; 
let session; 
let notificationAlert;
let se;

const Goalsettingedit = (e) => {    

  notificationAlert = React.useRef(); 
  
  const history = useHistory();

  if(session === null){    
    window.location = '/';
  }
    
  const preparationPage = (e) => {    
    history.push('../admin/biggoalsetting');
  }
  
///////////////////

  const [edit_date, setEditdate] = useState();
  const getInputEditdateValue = (event)=>{    
    setEditdate(event.target.value);     
    target['edit_date'] = event.target.value;
    
  };

  const [target1, settarget1] = useState();
  const getInputTarget1Value = (event)=>{    
    settarget1(event.target.value);     
    target['target1'] = event.target.value;
  };
  const [target2, settarget2] = useState();
  const getInputTarget2Value = (event)=>{    
    settarget2(event.target.value);     
    target['target2'] = event.target.value;
  };
  const [target3, settarget3] = useState();
  const getInputTarget3Value = (event)=>{    
    settarget3(event.target.value);     
    target['target3'] = event.target.value;
  };
  
  const [longtarget1, setlongtarget1] = useState();
  const getInputLognTarget1Value = (event)=>{    
    setlongtarget1(event.target.value);     
    target['longtarget1'] = event.target.value;
  };
  const [longtarget1_, setlongtarget1_] = useState();
  const getInputLognTarget1_Value = (event)=>{    
    setlongtarget1_(event.target.value);     
    target['longtarget1_'] = event.target.value;
  };
  const [longtarget2, setlongtarget2] = useState();
  const getInputLognTarget2Value = (event)=>{    
    setlongtarget2(event.target.value);     
    target['longtarget2'] = event.target.value;
  };
  const [longtarget2_, setlongtarget2_] = useState();
  const getInputLognTarget2_Value = (event)=>{    
    setlongtarget2_(event.target.value);     
    target['longtarget2_'] = event.target.value;
  };
  const [longtarget3, setlongtarget3] = useState();
  const getInputLognTarget3Value = (event)=>{    
    setlongtarget3(event.target.value);     
    target['longtarget3'] = event.target.value;
  };
  const [longtarget3_, setlongtarget3_] = useState();
  const getInputLognTarget3_Value = (event)=>{    
    setlongtarget3_(event.target.value);     
    target['longtarget3_'] = event.target.value;
  };

  const [midtarget1, setmidtarget1] = useState();
  const getInputMidtarget1Value = (event)=>{    
    setmidtarget1(event.target.value);     
    target['midtarget1'] = event.target.value;
  };
  const [midtarget1_, setmidtarget1_] = useState();
  const getInputMidtarget1_Value = (event)=>{    
    setmidtarget1_(event.target.value);     
    target['midtarget1_'] = event.target.value;
  };
  const [midtarget2, setmidtarget2] = useState();
  const getInputMidtarget2Value = (event)=>{    
    setmidtarget2(event.target.value);     
    target['midtarget2'] = event.target.value;
  };
  const [midtarget2_, setmidtarget2_] = useState();
  const getInputMidtarget2_Value = (event)=>{    
    setmidtarget2_(event.target.value);     
    target['midtarget2_'] = event.target.value;
  };
  const [midtarget3, setmidtarget3] = useState();
  const getInputMidtarget3Value = (event)=>{    
    setmidtarget3(event.target.value);     
    target['midtarget3'] = event.target.value;
  };
  const [midtarget3_, setmidtarget3_] = useState();
  const getInputMidtarget3_Value = (event)=>{    
    setmidtarget3_(event.target.value);     
    target['midtarget3_'] = event.target.value;
  };

  const [shorttarget1, setshorttarget1] = useState();
  const getInputShorttarget1Value = (event)=>{    
    setshorttarget1(event.target.value);     
    target['shorttarget1'] = event.target.value;
  };
  const [shorttarget1_, setshorttarget1_] = useState();
  const getInputShorttarget1_Value = (event)=>{    
    setshorttarget1_(event.target.value);     
    target['shorttarget1_'] = event.target.value;
  };
  const [shorttarget2, setshorttarget2] = useState();
  const getInputShorttarget2Value = (event)=>{    
    setshorttarget2(event.target.value);     
    target['shorttarget2'] = event.target.value;
  };
  const [shorttarget2_, setshorttarget2_] = useState();
  const getInputShorttarget2_Value = (event)=>{    
    setshorttarget2_(event.target.value);     
    target['shorttarget2_'] = event.target.value;
  };
  const [shorttarget3, setshorttarget3] = useState();
  const getInputShorttarget3Value = (event)=>{    
    setshorttarget3(event.target.value);     
    target['shorttarget3'] = event.target.value;
  };
  const [shorttarget3_, setshorttarget3_] = useState();
  const getInputShorttarget3_Value = (event)=>{    
    setshorttarget3_(event.target.value);     
    target['shorttarget3_'] = event.target.value;
  };

  const [plan1, setplan1] = useState();
  const getInputPlan1Value = (event)=>{    
    setplan1(event.target.value);     
    target['plan1'] = event.target.value;
  };
  const [plan2, setplan2] = useState();
  const getInputPlan2Value = (event)=>{    
    setplan2(event.target.value);     
    target['plan2'] = event.target.value;
  };

  const [playname, setplayname] = useState();
  const getInputPlaynameValue = (event)=>{    
    setplayname(event.target.value);     
    target['playname'] = event.target.value;
  };

  const [cotchname, setcotchname] = useState();
  const getInputCotchnameValue = (event)=>{    
    setcotchname(event.target.value);     
    target['cotchname'] = event.target.value;
  };

  useEffect(() => {
    
    session = window.sessionStorage.getItem('sessions'); 
    
    if(window.sessionStorage.getItem('target') !== null ){

      target = window.sessionStorage.getItem('target'); 
      target = JSON.parse(target); 

      settarget1(target['target1']);
      settarget2(target['target2']);
      settarget3(target['target3']);
      
      setlongtarget1(target['longtarget1']);
      setlongtarget2(target['longtarget2']);
      setlongtarget3(target['longtarget3']);
      setlongtarget1_(target['longtarget1_']);
      setlongtarget2_(target['longtarget2_']);
      setlongtarget3_(target['longtarget3_']);

      setmidtarget1(target['midtarget1']);
      setmidtarget1_(target['setmidtarget1_']);
      setmidtarget2(target['midtarget2']);
      setmidtarget2_(target['midtarget2_']);
      setmidtarget3(target['midtarget3']);
      setmidtarget3_(target['midtarget3_']);
      setshorttarget1(target['shorttarget1']);
      setshorttarget1_(target['shorttarget1_']);
      setshorttarget2(target['shorttarget2']);
      setshorttarget2_(target['shorttarget2_']);
      setshorttarget3(target['shorttarget3']);
      setshorttarget3_(target['shorttarget3_']);
      setplan1(target['plan1']);
      setplan2(target['plan2']);
      setplayname(target['playname']);
      setEditdate(target['edit_date']);
      setcotchname(target['cotchname']);
    }else{
      
    }

    // console.log(biggoal);
    // // //戦術
    se = JSON.parse(session);

    target['user_id'] = se["id"];

  }, []);
  return (

    <>
      <div className="content coach-user-content px-0 pb-0">
        <NotificationAlert ref={notificationAlert}  className="col-12"/>
        <Row className="px-0 mx-0">          
          <Col md="12" className="px-0">
            <Card className="card-user mb-0">              
              <CardBody>
                <Form>     
                  <Row>
                    <Col style={{marginTop:'10px'}}><h6>大きな目標（夢）とする事	</h6></Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">                                                    
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>①</label> 
                      <Input type="text" style={{border:'none'}} placeholder="例 : 世界ランキン1位" onChange={getInputTarget1Value} id="target1" value={target1 == null ? '' : target1}/>                                            
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>②</label>
                      <Input type="text" style={{border:'none'}} placeholder="例 : グランドスラム出場" onChange={getInputTarget2Value} id="technical2" value={target2 == null ? '' : target2}/>                                            
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>③</label>
                      <Input type="text" style={{border:'none'}} placeholder="例 : 全国大会優勝" onChange={getInputTarget3Value} id="technical3" value={target3 == null ? '' : target3}/>                                            
                    </Col>
                  </Row>                  

                  <Row>
                    <Col style={{marginTop:'10px'}}><h6>長期的目標（1年後の目標）</h6></Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>①</label>
                      <Input type="text" style={{border:'none'}} placeholder="例 : 全日本ジュニアベスト8" onChange={getInputLognTarget1Value} id="longtarget1" value={longtarget1 == null ? '' : longtarget1}/>                                            
                    </Col>                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none'}} placeholder="例 : 結果を記入" onChange={getInputLognTarget1_Value} id="longtarget1_" value={longtarget1_ == null ? '' : longtarget1_}/>                                        
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>②</label>
                      <Input type="text" style={{border:'none'}} placeholder="例 : ITFランキング1000位代" onChange={getInputLognTarget2Value} id="longtarget2" value={longtarget2 == null ? '' : longtarget2}/>                        
                    </Col>      
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none'}} placeholder="例 : 結果を記入" onChange={getInputLognTarget2_Value} id="longtarget2_" value={longtarget2_ == null ? '' : longtarget2_}/>                                          
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>③</label>
                      <Input type="text" style={{border:'none'}} placeholder="例 : 公認大会で3Rまで進む" onChange={getInputLognTarget3Value} id="longtarget3" value={longtarget3 == null ? '' : longtarget3}/>                      
                    </Col>                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none'}} placeholder="例 : 結果を記入" onChange={getInputLognTarget3_Value} id="longtarget3_" value={longtarget3_ == null ? '' : longtarget3_}/>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{marginTop:'10px'}}><h6>中期的な目標（半年後の目標）</h6></Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">                      
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>①</label>                        
                      <Input type="text" style={{border:'none'}} placeholder="例 : 関東ジュニアベスト8" onChange={getInputMidtarget1Value} id="midtarget1" value={midtarget1 == null ? '' : midtarget1}/>                      
                    </Col>                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none'}} placeholder="例 : 結果を記入" onChange={getInputMidtarget1_Value} id="midtarget1_" value={midtarget1_ == null ? '' : midtarget1_}/>                      
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>②</label>                     
                      <Input type="text" style={{border:'none'}} placeholder="例 : 毎卜ー優勝4" onChange={getInputMidtarget2Value} id="midtarget2" value={midtarget2 == null ? '' : midtarget2}/>
                      
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none'}} placeholder="例 : 結果を記入" onChange={getInputMidtarget2_Value} id="midtarget2_" value={midtarget2_ == null ? '' : midtarget2_}/>                     
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>③</label> 
                      <Input type="text" style={{border:'none'}} placeholder="例 : 公認大会に5試合以上出場する" onChange={getInputMidtarget3Value} id="midtarget3" value={midtarget3== null ? '' : midtarget3}/>
                      
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none'}} placeholder="例 : 結果を記入" onChange={getInputMidtarget3_Value} id="midtarget3_" value={midtarget3_ == null ? '' : midtarget3_}/>                      
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{marginTop:'10px'}}><h6>短期的目標（3ヶ月後の目標）</h6></Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>①</label> 
                      <Input type="text" style={{border:'none'}} placeholder="例 : 県ジュニアべスト" onChange={getInputShorttarget1Value} id="shorttarget1" value={shorttarget1 == null ? '' : shorttarget1}/>
                      
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                     <Input type="text" style={{border:'none'}} placeholder="例 : 結果を記入" onChange={getInputShorttarget1_Value} id="shorttarget1_" value={shorttarget1_ == null ? '' : shorttarget1_}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>②</label> 
                      <Input type="text" style={{border:'none'}} placeholder="例 : 小学生大会、中学生大会、高体連べスト8" onChange={getInputShorttarget2Value} id="shorttarget2" value={shorttarget2 == null ? '' : shorttarget2}/>
                                            
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none'}} placeholder="例 : 結果を記入" onChange={getInputShorttarget2_Value} id="shorttarget2_" value={shorttarget2_ == null ? '' : shorttarget2_}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>③</label>
                      <Input type="text" style={{border:'none'}} placeholder="例 : 〇〇くんに勝つ" onChange={getInputShorttarget3Value} id="shorttarget3" value={shorttarget3 == null ? '' : shorttarget3}/>
                                            
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                    <Input type="text" style={{border:'none'}} placeholder="例 : 結果を記入" onChange={getInputShorttarget3_Value} id="shorttarget3_" value={shorttarget3_ == null ? '' : shorttarget3_}/>
                    </Col>
                  </Row>
                  {/*<Row>
                                      <Col style={{marginTop:'10px'}}><h6>短期的目標に向かって行動計画</h6></Col>
                                    </Row>
                                    <Row>
                                      <Col className="px-1">
                                        <FormGroup>                       
                                          <Input type="text" onChange={getInputPlan1Value} id="plan1" value={plan1 == null ? '' : plan1}/>                       
                                          <Input type="text" onChange={getInputPlan2Value} id="plan2" value={plan2 == null ? '' : plan2}/>                       
                                        </FormGroup>
                                      </Col>
                                    </Row>*/}

                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>                        
                        <label>名前	プレイヤー</label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>                        
                        <Input type="text" onChange={getInputPlaynameValue} id="playname" value={playname == null ? '' : playname}/>                                               
                      </FormGroup>
                    </Col>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <Input type="date" onChange={getInputEditdateValue} id="edit_date" value={edit_date == null ? '' : edit_date}  step="1"/>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="col-6">
                      <FormGroup style={{marginBottom:'3px'}}>   
                        <label>名前	コーチ</label>                     
                        <Input type="text" onChange={getInputCotchnameValue} id="cotchname" value={cotchname == null ? '' : cotchname}/>                                               
                      </FormGroup>
                    </Col>                    
                  </Row> */}
                  <Row>                    
                    <Col className="px-1">
                      <input type="button" name="user" value="保存" className="btn-round btn btn-danger" onClick={goalUpdateFunc}/>
                    </Col>    
                    <Col className="px-1">
                      <input type="button" name="user" value="戻る" className="btn-round btn btn-primary" onClick={preparationPage}/>
                    </Col>                                  
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

function goalUpdateFunc(){
 
  var color = Math.floor(Math.random() * 5 + 1);
  var type;
  switch (color) {
    case 1:
      type = "primary";
      break;
    case 2:
      type = "success";
      break;
    case 3:
      type = "danger";
      break;
    case 4:
      type = "warning";
      break;
    case 5:
      type = "info";
      break;
    default:
      break;
  }
  
  let erroMsg = "";

  if(target== undefined || !target["edit_date"] || target["edit_date"] == ""){
    erroMsg += "「記入日」";
  }
  if(erroMsg != ""){
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            <b>{erroMsg}</b>が必須項目です。
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
    return false;
  }

  
  var data = JSON.stringify(target);
  
  axios.get(URL_API+"bigUpdateAPI", {
    params: {
      data: data, 
    }
  })
  .then(function (response) {    
      
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            <b>「課題目標設定画面編集」</b>が 完了しました。
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
  
    if(response.data != "y"){
        
      window.sessionStorage.setItem("target", JSON.stringify(response.data));
      target = window.sessionStorage.getItem('target');
      target = JSON.parse(target);
    }      
    
    notificationAlert.current.notificationAlert(options);
  })
}
export default Goalsettingedit;
