/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import PlayerList from './PlayerList.js';

import {  
  Card,  
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let target = {}; 
let session; 
let notificationAlert;
let se;

const BigGoalSettingedit = (e) => {    

  notificationAlert = React.useRef(); 
  
  const history = useHistory();

  if(session === null){    
    window.location = '/';
  }
    
  const preparationPage = (e) => {    
    history.push('../coach/biggoalsetting');
  }
  
///////////////////

  const [edit_date, setEditdate] = useState();
  const [target1, settarget1] = useState();
  const [target2, settarget2] = useState();
  const [target3, settarget3] = useState();
  const [longtarget1, setlongtarget1] = useState();
  const [longtarget1_, setlongtarget1_] = useState();
  const [longtarget2, setlongtarget2] = useState();
  const [longtarget2_, setlongtarget2_] = useState();
  const [longtarget3, setlongtarget3] = useState();
  const [longtarget3_, setlongtarget3_] = useState();
  const [midtarget1, setmidtarget1] = useState();
  const [midtarget1_, setmidtarget1_] = useState();
  const [midtarget2, setmidtarget2] = useState();
  const [midtarget2_, setmidtarget2_] = useState();
  const [midtarget3, setmidtarget3] = useState();
  const [midtarget3_, setmidtarget3_] = useState();
  const [shorttarget1, setshorttarget1] = useState();
  const [shorttarget1_, setshorttarget1_] = useState();
  const [shorttarget2, setshorttarget2] = useState();
  const [shorttarget2_, setshorttarget2_] = useState();
  const [shorttarget3, setshorttarget3] = useState();
  const [shorttarget3_, setshorttarget3_] = useState();
  const [plan1, setplan1] = useState();
  const [plan2, setplan2] = useState();
  const [playname, setplayname] = useState();
  const [cotchname, setcotchname] = useState();
  useEffect(() => {
    
    session = window.sessionStorage.getItem('player'); 
    let se = JSON.parse(session);
    if(!se){
      se = JSON.parse(window.sessionStorage.getItem('players'))[0];
    }
    if(!se){
      history.push('../coach/userMg');
    }
    // session = window.sessionStorage.getItem('sessions');
    
    if(window.sessionStorage.getItem('target') !== null ){

      target = window.sessionStorage.getItem('target'); 
      target = JSON.parse(target); 

      settarget1(target['target1']);
      settarget2(target['target2']);
      settarget3(target['target3']);
      
      setlongtarget1(target['longtarget1']);
      setlongtarget2(target['longtarget2']);
      setlongtarget3(target['longtarget3']);
      setlongtarget1_(target['longtarget1_']);
      setlongtarget2_(target['longtarget2_']);
      setlongtarget3_(target['longtarget3_']);

      setmidtarget1(target['midtarget1']);
      setmidtarget1_(target['setmidtarget1_']);
      setmidtarget2(target['midtarget2']);
      setmidtarget2_(target['midtarget2_']);
      setmidtarget3(target['midtarget3']);
      setmidtarget3_(target['midtarget3_']);
      setshorttarget1(target['shorttarget1']);
      setshorttarget1_(target['shorttarget1_']);
      setshorttarget2(target['shorttarget2']);
      setshorttarget2_(target['shorttarget2_']);
      setshorttarget3(target['shorttarget3']);
      setshorttarget3_(target['shorttarget3_']);
      setplan1(target['plan1']);
      setplan2(target['plan2']);
      setplayname(target['playname']);
      setEditdate(target['edit_date']);
      setcotchname(target['cotchname']);
    }else{
      
    }

    // console.log(biggoal);
    // // //戦術
    // se = JSON.parse(session);

    target['user_id'] = se["id"];

  }, []);
  return (

    <>
      <div className="content" style={{padding:'10px'}}>
        <NotificationAlert ref={notificationAlert}  className="col-12"/>
        <Row>          
          <Col md="12">
            <Card className="card-user">              
              <CardBody>
                <Form>     
                  <Row>
                    <Col style={{marginTop:'10px'}}><h6>大きな目標（夢）とする事	</h6></Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">                                                    
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>①</label> 
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 世界ランキン1位" readOnly="readonly" id="target1" value={target1 == null ? '' : target1}/>                                            
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>②</label>
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : グランドスラム出場" readOnly="readonly" id="technical2" value={target2 == null ? '' : target2}/>                                            
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>③</label>
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 全国大会優勝" readOnly="readonly" id="technical3" value={target3 == null ? '' : target3}/>                                            
                    </Col>
                  </Row>                  

                  <Row>
                    <Col style={{marginTop:'10px'}}><h6>長期的目標（1年後の目標）</h6></Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>①</label>
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 全日本ジュニアベスト8" readOnly="readonly" id="longtarget1" value={longtarget1 == null ? '' : longtarget1}/>                                            
                    </Col>                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 結果を記入" readOnly="readonly" id="longtarget1_" value={longtarget1_ == null ? '' : longtarget1_}/>                                        
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>②</label>
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : ITFランキング1000位代" readOnly="readonly" id="longtarget2" value={longtarget2 == null ? '' : longtarget2}/>                        
                    </Col>      
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 結果を記入" readOnly="readonly" id="longtarget2_" value={longtarget2_ == null ? '' : longtarget2_}/>                                          
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>③</label>
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 公認大会で3Rまで進む" readOnly="readonly" id="longtarget3" value={longtarget3 == null ? '' : longtarget3}/>                      
                    </Col>                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 結果を記入" readOnly="readonly" id="longtarget3_" value={longtarget3_ == null ? '' : longtarget3_}/>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{marginTop:'10px'}}><h6>中期的な目標（半年後の目標）</h6></Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">                      
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>①</label>                        
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 関東ジュニアベスト8" readOnly="readonly" id="midtarget1" value={midtarget1 == null ? '' : midtarget1}/>                      
                    </Col>                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 結果を記入" readOnly="readonly" id="midtarget1_" value={midtarget1_ == null ? '' : midtarget1_}/>                      
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>②</label>                     
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 毎卜ー優勝4" readOnly="readonly" id="midtarget2" value={midtarget2 == null ? '' : midtarget2}/>
                      
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 結果を記入" readOnly="readonly" id="midtarget2_" value={midtarget2_ == null ? '' : midtarget2_}/>                     
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>③</label> 
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 公認大会に5試合以上出場する" readOnly="readonly" id="midtarget3" value={midtarget3== null ? '' : midtarget3}/>
                      
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 結果を記入" readOnly="readonly" id="midtarget3_" value={midtarget3_ == null ? '' : midtarget3_}/>                      
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{marginTop:'10px'}}><h6>短期的目標（3ヶ月後の目標）</h6></Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>①</label> 
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 県ジュニアべスト" readOnly="readonly" id="shorttarget1" value={shorttarget1 == null ? '' : shorttarget1}/>
                      
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                     <Input type="text" style={{border:'none', background:'white'}} readOnly="readonly" placeholder="例 : 結果を記入" id="shorttarget1_" value={shorttarget1_ == null ? '' : shorttarget1_}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>②</label> 
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 小学生大会、中学生大会、高体連べスト8" readOnly="readonly" id="shorttarget2" value={shorttarget2 == null ? '' : shorttarget2}/>
                                            
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 結果を記入" readOnly="readonly" id="shorttarget2_" value={shorttarget2_ == null ? '' : shorttarget2_}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                      
                      <label style={{marginLeft:'10px', paddingTop:'5px', textAlign:'center'}}>③</label>
                      <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 〇〇くんに勝つ" readOnly="readonly" id="shorttarget3" value={shorttarget3 == null ? '' : shorttarget3}/>
                                            
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="biggoalcol">
                    <Input type="text" style={{border:'none', background:'white'}} placeholder="例 : 結果を記入" readOnly="readonly" id="shorttarget3_" value={shorttarget3_ == null ? '' : shorttarget3_}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{marginTop:'10px'}}><h6>短期的目標に向かって行動計画</h6></Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup>                       
                        <Input type="text" readOnly="readonly" style={{background:'white'}} id="plan1" value={plan1 == null ? '' : plan1}/>                       
                        <Input type="text" readOnly="readonly" style={{background:'white'}} id="plan2" value={plan2 == null ? '' : plan2}/>                       
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>                        
                        <label>名前	プレイヤー</label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>                        
                        <Input type="text" readOnly="readonly" style={{background:'white'}} id="playname" value={playname == null ? '' : playname}/>                                               
                      </FormGroup>
                    </Col>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <Input type="date" readOnly="readonly" style={{background:'white'}} id="edit_date" value={edit_date == null ? '' : edit_date}  step="1"/>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="col-6">
                      <FormGroup style={{marginBottom:'3px'}}>   
                        <label>名前	コーチ</label>                     
                        <Input type="text" readOnly="readonly" style={{background:'white'}} id="cotchname" value={cotchname == null ? '' : cotchname}/>                                               
                      </FormGroup>
                    </Col>                    
                  </Row> */}
                  <Row>  
                    <Col className="px-1">
                      <input type="button" name="user" value="戻る" className="btn-round btn btn-primary" onClick={preparationPage}/>
                    </Col>                                  
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

function goalUpdateFunc(){
 
  var color = Math.floor(Math.random() * 5 + 1);
  var type;
  switch (color) {
    case 1:
      type = "primary";
      break;
    case 2:
      type = "success";
      break;
    case 3:
      type = "danger";
      break;
    case 4:
      type = "warning";
      break;
    case 5:
      type = "info";
      break;
    default:
      break;
  }
  
  let erroMsg = "";

  if(target== undefined || !target["edit_date"] || target["edit_date"] == ""){
    erroMsg += "「記入日」";
  }
  if(erroMsg != ""){
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            <b>{erroMsg}</b>が必須項目です。
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
    return false;
  }

  
  var data = JSON.stringify(target);
  
  axios.get(URL_API+"bigUpdateAPI", {
    params: {
      data: data, 
    }
  })
  .then(function (response) {    
      
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            <b>「課題目標設定画面編集」</b>が 完了しました。
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
  
    if(response.data != "y"){
        
      window.sessionStorage.setItem("target", JSON.stringify(response.data));
      target = window.sessionStorage.getItem('target');
      target = JSON.parse(target);
    }      
    
    notificationAlert.current.notificationAlert(options);
  })
}
export default BigGoalSettingedit;
