/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Login from "views/user/Login.js";
import Register from  "views/user/Register.js";
import Register1 from  "views/user/Register1.js";
import Icons from "views/Icons.js";
var routes = [
  {
    path: "/login",
    name: "ダッシュボード",
    icon: "nc-icon nc-chart-bar-32",
    component: Login,
    layout: "/user",
  },
  {
    path: "/register",
    name: "ダッシュボード",
    icon: "nc-icon nc-chart-bar-32",
    component: Register,
    layout: "/user",
  },
  {
    path: "/register1",
    name: "ダッシュボード",
    icon: "nc-icon nc-chart-bar-32",
    component: Register1,
    layout: "/user",
  },  
  {
    path: "/icons",
    name: "自己分析画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Icons,
    layout: "/user",
  },
];
export default routes;
