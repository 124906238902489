/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";
import DatePicker from 'react-mobile-datepicker';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Modal from 'react-modal';
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

// add new codes
import { useHistory } from 'react-router-dom';
import dataTypes from "views/diary/dataType.js";

import "assets/css/diary.css";
// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
var aTimeout = null;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0.25rem',
    width:'85%',
    borderRadius:'15px',
    fontWeight:'bold'
  },
};

function checkTimeNum(t, f){
    if(Number(t) > f)
      return f;
    else
      return Number(t);
}
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function Diary() {

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }
  
  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

  // let session = window.sessionStorage.getItem('sessions');
  
  // add new codes  

    var date = new Date();  
    const [diaryData, setDiaryData] = useState([]);
    const [dataItems, setDataItems] = useState([]);
    const [renderData, setRenderData] =useState({});

    const [todayDate, setTodayDate] =  useState(date.getDate());
    const [todayDay, setTodayDay] = useState(weekday[date.getDay()]);
    const [todayVDate, setTodayVDate] =  useState(month[date.getMonth()]+" "+date.getFullYear());
    const [today, setToday] =  useState(date);
    const [sToday, setSToday] =  useState(date);
    // const [timeFname, setTimeFname] = useState('studystart');
    const [reLoadFlag, setReLoadFlag] = useState(false);
    // console.log(todayDate);
    // var datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    // d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    const [getKey, setGetKey] = useState(date.getFullYear()+"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2));
    const [dateType, setDateType] = useState('ymd');
    const [dragPadding, setDragPadding] = useState({paddingBottom:'15px'});
    const [dateConfig, setDateConfig] = useState({
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        },
        'month': {
            format: 'MM',
            caption: 'Mon',
            step: 1,
        },
        'date': {
            format: 'DD',
            caption: 'Day',
            step: 1,
        }
    });

    const [isDualNumberModal, setIsDualNumberModal] = useState('hide');
    const [startNumber, setStartNumber]             = useState(0);
    const [endNumber, setEndNumber]                 = useState(0);
    const [curEditItem, setCurEditItem]             = useState({});
    const [isNumbersModal, setIsNumbersModal]       = useState('hide');
    const [numberLabel, setNumberLabel]             = useState('');
    const [labelNumber, setLabelNumber]             = useState('');

    const [isDualTimeModal, setIsDualTimeModal] = useState('hide');
    const [startHm, setStartHm]  = useState('00:00');
    const [endHm, setEndHm]  = useState('00:00');
    const [editTFlag, setEditTFlag] = useState(1);

    const [isDualTimesModal, setIsDualTimesModal] = useState('hide');
    const [startHms, setStartHms]  = useState('00:00:00');
    const [endHms, setEndHms]  = useState('00:00:00');
    const [isModal, setIsModal]         = useState('hide');
    const [number1, setNumber1]         = useState(0);
    const [nMax, setNMax]               = useState(100);
    const [isComModal, setIsComModal]   = useState(false);
    const [comment, setComment]         = useState('');


    const setDiaryDataStorage = (key, v, setFuncName) => {
      // console.log(key, v, setFuncName);
      let d = JSON.parse(window.sessionStorage.getItem('diaryData'));
      d[getKey][key] = v;
      window.sessionStorage.setItem('diaryData', JSON.stringify(d));
      eval(setFuncName)(v);
      sendToServerData(key, v);
    }
  // end
  const technicalPlus = (event, dn)=>{  
    var sel = event.target.getAttribute('val');
    var func = event.target.getAttribute('func');
    // console.log(sel, func, dn);
    if(sel != "eat1" && sel != "eat2" && sel != "eat3"){
      if(dn<5){
        setDiaryDataStorage(sel, ++dn, func); 
        return;
      }
    }else{
      if(dn<3){
        setDiaryDataStorage(sel, ++dn, func); 
        return;
      }
    }
  };
  const technicalMinu = (event, dn)=>{    
    // console.log(event);
    var sel = event.target.getAttribute('val');
    var func = event.target.getAttribute('func');
    if(dn > 0){ 
        setDiaryDataStorage(sel, --dn, func);
    }    
  };

  const changeDateValue = (event)=>{   
    var sel = event.target.getAttribute('val');
    var func = event.target.getAttribute('func');
    var v = event.target.value;
    setDiaryDataStorage(sel, v, func);
  };

  const sendToServerData = (fname, v) => {
    let session = window.sessionStorage.getItem('sessions');
    let se = JSON.parse(session);
    axios.get(URL_API+"setChartData", {
        params: {
          fname: fname, 
          v:v,
          seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
          flag:0,
          user: se['id'],
        }
    })
    .then(function (response) {
      
    }); 
  }

  const setStateDatas = (data) => {
    
  }

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(f, fname) {
    // console.log(f, fname);
    setIsOpen(true);
    setDateType(f);
    if(f == 'ymd'){
      setDateConfig({
          'year': {
              format: 'YYYY',
              caption: 'Year',
              step: 1,
          },
          'month': {
              format: 'MM',
              caption: 'Mon',
              step: 1,
          },
          'date': {
              format: 'DD',
              caption: 'Day',
              step: 1,
          }
      });
    }else{
      // setTimeFname(fname);
      var t = eval(fname).split(':');
      if(isNaN(Number(t[0])))
        t[0] = 0;
      if(isNaN(Number(t[1])))
        t[1] = 0;
      // console.log(eval(fname), t);
      t[0] = checkTimeNum(t[0], 23);
      t[1] = checkTimeNum(t[1], 59);
      // var tod = sToday;
      // tod.setHours(t[0]);
      // tod.setMinutes(t[1]);
      var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
      // console.log(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
      setSToday(tods);
      setDateConfig({
        'hour': {
          format: 'hh',
          caption: 'Hour',
          step: 1,
        },
        'minute': {
          format: 'mm',
          caption: 'Min',
          step: 1,
        },
      });
    }
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    
  }

  function closeModal() {
    setIsOpen(false);
  }
  // add new codes
  const history = useHistory();

  const setStorages = (item) => {    
    window.sessionStorage.setItem('selectedItem', JSON.stringify(item));
    let to_day = today;
    if(!isValidDate(to_day)){
      to_day = new Date();
      setToday(to_day)
    }
    sessionStorage.setItem("today", to_day);
  }

  const showChart = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryChart');
  };

  const showChart3 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryChart3');
  };

  const showChart4 = (item, e) => { 
    setStorages(item);
    history.push('../admin/diaryChart4');
  };

  const showChart5 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryChart5');
  };

  const showChart6 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryChart6');
  };

  const showChart7 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryChart7');
  };

  const showChart8 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryChart8');
  };

  const showChart9 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryChart9');
  };

  const showChart10 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryChart10');
  };

  const showChart11 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryChart11');
  };


  const showList = (item, e) => { 
    setStorages(item);
    history.push('../admin/diaryList');
  };

  const showList3 = (item, e) => { 
    setStorages(item);
    history.push('../admin/diaryList3');
  };

  const showList4 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryList4');
  };

  const showList5 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryList5');
  };

  const showList6 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryList6');
  };

  const showList7 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryList7');
  };

  const showList8 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryList8');
  };

  const showList9 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryList9');
  };

  const showList10 = (item, e) => {
    setStorages(item);
    history.push('../admin/diaryList10');
  };

  const selectDate = (time) => {
    if(dateType == 'ymd'){
      setToday(time);
      setSToday(time);
      setTodayDate(time.getDate());
      setTodayDay(weekday[time.getDay()]);
      setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
      setGetKey(time.getFullYear()+"-"+("0"+(time.getMonth()+1)).slice(-2)+"-"+("0" + time.getDate()).slice(-2));
    }else if(dateType == 'hm'){
      var h = time.getHours();
      var m = time.getMinutes();
      // console.log(timeFname, h, m);
      var t = ("0"+h).slice(-2)+":"+("0" + m).slice(-2);
      var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t);
      // console.log(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
      setSToday(tods);
      let sData = {};
      if(curEditItem.data_type == 5){
        sData = {start:t};
        sendServerData(curEditItem, sData);
      }else if(curEditItem.data_type == 7){
        if(editTFlag == 1){
          setStartHm(t);
        }else{
          setEndHm(t);
        }
      }
    }else{
      var h = time.getHours();
      var m = time.getMinutes();
      var s = time.getSeconds();
      // console.log(timeFname, h, m);
      var t = ("0"+h).slice(-2)+":"+("0" + m).slice(-2)+":"+("0" + s).slice(-2);
      var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t);
      // console.log(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
      setSToday(tods);
      let ts = ("0"+h).slice(-2)+":"+("0" + m).slice(-2)+"`"+("0" + s).slice(-2);
      let sData = {};
      if(curEditItem.data_type == 6){
        sData = {start:t};
        sendServerData(curEditItem, sData);
      }else if(curEditItem.data_type == 8){
        if(editTFlag == 1){
          setStartHms(ts);
        }else{
          setEndHms(ts);
        }
      }
      
    }
    setIsOpen(false);
    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  const goToPage = (pname) =>{
    history.push('../admin/setting');
  }

  const onChangeDate = (e) => {
    // console.log(e);
  }

  const sendServerData = (item, v) => {    
    axios.get(URL_API+"setDiaryItemData", {
      params: {
        seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
        user: se['id'],
        item_id: item.id,
        v:v
      }
    })
    .then(function (response) {
      // setReLoadFlag(!reLoadFlag);
      window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
      // window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));

      setDiaryData(response.data.data);
      setDataItems(response.data.items);
      // let data = response.data.data[getKey];
      // console.log(getKey, response.data.data[getKey]);
      if(response.data.data[getKey]){
        checkRenderData(response.data.data[getKey], response.data.items);
      }
      else{
        setRenderData({});
      }
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
  }

  const renderStarData = (item, num) => {
    let rd = renderData;
    if(!rd[item.id]){
      rd[item.id] = 0;
    }
    // console.log(rd);
    let dArr = [];
    for(let i=1;i<=num;i++){
      dArr.push({n:i, sel:(i<=rd[item.id] ? 'gold':'noGold')});
    }
    // setRenderData(rd);
    return dArr;
  }

  const onReSetStar = (e, item, sItem) => {
    let rd = renderData;
    if(!rd[item.id]){
      rd[item.id] = 0;
    }
    // console.log(rd[item.id], sItem.n);
    if(sItem.n == rd[item.id] && rd[item.id] == 1)
      rd[item.id] = 0;
    else
      rd[item.id] = sItem.n;
    checkRenderData(rd, dataItems);
    sendServerData(item, rd[item.id]);
  }

  const changeCommentDataValue = (e, item) => {
    setIsComModal(true);
    setCurEditItem(item);
    let rd = renderData;
    if(!rd[item.id]){
      rd[item.id] = '';
      checkRenderData(rd, dataItems);
    }
    setComment(rd[item.id]);
  }

  const onChangeComment = (e) => {
    setComment(e.target.value);
    if(aTimeout)
      clearTimeout(aTimeout);

    aTimeout = setTimeout(function(){
      let d = renderData[curEditItem.id];
      d = e.target.value;
      sendServerData(curEditItem, d);
    }, 500);
  }

  function afterOpenModal() {

  }

  function closeModal() {
    setIsComModal(false);
  }

  const changeNumberDataValue = (event, item)=>{
    setIsModal('_show');
    setCurEditItem(item);
    let rd = renderData;
    if(!rd[item.id]){
      rd[item.id] = 0;
      checkRenderData(rd, dataItems);
    }
    setNumber1(rd[item.id]);
    // checkRenderData(d, dataItems);
    // sendServerData(item, v);    
  };

  const onChangeNumber = (event, i) => {
    if(i==1){
      setStartNumber(event.target.value);
    }else{
      setEndNumber(event.target.value);
    }
  }

  const onChangeNumber1 = (event) => {
    let v = event.target.value;
    if(curEditItem.data_type == 1)
      v = Math.floor(v);
    setNumber1(v);
  }

  const changeDualNumberDataValue = (event, item) => {
    if(!renderData[item.id]){
      let rd = renderData;
      rd[item.id] = {start:0, end:0};
    }
    setStartNumber(renderData[item.id] ? renderData[item.id].start : 0);
    setEndNumber(renderData[item.id] ? renderData[item.id].end : 0);
    setCurEditItem(item);
    setIsDualNumberModal('');
  }

  const changeNumbersDataValue = (event, item) => {
    if(!renderData[item.id]){
      let rd = renderData;
      rd[item.id] = {};
      checkRenderData(rd, dataItems);
    }
    setCurEditItem(item);
    setLabelNumber('');
    setNumberLabel('');
    setIsNumbersModal('');
  }

  const changeHm2DataValue = (event, item) => {
    if(!renderData[item.id]){
      let rd = renderData;
      rd[item.id] = {start:'00:00', end:'00:00'};
      checkRenderData(rd, dataItems);
    }
    setStartHm(renderData[item.id] && renderData[item.id].start ? renderData[item.id].start : '00:00');
    setEndHm(renderData[item.id] && renderData[item.id].end ? renderData[item.id].end : '00:00');
    setCurEditItem(item);
    setIsDualTimeModal('');
  }

  const changeHms2DataValue = (event, item) => {
    if(!renderData[item.id]){
      let rd = renderData;
      rd[item.id] = {start:'00:00:00', end:'00:00:00'};
      checkRenderData(rd, dataItems);
    }
    setStartHms(renderData[item.id] && renderData[item.id].start ? formatDateTimeType(renderData[item.id].start) : '00:00`00');
    setEndHms(renderData[item.id] && renderData[item.id].end ? formatDateTimeType(renderData[item.id].end) : '00:00`00');
    setCurEditItem(item);
    setIsDualTimesModal('');
  }

  const changeCheckDataValue = (event, item, idx) => {
    if(event.target.id == "ci_"+idx)
      return;
    let rd = renderData;
    if(rd[item.id]==undefined || rd[item.id]==null){
      rd[item.id] = 1;
      checkRenderData(rd, dataItems);
    }else{
      rd[item.id] = rd[item.id] == 1 ? 0 : (rd[item.id] == 2 ? 0 : 1);
      checkRenderData(rd, dataItems);
    }
    setCurEditItem(item);
    sendServerData(item, rd[item.id]);
  }

  const changeCheckDataValue1 = (event, item, idx) => {
    // console.log(event.target.id, id);
    if(renderData[item.id]==undefined || renderData[item.id]==null){
      let rd = renderData;
      rd[item.id] = 2;
      // console.log('8');
      checkRenderData(rd, dataItems);
    }else{
      let rd = renderData;
      rd[item.id] = 2;
      checkRenderData(rd, dataItems);
    }
    setCurEditItem(item);
    sendServerData(item, renderData[item.id]);
  }

  const onChangeHm2 = (event, i) => {
    setEditTFlag(i); 
    let item = curEditItem;
    var t=['00', '00'];
    if(i ==1){
      if(renderData[item.id] && renderData[item.id].start){
        t = renderData[item.id].start.split(':');        
        if(isNaN(Number(t[0])))
          t[0] = 0;
        if(isNaN(Number(t[1])))
          t[1] = 0;
      }else{
        let rd = renderData;
        rd[item.id] = {start:t[0]+":"+t[1]};
        checkRenderData(rd, dataItems);
      }
    }else{
      if(renderData[item.id] && renderData[item.id].end){
        t = renderData[item.id].end.split(':');
        if(isNaN(Number(t[0])))
          t[0] = 0;
        if(isNaN(Number(t[1])))
          t[1] = 0;
      }else{
        let rd = renderData;
        rd[item.id] = {end:t[0]+":"+t[1]};
        checkRenderData(rd, dataItems);
      }
    }
    //console.log(t);

    t[0] = checkTimeNum(t[0], 23);
    t[1] = checkTimeNum(t[1], 59);

    // var tod = sToday;
    // tod.setHours(t[0]);
    // tod.setMinutes(t[1]);
    var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
    // console.log(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
    setDateType('hm');
    setSToday(tods);
    setDateConfig({
      'hour': {
        format: 'hh',
        caption: 'Hour',
        step: 1,
      },
      'minute': {
        format: 'mm',
        caption: 'Min',
        step: 1,
      },
    });
    setIsOpen(true);
  }

  const onChangeHms2 = (event, i) => {   
    setEditTFlag(i); 
    let item = curEditItem;
    var t=['00', '00', '00'];
    if(i ==1){
      if(renderData[item.id] && renderData[item.id].start){
        t = renderData[item.id].start.split(':');        
        if(isNaN(Number(t[0])))
          t[0] = 0;
        if(isNaN(Number(t[1])))
          t[1] = 0;
        if(isNaN(Number(t[2])))
          t[2] = 0;
      }else{
        let rd = renderData;
        rd[item.id] = {start:t[0]+":"+t[1]+":"+t[2]};
        checkRenderData(rd, dataItems);
      }
    }else{
      if(renderData[item.id] && renderData[item.id].end){
        t = renderData[item.id].end.split(':');        
        if(isNaN(Number(t[0])))
          t[0] = 0;
        if(isNaN(Number(t[1])))
          t[1] = 0;
        if(isNaN(Number(t[2])))
          t[2] = 0;
      }else{
        let rd = renderData;
        rd[item.id] = {end:t[0]+":"+t[1]+":"+t[2]};
        checkRenderData(rd, dataItems);
      }
    }
    t[0] = checkTimeNum(t[0], 23);
    t[1] = checkTimeNum(t[1], 59);
    t[2] = checkTimeNum(t[2], 59);
    // var tod = sToday;
    // tod.setHours(t[0]);
    // tod.setMinutes(t[1]);
    // tod.setSeconds(t[2]);
    var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]+":"+t[2]);
    // console.log(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
    setDateType('hms');
    setSToday(tods);
    setDateConfig({
      'hour': {
        format: 'hh',
        caption: 'Hour',
        step: 1,
      },
      'minute': {
        format: 'mm',
        caption: 'Min',
        step: 1,
      },
      'second': {
        format: 'ss',
        caption: 'Sec',
        step: 1,
      },
    });
    setIsOpen(true);
  }

  const changeHm1DataValue = (event, item) => {
    setCurEditItem(item);
    var t = ['00', '00'];
    if(renderData[item.id] && renderData[item.id].start){
      t = renderData[item.id].start.split(':');
      if(isNaN(Number(t[0])))
        t[0] = 0;
      if(isNaN(Number(t[1])))
        t[1] = 0;
    }else{
      let rd = renderData;
      rd[item.id] = {start:t[0]+":"+t[1]};
      checkRenderData(rd, dataItems);
    }
    t[0] = checkTimeNum(t[0], 23);
    t[1] = checkTimeNum(t[1], 59);
    // var tod = sToday;
    // tod.setHours(t[0]);
    // tod.setMinutes(t[1]);
    var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
    // console.log(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
    setDateType('hm');
    setSToday(tods);
    setDateConfig({
      'hour': {
        format: 'hh',
        caption: 'Hour',
        step: 1,
      },
      'minute': {
        format: 'mm',
        caption: 'Min',
        step: 1,
      },
    });
    setIsOpen(true);
  }

  const changeHms1DataValue = (event, item) => {
    setCurEditItem(item);
    // var ts="00:00`00";
    var t = ['00','00','00'];
    if(renderData[item.id]){
      t = renderData[item.id].start.split(':');
      if(isNaN(Number(t[0])))
        t[0] = 0;
      if(isNaN(Number(t[1])))
        t[1] = 0;
      if(isNaN(Number(t[2])))
        t[2] = 0;
    }
    else{
      let rd = renderData;
      rd[item.id] = {start:t[0]+":"+t[1]+":"+t[2]};
      checkRenderData(rd, dataItems);
    }
    t[0] = checkTimeNum(t[0], 23);
    t[1] = checkTimeNum(t[1], 59);
    t[2] = checkTimeNum(t[2], 59);
    // var tod = sToday;
    // tod.setHours(t[0]);
    // tod.setMinutes(t[1]);
    // tod.setSeconds(t[2]);
    var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]+":"+t[2]);
    // console.log(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
    setDateType('hms');
    setSToday(tods);
    setDateConfig({
      'hour': {
        format: 'hh',
        caption: 'Hour',
        step: 1,
      },
      'minute': {
        format: 'mm',
        caption: 'Min',
        step: 1,
      },
      'second': {
        format: 'ss',
        caption: 'Sec',
        step: 1,
      },
    });
    setIsOpen(true);
  }

  const sumDataNumbers = (ds) => {
    let sum = 0;
    for (let key in ds) {
      sum += isNaN(Number(ds[key])) ? 0 : Number(ds[key]);
    }
    return sum;
  }

  const formatDateTimeType = (t) => {
    // console.log(t);
    let hms = t.split(':');
    return ("0"+hms[0]).slice(-2)+":"+("0" + hms[1]).slice(-2)+"`"+("0" + hms[2]).slice(-2);
  }

  const formatCheckData = (t) => {
    if(t == undefined || t == null || t == '0'){
      return "Not Yet.";
    }
    if(t == '1'){
      return 'DONE!';
    }
    if(t == '2'){
      return 'Not Done';
    }
  }

  const formatCheckData1 = (t, item, idx) => {
    if(!t || t == '0'){
      return (<i id={"ci_"+idx} className="fa fa-empty-set" style={{fontSize:'20px'}} onClick={(event) => changeCheckDataValue1(event, item, idx)}></i>);
    }else{
      return '';
    }
  }

  const getImgEffClass = (dat, item) => {
    // console.log(dat, item);
    let imgCls = ' no-fill-img';
    if(!dat || dat == {} || !dat[item.id])
      return imgCls;

    if(item.data_type == 1 || item.data_type == 2){
      imgCls = (!dat[item.id] || dat[item.id] <=0 ? ' no-fill-img' : '');
    }else if(item.data_type == 3){
      imgCls = ' no-fill-img';
      if(dat[item.id] && dat[item.id] != {}){
        if(dat[item.id].start && dat[item.id].start > 0){
          imgCls = ' some-fill-img';
        }
        if(dat[item.id].end && dat[item.id].end > 0){
          imgCls = ' fill-img';
        }
      }
    }else if(item.data_type == 4){
      if(sumDataNumbers(dat[item.id]) > 0)
        imgCls = ' fill-img';
      else
        imgCls = ' no-fill-img';
    }else if(item.data_type == 5){
      if(dat[item.id] == {})
        imgCls = ' no-fill-img';
      if(!dat[item.id].start || dat[item.id].start == '00:00')
        imgCls = ' no-fill-img';
      if(dat[item.id].start && dat[item.id].start != '00:00')
        imgCls = ' fill-img';
    }else if(item.data_type == 6){
      if(dat[item.id] == {})
        imgCls = ' no-fill-img';
      if(!dat[item.id].start || dat[item.id].start == '00:00:00')
        imgCls = ' no-fill-img';
      if(dat[item.id].start && dat[item.id].start != '00:00:00')
        imgCls = ' fill-img';
    }else if(item.data_type == 7){
      if(dat[item.id] == {})
        imgCls = ' no-fill-img';
      if(!dat[item.id].start || dat[item.id].start == '00:00')
        imgCls = ' no-fill-img';
      if(dat[item.id].start && dat[item.id].start != '00:00')
        imgCls = ' some-fill-img';
      // console.log(dat[item.id].end && dat[item.id].end != '00:00');
      if(dat[item.id].end && dat[item.id].end != '00:00'){
        imgCls = ' fill-img';
      }
    }else if(item.data_type == 8){
      if(dat[item.id] == {})
        imgCls = ' no-fill-img';
      if(!dat[item.id].start || dat[item.id].start == '00:00:00')
        imgCls = ' no-fill-img';
      if(dat[item.id].start && dat[item.id].start != '00:00:00')
        imgCls = ' some-fill-img';
      if(dat[item.id].end && dat[item.id].end != '00:00:00')
        imgCls = ' fill-img';
    }else if(item.data_type == 9){
      imgCls = (dat[item.id] == 1 ? ' fill-img' : ' no-fill-img');
    }else if(item.data_type == 10){
      imgCls = (dat[item.id] >= 1 ? ' fill-img' : ' no-fill-img');
    }else if(item.data_type == 12){
      // imgCls = (dat[item.id] >= 1 ? ' fill-img' : ' no-fill-img');
      imgCls = ((dat[item.id] && dat[item.id] != '') ? ' fill-img' : ' no-fill-img');
    }
    return imgCls;
  }

  const getValueEffClass = (dat, item, txt) => {
    let clsText = ' no-fill-'+txt;
    if(!dat || dat == {} || !dat[item.id])
      return clsText;

    if(item.data_type == 1 || item.data_type == 2){
      clsText = (!dat[item.id] || dat[item.id] <= 0 ? ' no-fill-'+txt : 'fill-'+txt);
    }else if(item.data_type == 3){
      clsText = ' no-fill-'+txt;
      if(dat[item.id] && dat[item.id] != {}){
        if(dat[item.id].start && dat[item.id].start > 0){
          clsText = ' some-fill-'+txt;
        }
        if(dat[item.id].end && dat[item.id].end > 0){
          clsText = ' fill-'+txt;
        }
      }
    }else if(item.data_type == 4){
      if(sumDataNumbers(dat[item.id]) > 0)
        clsText = ' fill-'+txt;
      else
        clsText = ' no-fill-'+txt;
    }else if(item.data_type == 5){
      if(dat[item.id] == {}){
        clsText = ' no-fill-'+txt;
      }
      if(!dat[item.id].start || dat[item.id].start == '00:00')
        clsText = ' no-fill-'+txt;
      if(dat[item.id].start && dat[item.id].start != '00:00')
        clsText = ' fill-'+txt;
    }else if(item.data_type == 6){
      if(dat[item.id] == {}){
        clsText = ' no-fill-'+txt;
      }
      if(!dat[item.id].start || dat[item.id].start == '00:00:00')
        clsText = ' no-fill-'+txt;
      if(dat[item.id].start && dat[item.id].start != '00:00:00')
        clsText = ' fill-'+txt;
    }else if(item.data_type == 7){
      if(dat[item.id] == {}){
        clsText = ' no-fill-'+txt;
      }
      if(!dat[item.id].start || dat[item.id].start == '00:00')
        clsText = ' no-fill-'+txt;
      if(dat[item.id].start && dat[item.id].start != '00:00')
        clsText = ' some-fill-'+txt;
      if(dat[item.id].end && dat[item.id].end != '00:00')
        clsText = ' fill-'+txt;
    }else if(item.data_type == 8){
      if(dat[item.id] == {}){
        clsText = ' no-fill-'+txt;
      }
      if(!dat[item.id].start || dat[item.id].start == '00:00:00')
        clsText = ' no-fill-'+txt;
      if(dat[item.id].start && dat[item.id].start != '00:00:00')
        clsText = ' some-fill-'+txt;
      if(dat[item.id].end && dat[item.id].end != '00:00:00')
        clsText = ' fill-'+txt;
    }else if(item.data_type == 9){
      clsText = (dat[item.id] == 1 ? ' fill-'+txt : ' no-fill-'+txt);
    }else if(item.data_type == 10){
      clsText = (dat[item.id] >= 1 ? ' fill-'+txt : ' no-fill-'+txt);
    }else if(item.data_type == 12){
      clsText = ((dat[item.id] && dat[item.id] != '') ? ' fill-'+txt : ' no-fill-'+txt);
    }
    return clsText;
  }

  const renderItemData_1 = (item, idx, provided, snapshot) => {
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col className="col-7 justify-content-center px-0">
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
            {item.title +'('+item.i_unit+')'}
          </Col>
          <Col className="col-12 px-0 d-flex justify-content-between" style={{fontSize:'1.07rem'}} onClick={(event) => changeNumberDataValue(event, item)}>
            <span className={getValueEffClass(renderData, item, 'value')} style={{width:'100px', border:'0px', fontWeight:'bold'}}>{renderData[item.id] ? renderData[item.id] : 0}</span>
          </Col>                    
        </Col>
        <Col className="col-3 chartList px-0">
          <i className="fas fa-chart-line" onClick={(event) => showChart(item, event)}></i>
          <i className="fas fa-list" onClick={(event) => showList(item, event)}></i>   
        </Col>
      </div>
    )
  }

  const renderItemData_2 = (item, idx, provided, snapshot) => {
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col className="col-7 justify-content-center px-0">
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
            {item.title +'('+item.i_unit+')'}
          </Col>
          <Col className="col-12 px-0 d-flex justify-content-between" style={{fontSize:'1.07rem'}} onClick={(event) => changeNumberDataValue(event, item)}>
            <span className={getValueEffClass(renderData, item, 'value')} style={{width:'100px', border:'0px', fontWeight:'bold'}}>{renderData[item.id] ? renderData[item.id] : 0}</span>
          </Col>                    
        </Col>
        <Col className="col-3 chartList px-0">
          <i className="fas fa-chart-line" onClick={(event) => showChart(item, event)}></i>
          <i className="fas fa-list" onClick={(event) => showList(item, event)}></i>
        </Col>
      </div>
    )
  }

  const renderItemData_3 = (item, idx, provided, snapshot) => {
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col className="col-7 justify-content-center px-0" onClick={(event) => changeDualNumberDataValue(event, item)}>
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
            {item.title +'('+item.i_unit+')'}
          </Col>
          <Col className="col-12 px-0" style={{fontSize:'1.07rem'}}>
            <span className={"w-100" + getValueEffClass(renderData, item, 'value')}>{renderData[item.id] ? renderData[item.id].start+' / '+renderData[item.id].end : '0 / 0'}</span>
          </Col>                    
        </Col>
        <Col className="col-3 chartList px-0">   
          <i className="fas fa-chart-line" onClick={(event) => showChart3(item, event)}></i>
          <i className="fas fa-list" onClick={(event) => showList3(item, event)}></i>
        </Col>
      </div>
    )
  }

  const renderItemData_4 = (item, idx, provided, snapshot) => {
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col className="col-7 justify-content-center px-0" onClick={(event) => changeNumbersDataValue(event, item)}>
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
            {item.title +'('+item.i_unit+')'}
          </Col>
          <Col className="col-12 px-0" style={{fontSize:'1.07rem'}}>
            <span className={"w-100" + getValueEffClass(renderData, item, 'value')}>{renderData[item.id] ? sumDataNumbers(renderData[item.id]) : 0}</span>
          </Col>                    
        </Col>
        <Col className="col-3 chartList px-0">
          <i className="fas fa-chart-line" onClick={(event) => showChart4(item, event)}></i>
          <i className="fas fa-list" onClick={(event) => showList4(item, event)}></i>  
        </Col>
      </div>
    )
  }

  const renderItemData_5 = (item, idx, provided, snapshot) => {
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col className="col-7 justify-content-center px-0" onClick={(event) => changeHm1DataValue(event, item)}>
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
            {item.title +'('+item.i_unit+')'}
          </Col>
          <Col className="col-12 px-0" style={{fontSize:'1.07rem'}}>
            <span className={"w-100" + getValueEffClass(renderData, item, 'value')}>{renderData[item.id] ? renderData[item.id].start : '00:00'}</span>
          </Col>                    
        </Col>
        <Col className="col-3 chartList px-0">
          <i className="fas fa-chart-line" onClick={(event) => showChart5(item, event)}></i>
          <i className="fas fa-list" onClick={(event) => showList5(item, event)}></i>
        </Col>
      </div>
    )
  }

  const renderItemData_6 = (item, idx, provided, snapshot) => {
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col className="col-7 justify-content-center px-0" onClick={(event) => changeHms1DataValue(event, item)}>
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
            {item.title +'('+item.i_unit+')'}
          </Col>
          <Col className="col-12 px-0" style={{fontSize:'1.07rem'}}>
            <span className={"w-100" + getValueEffClass(renderData, item, 'value')}>{renderData[item.id] ? formatDateTimeType(renderData[item.id].start) : '00:00`00'}</span>
          </Col>                    
        </Col>
        <Col className="col-3 chartList px-0">
          <i className="fas fa-chart-line" onClick={(event) => showChart6(item, event)}></i>
          <i className="fas fa-list" onClick={(event) => showList6(item, event)}></i>    
        </Col>
      </div>
    )
  }

  const renderItemData_7 = (item, idx, provided, snapshot) => {
    // console.log(renderData)
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col className="col-7 justify-content-center px-0" onClick={(event) => changeHm2DataValue(event, item)}>
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
            {item.title +'('+item.i_unit+')'}
          </Col>
          <Col className="col-12 px-0" style={{fontSize:'1.07rem'}}>
            <span className={"w-100" + getValueEffClass(renderData, item, 'value')}>{renderData[item.id] ? renderData[item.id].start + ' / ' + renderData[item.id].end: '00:00 / 00:00'}</span>
          </Col>                    
        </Col>
        <Col className="col-3 chartList px-0">
          <i className="fas fa-chart-line" onClick={(event) => showChart7(item, event)}></i>
          <i className="fas fa-list" onClick={(event) => showList7(item, event)}></i>
        </Col>
      </div>
    )
  }

  const renderItemData_8 = (item, idx, provided, snapshot) => {
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col className="col-7 justify-content-center px-0" onClick={(event) => changeHms2DataValue(event, item)}>
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
            {item.title +'('+item.i_unit+')'}
          </Col>
          <Col className="col-12 px-0" style={{fontSize:'1.07rem'}}>
            <span className={"w-100" + getValueEffClass(renderData, item, 'value')}>{renderData[item.id] ? formatDateTimeType(renderData[item.id].start) + ' / ' + formatDateTimeType(renderData[item.id].end) : '00:00`00 / 00:00`00'}</span>
          </Col>                    
        </Col>
        <Col className="col-3 chartList px-0">
          <i className="fas fa-chart-line" onClick={(event) => showChart8(item, event)}></i>
          <i className="fas fa-list" onClick={(event) => showList8(item, event)}></i>   
        </Col>
      </div>
    )
  }

  const renderItemData_9 = (item, idx, provided, snapshot) => {
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col id={"check_" + idx} className="col-7 row mx-0 justify-content-center px-0" onClick={(event) => changeCheckDataValue(event, item, idx)}>
          <Col className="col-8 justify-content-center px-0">
            <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
              {item.title}
            </Col>
            <Col className={"col-12 px-0" + getValueEffClass(renderData, item, 'value')} style={{fontSize:'1.07rem'}}>
              {formatCheckData(renderData[item.id])}
            </Col>                    
          </Col>
          <Col className="col-4 px-0 d-flex justify-content-end align-items-center">
            {formatCheckData1(renderData[item.id], item, idx)}          
          </Col>
        </Col>
        <Col className="col-3 chartList px-0">
          <i className="far fa-calendar-alt" style={{paddingLeft: '7px', paddingRight: '7px'}} onClick={(event) => showChart9(item, event)}></i>
          <i className="fas fa-list" onClick={(event) => showList9(item, event)}></i> 
        </Col>
      </div>
    )
  }

  const renderItemData_10 = (item, idx, provided, snapshot) => {
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col className="col-7 justify-content-center px-0">
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
            {item.title}
          </Col>
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'value')} style={{fontSize:'1.3rem'}}>
            {
              renderStarData(item, 5).map((sItem, i) => 
                <span key={i}><i className={'fa fa-star p-1 '+ sItem.sel} onClick={(event) => onReSetStar(event, item, sItem)}></i></span>
              )
            }
          </Col>                    
        </Col>
        <Col className="col-3 chartList px-0">
          <i className="fas fa-chart-line" onClick={(event) => showChart10(item, event)}></i>
          <i className="fas fa-list" onClick={(event) => showList10(item, event)}></i>    
        </Col>
      </div>
    )
  }

  const renderItemData_11 = (item, idx, provided, snapshot) => {
    // console.log(item, idx, provided, snapshot);
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0 py-2">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className="item_icon" src={item.i_icon}/>
        </Col>
        <Col className="col-7 d-flex align-items-center justify-content-start px-0 fill-value" style={{fontSize:'18px', fontWeight:'bold'}}>
            {item.title}      
        </Col>
        <Col className="col-3 d-flex align-items-center justify-content-end pl-0 pr-3">
          <i className="fas fa-chevron-right" style={{fontSize:'24px', color:'rgb(16 163 233)'}} onClick={(event) => showChart11(item, event)}></i>
        </Col>
      </div>
    )
  }
  const renderItemData_12 = (item, idx, provided, snapshot) => {
    return (
      <div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps} className="itemRow col-12 d-flex px-0">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
          <img className={"item_icon" + getImgEffClass(renderData, item)} src={item.i_icon}/>
        </Col>
        <Col className="col-10 justify-content-center px-0" onClick={(event) => changeCommentDataValue(event, item)}>
          <Col className={"col-12 px-0 " + getValueEffClass(renderData, item, 'text')}>
            {item.title}
          </Col>
          <Col className="col-12 px-0 d-flex justify-content-between" style={{fontSize:'1.07rem'}}>
            <span className={getValueEffClass(renderData, item, 'value') + ' w-100'} style={{fontWeight:'bold'}}>{renderData[item.id] ? renderData[item.id] : ' '}</span>
          </Col>                    
        </Col>
        {/*<Col className="col-2 chartList px-0">
                  <i className="fas fa-list" onClick={(event) => showList(item, event)}></i>
                </Col>*/}
      </div>
    )
  }
  const renderItemData1 = (item, idx, provided, snapshot) => {
    let ar = item.enable_on.split(',');
    if(item.enable_on != '7')
      if(ar.indexOf(''+ today.getDay()) < 0){
        return (<div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps}></div>);
      }
    // console.log(item, idx, provided, snapshot);
    if(item.data_type == 1){
      return renderItemData_1(item, idx, provided, snapshot);
    }else if(item.data_type == 2){
      return renderItemData_2(item, idx, provided, snapshot);
    }else if(item.data_type == 3){
      return renderItemData_3(item, idx, provided, snapshot);
    }else if(item.data_type == 4){
      return renderItemData_4(item, idx, provided, snapshot);
    }else if(item.data_type == 5){
      return renderItemData_5(item, idx, provided, snapshot);
    }else if(item.data_type == 6){
      return renderItemData_6(item, idx, provided, snapshot);
    }else if(item.data_type == 7){
      return renderItemData_7(item, idx, provided, snapshot);
    }else if(item.data_type == 8){
      return renderItemData_8(item, idx, provided, snapshot);
    }else if(item.data_type == 9){
      return renderItemData_9(item, idx, provided, snapshot);
    }else if(item.data_type == 10){
      return renderItemData_10(item, idx, provided, snapshot);
    }else if(item.data_type == 11){
      return renderItemData_11(item, idx, provided, snapshot);
    }else if(item.data_type == 12){
      return renderItemData_12(item, idx, provided, snapshot);
    }else{
      return (<div ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps}>{item.data_type}</div>);
    }
  }

  const noHandleClick = (event) => {
    setIsDualNumberModal('_hide');
  }

  const deleteHandleClick = (event) => {
    let d = renderData[curEditItem.id];
    d.start = 0;
    d.end = 0;
    sendServerData(curEditItem, d);
    setIsDualNumberModal('_hide');
  }

  const okHandleClick = (event) => {
    let d = renderData[curEditItem.id];
    d.start = startNumber;
    d.end = endNumber;
    sendServerData(curEditItem, d);
    setIsDualNumberModal('_hide');
  }  

  const noHandleClickN1 = (event) => {
    setIsModal('_hide');
  }

  const deleteHandleClickN1 = (event) => {
    let d = renderData[curEditItem.id];
    d = 0;
    sendServerData(curEditItem, d);
    setIsModal('_hide');
  }

  const okHandleClickN1 = (event) => {
    let d = renderData[curEditItem.id];
    d = number1;
    sendServerData(curEditItem, d);
    setIsModal('_hide');
  }

  const noHandleLClick = (event) => {
    setIsNumbersModal('_hide');
  }

  const deleteHandleLClick = (event) => {
    let d = renderData[curEditItem.id];
    d.start = 0;
    d.end = 0;
    sendServerData(curEditItem, d);
    setIsNumbersModal('_hide');
  }

  const okHandleLClick = (event) => {
    let d = renderData[curEditItem.id];
    d[numberLabel] = labelNumber;
    sendServerData(curEditItem, d);
    setIsNumbersModal('_hide');
  }

  const noHandleHmClick = (event) => {
    setIsDualTimeModal('_hide');
  }

  const deleteHandleHmClick = (event) => {
    let d = renderData[curEditItem.id];
    d.start = '00:00';
    d.end = '00:00';
    sendServerData(curEditItem, d);
    setIsDualTimeModal('_hide');
  }

  const okHandleHmClick = (event) => {
    let d = renderData[curEditItem.id];
    d.start = startHm;
    d.end = endHm;
    sendServerData(curEditItem, d);
    setIsDualTimeModal('_hide');
  }

  const noHandleHmsClick = (event) => {
    setIsDualTimesModal('_hide');
  }

  const deleteHandleHmsClick = (event) => {
    let d = renderData[curEditItem.id];
    d.start = '00:00:00';
    d.end = '00:00:00';
    sendServerData(curEditItem, d);
    setIsDualTimesModal('_hide');
  }

  const okHandleHmsClick = (event) => {
    let d = renderData[curEditItem.id];
    // console.log(startHms, endHms);
    d.start = startHms.replace('`', ':');
    d.end = endHms.replace('`', ':');

    sendServerData(curEditItem, d);
    setIsDualTimesModal('_hide');
  }

  const onDragStart = (a) => {
    setDragPadding({paddingBottom:'70px'});
  }

  const onDragEnd = (result) => {
    if(!result.source || !result.destination)
      return;
    setDragPadding({paddingBottom:'15px'});
    const newItems = Array.from(dataItems);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    for(let i=0;i<newItems.length;i++){
      newItems[i].ord = i+1;
    }
    setDataItems(newItems);
    axios.post(URL_API+"UpdateDiaryItems", {
      params: {
        newItems:newItems,
        // seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
        user: se['id'],
      }
    })
    .then(function (response) {
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
    // console.log(newItems);
  };

  // const checkDateTime = (t, f) => {
  //   if(f == 1){
  //     let tt = t.split(':');
  //     if(tt.length > 1){
  //       let rt = [];
  //       for(let i=0;i<2;i++){
  //         rt[i] = isNaN(Number(tt[i])) ? 0 : tt[i];
  //       }
  //       return ("0"+rt[0]).slice(-2)+":"+("0" + rt[1]).slice(-2);
  //     }else{
  //       return '00:00';
  //     }
  //   }else{
  //     let tt = t.split(':');
  //     if(tt.length > 2){
  //       let rt = [];
  //       for(let i=0;i<3;i++){
  //         rt[i] = isNaN(Number(tt[i])) ? 0 : tt[i];
  //       }
  //       return ("0"+rt[0]).slice(-2)+":"+("0" + rt[1]).slice(-2)+":"+("0" + rt[2]).slice(-2);
  //     }else{
  //       return '00:00:00';
  //     }
  //   }
  // }

  const checkRenderData = (cData, items) => {
    // console.log(cData);
    for(let k in cData){
      for(let i=0;i<items.length;i++){
        if(k == items[i].id){
          if(items[i].data_type == 1 || items[i].data_type == 2 || items[i].data_type == 9 || items[i].data_type == 10){
            if(isNaN(Number(cData[k]))){
              cData[k] = 0;
            }
          }else if(items[i].data_type == 3){
            if(!cData[k].start || isNaN(Number(cData[k].start))){
              cData[k].start = 0;
            }
            if(!cData[k].end || isNaN(Number(cData[k].end))){
              cData[k].end = 0;
            }
          }else if(items[i].data_type == 4){
            if(Object.keys(cData[k]).length > 0){
              for(let kk in cData[k]){
                if(isNaN(Number(cData[k][kk]))){
                  cData[k][kk] = 0;
                }
              }
            }else{
              cData[k] = {};
            }
          }else if(items[i].data_type == 5){
            if(!cData[k].start){
              cData[k] = {start:'00:00'};
            }else{
              let d = cData[k].start.split(':');
              if(isNaN(Number(d[0])))
                d[0] = 0;
              if(isNaN(Number(d[1])))
                d[1] = 0;
              d[0] = checkTimeNum(d[0], 23);
              d[1] = checkTimeNum(d[1], 59);
              // let t = checkDateTime(cData[k].start, 2);
              let t = ("0"+d[0]).slice(-2)+":"+("0" + d[1]).slice(-2);
              cData[k] = {start:t};
            }
          }else if(items[i].data_type == 6){
            // console.log(cData[k].start);
            if(!cData[k].start){
              cData[k] = {start:'00:00:00'};
            }else{
              let d = cData[k].start.split(':');
              if(isNaN(Number(d[0])))
                d[0] = 0;
              if(isNaN(Number(d[1])))
                d[1] = 0;
              if(isNaN(Number(d[2])))
                d[2] = 0;
              d[0] = checkTimeNum(d[0], 23);
              d[1] = checkTimeNum(d[1], 59);
              d[2] = checkTimeNum(d[2], 59);
              // let t = checkDateTime(cData[k].start, 2);
              let t = ("0"+d[0]).slice(-2)+":"+("0" + d[1]).slice(-2)+":"+("0" + d[2]).slice(-2);
              // console.log(t);
              cData[k] = {start:t};
            }
          }else if(items[i].data_type == 7){
            // console.log(cData[k]);
            if(cData[k].start && cData[k].end){
              let d = cData[k].start.split(':');
              if(isNaN(Number(d[0])))
                d[0] = 0;
              if(isNaN(Number(d[1])))
                d[1] = 0;
              d[0] = checkTimeNum(d[0], 23);
              d[1] = checkTimeNum(d[1], 59);
              // let t = checkDateTime(cData[k].start, 2);
              let s = ("0"+d[0]).slice(-2)+":"+("0" + d[1]).slice(-2);
              d = cData[k].end.split(':');
              if(isNaN(Number(d[0])))
                d[0] = 0;
              if(isNaN(Number(d[1])))
                d[1] = 0;
              d[0] = checkTimeNum(d[0], 23);
              d[1] = checkTimeNum(d[1], 59);
              // let t = checkDateTime(cData[k].start, 2);
              let e = ("0"+d[0]).slice(-2)+":"+("0" + d[1]).slice(-2);
              cData[k] = {start:s, end:e};
            }else{
              if(cData[k].start && !cData[k].end){
                let d = cData[k].start.split(':');
                if(isNaN(Number(d[0])))
                  d[0] = 0;
                if(isNaN(Number(d[1])))
                  d[1] = 0;
                d[0] = checkTimeNum(d[0], 23);
                d[1] = checkTimeNum(d[1], 59);
                // let t = checkDateTime(cData[k].start, 2);
                let s = ("0"+d[0]).slice(-2)+":"+("0" + d[1]).slice(-2);
                cData[k] = {start:s, end:'00:00'};
              }else if(!cData[k].start && cData[k].end){
                let d = cData[k].end.split(':');
                if(isNaN(Number(d[0])))
                  d[0] = 0;
                if(isNaN(Number(d[1])))
                  d[1] = 0;
                d[0] = checkTimeNum(d[0], 23);
                d[1] = checkTimeNum(d[1], 59);
                // let t = checkDateTime(cData[k].start, 2);
                let e = ("0"+d[0]).slice(-2)+":"+("0" + d[1]).slice(-2);
                cData[k] = {start:'00:00', end:e};
              }else{
                cData[k] = {start:'00:00', end:'00:00'};
              }
            }
          }else if(items[i].data_type == 8){
            if(cData[k].start && cData[k].end){
              let d = cData[k].start.split(':');
              if(isNaN(Number(d[0])))
                d[0] = 0;
              if(isNaN(Number(d[1])))
                d[1] = 0;
              if(isNaN(Number(d[2])))
                d[2] = 0;

              d[0] = checkTimeNum(d[0], 23);
              d[1] = checkTimeNum(d[1], 59);
              d[2] = checkTimeNum(d[2], 59);
              // let t = checkDateTime(cData[k].start, 2);
              let s = ("0"+d[0]).slice(-2)+":"+("0" + d[1]).slice(-2)+":"+("0" + d[2]).slice(-2);
              d = cData[k].end.split(':');
              if(isNaN(Number(d[0])))
                d[0] = 0;
              if(isNaN(Number(d[1])))
                d[1] = 0;
              if(isNaN(Number(d[2])))
                d[2] = 0;

              d[0] = checkTimeNum(d[0], 23);
              d[1] = checkTimeNum(d[1], 59);
              d[2] = checkTimeNum(d[2], 59);
              // let t = checkDateTime(cData[k].start, 2);
              let e = ("0"+d[0]).slice(-2)+":"+("0" + d[1]).slice(-2)+":"+("0" + d[2]).slice(-2);
              cData[k] = {start:s, end:e};
            }else{
              if(cData[k].start && !cData[k].end){
                let d = cData[k].start.split(':');
                if(isNaN(Number(d[0])))
                  d[0] = 0;
                if(isNaN(Number(d[1])))
                  d[1] = 0;
                if(isNaN(Number(d[2])))
                  d[2] = 0;

                d[0] = checkTimeNum(d[0], 23);
                d[1] = checkTimeNum(d[1], 59);
                d[2] = checkTimeNum(d[2], 59);
                // let t = checkDateTime(cData[k].start, 2);
                let s = ("0"+d[0]).slice(-2)+":"+("0" + d[1]).slice(-2)+":"+("0" + d[2]).slice(-2);
                cData[k] = {start:s, end:'00:00:00'};
              }else if(!cData[k].start && cData[k].end){
                let d = cData[k].end.split(':');
                if(isNaN(Number(d[0])))
                  d[0] = 0;
                if(isNaN(Number(d[1])))
                  d[1] = 0;
                if(isNaN(Number(d[2])))
                  d[2] = 0;

                d[0] = checkTimeNum(d[0], 23);
                d[1] = checkTimeNum(d[1], 59);
                d[2] = checkTimeNum(d[2], 59);
                // let t = checkDateTime(cData[k].start, 2);
                let e = ("0"+d[0]).slice(-2)+":"+("0" + d[1]).slice(-2)+":"+("0" + d[2]).slice(-2);
                cData[k] = {start:'00:00:00', end:e};
              }else{
                cData[k] = {start:'00:00:00', end:'00:00:00'};
              }
            }
          }
        }
      }
    }
    // console.log(cData);    
    setRenderData(cData);
    if(window.sessionStorage.getItem('dataItems'))
      setDataItems(JSON.parse(window.sessionStorage.getItem('dataItems')));
  }

  useEffect(() => {    
    // add new codes diaryData202201
    if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
      setDiaryData(JSON.parse(window.sessionStorage.getItem('diaryData')));
      let data = diaryData[getKey];
      if(data){
        checkRenderData(data, dataItems);
      }else{
        setRenderData({});
      }
    }
    if(JSON.parse(window.sessionStorage.getItem('dataItems')) != null){
      setDataItems(JSON.parse(window.sessionStorage.getItem('dataItems')));
    }
    // var datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    // d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);

    axios.get(URL_API+"getDiaryAPI", {
      params: {
        seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
        user: se['id'],
      }
    })
    .then(function (response) {
      
      window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
      window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));

      setDiaryData(response.data.data);
      setDataItems(response.data.items);
      // let data = response.data.data[getKey];
      // console.log(getKey, response.data.data[getKey]);
      if(response.data.data[getKey]){
        checkRenderData(response.data.data[getKey], response.data.items);
      }
      else{
        setRenderData({});
      }
     
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 

  }, [today, reLoadFlag]);
  
  // console.log(renderData);
  return (
    <>
      <DatePicker
        dateConfig={dateConfig}
        value={sToday}
        isOpen={modalIsOpen}
        onSelect={selectDate}
        onCancel={handleCancel} />

      <div className={isDualNumberModal + ' pt-3 pb-2 px-2 showDataDiv'} id="showDualNumberDataDiv">
        <div className={'show_body'}> 
          <div className={'m-body'}>
            <div className="col-12 row px-0 mx-0">
              <div className="col-12 d-flex px-1">
                <label className="col-3 px-1 m-0 d-flex align-items-center">A : </label>
                <input className="col-9 px-1" type="number" id="sValue" value={startNumber} onChange={(event) => onChangeNumber(event, 1)}/>
              </div>
              <div className="col-12 d-flex px-1 mt-1">
                <label className="col-3 px-1 m-0 d-flex align-items-center">B : </label>
                <input className="col-9 px-1" type="number" id="eValue" value={endNumber} onChange={(event) => onChangeNumber(event, 2)}/>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between px-3 mt-1" style={{fontSize:'16px'}}>
            <span className="p-2"><i className="fa fa-trash-alt pointer" onClick={(event) => deleteHandleClick(event)}></i></span>
            <span className="p-2" onClick={(event) => noHandleClick(event)}>キャンセル</span>
            <span className="p-2" onClick={(event) => okHandleClick(event)}>OK</span>
          </div>
        </div>    
      </div>

      <div className={isNumbersModal + ' pt-3 pb-2 px-2 showDataDiv'} id="showNumbersDataDiv">
        <div className={'show_body'}> 
          <div className={'m-body'}>
            <div className="col-12 row px-0 mx-0">
              <div className="col-12 d-flex px-1">
                <label className="col-4 px-1 m-0 d-flex align-items-center">label : </label>
                <input className="col-8 px-1" type="text" id="sNValue" value={numberLabel} onChange={(event) => setNumberLabel(event.target.value)}/>
              </div>
              <div className="col-12 d-flex px-1 mt-1">
                <label className="col-4 px-1 m-0 d-flex align-items-center">VALUE : </label>
                <input className="col-8 px-1" type="number" id="eNValue" value={labelNumber} onChange={(event) => setLabelNumber(event.target.value)}/>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between px-3 mt-1" style={{fontSize:'16px'}}>
            <span className="p-2"><i className="fa fa-trash-alt pointer" onClick={(event) => deleteHandleLClick(event)}></i></span>
            <span className="p-2" onClick={(event) => noHandleLClick(event)}>キャンセル</span>
            <span className="p-2" onClick={(event) => okHandleLClick(event)}>OK</span>
          </div>
        </div>    
      </div>

      <div className={isDualTimeModal + ' pt-3 pb-2 px-2 showDataDiv'} id="showDualDateDataDiv">
        <div className={'show_body'}> 
          <div className={'m-body'}>
            <div className="col-12 row px-0 mx-0">
              <div className="col-12 d-flex px-1" onClick={(event) => onChangeHm2(event, 1)}>
                <label className="col-3 px-1 m-0 d-flex align-items-center">A : </label>
                <span className="col-9 px-1" id="eTValue">{startHm}</span>
              </div>
              <div className="col-12 d-flex px-1 mt-1" onClick={(event) => onChangeHm2(event, 2)}>
                <label className="col-3 px-1 m-0 d-flex align-items-center">B : </label>
                <span className="col-9 px-1" id="eTValue">{endHm}</span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between px-3 mt-1" style={{fontSize:'16px'}}>
            <span className="p-2"><i className="fa fa-trash-alt pointer" onClick={(event) => deleteHandleHmClick(event)}></i></span>
            <span className="p-2" onClick={(event) => noHandleHmClick(event)}>キャンセル</span>
            <span className="p-2" onClick={(event) => okHandleHmClick(event)}>OK</span>
          </div>
        </div>    
      </div>

      <div className={isDualTimesModal + ' pt-3 pb-2 px-2 showDataDiv'} id="showDualDatesDataDiv">
        <div className={'show_body'}> 
          <div className={'m-body'}>
            <div className="col-12 row px-0 mx-0">
              <div className="col-12 d-flex px-1" onClick={(event) => onChangeHms2(event, 1)}>
                <label className="col-3 px-1 m-0 d-flex align-items-center">A : </label>
                <span className="col-9 px-1" id="eTValue">{startHms}</span>
              </div>
              <div className="col-12 d-flex px-1 mt-1" onClick={(event) => onChangeHms2(event, 2)}>
                <label className="col-3 px-1 m-0 d-flex align-items-center">B : </label>
                <span className="col-9 px-1" id="eTValue">{endHms}</span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between px-3 mt-1" style={{fontSize:'16px'}}>
            <span className="p-2"><i className="fa fa-trash-alt pointer" onClick={(event) => deleteHandleHmsClick(event)}></i></span>
            <span className="p-2" onClick={(event) => noHandleHmsClick(event)}>キャンセル</span>
            <span className="p-2" onClick={(event) => okHandleHmsClick(event)}>OK</span>
          </div>
        </div>    
      </div>

      <div className={isModal + ' py-3 px-2 showDataDiv'} id="showDataDiv">
        <div className={'show_body'}> 
          <div className={'m-body'}>
            <div className="col-12 row px-0 mx-0 mt-2">
              <div className="col-1 px-0"></div>
              <div className="col-10 px-1 mx-0">
                <input className="col-12 px-0" type="number" id="value1" max={nMax} value={(number1 == 0 ? '' : number1)} onChange={(event) => onChangeNumber1(event)}/>
              </div>
              <div className="col-1 px-0"></div>
            </div>
          </div>
          <div className="d-flex justify-content-between px-3 mt-2" style={{fontSize:'16px'}}>
            <span className="p-2"><i className="fa fa-trash-alt pointer" onClick={(event) => deleteHandleClickN1(event)}></i></span>
            <span className="p-2" onClick={(event) => noHandleClickN1(event)}>キャンセル</span>
            <span className="p-2" onClick={(event) => okHandleClickN1(event)}>OK</span>
          </div>
        </div>    
      </div>
      <Modal
            isOpen={isComModal}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <div className="col-12 p-2 mt-1 mx-0 text-center">
              コメントテキスト
            </div>
            <div className="col-12 p-2 mx-0">
              <textarea className="col-12 px-0" value={(comment ? comment : '')} style={{minHeight:'150px', borderWidth:'1px',borderRadius:'10px', resize:'none', borderColor:'#999'}} onChange={(event) => onChangeComment(event)}>{(comment ? comment : '')}</textarea>
            </div>

      </Modal>

      <div className="content px-0 pb-0">
        <Row className="px-0 mx-0">
          <Col md="12" className="px-0">
            <Card className="mb-0">
            <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
              <div className="d-flex col-12 px-0">
                <Col className="col-2 px-0 d-flex justify-content-center align-items-center" style={{cursor:'pointer'}} onClick={() => openModal('ymd', '')}>
                  <b style={{fontSize:'40px'}}>{todayDate}</b>
                </Col>
                <Col className="col-5 d-flex align-items-center justify-content-start px-0" style={{fontSize:'16px', fontWeight:'bold'}} onClick={() => openModal('ymd', '')} >
                    <div style={{fontSize:'0.8rem'}}>
                        <Col className="col-12 px-0">
                          {todayVDate}
                        </Col>
                        <Col className="col-12 px-0">
                          {todayDay}
                        </Col>
                    </div>                    
                </Col>
                <div className="col-5 justify-content-end d-flex align-items-center pr-3">
                  <i className='fa fa-tools p-1' style={{fontSize:'26px', cursor:'pointer', color:'white'}} onClick={() => goToPage('setting')}></i>
                </div>
              </div>
            </CardBody>

            <CardBody className="px-1 diary_list"  onTouchMove={(event) => onChangeDate(event)} style={dragPadding}>
              {
                dataItems.length > 0 &&
              (<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {
                        dataItems.map((item, index) => (
                        <Draggable key={item.id} draggableId={"dnd_"+item.id} index={index}>
                          {(provided, snapshot) => (
                            renderItemData1(item, index, provided, snapshot)
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>)

              }
            </CardBody>
            </Card>
          </Col>          
        </Row>
      </div>
    </>
  );
}

export default Diary;
