/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Preparation from "views/Preparation.js";
import TableList from "views/Tables.js";
import Diary from "views/diary/Diary.js";
import UserPage from "views/User.js";
import Logout from "views/Logout.js";
import Analysis from "views/Analysis.js";
import Aphorisms from "views/Aphorisms.js";
import Goalsetting from "views/Goalsetting.js";
import BigGoalsetting from "views/BigGoalsetting.js";
import ChatRoom from "views/ChatRoom.js";
import Members from "views/Members.js";
import Evaluation from "views/Evaluation.js";
var routes = [  
  // {
  //   path: "/dashboard",
  //   name: "ダッシュボード",
  //   icon: "nc-icon nc-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  {
    path: "/user-page",
    name: "プロフィール編集",
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "/admin",
  },
  {
    path: "/analysis",
    name: "自己分析画面",
    icon: "nc-icon nc-chart-bar-32",
    component: Analysis,
    layout: "/admin",
  },
  {
    path: "/biggoalsetting",
    name: "大会目標画面",
    icon: "nc-icon nc-air-baloon",
    component: BigGoalsetting,
    layout: "/admin",
  },
  {
    path: "/goalsetting",
    name: "課題目標設定画面",
    icon: "nc-icon nc-vector",
    component: Goalsetting,
    layout: "/admin",
  },
  
  {
    path: "/diary",
    name: "日記",
    icon: "nc-icon nc-user-run",
    component: Diary,
    layout: "/admin",
  },
  {
    path: "/preparation",
    name: "試合準備画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Preparation,
    layout: "/admin",
  },  
  {
    path: "/tables",
    name: "試合後リフレクション画面",
    icon: "nc-icon nc-trophy",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/aphorisms",
    name: "格言集",
    icon: "nc-icon nc-atom",
    component: Aphorisms,
    layout: "/admin",
  },
  // {
  //   path: "/chat",
  //   name: "チャット",
  //   icon: "nc-icon nc-chat-33",
  //   component: ChatRoom,
  //   layout: "/admin",
  // }, 
  {
    path: "/members",
    name: "チャット",
    icon: "nc-icon nc-chat-33",
    component: Members,
    layout: "/admin",
  }, 
  {
    path: "/evaluation",
    name: "選手評価",
    icon: "nc-icon nc-user-run",
    component: Evaluation,
    layout: "/admin",
  }, 
  {
    path: "/logout",
    name: "ログアウト",
    icon: "nc-icon nc-share-66",
    component: Logout,
    layout: "/admin",
  },
  
];
export default routes;
