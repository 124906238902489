/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// add new code 
import AddPlayer from "views/coach/AddPlayer.js";
import Player from "views/coach/Player.js";
import BigGoalSettingedit from "views/coach/BigGoalSettingedit.js";
import GoalSettingedit from "views/coach/GoalSettingedit.js";
import Preparationedit from "views/coach/Preparationedit.js";
import Preparationview from "views/coach/Preparationview.js";
import TableView from "views/coach/TableView.js";
import Tablesedit from "views/coach/Tablesedit.js";
import ApCategoryEdit from "views/coach/ApCategoryEdit.js";
// import ApCList from "views/coach/ApCList.js";
import ApList from "views/coach/ApList.js";
import AphorismsEdit from "views/coach/AphorismsEdit.js";
import AphorismsEdit1 from "views/coach/AphorismsEdit1.js";
import AttendList from "views/coach/AttendList.js";
import AttendChart from "views/coach/AttendChart.js";
import AttendStat from "views/coach/AttendStat.js";
import AnalysisDataList from "views/coach/AnalysisDataList.js";
import AnalysisEdit from "views/coach/AnalysisEdit.js";
import TablesShow from "views/coach/TablesShow.js";
import ChatRoom from "views/coach/ChatRoom.js";
import EvaluationItem from "views/coach/EvaluationItem.js";
import EvaluationItemEdit from "views/coach/EvaluationItemEdit.js";

import DiaryChart from "views/diary/DiaryChart.js";
import DiaryList from "views/diary/DiaryList.js";
import DiaryChart3 from "views/diary/DiaryChart3.js";
import DiaryList3 from "views/diary/DiaryList3.js";
import DiaryChart4 from "views/diary/DiaryChart4.js";
import DiaryList4 from "views/diary/DiaryList4.js";
import DiaryChart5 from "views/diary/DiaryChart5.js";
import DiaryList5 from "views/diary/DiaryList5.js";
import DiaryChart6 from "views/diary/DiaryChart6.js";
import DiaryList6 from "views/diary/DiaryList6.js";
import DiaryChart7 from "views/diary/DiaryChart7.js";
import DiaryList7 from "views/diary/DiaryList7.js";
import DiaryChart8 from "views/diary/DiaryChart8.js";
import DiaryList8 from "views/diary/DiaryList8.js";

import DiaryChart9 from "views/diary/DiaryChart9.js";
import DiaryList9 from "views/diary/DiaryList9.js";
import DiaryChart10 from "views/diary/DiaryChart10.js";
import DiaryList10 from "views/diary/DiaryList10.js";
import DiaryChart11 from "views/diary/DiaryChart11.js";
// end
var noSidebarCoachRoutes = [ 
  {
    path: "/addPlayer",
    name: "新しい選手を追加",
    icon: "nc-icon nc-share-66",
    component: AddPlayer,
    layout: "/coach",
  },
  {
    path: "/player",
    name: "選手情報",
    icon: "nc-icon nc-share-66",
    component: Player,
    layout: "/coach",
  },
  {
    path: "/biggoalsettingedit",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: BigGoalSettingedit,
    layout: "/coach",
  },
  {
    path: "/goalsettingedit",
    name: "課題目標設定編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: GoalSettingedit,
    layout: "/coach",
  },
  {
    path: "/diaryChart",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart,
    layout: "/coach",
  },
  {
    path: "/diaryList",
    name: "日記リスト",
    icon: "nc-icon nc-share-66",
    component: DiaryList,
    layout: "/coach",
  },
  {
    path: "/diaryChart3",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart3,
    layout: "/coach",
  },
  {
    path: "/diaryList3",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList3,
    layout: "/coach",
  },
  {
    path: "/diaryChart4",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart4,
    layout: "/coach",
  },
  {
    path: "/diaryList4",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList4,
    layout: "/coach",
  },
  {
    path: "/diaryChart5",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart5,
    layout: "/coach",
  },
  {
    path: "/diaryList5",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList5,
    layout: "/coach",
  },
  {
    path: "/diaryChart6",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart6,
    layout: "/coach",
  },
  {
    path: "/diaryList6",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList6,
    layout: "/coach",
  },
  {
    path: "/diaryChart7",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart7,
    layout: "/coach",
  },
  {
    path: "/diaryList7",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList7,
    layout: "/coach",
  },
  {
    path: "/diaryChart8",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart8,
    layout: "/coach",
  },
  {
    path: "/diaryList8",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList8,
    layout: "/coach",
  },
  {
    path: "/diaryChart9",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart9,
    layout: "/coach",
  },
  {
    path: "/diaryList9",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList9,
    layout: "/coach",
  },
  {
    path: "/diaryChart10",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart10,
    layout: "/coach",
  },
  {
    path: "/diaryList10",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryList10,
    layout: "/coach",
  },
  {
    path: "/diaryChart11",
    name: "日記チャート",
    icon: "nc-icon nc-share-66",
    component: DiaryChart11,
    layout: "/coach",
  }, 
  {
    path: "/preparationedit",
    name: "試合準備編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Preparationedit,
    layout: "/coach",
  },  
  {
    path: "/preparationview/:view_id",
    name: "編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Preparationview,
    layout: "/coach",
  },  
  {
    path: "/tableview/:view_id",
    name: "編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: TableView,
    layout: "/coach",
  }, 
  {
    path: "/tablesedit",
    name: "試合後リフレクション編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Tablesedit,
    layout: "/coach",
  },  
  {
    path: "/apCategoryEdit",
    name: "格言集編集",
    icon: "nc-icon nc-bullet-list-67",
    component: ApCategoryEdit,
    layout: "/coach",
  },   
  {
    path: "/apList",
    name: "格言集編集",
    icon: "nc-icon nc-bullet-list-67",
    component: ApList,
    layout: "/coach",
  },
  {
    path: "/aphorismsEdit",
    name: "格言集編集",
    icon: "nc-icon nc-bullet-list-67",
    component: AphorismsEdit,
    layout: "/coach",
  },
  {
    path: "/aphorismsEdit1",
    name: "格言集編集",
    icon: "nc-icon nc-bullet-list-67",
    component: AphorismsEdit1,
    layout: "/coach",
  },   
  {
    path: "/attendList",
    name: "出席入力",
    icon: "nc-icon nc-bullet-list-67",
    component: AttendList,
    layout: "/coach",
  },    
  {
    path: "/attendChart",
    name: "出席入力",
    icon: "nc-icon nc-bullet-list-67",
    component: AttendChart,
    layout: "/coach",
  },    
  {
    path: "/attendStat",
    name: "出席入力",
    icon: "nc-icon nc-bullet-list-67",
    component: AttendStat,
    layout: "/coach",
  },    
  {
    path: "/analysisDataList",
    name: "自己分析編集",
    icon: "nc-icon nc-bullet-list-67",
    component: AnalysisDataList,
    layout: "/coach",
  },   
  {
    path: "/analysisEdit",
    name: "自己分析編集",
    icon: "nc-icon nc-bullet-list-67",
    component: AnalysisEdit,
    layout: "/coach",
  },   
  {
    path: "/tablesShow",
    name: "対戦相手検索",
    icon: "nc-icon nc-bullet-list-67",
    component: TablesShow,
    layout: "/coach",
  },  
  {
    path: "/chatRoom",
    name: "チャット",
    icon: "nc-icon nc-bullet-list-67",
    component: ChatRoom,
    layout: "/coach",
  },
  {
    path: "/evaluationItem",
    name: "チャット",
    icon: "nc-icon nc-bullet-list-67",
    component: EvaluationItem,
    layout: "/coach",
  },  
  {
    path: "/evaluationItemEdit",
    name: "チャット",
    icon: "nc-icon nc-bullet-list-67",
    component: EvaluationItemEdit,
    layout: "/coach",
  },  

];
export default noSidebarCoachRoutes;
