import React, { Component } from 'react';
import { render } from 'react-dom';

import {useState, useEffect } from "react";

import "assets/css/list.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import technicalImg from "assets/img/self/technical.png";
import graphImg from "assets/img/self/graph.png";
import listImg from "assets/img/self/list.png";


import { useHistory } from 'react-router-dom';
import DatePicker from 'react-mobile-datepicker';


let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}

function initDiaryStorage(tDate){
  // var date = new Date();
  // console.log(tDate);
  let key = tDate.getFullYear()+("0"+(tDate.getMonth()+1)).slice(-2)+("0" + tDate.getDate()).slice(-2);
  if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
    let diaryData = JSON.parse(window.sessionStorage.getItem('diaryData'));
    if(!diaryData[key]){
      diaryData[key] = {
        abdominal: 0,
        eat1: 0,
        eat2: 0,
        eat3: 0,
        mental1: 0,
        mental2: 0,
        mental3: 0,
        physical1: 0,
        physical2: 0,
        physical3: 0,
        pushup: 0,
        sleepend: "00:00",
        sleepstart: "00:00",
        spine: 0,
        stretch: 0,
        studyend: "00:00",
        studystart: "00:00",
        tactical1: 0,
        tactical2: 0,
        tactical3: 0,
        technical1: 0,
        technical2: 0,
        technical3: 0,
        todayDate: ""
      };
      window.sessionStorage.setItem('diaryData', JSON.stringify(diaryData));
    }
    return diaryData;
  }else{
    let diaryData = {};
    diaryData[key] = {
      abdominal: 0,
      eat1: 0,
      eat2: 0,
      eat3: 0,
      mental1: 0,
      mental2: 0,
      mental3: 0,
      physical1: 0,
      physical2: 0,
      physical3: 0,
      pushup: 0,
      sleepend: "00:00",
      sleepstart: "00:00",
      spine: 0,
      stretch: 0,
      studyend: "00:00",
      studystart: "00:00",
      tactical1: 0,
      tactical2: 0,
      tactical3: 0,
      technical1: 0,
      technical2: 0,
      technical3: 0,
      todayDate: ""
    };
    window.sessionStorage.setItem('diaryData', JSON.stringify(diaryData));
    return diaryData;
  }
}

function DiarySList(){

	const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const history = useHistory();
	var date = new Date();  
	const [flag, setFlag] 						= useState(0);
	const [todayDate, setTodayDate] 			= useState((new Date(sessionStorage.getItem('today'))).getDate());

	const [seriesName, setSeriesName] 			= useState('');
	const [fname, setFName] 					= useState(sessionStorage.getItem('chart_field_name') || 'technical1');

	const [chart_logo, setChart_logo] 			= useState(sessionStorage.getItem('chart_intro_img') ||technicalImg);
	const [starCnts, setStarCnts] 				= useState(sessionStorage.getItem('starCnts') || 5);

	const [showDate, setShowDate] 				= useState();
	const [keyData, setKeyData] 				= useState(1);
	const [vData, setVData] 					= useState('NO DATA');
	const [showInputV, setShowInputV] 			= useState('hide');
	const [showDataV, setShowDataV] 			= useState('show');

	const [showInputBtnf, setShowInputBtnf] 	= useState(false);
	const [isModal, setIsModal] 				= useState('hide');
	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	const [getKey, setGetKey] = useState((new Date(sessionStorage.getItem('today'))).getFullYear()+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));

	const [data_list, setDataList] = useState([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [dateConfig, setDateConfig] = useState({
                    'year': {
                        format: 'YYYY',
                        caption: 'Year',
                        step: 1,
                    },
                    'month': {
                        format: 'MM',
                        caption: 'Mon',
                        step: 1,
                    },
                    'date': {
                        format: 'DD',
                        caption: 'Day',
                        step: 1,
                    }
                });

	const setStateDatas = (d_datas) => {
		// setDiaryData(d_datas);
		let datas = [];
		let days = lastday(today.getFullYear(), today.getMonth());
		// for (let i in diaryData) {
		// let keys = [];
		// console.log(today.getFullYear(), today.getMonth());
		for(var i=1;i<=days;i++){
			let key = today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + i).slice(-2);
			let d = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+i);
			// console.log(d.getDay());
			if(d_datas[key]){
				datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:d_datas[key][fname], y:today.getFullYear(), m:(today.getMonth()+1), d:i});
			}else
				datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:0, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
		}
		// console.log(datas);
		setDataList(datas);
	}
	const technicalPlus = (event, dn, dt)=>{  
	    var sel = event.target.getAttribute('val');
	    // console.log(sel, dn);
	    if(sel != "eat1" && sel != "eat2" && sel != "eat3"){
	      if(dn<5){
	        setDiaryDataStorage(sel, ++dn, dt); 
	        return;
	      }
	    }else{
	      if(dn<3){
	        setDiaryDataStorage(sel, ++dn, dt); 
	        return;
	      }
	    }
	};
	const technicalMinu = (event, dn, dt)=>{    
		// console.log(event);
		var sel = event.target.getAttribute('val');
		var func = event.target.getAttribute('func');
		if(dn > 0){ 
		    setDiaryDataStorage(sel, --dn, dt);
		}    
	};
	const setDiaryDataStorage = (key, v, dt) => {
      	// console.log(key, v, dt, today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + dt).slice(-2));
      	let d = JSON.parse(window.sessionStorage.getItem('diaryData'));
      	if(!d[key])
	    		d = initDiaryStorage(new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-' + dt));
      	d[today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + dt).slice(-2)][key] = v;
      	window.sessionStorage.setItem('diaryData', JSON.stringify(d));
      	// eval(setFuncName)(v);
      	setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
      	sendToServerData(key, v, dt);
    }
    const sendToServerData = (fname, v, dt) => {
    	let session = window.sessionStorage.getItem('sessions');
    	let se = JSON.parse(session);
    	axios.get(URL_API+"setChartData", {
        	params: {
				fname: fname, 
				v:v,
				seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-' + dt,
				flag:0,
				user: se['id'],
        	}
    	}).then(function (response) {
      
    	}); 
  	}
	const onReSetStar = (e, s, da) => {
		// console.log(e, s, da);
		setTodayDate(da.d);
    	if(s == 'noGold'){
      		technicalPlus(e, da.v, da.d);
    	}else{
      		technicalMinu(e, da.v, da.d);
    	}
  	}
  	const renderItemData = (d, fname, num) => {
    	let dArr = [];
    	for(let i=1;i<=num;i++){
      		dArr.push({n:i, fname:fname, sel:(i<=d ? 'gold':'noGold')});
    	}
    	// console.log(dArr);
    	return dArr;
  	}
	const openModal = (f) => {
	    setIsOpen(true);
	    if(f == 'ymd'){
	      setDateConfig({
	                    'year': {
	                        format: 'YYYY',
	                        caption: 'Year',
	                        step: 1,
	                    },
	                    'month': {
	                        format: 'MM',
	                        caption: 'Mon',
	                        step: 1,
	                    },
	                    'date': {
	                        format: 'DD',
	                        caption: 'Day',
	                        step: 1,
	                    }
	                });
	    }
	  }

	const showChart = (title, fname, img, e) => {
	    sessionStorage.setItem("chart_title", title);
	    sessionStorage.setItem("chart_field_name", fname);
	    sessionStorage.setItem("chart_intro_img", img);
	    sessionStorage.setItem("today", today);
	    history.push('../admin/diaryChart');
	};

	const showList = (title, fname, img, e) => {
	    sessionStorage.setItem("chart_title", title);
	    sessionStorage.setItem("chart_field_name", fname);
	    sessionStorage.setItem("chart_intro_img", img);
	    sessionStorage.setItem("today", today);
	    history.push('../admin/diaryList');
	};

	const setDataInput = (e, item) => {
		var v = item.v;
		var key = item.key;
		setTodayDate(item.d);
		setVData(v == 0 ? 'NO DATA' : v);
		setKeyData(key);
		setIsModal('_show');
		setShowDate(item.m+' / '+ item.d + ' ' + weekday[item.w]);
	};

	const noHandleClick = (event) => {
		setIsModal('_hide');		
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
	}

	const onInputData = (e, f) => {
		if(f){
			setShowInputV('show');
			setShowDataV('hide');
			e.target.focus();
		}else{
			setShowInputV('hide');
			setShowDataV('show');
		}
		setShowInputBtnf(f);
	}

	const onChangeData = (e) => {
		// const d = new Date();
		let session = window.sessionStorage.getItem('sessions'); 
		setVData(e.target.value);
		let setDataDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-' + todayDate;
		let se = JSON.parse(session);
		axios.get(URL_API+"setChartData", {
	      	params: {
	        	fname: fname, 
	        	v:e.target.value,
	        	seldate: setDataDate,
	        	flag:flag,
	        	user: se['id'],
	      	}
	    })
	    .then(function (response) {
	    	// setCategories(response.data.categories);
	    	// setData(response.data.data);	   
	    	let ds = JSON.parse(window.sessionStorage.getItem('diaryData'));
	    	let key = today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + todayDate).slice(-2);
	    	// console.log(key, fname);
	    	// ds[key][fname] = e.target.value;
	    	if(!ds[key])
	    		ds = initDiaryStorage(new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-' + todayDate));
	    	ds[key][fname] = Number(e.target.value);
	    	window.sessionStorage.setItem('diaryData', JSON.stringify(ds));
	   		setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	    }); 
	}

	const onBeforeData = (e) => {
		onGetData(todayDate-1);
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
	}

	const onNextData = (e) => {
		onGetData(todayDate+1);
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
	}

	const onGetData = (i) => {		
		let data = JSON.parse(window.sessionStorage.getItem('diaryData'));
		let key = today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + i).slice(-2);

		let d = new Date(today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2));
		// console.log(key, today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2));
		let days = lastday(today.getFullYear(), today.getMonth());
		if(i>days)
			return;
		if(data[key] != undefined){
			setVData(data[key][fname] == 0 ? 'NO DATA' : data[key][fname]);
		}else{
			setVData('NO DATA');
		}
		setShowDate((today.getMonth()+1)+' / '+ i + ' ' + weekday[d.getDay()]);
		setTodayDate(i);
	}

	const selectDate = (time) => {
	    setToday(time);
	    setTodayDate(time.getDate());
	    setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	    setIsOpen(false);
	    setGetKey(time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	}

	const handleCancel = () => {
	    setIsOpen(false);
	}

	useEffect(() => {
		let session = window.sessionStorage.getItem('sessions'); 
	    let se = JSON.parse(session);
	    if(!se){
	      	window.location = '/';
	    }
	   	var chart_title = sessionStorage.getItem('chart_title');
	   	if(chart_title != null)
	   		setSeriesName(chart_title);
	   	else
	   		setSeriesName('');

	   	var chart_field_name = sessionStorage.getItem('chart_field_name');
	   	if(chart_field_name != null)
	   		setFName(chart_field_name);
	   	else
	   		setFName('technical1');

	   	var chart_intro_img = sessionStorage.getItem('chart_intro_img');
	   	if(chart_intro_img != null)
	   		setChart_logo(chart_intro_img);
	   	else
	   		setChart_logo(technicalImg);

	   	// console.log(chart_title, chart_field_name, chart_intro_img);
	   	if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
	   		// setDiaryData(JSON.parse(window.sessionStorage.getItem('diaryData')));
			setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   	}
   		axios.get(URL_API+"getDiaryAPI", {
			params: {
				seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
				user: se['id'],
			}
	    })
	    .then(function (response) {		      
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data));
	      	// setDiaryData(response.data);
	      	// let data = response.data[getKey];
	      	setStateDatas(response.data);
	    }).catch(
	      	error => {
	        	if(error.response){
	          		console.log(error.response.data);
	        	};
		        // let d_data = initDiaryStorage(today);
		        // setDiaryData(d_data);
		        // let data = d_data[getKey];
		        // setStateDatas(data); 
	      	}
	    );
  	}, [today]);
	return (
		<>		
			<DatePicker
		        dateConfig={dateConfig}
		        value={today}
		        isOpen={modalIsOpen}
		        onSelect={selectDate}
		        onCancel={handleCancel} />	

			<div className={isModal + ' py-3 px-2'} id="showDataDiv">
				<div className={'show_body'}>						
					<div className={'m-header mb-1'}>
						<div>{showDate}</div>
						<div className={'pos_abs pointer'} onClick={(event) => onInputData(event, !showInputBtnf)}>input</div>
					</div>
					<div className={'m-body'}>
						<span><i className={"fas fa-caret-left fa-fw fa-2x pointer"} onClick={(event) => onBeforeData(event)}></i></span>
						<div className={showDataV}>{vData}</div>
						<div className={showInputV}>
							<input type="number" id="dataValue" value={vData} onChange={(event) => onChangeData(event)} />
						</div>
						<span><i className={"fas fa-caret-right fa-fw fa-2x pointer"} onClick={(event) => onNextData(event)}></i></span>
					</div>
					<div className="d-flex justify-content-center">
						<span><i className="fas fa-angle-down fa-fw fa-2x pointer" onClick={(event) => noHandleClick(event)}></i></span>
					</div>
				</div>		
			</div>
			<div className="content">
		        <Row>
		          	<Col md="12">
						<Card>
							<CardBody className="seldate" id='seldate' onClick={() => openModal('ymd')} >
					            <Row>
					              	<Col className="col-12 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}}>
					                	{todayVDate}
					             	</Col>
					            </Row>
					         </CardBody>   
							<div className="d-flex justify-content-between align-items-center">
								<div className="ml-2 d-flex align-items-center">
									<img src={chart_logo} className="mr-2"/> {seriesName}
								</div>
								<div className="d-flex mr-2">
									<img src={graphImg} width="35px" style={{marginRight:'5px'}} onClick={(event) => showChart(seriesName, fname, chart_logo, event)}/>
									<img src={listImg} width="35px"/>
								</div>
							</div>
							<div className="content_body">					
							{
								data_list.map((item, i) => 
									<div key={i} className="d-flex justify-content-between px-3 border-bottom py-2 font-weight-bolder pointer fa-lg">
										<div className = {(item.w==0 ? 'sun' : (item.w == 6 ? 'sat' : '')) + ' d-flex align-items-center'}>{item.dtitle}</div>
										<div className="d-flex align-items-center" style={{color:'rgb(65, 150, 23)'}}>
										{
											renderItemData(item.v, fname, starCnts).map((star) => 
                          						<span key={star.n}><i style={{fontSize:'24px'}} className={'fa fa-star px-2 py-1 '+ star.sel} val={star.fname} onClick={(event) => onReSetStar(event, star.sel, item)}></i></span>
                        					)
										}
										</div>
									</div>
								)
							}
							</div>
						</Card>
					</Col>
				</Row>
			</div>
      	</>
    )
}

export default DiarySList;