/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
// import questionImg from "assets/img/self/question.png";
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import "assets/css/p.css";

import face1 from "assets/img/face/1.png";
import face2 from "assets/img/face/2.png";
import face3 from "assets/img/face/3.png";
import face4 from "assets/img/face/4.png";
import face5 from "assets/img/face/5.png";


import w1 from "assets/img/face/11.png";
import w2 from "assets/img/face/12.png";
import w3 from "assets/img/face/13.png";
import w4 from "assets/img/face/14.png";
import w5 from "assets/img/face/15.png";
import w6 from "assets/img/face/16.png";

import {  
  Card,  
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let presession = {}; 
let session; 
let notificationAlert;
function convetDateToIso(d){
  return d.getFullYear()+'-'
       + (("0"+(d.getMonth() + 1)).slice(-2))+'-'
       + ("0"+d.getDate()).slice(-2)+'T'
       + ("0"+d.getHours()).slice(-2)+':'
       + ("0"+d.getMinutes()).slice(-2)+':'
       + ("0"+d.getSeconds()).slice(-2);
}


const Preparationedit = (e) => {    

  notificationAlert = React.useRef(); 
  
  const history = useHistory();
  session = JSON.parse(window.sessionStorage.getItem('sessions')); 
  if(!session){
    window.location = '/';
  }
  
  const startTalbes = (e) => {    

    if ( presession['id'] > 0){

      window.sessionStorage.setItem('selTables', JSON.stringify(presession));      
      history.push('../sub/tablesedit');

    }else{
      
      var color = Math.floor(Math.random() * 5 + 1);
      var type;
      switch (color) {
        case 1:
          type = "primary";
          break;
        case 2:
          type = "success";
          break;
        case 3:
          type = "danger";
          break;
        case 4:
          type = "warning";
          break;
        case 5:
          type = "info";
          break;
        default:
          break;
      }
  
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>
              まず「保管ボタン」を押してください。
            </div>
          </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7,
      };
      notificationAlert.current.notificationAlert(options);
      return false;
      
      
    }
  } 

  const [coachs, setCoachs] = useState(JSON.parse(window.sessionStorage.getItem('coachs')));

  const onSendMsg = (e, pid) => {
    // console.log(session);
    // let td = presession['tourdate'];
    let td = new Date(presession['tourdate']);
    if(window.confirm("コーチ様に送信しますか？")){
      for(let i=0;i<coachs.length;i++){
        axios.get(URL_API+"setMessages", {
          params: {
            coach_id : coachs[i]['id'],
            player_id : session['id'],
            content:'<p>'+session['name']+'選手から競技準備データが届きました。</p><p>"'+presession['tournament']+' '+(td.getMonth() + 1)+'月'+' '+td.getDate()+'日'+' '+presession['opponentgroup']+' '+presession['opponentname']+'"</p>',
            p : pid,
            f : 0
          }
        })
        .then(function (response) {
          // setUFlag(uFlag+1);
          // setMsg('');
        }).catch(
          error => {
            if(error.response){
              console.log(error.response.data);
            };
          }
        ); 
      }
    }
  }

  const preparationPage = (e) => {    
    history.push('../admin/preparation');
  }

  const sendPreparationData = (e, f) => {
    preparationUpdateFunc(e, f);    
  }

  const preparationUpdateFunc = (e, f) => {
  
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    
    let erroMsg = "";
    if(!presession["tournament"] || presession["tournament"] == ""){
      erroMsg += "「大会名」、";
    }
    if(!presession["category"] || presession["category"] == ""){
      erroMsg += "「カテゴリー」、";
    }
    if(!presession["tourdate"] || presession["tourdate"] == ""){
      erroMsg += "「トーナメント日にち」、";
    }
    // if(!presession["opponentname"] || presession["opponentname"] == ""){
    //   erroMsg += "「対戦相手名前」、";
    // }
    // if(!presession["opponentgroup"] || presession["opponentgroup"] == ""){
    //   erroMsg += "「対戦相手クラブ名」、";
    // }

    // if(!presession["surface"] || presession["surface"] == ""){
    //   erroMsg += "「サーフェス」、";
    // }
    // if(!presession["match"] || presession["match"] == ""){
    //   erroMsg += "「試合形式」、";
    // }//mainround
    
    // if(!presession["mainround"] || presession["mainround"] == ""){
    //   erroMsg += "「本戦ラウンド」、";
    // }
    // if(!presession["subround"] || presession["subround"] == ""){
    //   erroMsg += "「予選ラウンド」、";
    // }
    // if(!presession["weather"] || presession["weather"] == ""){
    //   erroMsg += "「天気」、";
    // }
    // if(!presession["condition"] || presession["condition"] == ""){
    //   erroMsg += "「起きた時の体調や気分」、";
    // }

    if(erroMsg != ""){
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>
              <b>{erroMsg}</b>が必須項目です。
            </div>
          </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7,
      };
      notificationAlert.current.notificationAlert(options);
      return false;
    }

    presession['proposer'] = session['id'];
    presession['proposername'] = session['name'];
    presession['proposergroup'] = session['school'];
    
    var registerSession = JSON.stringify(presession);
    console.log(registerSession);
    axios.get(URL_API+"preparationUpdateAPI", {
      params: {
        data: registerSession, 
      }
    })
    .then(function (response) {    

      if(response.data == "n"){

        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>
                <b>対戦相手の名前が正確ではありません。</b>
              </div>
            </div>
          ),
          type: type,
          icon: "nc-icon nc-bell-55",
          autoDismiss: 100,
        };

      }else{
        if(f == 1){
          // console.log(response.data);
          onSendMsg(e, response.data.id);          
          return;
        }
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>
                <b>「試合前準備画面編集」</b>が 完了しました。
              </div>
            </div>
          ),
          type: type,
          icon: "nc-icon nc-bell-55",
          autoDismiss: 7,
        };
      
        // if(JSON.stringify(response.data) != "y"){
           
          window.sessionStorage.setItem("presession", JSON.stringify(response.data));
          presession = window.sessionStorage.getItem('presession');
          presession = JSON.parse(presession);
        //}
        
      }
      notificationAlert.current.notificationAlert(options);
    })
  }
  
///////////////////

  const [tournament, setTournament] = useState(presession['tournament']);
  const getInputTournamentValue = (event)=>{    
    setTournament(event.target.value);     
    presession['tournament'] = event.target.value;
  };
  const [category, setCategory] = useState(presession['category']);
  const getInputCategoryValue = (event)=>{    
    setCategory(event.target.value);     
    presession['category'] = event.target.value;
  };
  const [tourdate, setTourdate] = useState(presession['tourdate']);
  const getInputTourdateValue = (event)=>{    
    setTourdate(event.target.value);     
    presession['tourdate'] = event.target.value;
  };
  const [opponentname, setOpponentname] = useState(presession['opponentname']);
  const getInputOpponentnameValue = (event)=>{    
    setOpponentname(event.target.value);     
    presession['opponentname'] = event.target.value;
  };
  const [opponentgroup, setOpponentgroup] = useState(presession['opponentgroup']);
  const getInputOpponentgroupValue = (event)=>{    
    setOpponentgroup(event.target.value);     
    presession['opponentgroup'] = event.target.value;
  };
  const [surface, setSurfaceNumber] = useState(presession['surface']);
  const getSurfaceNumberValue = (event)=>{    
    setSurfaceNumber(event.target.value);     
    presession['surface'] = event.target.value;
  };
  const [match, setMatch] = useState(presession['match']);
  const getInputMatchValue = (event)=>{    
    setMatch(event.target.value);     
    presession['match'] = event.target.value;
  };
  const [mainround, setMainround] = useState( presession['mainround']);
  const getInputMainroundValue = (event)=>{    
    setMainround(event.target.value);     
    presession['mainround'] = event.target.value;
  };
  const [subround, setSubround] = useState(presession['subround']);
  const getInputSubroundValue = (event)=>{    
    setSubround(event.target.value);     
    presession['subround'] = event.target.value;
  };
  const [weather, setWeather] = useState(presession['weather']);
  // const getInputWeatherValue = (event)=>{    
  //   setWeather(event.target.value);     
  //   presession['weather'] = event.target.value;
  // };
  const getInputWeatherValue = (event, v)=>{    
    setWeather(v);     
    presession['weather'] = v;
  };
  const [condition, setCondition] = useState(presession['condition']);
  // const getInputConditionValue = (event)=>{    
  //   setCondition(event.target.value);     
  //   presession['condition'] = event.target.value;
  // };
  const getInputConditionValue = (event, v)=>{    
    setCondition(v);     
    presession['condition'] = v;
  };
  const [think1, setThink1] = useState(presession['think1']);
  const getInputThink1Value = (event)=>{    
    setThink1(event.target.value);     
    presession['think1'] = event.target.value;
  };
  const [think2, setThink2] = useState(presession['think2']);
  const getInputThink2Value = (event)=>{    
    setThink2(event.target.value);     
    presession['think2'] = event.target.value;
  };
  const [think3, setThink3] = useState(presession['think3']);
  const getInputThink3Value = (event)=>{    
    setThink3(event.target.value);     
    presession['think3'] = event.target.value;
  };
  const [think4, setThink4] = useState(presession['think4']);
  const getInputThink4Value = (event)=>{    
    setThink4(event.target.value);     
    presession['think4'] = event.target.value;
  };
  const [think5, setThink5] = useState(presession['think5']);
  const getInputThink5Value = (event)=>{    
    setThink5(event.target.value);     
    presession['think5'] = event.target.value;
  };
  
  const [checkboxgroup, setCheckboxgroup] = useState(
    [ { en: 's_serverData', jp: 'サーブ', fc: 's_setServerData', val:true },
      { en: 's_receiveData', jp: 'レシーブ', fc: 's_setReceiveData', val:true},
      { en: 's_strokeData', jp: 'ストローク', fc: 's_setStrokeData', val:true},
      { en: 's_netplayData', jp: 'ネットプレー', fc: 's_setNetplayData', val:true},
      { en: 's_mentalData', jp: 'メンタル', fc: 's_setMentalData', val:true},
      { en: 's_tacticsData', jp: '戦術', fc: 's_setTacticsData', val:true},]
    );

  const [s_serverData, s_setServerData] = useState([]);
  const [s_receiveData, s_setReceiveData] = useState([]);
  const [s_strokeData, s_setStrokeData] = useState([]);
  const [s_netplayData, s_setNetplayData] = useState([]);
  const [s_mentalData, s_setMentalData] = useState([]);
  const [s_tacticsData, s_setTacticsData] = useState([]);
   
  const [checkedState, setCheckedState] = useState(
    new Array(s_serverData.length).fill(false)
  );

  
  useEffect(() => {   
    
    if(window.sessionStorage.getItem('presession') !== null ){

      presession = window.sessionStorage.getItem('presession'); 
      presession = JSON.parse(presession); 

      setCategory(presession['category']);
      setCondition(presession['condition']);
      setMainround(presession['mainround']);
      setMatch(presession['match']);
      setOpponentgroup(presession['opponentgroup']);
      setOpponentname(presession['opponentname']);
      setSubround(presession['subround']);
      setSurfaceNumber(presession['surface']);
      setThink1(presession['think1']);
      setThink2(presession['think2']);
      setThink3(presession['think3']);
      setThink4(presession['think4']);
      setThink5(presession['think5']);
 
      setTourdate(presession['tourdate']);
      setTournament(presession['tournament']);
      setWeather(presession['weather']);
      
    }else{
      presession = {};
    }

    //戦術
    // se = JSON.parse(session);

    for(var i = 0 ; i < checkboxgroup.length; i++){

      if(JSON.parse(window.sessionStorage.getItem(checkboxgroup[i]['en'])) != null)
        eval(checkboxgroup[i]['fc'])(JSON.parse(window.sessionStorage.getItem(checkboxgroup[i]['en'])));
      
      axios.get(URL_API+"getSelfSelAPI", {
        params: {
          data: checkboxgroup[i]['en'],
          user: session['id'], 
        }
      })
      .then(function (response) {
        
        if(response.data !== null && response.data.length > 0){           
          
          window.sessionStorage.setItem(response.data[0]['group_id'], JSON.stringify(response.data));

          eval(response.data[0]['fc'])(JSON.parse(window.sessionStorage.getItem(response.data[0]['group_id'])));

        }

      });
    }

  }, []);
  return (

    <>
      <div className="content px-0 pb-0">
        <NotificationAlert ref={notificationAlert}  className="col-12"/>
        <Row className="px-0 mx-0">          
          <Col md="12" className="px-0">
            <Card className="card-user">              
              <CardBody>
                <Form>
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup>
                        <input type="button" name="user" value="大会開始" className="btn-round btn btn-warning" onClick={startTalbes}/>
                      </FormGroup>
                    </Col>  
                    
                  </Row>
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup>
                        <label>大会名</label>
                        <Input type="text" onChange={getInputTournamentValue} id="tournament" value={tournament == null ? '' : tournament}/>
                      </FormGroup>
                    </Col>  
                    
                  </Row>
                 
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup>
                        <label>カテゴリー</label>
                        
                        <select className="form-control" id="category"
                          value={category == null ? '' : category} 
                          onChange={getInputCategoryValue} 
                        >
                          <option value=""></option>
                          <option value="U8">U8</option>
                          <option value="U9">U9</option>
                          <option value="U10">U10</option>
                          <option value="U11">U11</option>
                          <option value="U12">U12</option>
                          <option value="U13">U13</option>
                          <option value="U14">U14</option>
                          <option value="U15">U15</option>
                          <option value="U16">U16</option>
                          <option value="U17">U17</option>
                          <option value="U18">U18</option>
                          <option value="一般">一般</option>
                        </select>
                      </FormGroup>
                    </Col>

                    <Col className="px-1">
                      <FormGroup>
                        <label>トーナメント日にち</label>
                        <Input type="datetime-local" onChange={getInputTourdateValue} id="tourdate" value={tourdate == null ? '' : convetDateToIso(new Date(tourdate))}  step="1"/>
                      </FormGroup>
                    </Col>                    
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup>
                        <label>対戦相手名前</label>
                        <Input type="text" onChange={getInputOpponentnameValue} id="opponentname" value={opponentname == null ? '' : opponentname}/>
                      </FormGroup>
                    </Col>                  
                    <Col className="px-1">
                      <FormGroup>
                        <label>対戦相手クラブ名</label>
                        <Input type="text" onChange={getInputOpponentgroupValue} id="opponentgroup" value={opponentgroup == null ? '' : opponentgroup}/>
                      </FormGroup>
                    </Col>                  
                    
                  </Row>
                  <Row>                  
                    <Col className="px-1">
                      <FormGroup>
                        <label>サーフェス</label>
                        <select className="form-control" id="surface"
                          value={surface == null ? '' : surface} 
                          onChange={getSurfaceNumberValue} 
                        >
                          <option value="0"></option>
                          {/* <option value="1set">1set</option>
                          <option value="3set">3set</option> */}
                          <option value="オムニコート">オムニコート</option>
                          <option value="ハードコート">ハードコート</option>
                          <option value="クレーコート">クレーコート</option>
                        </select>
                      </FormGroup>
                    </Col>  
                    <Col className="px-1">
                      <FormGroup>
                        <label>試合形式</label>
                        <select className="form-control" id="match"
                          value={match == null ? '' : match} 
                          onChange={getInputMatchValue} 
                        >
                          <option value="0"></option>
                          <option value="1セットマッチ">1セットマッチ</option>
                          <option value="3セットマッチ">3セットマッチ</option>
                          <option value="8ゲームマッチ">8ゲームマッチ</option>
                          <option value="その他">その他</option>
                        </select>
                      </FormGroup>
                    </Col>                  
                    
                  </Row>
                  <Row>   
                    <Col className="px-1">
                      <FormGroup>
                        <label>本戦ラウンド</label>
                        
                        <select className="form-control" id="mainround"
                          value={mainround == null ? '' : mainround} 
                          onChange={getInputMainroundValue} 
                        >
                          <option value=""></option>
                          <option value="1R">1R</option>
                          <option value="2R">2R</option>
                          <option value="3R">3R</option>
                          <option value="4R">4R</option>
                          <option value="5R">5R</option>
                          <option value="QF">QF</option>
                          <option value="SF">SF</option>
                          <option value="F">F</option>
                        </select>
                      </FormGroup>
                    </Col>                 
                    <Col className="px-1">
                      <FormGroup>
                        <label>予選ラウンド</label>
                        
                        <select className="form-control" id="subround"
                          value={subround == null ? '' : subround} 
                          onChange={getInputSubroundValue} 
                        >
                          <option value=""></option>
                          <option value="1R">1R</option>
                          <option value="2R">2R</option>
                          <option value="3R">3R</option>
                          <option value="4R">4R</option>
                          <option value="5R">5R</option>
                          <option value="QF">QF</option>
                          <option value="SF">SF</option>
                          <option value="F">F</option>
                        </select>
                      </FormGroup>
                    </Col>                  
                    
                  </Row>
                  <Row>  
                    <Col className="px-1">
                      <div><label>天気</label></div>
                      <FormGroup>
                        <div className="row col-12 p2-0 py-0 mx-0 d-flex justify-content-between align-items-center">
                          <img src={w1} className={weather == 1 ? 'w_selected' : 'no_selected'} onClick={(event) => getInputWeatherValue(event, 1)}/>
                          <img src={w2} className={weather == 2 ? 'w_selected' : 'no_selected'} onClick={(event) => getInputWeatherValue(event, 2)} />
                          <img src={w3} className={weather == 3 ? 'w_selected' : 'no_selected'} onClick={(event) => getInputWeatherValue(event, 3)} />
                          <img src={w4} className={weather == 4 ? 'w_selected' : 'no_selected'} onClick={(event) => getInputWeatherValue(event, 4)} />
                          <img src={w5} className={weather == 5 ? 'w_selected' : 'no_selected'} onClick={(event) => getInputWeatherValue(event, 5)} />
                          <img src={w6} className={weather == 6 ? 'w_selected' : 'no_selected'} onClick={(event) => getInputWeatherValue(event, 6)} />
                        </div>
                      </FormGroup>
                      <FormGroup style={{display:'none'}}>
                        <label>天気</label>                        
                        <select className="form-control" id="weather"
                          value={weather == null ? '' : weather} 
                          onChange={getInputWeatherValue} 
                        >
                          <option value="0"></option>
                          <option value="1">☀️</option>
                          <option value="2">⛅</option>
                          <option value="3">☁️</option>
                          <option value="4">🌧️</option>
                          <option value="5">☔</option>
                          <option value="6">☃️</option>
                        </select>
                      </FormGroup>
                    </Col>  
                  </Row>
                  <Row>                
                    <Col className="px-1">
                      <div><label>起きた時の体調や気分</label></div>
                      <FormGroup>
                        <div className="row col-12 p2-0 py-2 mx-0 d-flex justify-content-between align-items-center">
                          <img src={face1} className={condition == 1 ? 'c_selected' : 'cno_selected'} onClick={(event) => getInputConditionValue(event, 1)}/>
                          <img src={face2} className={condition == 2 ? 'c_selected' : 'cno_selected'} onClick={(event) => getInputConditionValue(event, 2)} />
                          <img src={face3} className={condition == 3 ? 'c_selected' : 'cno_selected'} onClick={(event) => getInputConditionValue(event, 3)} />
                          <img src={face4} className={condition == 4 ? 'c_selected' : 'cno_selected'} onClick={(event) => getInputConditionValue(event, 4)} />
                          <img src={face5} className={condition == 5 ? 'c_selected' : 'cno_selected'} onClick={(event) => getInputConditionValue(event, 5)} />
                        </div>
                      </FormGroup>
                      <FormGroup style={{display:'none'}}>      
                        <label>起きた時の体調や気分</label>                        
                          <select className="form-control" id="condition"
                            value={condition == null ? '' : condition} 
                            onChange={getInputConditionValue} 
                          >
                            <option value="0"></option>
                            <option value="1">😆</option>
                            <option value="2">😀</option>
                            <option value="3">🙂</option>
                            <option value="4">😞</option>
                            <option value="5">😣</option>
                          </select>
                      </FormGroup>
                    </Col>                                     
                  </Row>
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup>      
                        <label>試合前に心がける事（下の自己分析結果や相手のタイプを考えて最低3つ書く）</label>
                        <Input type="text" onChange={getInputThink1Value} value={think1 == null ? '' : think1} style={{marginTop:'5px'}}/>
                        <Input type="text" onChange={getInputThink2Value} value={think2 == null ? '' : think2} style={{marginTop:'5px'}}/>
                        <Input type="text" onChange={getInputThink3Value} value={think3 == null ? '' : think3} style={{marginTop:'5px'}}/>
                        <Input type="text" onChange={getInputThink4Value} value={think4 == null ? '' : think4} style={{marginTop:'5px'}}/>
                        <Input type="text" onChange={getInputThink5Value} value={think5 == null ? '' : think5} style={{marginTop:'5px'}}/>                        
                      </FormGroup>
                    </Col>                                     
                  </Row>
                  <Row>
                    <Col md="12">
                      {
                        checkboxgroup.map((group, num) =>
                        <Card className="demo-icons" key={num} style={{padding:'10px'}}>
                          <CardTitle tag="h6" style={{marginTop:'0.75rem'}}>{group.jp}</CardTitle>  
                          
                            {
                              eval(group.en).map((tmp, index) => 
                              
                              <Row key={index }>
                                <Col className="col-12" style={{color:'rgb(3, 115, 202)', fontSize:'14px'}}>
                                  {/*<img src={questionImg}  width="20px"/>*/} {tmp.item}
                                </Col>
                                <Col className="col-12" style={{color:'#ff3d02', fontSize:'14px', paddingLeft:'40px'}}>
                                  {tmp.solution}
                                </Col>
                                                          
                              </Row>
                              
                              )
                            }                
                            
                        </Card>
                      )}
                      
                    </Col>
                  </Row>

                  <Row>   
                    <Card className="demo-icons col-12" style={{padding:'10px'}}>
                      <CardTitle tag="h6" style={{marginTop:'0.75rem'}}>コメント</CardTitle>  
                      <textarea className="form-control p-2" id="remark" readOnly="readonly" value={presession.comment ? presession.comment : ''} style={{background:'white'}}>{presession.comment ? presession.comment : ''}</textarea>
                    </Card>                               
                  </Row>

                  <Row>                    
                    <Col className="px-1">
                      <input type="button" name="user" value="保存" className="btn-round btn btn-danger" onClick={(e) => preparationUpdateFunc(e, 0)}/>
                    </Col>    
                    <Col className="px-1">
                      <input type="button" name="user" value="送信" className="btn-round btn btn-primary" onClick={(e) => sendPreparationData(e, 1)}/>
                    </Col>    
                    <Col className="px-1">
                      <input type="button" name="user" value="戻る" className="btn-round btn btn-primary" onClick={preparationPage}/>
                    </Col>                                 
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Preparationedit;
