/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";

import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

// add new codes
import { useHistory } from 'react-router-dom';


import "assets/css/coach.css";


let Pusher = require('pusher-js');
let channel7 = null;

// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function Members() {

  const [msgCnts, setMsgCnts] = useState({});

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }

  const history = useHistory();

  const [players, setPlayers] = useState([]);

  const toPage = (e, pname) => {
    history.push('../coach/'+pname);
  }

  const onSelectPlayer = (e, player) => {
    window.sessionStorage.setItem('player', JSON.stringify(player));
    toPage(e, 'chatRoom');
  }

  useEffect(() => {
    let session = window.sessionStorage.getItem('sessions');
    let se = JSON.parse(session);
    if(!se){
      window.location = '/';
    }

    if(channel7){
      channel7.unbind();
      channel7 = null;
    }

    Pusher.logToConsole = true;
    var pusher = new Pusher('6ecccbbacc37f7103bdb', {
        cluster: 'eu'
    });
    // console.log('my-receive-msg-'+se['id']);
    channel7 = pusher.subscribe('my-all-'+se['id']);
    channel7.bind('my-event', function(data) {
      console.log(data['data']['cnts']);
      setMsgCnts(data['data']['cnts']);
      // setMsgCnt(data['data']['cnt']);
    });

    if(JSON.parse(window.sessionStorage.getItem('players')) != null){
      setPlayers(JSON.parse(window.sessionStorage.getItem('players')));
    }
    
    axios.post(URL_API+"getPlayers", {
      params: {
        user: se['id']
      }
    })
    .then(function (response) {
      // let d = JSON.parse(response);
      window.sessionStorage.setItem('players', JSON.stringify(response.data));
      setPlayers(response.data);
      axios.get(URL_API+"getMCnts", {
        params: {
          id : se['id']
        }
      })
      .then(function (response) {
        setMsgCnts(response.data);
      }).catch(
        error => {
          if(error.response){
            console.log(error.response.data);
          };
        }
      ); 
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    );   

  }, []);

  return (
    <>      
      <div className="content coach_content">
        <div className="titleDiv">
          <Card className="mb-0">
            <CardBody className="px-1 coach-anal">
              <div className="col-12 px-0">                
                <div className="col-12 px-0 row mx-0 players">
                  {
                    players.map((player, idx) => 
                      <div key={idx} className={'col-4 p-1 mb-2 '} onClick={(event) => onSelectPlayer(event, player)}>
                        <div className="text-center">
                          <img src={(player.profile_photo_path ? "https://xs458763.xsrv.jp/uploads/"+player.profile_photo_path : require("assets/img/mike.jpg").default)}/>
                          {
                            (msgCnts[player.id] && msgCnts[player.id]) > 0 && (
                              <span className="msgCnt" style={{right:'1.5rem'}}>{msgCnts[player.id]}</span>)
                          }
                        </div>                        
                        <div className="text-center mt-2">
                          <span style={{width:'100%', whiteSpace:'pre-wrap'}}>{player.name}</span>
                        </div>                
                      </div>
                    )
                  }
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Members;
