/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
import { useHistory } from 'react-router-dom';
import { Card, CardBody} from "reactstrap";

// reactstrap components
import NotificationAlert from "react-notification-alert";

let URL_API = "https://xs458763.xsrv.jp/api/";
let notificationAlert;

function Aphorisms() {

  let session = JSON.parse(window.sessionStorage.getItem('sessions'));
  if(!session)
    window.location = '/';

  notificationAlert = React.useRef();  
  const history = useHistory();
  const [checkboxgroup, setCheckboxgroup] = useState(
    [{ en: 'serverData', jp: 'サーブ', fc: 'setServerData', val:true },
      { en: 'receiveData', jp: 'レシーブ', fc: 'setReceiveData', val:true},
      { en: 'strokeData', jp: 'ストローク', fc: 'setStrokeData', val:true},
      { en: 'netplayData', jp: 'ネットプレー', fc: 'setNetplayData', val:true},
      { en: 'mentalData', jp: 'メンタル', fc: 'setMentalData', val:true},
      { en: 'tacticsData', jp: '戦術', fc: 'setTacticsData', val:true}]
  );

  const onSelectCategory = (e, category) => {
    window.sessionStorage.setItem('a_category', JSON.stringify(category));
    history.push('../coach/analysisDataList');
  }
  
  return (
    <>
      <div className="content px-0 pb-0">
        <NotificationAlert ref={notificationAlert} />
        <Card className="mb-0">
          <CardBody className="px-1 coach-anal">
            <div className="col-12">
              {
                checkboxgroup.map((group, num) =>(
                  <div key={num} className="col-12 border-bottom py-2" onClick={(e) => onSelectCategory(e, group)}>
                    {group.jp}
                  </div>
                ))
              }
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default Aphorisms;
