/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState, useEffect } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { useHistory } from 'react-router-dom';

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let session;
let se;
let selTable;
function Other() {

  const [checkboxgroup, setCheckboxgroup] = useState(
    [{ en: 'serverData', jp: 'サーブ', fc: 'setServerData', val:true },
      { en: 'receiveData', jp: 'レシーブ', fc: 'setReceiveData', val:true},
      { en: 'strokeData', jp: 'ストローク', fc: 'setStrokeData', val:true},
      { en: 'netplayData', jp: 'ネットプレー', fc: 'setNetplayData', val:true},
      { en: 'mentalData', jp: 'メンタル', fc: 'setMentalData', val:true},
      { en: 'tacticsData', jp: '戦術', fc: 'setTacticsData', val:true},]
    );
   

  const [serverData, setServerData] = useState([]);
  const [receiveData, setReceiveData] = useState([]);
  const [strokeData, setStrokeData] = useState([]);
  const [netplayData, setNetplayData] = useState([]);
  const [mentalData, setMentalData] = useState([]);
  const [tacticsData, setTacticsData] = useState([]);

 
  const handleOnChange = (position, aryName, fcName) => {
    
    eval(aryName)[position]['checked'] = !eval(aryName)[position]['checked'] ;    
    window.sessionStorage.setItem(aryName, JSON.stringify(eval(aryName))); 
    eval(fcName)(eval(aryName));

    let tmp = [...checkboxgroup];
    tmp[0]['val'] = !tmp[0]['val'];
    setCheckboxgroup(tmp);

  };
  const history = useHistory();

  const analyserPage = (e) => {
    history.push('../sub/tablesedit');
  };
 

  useEffect(() => {
    session = window.sessionStorage.getItem('sessions');
    selTable = window.sessionStorage.getItem('other');
    
    se = JSON.parse(session);  

    for(var i = 0 ; i < checkboxgroup.length; i++){

      if(JSON.parse(window.sessionStorage.getItem(checkboxgroup[i]['en'])) != null)
        eval(checkboxgroup[i]['fc'])(JSON.parse(window.sessionStorage.getItem(checkboxgroup[i]['en'])));
      
      axios.get(URL_API+"getSelfOtherSelAPI", {
        params: {
          data: checkboxgroup[i]['en'],
          sel: selTable, 
        }
      })
      .then(function (response) {
        
        if(response.data !== null && response.data.length > 0){           
          
          window.sessionStorage.setItem(response.data[0]['group_id'], JSON.stringify(response.data));

          eval(response.data[0]['fc'])(JSON.parse(window.sessionStorage.getItem(response.data[0]['group_id'])));

        }

      });
    }

  }, []);


 

  return (
    <>
      <div className="content" style={{padding:'10px'}}>
        <Row>
          <Col md="12">
            
            {
              checkboxgroup.map((group, num) =>
              <Card className="demo-icons" key={num}>
                <CardHeader>
                  <CardTitle tag="h6">{group.jp}</CardTitle>                
                </CardHeader>
                <CardBody className="all-icons"> 
                  {
                    eval(group.en).map((tmp, index) => 

                    <Row key={index }>
                      <Col className="col-10">
                        <h6> 
                          {tmp.item} <br/>    
                          <small> </small>                 
                        </h6>
                      </Col>
                      <Col className="col-2" style={{textAlign:'right'}}>
                        <input
                          type="checkbox"
                          id={"an_"+num+"_" + index}
                          name={"an_"+num}
                          value={tmp.id}                          
                          onChange={() => handleOnChange(index, group.en, group.fc)}
                          checked={tmp.checked ? true : false}
                        />
                        </Col>
                    </Row>
                    )
                  }                
                  
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
        <Row style={{padding:'15px'}}>                    
          <Col className="col-6">
            <input type="button" name="user" value="保存" className="btn-round btn btn-danger" onClick={profileUpdateFunc}/>
          </Col>   
          <Col className="col-6">
            <input type="button" name="user" value="戻る" className="btn-round btn btn-primary" onClick={analyserPage}/>
          </Col>                                      
        </Row>
      </div>
    </>
  );
}

function profileUpdateFunc() { 

  var selAnVal = '';

  for(var i = 0; i < 6 ; i++){
    for( var j = 0; j < document.querySelectorAll("input[name='an_"+i+"']:checked").length; j++){
      selAnVal += document.querySelectorAll("input[name='an_"+i+"']:checked")[j].value+",";
    }
  }
  
  axios.get(URL_API+"getOtherAnaAPISave", {
    params: {
      selAnVal: selAnVal, 
      user:se["id"],
      sel: selTable,
    }
  })
  .then(function (response) {    

  });

}
export default Other;
