/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";

import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";
import Modal from 'react-modal';

// add new codes
import { useHistory } from 'react-router-dom';

// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0.25rem',
    width:'85%',
    borderRadius:'15px',
    fontWeight:'bold'
  },
};

function ApCategoryEdit() {

  let session = JSON.parse(window.sessionStorage.getItem('sessions'));
  if(!session){
    window.location = '/';
  }

  const history = useHistory();
  const newFlag = window.sessionStorage.getItem('newFlag');
  const category = JSON.parse(window.sessionStorage.getItem('s_category')) || null;
  const [title, setTitle] = useState(category && newFlag == 0 ? category.title : '' );
  const [modalIsOpen, setIsOpen]    = useState(false);

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  }
  const onSave = (e) => {
    if(newFlag == 1){
      axios.get(URL_API+"AddNewCatg", {
        params: {
          user_id: session['id'], 
          title:title
        }
      })
      .then(function (response) {    
        history.push('../coach/aphorisms');
      }); 
    }else{
      axios.get(URL_API+"saveNewCatg", {
        params: {
          user_id: session['id'], 
          id:category.id,
          title:title
        }
      })
      .then(function (response) {    
        history.push('../coach/aphorisms');
      }); 
    }
  }

  const onRemoveSelectedItem = (event) => {
    setIsOpen(true);
  }

  const onRemoveSelItem = (event) => {
    axios.get(URL_API+"delApCatgItem", {
      params: {
        id:category.id,
        user: session['id'],
      }
    })
    .then(function (response) {
      history.push('../coach/aphorisms');
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    );  
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  // useEffect(() => {

  // }, []);

  return (
    <>      
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="row col-12 px-0 mx-0 py-2 justify-content-center align-items-center border-bottom">
          削除
        </div>
        <div className="row col-12 px-0 mx-0 py-4 justify-content-center align-items-center border-bottom">
          本当に削除しますか？
        </div>
        <div className="col-12 px-0 mx-0 d-flex justify-content-between align-items-center">
          <span className="col-6 py-2 px-0 d-flex justify-content-center align-items-center" onClick={(event) => setIsOpen(false)}>キャンセル</span>
          <span className="col-6 py-2 px-0 d-flex justify-content-center align-items-center border-left" onClick={(event) => onRemoveSelItem(event)}>削除</span>
        </div>
      </Modal>
      <div className="content px-0 pb-0">
        <div className="titleDiv">
          <Card className="mb-0">
            <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
              <div className="d-flex col-12 px-2">
                <div className="col-3 col-lg-2 d-flex align-items-center justify-content-start px-0">
                  <span className="p-1" style={{fontSize:'14px'}} onClick={(e) => history.push('../coach/aphorisms')}>キャンセル</span>
                </div>
                <div className="col-6 col-lg-8 text-center px-0 d-flex align-items-center justify-content-center head_title">
                    新規カテゴリー
                </div>
                <div className="col-3 col-lg-2 d-flex align-items-center justify-content-end px-0">
                  <span className="p-1" style={{fontSize:'14px'}} onClick={(e) => onSave(e)}>完了</span>
                </div>
              </div>
            </CardBody>
            <CardBody className="px-2 py-0">
              <div className="col-12 px-0 py-3 coach-aphCtg">
                <div className="col-12 px-0 d-flex">
                  <span className="p-1 col-3 text-center">タイトル : </span>
                  <input className="col-9" type="text" value={title} id="title" name="title" onChange={onChangeTitle} placeholder="タイトル" style={{height:'30px'}}/>
                </div>
                <div className="col-12 px-1">
                  { newFlag == 0 && (
                    <span className="col-12 px-0 text-center py-3 btn btn-simple" onClick={(event) => onRemoveSelectedItem(event)}>削除</span>)
                  }
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ApCategoryEdit;
