/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import gameImg from "assets/img/self/game.png";
import ballImg from "assets/img/self/ball.png";
import { useHistory } from 'react-router-dom';

// reactstrap components
import NotificationAlert from "react-notification-alert";
import {  
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let session = window.sessionStorage.getItem('sessions'); 
let se = JSON.parse(session);

let notificationAlert;

function Preparation() {

  notificationAlert = React.useRef();  
  
  const [p_proposer, s_setProposerData] = useState([]);
  //const [p_opponent, s_setOpponentData] = useState([]);
  
  const history = useHistory();
  
  const preparationUpdateFunc = (e) => {

    window.sessionStorage.setItem('presession', '{}');   
    history.push('../sub/Preparationedit');

  };
  const getViewpageUrl = (tmp) => {

    window.sessionStorage.setItem('presession', JSON.stringify(tmp)); 
    history.push('../sub/Preparationedit');

  };
  
  useEffect(() => {    
    session = window.sessionStorage.getItem('sessions'); 
    se = JSON.parse(session);

    if(session === null){    
      window.location = '/';
    }        
    //サーブ
    s_setProposerData(JSON.parse(window.sessionStorage.getItem("p_proposer")));
    axios.get(URL_API+"getPreparaListAPI", {
      params: {
        sel: se['id'], 
      }
    })
    .then(function (response) {

      if(Array.isArray(response.data)){
        s_setProposerData(response.data);
        window.sessionStorage.setItem('p_proposer', JSON.stringify(response.data));
      }
    }); 
    
    // s_setOpponentData(JSON.parse(window.sessionStorage.getItem("p_opponent")));
    // axios.get(URL_API+"getOpponentListAPI", {
    //   params: {
    //     sel: se['id'], 
    //   }
    // })
    // .then(function (response) {

    //   if(Array.isArray(response.data)){        
    //       s_setOpponentData(response.data);
    //       window.sessionStorage.setItem('p_opponent', JSON.stringify(response.data));        
    //   }
    // });  
    

  }, []);
 

  return (

    <>
      <div className="content px-0">
        <NotificationAlert ref={notificationAlert} />
        <Row className="px-0 mx-0">          
          <Col md="12" className="px-0">
            <Card className="card-user">              
              <CardBody>
                <Form>
                  <Row>
                    <CardHeader>
                      <CardTitle tag="h6">トーナメントリスト</CardTitle>                
                    </CardHeader>
                    <Col md="12">                                         
                      {
                        p_proposer?.map((tmp, index) => 

                        <Row key={index} onClick={() => getViewpageUrl(tmp)} val={tmp.id}> 
                          <Col className="col-2 px-1 d-flex align-items-center justify-content-center" style={{color:'rgb(3, 115, 202)', fontSize:'16px', paddingRight:'0px', textAlign: 'center'}}>
                            <img src={gameImg}  width="25px"/>
                          </Col>                        
                          <Col className="col-4 px-1 d-flex align-items-center" style={{color:'rgb(3, 115, 202)', fontSize:'16px', padding:'0px'}}>
                            <b>{tmp.tournament}</b>
                          </Col>
                          <Col className="col-3 px-1 d-flex align-items-center" style={{color:'rgb(3, 115, 202)', fontSize:'14px', padding:'0px'}}>
                            {tmp.tourdate_}   
                          </Col>
                          <Col className="col-3 px-1 d-flex align-items-center" style={{color:'rgb(3, 115, 202)', fontSize:'14px', padding:'0px'}}>
                          {tmp.opponentgroup}<br/> {tmp.opponentname}
                          </Col>
                        </Row>
                        )
                      } 
                    </Col>
                  </Row>
                  {/* <Row>
                    <CardHeader>
                      <CardTitle tag="h6">トーナメントリスト</CardTitle>                
                    </CardHeader>
                    <Col md="12">                                         
                      {
                        p_opponent?.map((tmp, index) => 

                        <Row key={index} onClick={() => getViewpageUrl(tmp)} val={tmp.id}> 
                          <Col className="col-2" style={{color:'#18258c', fontSize:'16px', paddingRight:'0px'}}>
                            <img src={ballImg}  width="25px"/>
                          </Col>                        
                          <Col className="col-4" style={{color:'#18258c', fontSize:'16px', padding:'0px'}}>
                            <b>{tmp.tournament}</b>
                          </Col>
                          <Col className="col-3" style={{color:'#419617', fontSize:'14px', padding:'0px'}}>
                            {tmp.tourdate}   
                          </Col>
                          <Col className="col-3" style={{color:'#419617', fontSize:'14px', padding:'0px'}}>
                          {tmp.proposergroup} {tmp.proposername}
                          </Col>
                        </Row>
                        )
                      } 
                    </Col>
                  </Row> */}
                  <Row>                    
                    <Col className="px-1">
                      <input type="button" name="user" value="追加" className="btn-round btn btn-primary" onClick={preparationUpdateFunc}/>
                    </Col>                                     
                  </Row>                  
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}


export default Preparation;
