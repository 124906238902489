/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";

import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import dataTypes from "views/diary/dataType.js";
import Modal from 'react-modal';

// add new codes
import { useHistory } from 'react-router-dom';

// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0.25rem',
    width:'85%',
    borderRadius:'15px',
    fontWeight:'bold'
  },
};

// function importAll(r) {
//   let images = [];
//   r.keys().map((item, index) => { 
//     var o = {};
//     o['name'] = item.replace('./', '');
//     o['data'] = r(item);
//     // o[item.replace('./', '')] = r(item);
//     images.push(o);
//     // images[item.replace('./', '')] = r(item); 
//   });
//   return images;
// }

// const images = importAll(require.context('assets/img/self/icon', false, /\.(png|jpe?g|svg)$/));

function EditItem() {
  
  const history = useHistory();

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }

  if(!window.sessionStorage.getItem('selectedItem'))
    history.push('../admin/setting');

  // if(!window.sessionStorage.getItem('s_icons')){
  //   window.sessionStorage.setItem('s_icons', JSON.stringify(importAll(require.context('assets/img/self/icon', false, /\.(png|jpe?g|svg)$/))));
  // }  

  if(!window.sessionStorage.getItem('s_day')){
    window.sessionStorage.setItem('s_day', '7');
    window.sessionStorage.setItem('s_day_name', 'Every day');
  } 

  const [data_type, setDataType] = useState(JSON.parse(window.sessionStorage.getItem('s_dataType')) || dataTypes.basic[0]);

  let ics = JSON.parse(window.sessionStorage.getItem('s_icons'));
  const [sIcon, setSIcon] = useState(JSON.parse(window.sessionStorage.getItem('s_Icon')) || ics[0]);

  const [sDay, setSDay] = useState(window.sessionStorage.getItem('s_day') || '7');
  const [sDayName, setSDayName] = useState(window.sessionStorage.getItem('s_day_name') || 'Every day');
  const [title, setTitle] = useState(window.sessionStorage.getItem('ititle') || '');
  const [unit, setUnit] = useState(window.sessionStorage.getItem('unit') || '');
  const [uHide, setUHide] = useState('');
  const [dHide, setDHide] = useState('');
  const [tFlag, setTFlag] = useState(1);
  const [uFlag, setUFlag] = useState(1);
  const [modalIsOpen, setIsOpen]    = useState(false);
  const [modalIsOpen1, setIsOpen1]    = useState(false);

  const [selectedItem, setSelectedItem] = useState(JSON.parse(window.sessionStorage.getItem('selectedItem')) || {});

  const cancelAddNewItem = (event) => {
    toPage(event, 'setting');
  }

  const onSetDataValue = (event, func, fname) => {
    eval(func)(event.target.value);
    window.sessionStorage.setItem(fname, event.target.value);
  }

  const onRemoveSelectedItem = (event) => {
    setIsOpen(true);
  }

  const onRemoveSelItem = (event) => {
    let session = window.sessionStorage.getItem('sessions');
    let se = JSON.parse(session);
    if(!se){
      window.location = '/';
    }
    axios.get(URL_API+"delDiaryDataItem", {
      params: {
        id:selectedItem.id,
        user: se['id'],
      }
    })
    .then(function (response) {
      toPage(event, 'setting');
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    );  
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModal1() {
    setIsOpen1(false);
  }

  const onChangeDataType = (event) => {    
    history.push('../admin/selectType');
  }

  const doneSaveItem = (event) => {
    if(data_type.iType == 8 || data_type.iType == 9 || data_type.iType == 10 || data_type.iType == 11){
      if(title == '')
        return;
    }else{
      if(title == '' || unit == '')
        return;
    }
    let session = window.sessionStorage.getItem('sessions');
    let se = JSON.parse(session);
    if(!se){
      window.location = '/';
    }
    axios.get(URL_API+"reSetDiaryDataItem", {
      params: {
        data_type: data_type.id,
        i_icon:sIcon.data.default,
        enable_on:sDay,
        i_name:sIcon.name,
        title:title,
        i_unit:unit,
        id:selectedItem.id,
        eFlag:selectedItem.eFlag,
        user: se['id'],
      }
    })
    .then(function (response) {
      toPage(event, 'setting');
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    );     
  }

  const toPage = (e, pname) => {
    history.push('../admin/'+pname);
  }

  const onChangeType = (e, pname) => {
    setIsOpen1(true);
  }

  useEffect(() => {
    // console.log(data_type);
    if(data_type.iType == 9 || data_type.iType == 8){
      setUHide('hide');
      setUnit('');
    }
    if(data_type.iType == 4 || data_type.iType == 6){
      setUnit('h:m');
      setUFlag(0);
    }
    if(data_type.iType == 5 || data_type.iType == 7){
      setUnit('h:m`s');
      setUFlag(0);
    }
    if(data_type.iType == 10){
      setDHide('hide');
      setUHide('hide');
      setTitle('グラフビューア');
      setTFlag(0);
    }
    if(data_type.iType == 11){
      setUHide('hide');
    }
  }, []);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="row col-12 px-0 mx-0 py-2 justify-content-center align-items-center border-bottom">
          削除
        </div>
        <div className="row col-12 px-0 mx-0 py-4 justify-content-center align-items-center border-bottom">
          本当に削除しますか？
        </div>
        <div className="col-12 px-0 mx-0 d-flex justify-content-between align-items-center">
          <span className="col-6 py-2 px-0 d-flex justify-content-center align-items-center" onClick={(event) => setIsOpen(false)}>キャンセル</span>
          <span className="col-6 py-2 px-0 d-flex justify-content-center align-items-center border-left" onClick={(event) => onRemoveSelItem(event)}>削除</span>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen1}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal1}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="row col-12 px-0 mx-0 py-2 justify-content-center align-items-center border-bottom">
          注意
        </div>
        <div className="row col-12 px-0 mx-0 py-4 justify-content-center align-items-center border-bottom">
          登録項目のデータ型を変更すると、記録されたデータは削除されます。
        </div>
        <div className="col-12 px-0 mx-0 d-flex justify-content-between align-items-center">
          <span className="col-6 py-2 px-0 d-flex justify-content-center align-items-center" onClick={(event) => setIsOpen1(false)}>キャンセル</span>
          <span className="col-6 py-2 px-0 d-flex justify-content-center align-items-center border-left" onClick={(event) => onChangeDataType(event)}>変更</span>
        </div>
      </Modal>
      <div className="content px-0 coach-user-content" style={{background:'white'}}>
        <div className="titleDiv">
          <Card className="mb-0" style={{boxShadow:'unset'}}>
            <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
              <div className="d-flex col-12 px-2">
                <div className="col-4 col-lg-2 d-flex align-items-center justify-content-start px-0">
                  <span className="p-1" style={{fontSize:'16px'}} onClick={(event) => cancelAddNewItem(event)}>キャンセル</span>
                </div>
                <div className="col-4 col-lg-8 text-center px-0 d-flex align-items-center justify-content-center head_title">
                  編集アイテム
                </div>
                <div className="col-4 col-lg-2 d-flex align-items-center justify-content-end px-0">
                  <span className="p-1" style={{fontSize:'16px'}} onClick={(event) => doneSaveItem(event)}>完了</span>
                </div>
              </div>
            </CardBody>
            <CardBody className="px-1">

              <div className="itemRow col-12 d-flex px-0 py-3">
                <Col className="col-4 col-lg-2 px-0 py-0 d-flex justify-content-start align-items-center">
                  <span className="p-1">データタイプ : </span>
                </Col>
                <Col className="col-6 col-lg-8 d-flex justify-content-start align-items-center px-0 n_select_text">
                  <span>{data_type.name}</span>      
                </Col>
                <Col className="col-2 col-lg-2 px-0 d-flex justify-content-end align-items-center">
                  <span className="pr-2" onClick={(event) => onChangeType(event, 'selectType')}><i className="fas fa-chevron-right i_move"></i></span>
                </Col>
              </div>

              <div className="itemRow col-12 d-flex px-0 py-3">
                <Col className="col-4 col-lg-2 px-0 py-0 d-flex justify-content-start align-items-center">
                  <span className="p-1">タイトル : </span>
                </Col>
                <Col className="col-8 col-lg-10 d-flex justify-content-start align-items-center px-0">
                  <input type="text" className={"w-100 mr-1 " + (tFlag ? '' : 'hide')} value={title} id="title" name="title" onChange={(event) => onSetDataValue(event, 'setTitle', 'ititle')} style={{border:'0px', borderBottom:'1px solid #999'}} placeholder="title"/>      
                  <span className={"w-100 " + (tFlag ? 'hide' : '')}>{title}</span>
                </Col>
              </div>

              <div className={"itemRow col-12 d-flex px-0 py-3 " + uHide}>
                <Col className="col-4 col-lg-2 px-0 py-0 d-flex justify-content-start align-items-center">
                  <span className="p-1">単位 : </span>
                </Col>
                <Col className="col-8 col-lg-10 d-flex justify-content-start align-items-center px-0 ">
                  <input type="text" className={"w-100 mr-1 " + (uFlag ? '' : 'hide')} value={unit} id="unit" name="unit" onChange={(event) => onSetDataValue(event, 'setUnit', 'unit')} style={{border:'0px', borderBottom:'1px solid #999'}} placeholder="unit"/>      
                  <span className={"w-100 text-center " + (uFlag ? 'hide' : '')}>{unit}</span>
                </Col>
              </div>

              <div className="itemRow col-12 d-flex px-0 py-3">
                <Col className="col-4 col-lg-2 px-0 py-0 d-flex justify-content-start align-items-center">
                  <span className="p-1">アイコン : </span>
                </Col>
                <Col className="col-6 col-lg-8 d-flex justify-content-center align-items-center px-0" style={{fontWeight:'bold'}}>
                  <img src={sIcon.data.default} style={{width:'15%'}}/>
                </Col>
                <Col className="col-2 col-lg-2 px-0 d-flex justify-content-end align-items-center">
                  <span className="pr-2" onClick={(event) => toPage(event, 'selectIcon')}><i className="fas fa-chevron-right i_move"></i></span>
                </Col>
              </div>

              <div className={"itemRow col-12 d-flex px-0 py-3 " + dHide}>
                <Col className="col-4 col-lg-2 px-0 py-0 d-flex justify-content-start align-items-center">
                  <span className="p-1">有効日 : </span>
                </Col>
                <Col className="col-6 col-lg-8 d-flex justify-content-start align-items-center px-0 n_select_text">
                  <span>{sDayName}</span>      
                </Col>
                <Col className="col-2 col-lg-2 px-0 d-flex justify-content-end align-items-center">
                  <span className="pr-2" onClick={(event) => toPage(event, 'selectDays')}><i className="fas fa-chevron-right i_move"></i></span>
                </Col>
              </div>

              <div className="col-12 px-0">
                <span className="col-12 px-0 text-center py-3 btn btn-simple" onClick={(event) => onRemoveSelectedItem(event)}>削除</span>
              </div>
            </CardBody>
            <CardBody>
              
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default EditItem;
