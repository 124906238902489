/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Row, Col} from "reactstrap";

// reactstrap components
import NotificationAlert from "react-notification-alert";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let notificationAlert;
let se;
function ApList() {

  let session = JSON.parse(window.sessionStorage.getItem('sessions')); 
  notificationAlert = React.useRef();  
  const history = useHistory();  
  const [p_aphorism, setPAhorism] = useState(JSON.parse(window.sessionStorage.getItem('p_aphorism')) || null);
  const [uFlag, setUFlag] = useState(0);

  const [aphorisms, setAphorisms] = useState([]);

  const onSelectItem = (e, aphorism) => {
    if(p_aphorism){
      axios.get(URL_API+"getAphorismsAPI", {
        params: {
          sel: session['id'], 
          p_id:aphorism.id,
          f:0
        }
      })
      .then(function (response) {
        // console.log(response.data, response.data != "");
        if(Array.isArray(response.data) && response.data.length > 0){
          setAphorisms(response.data);
          window.sessionStorage.setItem('p_aphorism', JSON.stringify(aphorism));
          setPAhorism(aphorism);
          setUFlag((c) => c + 1);
        }
      }); 
    }    
    // history.push('../coach/apList');
  }
  const onUpPage = (e) => {
    if(p_aphorism.p_id == 0){
      history.push('../admin/aphorisms');
      return;
    }
    axios.get(URL_API+"getParentAPI", {
        params: {
          sel: session['id'], 
          p_id:p_aphorism.p_id,
          f:0
        }
      })
      .then(function (response) {     
        let data = response.data;
        window.sessionStorage.setItem('p_aphorism', JSON.stringify(response.data));     
        setPAhorism(response.data);
        setUFlag((c) => c + 1);
      }); 
    // history.push('../coach/aphorisms');
  }
  
  useEffect(() => {    
    if(session === null){
      window.location = '/';
    }
    if(p_aphorism){
      axios.get(URL_API+"getAphorismsAPI", {
        params: {
          sel: session['id'], 
          p_id:p_aphorism.id,
          f:0
        }
      })
      .then(function (response) {
        // console.log(response.data, response.data != "");
        if(Array.isArray(response.data) && response.data.length > 0){
          setAphorisms(response.data);
        }
      }); 
    }
  }, [uFlag]);
  

  return (
    <>
      <div className="content px-0 pb-0">
        <NotificationAlert ref={notificationAlert} />
        <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
          <div className="d-flex col-12 px-2">
            <div className="col-2 col-lg-1 d-flex align-items-center px-0">
              <i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => onUpPage(event)}></i>
            </div>
          </div>
        </CardBody>
        <Card className="mb-0">
            <CardBody className="px-1 coach-aphCtg">
              <div className="col-12">
              {
                aphorisms?.map((tmp, index) =>
                                
                <div key={index} className="col-12 border-bottom py-2" onClick={(e) => onSelectItem(e, tmp)}>                                         
                  {tmp.content}
                </div>
                    
              )}
              </div>
            </CardBody>
          </Card>
      </div>
    </>
  );
}

export default ApList;
