/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Card, CardBody} from "reactstrap";

// reactstrap components
import NotificationAlert from "react-notification-alert";


let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let notificationAlert;
var aTimeout = null;

function EvaluationItem() {

  let session = JSON.parse(window.sessionStorage.getItem('sessions'));
  notificationAlert = React.useRef();  
  const history = useHistory();

  const [items, setItems] = useState([]);
  // const pId = !window.sessionStorage.getItem('pId') || window.sessionStorage.getItem('pId') == -1 ? 0 : window.sessionStorage.getItem('pId');

  const onSelectItem = (e, item) => {
    window.sessionStorage.setItem('newFlag', 0);
    window.sessionStorage.setItem('evalItem', JSON.stringify(item));
    history.push('../coach/evaluationItemEdit');
  }

  const addItem = (e) => {
    window.sessionStorage.setItem('newFlag', 1);
    history.push('../coach/evaluationItemEdit');
  }

  useEffect(() => {
     
    if(session === null){
      window.location = '/';
    }

    axios.get(URL_API+"getEvalItemAPI", {
      params: {
        c_id: session['id']
      }
    })
    .then(function (response) {    
      if(response.data != ""){
        setItems(response.data);
      }
    }); 
  }, []);
  
  return (
    <>
      <div className="content px-0 pb-0">
        <NotificationAlert ref={notificationAlert} />
        <Card className="mb-0">
            <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
              <div className="d-flex col-12 px-2">
                <div className="col-2 col-lg-1 d-flex align-items-center px-0">
                  <i className="fas fa-chevron-left" style={{fontSize:'20px'}} onClick={(event) => history.push('../coach/evaluation')}></i>
                </div>
                <div className="col-8 col-lg-10 text-center px-0 d-flex align-items-center justify-content-center head_title">
                    設定
                </div>
                <div className="col-2 col-lg-1 px-0 d-flex align-items-center justify-content-end">
                  <span className="p-2" onClick={(event) => addItem(event)}><i className="fas fa-plus add_s_items" style={{color:'white'}}></i></span>
                </div>
              </div>
            </CardBody>
            <CardBody className="px-1 coach-aphCtg">
              <div className="col-12">
              {
                items?.map((item, index) =>                                
                  <div key={index} className="col-12 border-bottom py-2" onClick={(e) => onSelectItem(e, item)}>                                         
                    {item.title}
                  </div>                    
              )}
              </div>
            </CardBody>
          </Card>
      </div>
    </>
  );
}

export default EvaluationItem;
