/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import loginImg from "assets/img/logo.png";
import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useHistory } from 'react-router-dom';

// react plugin used to create charts
let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

let email, password;

const getInputEmailValue = (event)=>{    
  email = event.target.value;  
};
const getInputPassValue = (event)=>{    
  password = event.target.value;  
};


function Login() {
  const history = useHistory();

  if(window.sessionStorage.getItem("sessions") != null){

    let session = JSON.parse(window.sessionStorage.getItem("sessions"));

    if(session['name'] != ""){      
      if(session['userkind'] == "player"){
        history.push('../admin/user-page');
        // window.location = "/"; 
      }else if(session['userkind'] == "coach"){//coach
        history.push('../coach/user');
        // window.location = "/"; 
      }
    }
  }
  const register = (event)=>{
    history.push('register');
  }
  const loginFunc = (event)=>{
    axios.post(URL_API+"login", {
      params: {
        email: email,
        password: password,
      }
    })
    .then(function (response) {
      
      console.log(response.data);

      if(response.data != null && response.data != "Not Matched"){

        window.sessionStorage.setItem("sessions", JSON.stringify(response.data));

        if(response.data['userkind'] == "player"){
          history.push('../admin/user-page');

          axios.post(URL_API+"getCoachs", {
            params: {
              id: response.data.id
            }
          })
          .then(function (response) {
            window.sessionStorage.setItem("coachs", JSON.stringify(response.data));
            
          });
        }else if(response.data['userkind'] == "coach"){
          history.push('../coach/user');
        }
      }else{
        NotificationManager.error("登録されたユーザーではありません。", null, 10000, null, true);
      }      
      
    });
  }

  return (
    <>
      <NotificationContainer/>
      <div className="user-login">
        <div>
          <img src={loginImg} alt="react-logo" className="user-logo"/>
        </div>
        <div style={{marginTop : "130px"}}>
          <input placeholder="メールアドレス" type="email" name="user" id="user" className="user-user" onChange={getInputEmailValue}/>
        </div>    
        <div style={{marginTop : "20px"}}>
          <input placeholder="パスワード" type="password" name="pass" id="pass" className="user-user" onChange={getInputPassValue}/>
        </div>     
        <div style={{marginTop : "70px"}}>
          <input type="button" name="user" value="ログイン" className="user-user" onClick={loginFunc}/>
        </div>  
        <div style={{marginTop : "40px"}}>
          <a className="user-resetPass">パスワードを忘れた方はこちら</a>
        </div>  
        <div style={{marginTop : "40px"}}>
          <a className="user-sign" onClick={register}>新規会員登録</a>
        </div>
      </div>
    </>
  );
}

export default Login;
