import React, { Component } from 'react';
import { render } from 'react-dom';

import {useState, useEffect } from "react";

import "assets/css/list.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import { useHistory } from 'react-router-dom';
import DatePicker from 'react-mobile-datepicker';

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function AttendList(){

	let session = JSON.parse(window.sessionStorage.getItem('sessions'));
	if(!session)
		window.location = '/';
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const history = useHistory();
	var date = new Date();  
	const [attends, setAttends] = useState({});
	const [todayDate, setTodayDate] 			= useState((new Date(sessionStorage.getItem('today'))).getDate());
	const player = JSON.parse(sessionStorage.getItem('selectedPlayer')) || {};

	const [keyData, setKeyData] 				= useState(1);

	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	const [getKey, setGetKey] = useState((new Date(sessionStorage.getItem('today'))).getFullYear()+"-"+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+"-"+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));

	const [data_list, setDataList] = useState([]);
	const [uFlag, setUFlag] =  useState(true);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [total, setTotal] = useState(0);
	const [dateConfig, setDateConfig] = useState({
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        },
        'month': {
            format: 'MM',
            caption: 'Mon',
            step: 1,
        }
    });

	const setStateDatas = (d_datas) => {
		let datas = [];
		let days = lastday(today.getFullYear(), today.getMonth());
		let tNum = 0;
		for(var i=1;i<=days;i++){
			let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
			let d = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+i);
			if(!isValidDate(d)){
				d = new Date();
				setToday(d);
				sessionStorage.setItem("today", d);
			}
			if(d_datas[key]){
				let content = d_datas[key].split(',');
				let v = content.indexOf(player.id+"") == -1 ? 0 : 1;
				tNum += v;
				datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:v, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
			}
			else
				datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:0, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
		}
		setTotal(tNum);
		setDataList(datas);
	}	
	const openModal = (f) => {
	    setIsOpen(true);
	}

	const showChart = (e) => {
	    sessionStorage.setItem("today", today);
		history.push('../coach/attendChart');
	};

	const selectDate = (time) => {
	    setToday(time);
	    setTodayDate(time.getDate());
	    setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	    setIsOpen(false);
	    setGetKey(time.getFullYear()+"-"+("0"+(time.getMonth()+1)).slice(-2)+"-"+("0" + time.getDate()).slice(-2));
	    window.sessionStorage.setItem('today', time);
	}

	const handleCancel = () => {
	    setIsOpen(false);
	}

	const toPage = (e, pname) => {
		history.push('../coach/'+pname);
	}

	const formatCheckData1 = (item) => {
    	if(!item.v || item.v == '0'){
      		return (<i className="fa fa-empty-set" style={{fontSize:'25px', color:'#ccc'}}></i>);
    	}else{
    		return (<span className="s_1"></span>);
    	}
  	}

	const changeCheckDataValue = (e, item) => {
		let atts = attends;
	    let att_data = atts[item.key];
	    let v = [];
	    if(att_data)
	      v = att_data.split(',');
	    let idx = v.indexOf(""+player.id);
	    if( idx > -1){
	      v.splice(idx, 1);
	    }else{
	      v.push(player.id);
	    }
	    atts[item.key] = v.join(',');
	    setAttends(atts);
	    setUFlag(!uFlag);
	    window.sessionStorage.setItem('attends', JSON.stringify(atts));
		sendServerData(v.join(','), item.key);
		setStateDatas(atts);
	};

  	const sendServerData = (v, key) => {
  		axios.get(URL_API+"setPlayerAttendData", {
	      params: {
	        todayDate: key, 
	        c_id: session['id'],
	        content:v
	      }
	    })
	    .then(function (response) {

	    }).catch(
	      error => {
	        if(error.response){
	          console.log(error.response.data);
	        };
	      }
	    ); 
  	}

  	const loadUserImg = () => {
	    if(player.profile_photo_path){
	      return "https://xs458763.xsrv.jp/uploads/" + player.profile_photo_path;
	    }else{
	      return require("assets/img/mike.jpg").default;
	    }
	}

	useEffect(() => {
		if(!session){
	      	window.location = '/';
	    }
	    if(window.sessionStorage.getItem('attends') && window.sessionStorage.getItem('attends') != 'undefined'){
      		setAttends(JSON.parse(window.sessionStorage.getItem('attends'))); 
      		setStateDatas(JSON.parse(window.sessionStorage.getItem('attends')));
	    }
  	}, [today]);
	return (
		<>		
			<DatePicker
		        dateConfig = {dateConfig}
		        value = {today}
		        isOpen = {modalIsOpen}
		        onSelect = {selectDate}
		        onCancel = {handleCancel} />	

			<div className="content px-0 pb-0">
        		<Row className="px-0 mx-0">
          			<Col md="12" className="px-0 mb-0">
						<Card className="mb-0">
							<CardBody className="seldate" id='seldate' style={{color:'white'}}>
					            <Row>					            	
					            	<Col className="col-2 d-flex justify-content-center align-items-center">
					            		<i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toPage(event, 'attend')}></i>
					            	</Col>
					              	<Col className="col-8 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ymd')} >
					                	{todayVDate}
					             	</Col>
					             	<Col className="col-2 d-flex justify-content-center">
					             	</Col>
					            </Row>
					        </CardBody>  
					        <div className="chart_body">
								<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center">
									<div className="col-9 px-0 d-flex align-items-center">
										<div className="d-flex col-3 px-0 justify-content-center align-items-center">
											<img src={loadUserImg()} className="chart_list_logo" style={{borderRadius:'50%'}}/>
										</div>
										<div className="col-8 px-0 justify-content-start align-items-center" style={{fontSize:'16px', fontWeight:'bold'}}>
											{player.name}
										</div>
										<div className="col-1" style={{fontSize:'16px'}}>
											{total}
										</div>
									</div>
									<div className="d-flex col-3 px-0 justify-content-center align-items-center chartList">
										<i className="far fa-calendar-alt" style={{marginRight:'5px', paddingLeft: '7px', paddingRight: '7px'}} onClick={(event) => showChart(event)}></i>
	      								<i className="fas fa-list"></i>
									</div>
								</div>
							</div>
							<div className="content_body">					
							{
								data_list.map((item, i) => 
									<div key={i} className="d-flex justify-content-between px-3 border-bottom py-2 font-weight-bolder pointer fa-lg">
										<div className = {(item.w==0 ? 'sun' : (item.w == 6 ? 'sat' : '')) + ' d-flex align-items-center'} onClick={(event) => changeCheckDataValue(event, item)} style={{width:'calc(100% - 25px)'}}>{item.dtitle}</div>
										<div className="d-flex align-items-center" style={{color:'rgb(65, 150, 23)'}}>
											{
												formatCheckData1(item)
											}
										</div>
									</div>
								)
							}
							</div>
						</Card>
					</Col>
				</Row>
			</div>
      	</>
    )
}

export default AttendList;