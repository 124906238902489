import React, { Component } from 'react';
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useState, useEffect } from "react";
import Modal from 'react-modal';
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import dataTypes from "views/diary/dataType.js";

import "assets/css/chart.css";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0.25rem',
    width:'85%',
    borderRadius:'15px',
    fontWeight:'bold'
  },
};

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}
// function getMaxV(item){
// 	for(let i=0;i<dataTypes.basic.length;i++){
// 		if(dataTypes.basic[i].id == item.data_type)
// 			return dataTypes.basic[i].max;
// 	} 
// 	return 5;
// }
function Diary1(props){

	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const flag			 						= props.flag;
	const [categories, setCategories] 			= useState([]);
	const [data, setData] 						= useState([]);
	const [seriesName, setSeriesName] 			= useState(props.sItem['title'] || '');
	const [fname, setFName] 					= useState(props.sItem['id'] || 0);
	const [chartType, setChartType] 			= useState('line');
	const [chart_logo, setChart_logo] 			= useState(props.sItem['i_icon'] || '');
	// const [maxNum, setMaxNum] 					= useState(getMaxV(props.sItem) || 5);
	const [tooltipEnabled, setTooltipEnabled] 	= useState(false);
	
	const today									= props.today;
	const [modalIsOpen, setIsOpen] 				= useState(false);
	const [fromTo, setFromTo]					= useState('');
	const [cnts, setCnts]						= useState(0);
	const [tSum, setTSum]						= useState(0);
	const [rAve, setRAve]						= useState(0);
	const [vMin, setVMin]						= useState(0);
	const [dMin, setDMin]						= useState('');
	const [vMax, setVMax]						= useState(0);
	const [dMax, setDMax]						= useState('');
	
	const handleClick = (chart) => {
		setIsOpen(true);
	};

  	function afterOpenModal() {
    	// references are now sync'd and can be accessed.
    	// subtitle.style.color = '#f00';
  	}

  	function closeModal() {
    	setIsOpen(false);
  	}

	const onSwitchChart = (c) => {
		setChartType(c);
	}

	let cnt = 0;
	let sum = 0;
	let ave = 0;
	let min = 0;
	let max = 0;
	let dmin = '';
	let dmax = '';

	const setValues = (ymd, v) =>{
		// console.log(ymd, v, props.sItem['id']);
		let dates = ymd.split('-');
		sum += v;
		cnt += 1;
		ave = sum / cnt;
		// setRAve( ((tSum+v) / (cnts+1)).toFixed(2) );
		if(min == 0){
			// setVMin(v.toFixed(2));
			min = v;
			dmin = Number(dates[1]) + ' / ' + Number(dates[2]);
			// setDMin(Number(dates[1]) + ' / ' + Number(dates[2]));
		}else{
			if(min > v){
				min = v;
				dmin = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
		if(max == 0){
			max = v;
			dmax = Number(dates[1]) + ' / ' + Number(dates[2]);
			// setVMax(v.toFixed(2));
			// setDMax(Number(dates[1]) + ' / ' + Number(dates[2]));
		}else{
			if(max < v){
				max = v;
				dmax = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
	}

	const setStateDatas = (d_datas) => {
		var categs = [];
		var dats = [];
		let y = today.getFullYear();
		let m =today.getMonth();
		// console.log(flag, fname);		
		if(flag == 0){
			let m =today.getMonth();
			let days = lastday(y, m);
			setFromTo(month[m]+'.01,'+y+' - '+month[m]+'.'+days+','+y+" ("+days+"days)");
			if(d_datas == null){
				for(var i=1;i<=days;i++){
					categs.push((today.getMonth()+1)+'/'+i);
					dats.push(0);		
				}
			}else{
				for(var i=1;i<=days;i++){
					let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
					categs.push((today.getMonth()+1)+'/'+i);
					// console.log(key, d_datas[key]);
					if(d_datas[key]){
						if(d_datas[key][fname]){
							dats.push(isNaN(Number(d_datas[key][fname])) ? 0 : Number(d_datas[key][fname]));
							setValues(key, isNaN(Number(d_datas[key][fname])) ? 0 : Number(d_datas[key][fname]));
						}else{
							dats.push(0);
						}
					}else{
						dats.push(0);
					}
				}
			}
		}else if(flag == 1){
			let mon = m + 1;
			let m1 = mon % 3 == 0 ? mon - 2 : ( mon % 3 == 1 ? mon : mon - 1);
			let m2 = m1 + 1;
			let m3 = m1 + 2;
			let ms = [m1, m2, m3];			
			if(d_datas == null){
				let sdays = 0;
				let days = 0;
				for(var i = 0; i<ms.length;i++){
					days = lastday(y, ms[i]-1);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						dats.push(0);
					}
				}
				setFromTo(month[m1-1]+'.01,'+y+'-'+month[m3-1]+'.'+days+','+y+" ("+sdays+"days)");
			}else{
				let sdays = 0;
				let days = 0;
				for(var i = 0; i<ms.length;i++){
					days = lastday(y, ms[i]-1);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						let key = today.getFullYear()+"-"+("0"+ms[i]).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							if(d_datas[key][fname]){
								dats.push(isNaN(Number(d_datas[key][fname])) ? 0 : Number(d_datas[key][fname]));
								setValues(key, isNaN(Number(d_datas[key][fname])) ? 0 : Number(d_datas[key][fname]));
							}else{
								dats.push(0);
							}
						}else{
							dats.push(0);
						}
					}
				}
				setFromTo(month[m1-1]+'.01,'+y+'-'+month[m3-1]+'.'+days+','+y+" ("+sdays+"days)");
			}
		}else{
			let sdays = 0;
			if(d_datas == null){
				for(var i = 0; i<12;i++){
					let days = lastday(y, i);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						dats.push(0);
					}
				}
			}else{
				for(var i=0;i<12;i++){
					let days = lastday(y, i);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						let key = today.getFullYear()+"-"+("0"+(i+1)).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							if(d_datas[key][fname]){
								dats.push(isNaN(Number(d_datas[key][fname])) ? 0 : Number(d_datas[key][fname]));
								setValues(key, isNaN(Number(d_datas[key][fname])) ? 0 : Number(d_datas[key][fname]));
							}else{
								dats.push(0);
							}
						}else{
							dats.push(0);
						}
					}
				}
			}
			setFromTo(month[0]+'.01,'+y+'-'+month[11]+'.31,'+y+" ("+sdays+"days)");
		}
		setCnts(cnt);
		setRAve(ave.toFixed(2));
		setTSum(sum.toFixed(2));
		setVMax(max.toFixed(2));
		setVMin(min.toFixed(2));
		setDMax(dmax);
		setDMin(dmin);
		setCategories(categs);
		console.log(dats);
		setData(dats);
	}

	const chartOptions = {
		xAxis: {
  			categories: categories,
  			title:'',
  			labels:{
  				enabled:false
  			}
  		},
  		yAxis:{
  			title:""
  		},
  		series: [
      		{ 
      			type: chartType,
      			name: seriesName,
        		data: data,
	          	color: '#0071ce',        		
      		}
		],
		credits: {
    		enabled: false
  		},
		tooltip: {
	        shared: true,
	        useHTML: true,
	        headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
	        pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
	            '<td style="text-align: right"><b>{point.y}</b></td></tr>',
	        footerFormat: '</table>',
	        enabled: tooltipEnabled,
	    },
		title:{
			text:''
		},
		plotOptions:{
			series : {
				point: {
		          events: {
		            click: handleClick.bind(this),
		          },
		        },
			},
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},
		},
		chart:{
			events:{
				click:handleClick.bind(this)
			},
			height:120
		}
	}

	useEffect(() => {
	    if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
	        setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	    }
  	}, [flag, today]);
	return (
		<>  
			<Modal
		        isOpen={modalIsOpen}
		        onAfterOpen={afterOpenModal}
		        onRequestClose={closeModal}
		        style={customStyles}
		        contentLabel="Example Modal">
	        	<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center border-bottom">
	        		<div className="col-3 px-0 d-flex align-items-center justify-content-center">
	        			<img src={chart_logo} className="chart_list_logo"/>
	        		</div>
					<div className="col-6 px-0 d-flex align-items-center justify-content-center">
						{seriesName}
					</div>
					<div className="col-3 d-flex">
					</div>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-center align-items-center border-bottom">
					{
						fromTo
					}
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Count</label>
					<span>{cnts}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Sum</label>
					<span>{tSum}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Ave</label>
					<span>{rAve}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Min : {dMin}</label>
					<span>{vMin}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Max : {dMax}</label>
					<span>{vMax}</span>
				</div>
		    </Modal>
      		<div className="chart_body">
				<div className="row col-12 px-0 mx-0 py-1 justify-content-between align-items-center">
					<div className="col-8 px-0 d-flex align-items-center">
						<div className="d-flex col-3 px-0 justify-content-center align-items-center">
							<img src={chart_logo} className="chart_list_logo"/>
						</div>
						<div className="col-9 px-0 justify-content-start align-items-center">
							{seriesName}
						</div>
					</div>
					<div className="col-4 d-flex">
						<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='line'?'active':'')} onClick={() => onSwitchChart('line')}><i className="fas fa-chart-line fa-fw fa-2x pointer"></i></span>
						<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='column'?'active':'')} onClick={() => onSwitchChart('column')}><i className="fas fa-chart-bar fa-fw fa-2x pointer"></i></span>
					</div>
				</div>
			</div>
			<div style={{borderBottom:'1px solid #000'}}>
        		<HighchartsReact
          			highcharts={Highcharts}
          			options={chartOptions}
          			handleClick={handleClick}
        		/>
      		</div>
      	</>
    )
}

export default Diary1;