/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
// import Nav from 'react-bootstrap/Nav';
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';
import React, {useState, useEffect } from "react";

import dataTypes from "views/diary/dataType.js";


import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

// add new codes
import { useHistory } from 'react-router-dom';

// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function SelectType() {

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }

  const history = useHistory();

  const [sDataType, setSDataType] = useState(JSON.parse(window.sessionStorage.getItem('s_dataType')) || dataTypes.basic[0]);
  const editFlag = window.sessionStorage.getItem('editFlag') || 0;

  const toPage = (e, pname) => {
    history.push('../admin/'+pname);
  }

  const selectDataType = (e, item) =>{
    window.sessionStorage.setItem('s_dataType', JSON.stringify(item));
    if(editFlag == 0)
      toPage(e, 'newItem');
    else
      toPage(e, 'editItem');
  }

  return (
    <>      
      <div className="content px-0 pb-0">
        <div className="titleDiv">
          <Card className="mb-0">
            <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
              <div className="d-flex col-12 px-2">
                <div className="col-2 col-lg-1 d-flex align-items-center px-0">
                  <i className="fas fa-chevron-left" style={{fontSize:'20px'}} onClick={(event) => toPage(event, editFlag == 0 ? 'newItem' : 'editItem')}></i>
                </div>
                <div className="col-8 col-lg-10 text-center px-0 d-flex align-items-center justify-content-center head_title">
                    データ型を選択する
                </div>
                <div className="col-2 col-lg-1 px-0">
                </div>
              </div>
            </CardBody>
            <CardBody className="px-0 pt-1 diary_list">
              <div className="select_tabs" style={{display: 'block', width: '100%'}}>
                {
                  dataTypes.basic.map((item, i)=>
                    <div key={i} className="itemRow col-12 d-flex px-0 py-3">
                      <Col className="col-12 pl-2 py-0">
                        <div className="d-flex justify-content-start align-items-center">
                          <label name="data_type" htmlFor={'t_'+item.id} className="m-0 d-flex justify-content-start align-items-center" style={{fontSize:'1rem'}}>
                            <input name="data_type" onChange={(event) => selectDataType(event, item)} checked = {sDataType.id == item.id ? 'checked' : ''} type="radio" id={'t_'+item.id} value={item.id} className="mr-1"/>
                            {item.name}    
                          </label>                          
                        </div>
                        <div>{item.comment}</div>
                      </Col>
                    </div>
                  )
                }
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default SelectType;
