import React, { Component } from 'react';
import { render } from 'react-dom';

import {useState, useEffect } from "react";

import "assets/css/list.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import { useHistory } from 'react-router-dom';
import DatePicker from 'react-mobile-datepicker';

import dataTypes from "views/diary/dataType.js";


let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}
function getMaxV(item){
	for(let i=0;i<dataTypes.basic.length;i++){
		if(dataTypes.basic[i].id == item.data_type)
			return dataTypes.basic[i].max;
	} 
	return 5;
}

function DiaryList3(){
	let session = JSON.parse(window.sessionStorage.getItem('sessions'));
	if(!session)
		window.location = "/";
	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const history = useHistory();
	var date = new Date();  
	const [flag, setFlag] 						= useState(0);
	const [todayDate, setTodayDate] 			= useState((new Date(sessionStorage.getItem('today'))).getDate());

	const [seriesName, setSeriesName] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['title'] + "("+JSON.parse(sessionStorage.getItem('selectedItem'))['i_unit']+")" || '');
	const [fname, setFName] 					= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['id'] || 0);

	const [chart_logo, setChart_logo] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['i_icon'] || '');
	const [maxNum, setMaxNum] 					= useState(getMaxV(JSON.parse(sessionStorage.getItem('selectedItem'))) || 5);

	const [showDate, setShowDate] 				= useState();
	const [keyData, setKeyData] 				= useState(1);
	// const [vData, setVData] 					= useState(0);
	const [showInputV, setShowInputV] 			= useState('show');
	// const [showDataV, setShowDataV] 			= useState('show');

	const [showInputBtnf, setShowInputBtnf] 	= useState(false);
	const [isModal, setIsModal] 				= useState('hide');
	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	const [getKey, setGetKey] 					= useState((new Date(sessionStorage.getItem('today'))).getFullYear()+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));

	const [startNumber, setStartNumber]         = useState(0);
    const [endNumber, setEndNumber]             = useState(0);

	const [data_list, setDataList] = useState([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [dateConfig, setDateConfig] = useState({
                    'year': {
                        format: 'YYYY',
                        caption: 'Year',
                        step: 1,
                    },
                    'month': {
                        format: 'MM',
                        caption: 'Mon',
                        step: 1,
                    }
                });

	const setStateDatas = (d_datas) => {
		// setDiaryData(d_datas);
		let datas = [];
		let days = lastday(today.getFullYear(), today.getMonth());
		// for (let i in diaryData) {
		// let keys = [];
		// console.log(today.getFullYear(), today.getMonth());
		for(var i=1;i<=days;i++){
			let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
			let d = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+i);
			// console.log(d.getDay());
			if(d_datas[key]){
				if(d_datas[key][fname])
					if(d_datas[key][fname]){
						let s = d_datas[key][fname].start;
						if(s)
							s = isNaN(Number(s)) ? 0 : Number(s);
						else
							s = 0;
						let e = d_datas[key][fname].end;
						if(e)
							e = isNaN(Number(e)) ? 0 : Number(e);
						else
							e = 0;
						datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:{start:s,end:e}, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
					}
					else
						datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:{start:0,end:0}, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
				else
					datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:{start:0,end:0}, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
			}else
				datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:{start:0,end:0}, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
		}
		// console.log(datas);
		setDataList(datas);
	}	
	const openModal = (f) => {
	    setIsOpen(true);
	    setIsModal('_hide');
	    if(f == 'ymd'){
	      	setDateConfig({
	            'year': {
	                format: 'YYYY',
	                caption: 'Year',
	                step: 1,
	            },
	            'month': {
	                format: 'MM',
	                caption: 'Mon',
	                step: 1,
	            }
	        });
	    }
	}

	const showChart = (e) => {
	    sessionStorage.setItem("today", today);
	    if(session.userkind == 'coach')
	    	history.push('../coach/diaryChart3');
	    else
	    	history.push('../admin/diaryChart3');
	};

	const setDataInput = (e, item) => {
		if(session.userkind == 'coach')
			return;
		var v = item.v;
		var key = item.key;
		setTodayDate(item.d);
		// setVData(v);
		setStartNumber(v.start);
		setEndNumber(v.end);
		setKeyData(key);
		setIsModal('_show');
		setShowDate(item.m+' / '+ item.d + ' ' + weekday[item.w]);
	};

	const noHandleClick = (event) => {
		setIsModal('_hide');
		setShowInputBtnf(false);
	}

	const onChangeNumber = (event, i) => {
		let s = startNumber;
		let e = endNumber
		if(i==1){
		  	setStartNumber(event.target.value);
		  	s = event.target.value;
		}else{
		  	setEndNumber(event.target.value);
		  	e = event.target.value;
		}
		// let catg = categories[xData];
		// let md = catg.split('/');
		let setDataDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-' + todayDate;
		// let session = window.sessionStorage.getItem('sessions'); 
		// let se = JSON.parse(session);
		let vv = {start:s,end:e};
		axios.get(URL_API+"setDiaryItemData", {
	      params: {
	        seldate: setDataDate, 
	        user: session['id'],
	        item_id: fname,
	        v:vv
	      }
	    })
	    .then(function (response) {
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
	  		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
	      	setStateDatas(response.data.data);
	    }).catch(
	      error => {
	        if(error.response){
	          console.log(error.response.data);
	        };
	      }
	    ); 
	}

	const onBeforeData = (e) => {
		onGetData(todayDate-1);
		setShowInputBtnf(false);
	}

	const onNextData = (e) => {
		onGetData(todayDate+1);
		setShowInputBtnf(false);
	}

	const onGetData = (i) => {	
		if(i < 1)
			return;	
		let data = JSON.parse(window.sessionStorage.getItem('diaryData'));
		let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);

		let d = new Date(today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2));
		// console.log(key, today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2));
		let days = lastday(today.getFullYear(), today.getMonth());
		if(i > days)
			return;
		if(data[key] != undefined){
			if(data[key][fname]){
				setStartNumber(data[key][fname].start);
				setEndNumber(data[key][fname].end);
			}else{
				setStartNumber(0);
				setEndNumber(0);
			}
		}else{
			setStartNumber(0);
			setEndNumber(0);
		}
		setShowDate((today.getMonth()+1)+' / '+ i + ' ' + weekday[d.getDay()]);
		setTodayDate(i);
	}

	const selectDate = (time) => {
	    setToday(time);
	    setTodayDate(time.getDate());
	    setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	    setIsOpen(false);
	    setGetKey(time.getFullYear()+"-"+("0"+(time.getMonth()+1)).slice(-2)+"-"+("0" + time.getDate()).slice(-2));
	    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	}

	const handleCancel = () => {
	    setIsOpen(false);
	}

	const toPage = (e, pname) => {
		if(session.userkind == 'coach' && pname == 'diary'){
			history.push('../coach/'+pname);
			return;
		}
		history.push('../admin/'+pname);
	}

	useEffect(() => {
		// let session = window.sessionStorage.getItem('sessions'); 
	 //    let se = JSON.parse(session);
	    if(!session){
	      	window.location = '/';
	    }

	   	// console.log(chart_title, chart_field_name, chart_intro_img);
	   	if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
			setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   	}

	   	let p = session;
	   	if(session.userkind == 'coach'){
			p = JSON.parse(window.sessionStorage.getItem('player')); 
			if(!p){
			  p = JSON.parse(window.sessionStorage.getItem('players'))[0];
			}
		}
   		axios.get(URL_API+"getDiaryAPI", {
			params: {
				seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
				user: p['id'],
			}
	    })
	    .then(function (response) {		      
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
      		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
	      	setStateDatas(response.data.data);
	    }).catch(
	      	error => {
	        	if(error.response){
	          		console.log(error.response.data);
	        	};
	      	}
	    );
  	}, [today]);
	return (
		<>		
			<DatePicker
		        dateConfig={dateConfig}
		        value={today}
		        isOpen={modalIsOpen}
		        onSelect={selectDate}
		        onCancel={handleCancel} />	

			<div className={isModal + ' py-3 px-2 showDataDiv'} id="showDataDiv">
				<div className={'show_body'}>						
					<div className={'m-header mb-1'}>
						<div>{showDate}</div>
					</div>
					<div className="m-body row col-12 px-0 mx-0 align-items-center">
						<i className={"col-2 fas fa-caret-left fa-fw fa-2x pointer"} onClick={(event) => onBeforeData(event)}></i>
						<div className="col-8 row px-0 mx-0">
							<div className="col-12 d-flex px-1">
				                <label className="col-3 px-1 m-0 d-flex align-items-center">A : </label>
				                {
									session.userkind == 'player' ? (
				                		<input className="col-9 px-1" type="number" id="sValue" value={startNumber} onChange={(event) => onChangeNumber(event, 1)}/>) : startNumber
								}
				              </div>
				              <div className="col-12 d-flex px-1 mt-1">
				                <label className="col-3 px-1 m-0 d-flex align-items-center">B : </label>
				                {
									session.userkind == 'player' ? (
				                		<input className="col-9 px-1" type="number" id="eValue" value={endNumber} onChange={(event) => onChangeNumber(event, 2)}/>) : endNumber
								}
				              </div>
						</div>
						<i className="col-2 fas fa-caret-right fa-fw fa-2x pointer" onClick={(event) => onNextData(event)}></i>
					</div>
					<div className="d-flex justify-content-center mt-2">
						<span><i className="fas fa-angle-down fa-fw fa-2x pointer" onClick={(event) => noHandleClick(event)}></i></span>
					</div>
				</div>		
			</div>
			<div className="content px-0">
        		<Row className="px-0 mx-0">
          			<Col md="12" className="px-0">
						<Card>
							<CardBody className="seldate" id='seldate' style={{color:'white'}}>
					            <Row>					            	
					            	<Col className="col-2 d-flex justify-content-center align-items-center">
					            		<i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toPage(event, 'diary')}></i>
					            	</Col>
					              	<Col className="col-8 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ymd')} >
					                	{todayVDate}
					             	</Col>
					             	<Col className="col-2 d-flex justify-content-center">
					             	</Col>
					            </Row>
					        </CardBody>  
					        <div className="chart_body">
								<div className="row col-12 px-0 mx-0 py-1 justify-content-between align-items-center">
									<div className="col-9 px-0 d-flex align-items-center">
										<div className="d-flex col-3 px-0 justify-content-center align-items-center">
											<img src={chart_logo} className="chart_list_logo"/>
										</div>
										<div className="col-9 px-0 justify-content-start align-items-center">
											{seriesName}
										</div>
									</div>
									<div className="d-flex col-3 px-0 justify-content-center align-items-center chartList">										
										<i className="fas fa-chart-line" style={{marginRight:'5px'}} onClick={(event) => showChart(event)}></i>
          								<i className="fas fa-list"></i>
									</div>
								</div>
							</div>
							<div className="content_body">					
							{
								data_list.map((item, i) => 
									<div key={i} className="d-flex justify-content-between px-3 border-bottom py-2 font-weight-bolder pointer fa-lg" onClick={(event) => setDataInput(event, item)}>
										<div className = {(item.w==0 ? 'sun' : (item.w == 6 ? 'sat' : '')) + ' d-flex align-items-center'}>{item.dtitle}</div>
										<div className="d-flex align-items-center" style={{color:'rgb(65, 150, 23)'}}>{item.v.start} / {item.v.end}</div>
									</div>
								)
							}
							</div>
						</Card>
					</Col>
				</Row>
			</div>
      	</>
    )
}

export default DiaryList3;