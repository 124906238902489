/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
// import questionImg from "assets/img/self/question.png";
import ball from "assets/img/ball.jpg";
import { useHistory } from 'react-router-dom';
// reactstrap components

import NotificationAlert from "react-notification-alert";
import {  
  Card,  
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import face1 from "assets/img/face/1.png";
import face2 from "assets/img/face/2.png";
import face3 from "assets/img/face/3.png";
import face4 from "assets/img/face/4.png";
import face5 from "assets/img/face/5.png";


import w1 from "assets/img/face/11.png";
import w2 from "assets/img/face/12.png";
import w3 from "assets/img/face/13.png";
import w4 from "assets/img/face/14.png";
import w5 from "assets/img/face/15.png";
import w6 from "assets/img/face/16.png";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let selTables = {};
let notificationAlert;
let selTab = {};
let se;

function Tablesedit() {

  let session = JSON.parse(window.sessionStorage.getItem('sessions')); 
  if(!session){    
    window.location = '/';
  }   

  notificationAlert = React.useRef(); 

  selTables = JSON.parse(window.sessionStorage.getItem('selTables'));

  const category = selTables.category;
  const tourdate = selTables.tourdate;
  const opponentname = selTables.opponentname;
  const opponentgroup = selTables.opponentgroup;
  const surface = selTables.surface;
  const match = selTables.match;
  const mainround = selTables.mainround;
  const subround = selTables.subround;
  const weather = selTables.weather;
  const condition = selTables.condition;

  const history = useHistory();
  
  const preparationPage = (e) => {   
    history.push('../coach/otherS');
  }
///////////////////
  const [resthink1, setResthink1] = useState();
  const [resthink2, setResthink2] = useState();
  const [resthink3, setResthink3] = useState();
  const [resthink4, setResthink4] = useState();
  const [resthink5, setResthink5] = useState();

  const [offort, setOffort] = useState();
  const [fight, setFight] = useState();
  /////////////////////// start //////////////////////
  const [g1_1, setg1_1] = useState(0);
  const [g1_2, setg1_2] = useState(0);
  const [g1_3, setg1_3] = useState(0);
  const [g1_4, setg1_4] = useState(0);
  const [g1_5, setg1_5] = useState(0);
  const [g1_6, setg1_6] = useState(0);
  const [g1_7, setg1_7] = useState(0);
  const [g1_8, setg1_8] = useState(0);
  const [g1_9, setg1_9] = useState(0);
  const [g1_10, setg1_10] = useState(0);
  const [g1_11, setg1_11] = useState(0);
  const [g1_12, setg1_12] = useState(0);
  const [g1_13, setg1_13] = useState(0);
  const [g1_14, setg1_14] = useState(0);
  const [g1_15, setg1_15] = useState(0);
  const [g1_16, setg1_16] = useState(0);
  const [g1_17, setg1_17] = useState(0);

  const [_g1_1, set_g1_1] = useState(0);
  const [_g1_2, set_g1_2] = useState(0);
  const [_g1_3, set_g1_3] = useState(0);
  const [_g1_4, set_g1_4] = useState(0);
  const [_g1_5, set_g1_5] = useState(0);
  const [_g1_6, set_g1_6] = useState(0);
  const [_g1_7, set_g1_7] = useState(0);
  const [_g1_8, set_g1_8] = useState(0);
  const [_g1_9, set_g1_9] = useState(0);
  const [_g1_10, set_g1_10] = useState(0);
  const [_g1_11, set_g1_11] = useState(0);
  const [_g1_12, set_g1_12] = useState(0);
  const [_g1_13, set_g1_13] = useState(0);
  const [_g1_14, set_g1_14] = useState(0);
  const [_g1_15, set_g1_15] = useState(0);
  const [_g1_16, set_g1_16] = useState(0);
  const [_g1_17, set_g1_17] = useState(0);

  const [g2_1, setg2_1] = useState(0);
  const [g2_2, setg2_2] = useState(0);
  const [g2_3, setg2_3] = useState(0);
  const [g2_4, setg2_4] = useState(0);
  const [g2_5, setg2_5] = useState(0);
  const [g2_6, setg2_6] = useState(0);
  const [g2_7, setg2_7] = useState(0);
  const [g2_8, setg2_8] = useState(0);
  const [g2_9, setg2_9] = useState(0);
  const [g2_10, setg2_10] = useState(0);
  const [g2_11, setg2_11] = useState(0);
  const [g2_12, setg2_12] = useState(0);
  const [g2_13, setg2_13] = useState(0);
  const [g2_14, setg2_14] = useState(0);
  const [g2_15, setg2_15] = useState(0);
  const [g2_16, setg2_16] = useState(0);
  const [g2_17, setg2_17] = useState(0);

  const [_g2_1, set_g2_1] = useState(0);
  const [_g2_2, set_g2_2] = useState(0);
  const [_g2_3, set_g2_3] = useState(0);
  const [_g2_4, set_g2_4] = useState(0);
  const [_g2_5, set_g2_5] = useState(0);
  const [_g2_6, set_g2_6] = useState(0);
  const [_g2_7, set_g2_7] = useState(0);
  const [_g2_8, set_g2_8] = useState(0);
  const [_g2_9, set_g2_9] = useState(0);
  const [_g2_10, set_g2_10] = useState(0);
  const [_g2_11, set_g2_11] = useState(0);
  const [_g2_12, set_g2_12] = useState(0);
  const [_g2_13, set_g2_13] = useState(0);
  const [_g2_14, set_g2_14] = useState(0);
  const [_g2_15, set_g2_15] = useState(0);
  const [_g2_16, set_g2_16] = useState(0);
  const [_g2_17, set_g2_17] = useState(0);

  const [g3_1, setg3_1] = useState(0);
  const [g3_2, setg3_2] = useState(0);
  const [g3_3, setg3_3] = useState(0);
  const [g3_4, setg3_4] = useState(0);
  const [g3_5, setg3_5] = useState(0);
  const [g3_6, setg3_6] = useState(0);
  const [g3_7, setg3_7] = useState(0);
  const [g3_8, setg3_8] = useState(0);
  const [g3_9, setg3_9] = useState(0);
  const [g3_10, setg3_10] = useState(0);
  const [g3_11, setg3_11] = useState(0);
  const [g3_12, setg3_12] = useState(0);
  const [g3_13, setg3_13] = useState(0);
  const [g3_14, setg3_14] = useState(0);
  const [g3_15, setg3_15] = useState(0);
  const [g3_16, setg3_16] = useState(0);
  const [g3_17, setg3_17] = useState(0);


  const [_g3_1, set_g3_1] = useState(0);
  const [_g3_2, set_g3_2] = useState(0);
  const [_g3_3, set_g3_3] = useState(0);
  const [_g3_4, set_g3_4] = useState(0);
  const [_g3_5, set_g3_5] = useState(0);
  const [_g3_6, set_g3_6] = useState(0);
  const [_g3_7, set_g3_7] = useState(0);
  const [_g3_8, set_g3_8] = useState(0);
  const [_g3_9, set_g3_9] = useState(0);
  const [_g3_10, set_g3_10] = useState(0);
  const [_g3_11, set_g3_11] = useState(0);
  const [_g3_12, set_g3_12] = useState(0);
  const [_g3_13, set_g3_13] = useState(0);
  const [_g3_14, set_g3_14] = useState(0);
  const [_g3_15, set_g3_15] = useState(0);
  const [_g3_16, set_g3_16] = useState(0);
  const [_g3_17, set_g3_17] = useState(0);

  const[win1, setwin1] = useState(0);
  const[lose1, setlose1] = useState(0);
  const[win2, setwin2] = useState(0);
  const[lose2, setlose2] = useState(0);
  const[win3, setwin3] = useState(0);
  const[lose3, setlose3] = useState(0);

  ////////////////////////////////////////////////////
  const [improved1, setImproved1] = useState();
  const [improved2, setImproved2] = useState();
  const [improved3, setImproved3] = useState();
  const [improved4, setImproved4] = useState();
  const [improved5, setImproved5] = useState();
  //HowPlay
  const [howplay1, setHowplay1] = useState();
  const [howplay2, setHowplay2] = useState();
  const [howplay3, setHowplay3] = useState();
  const [howplay4, setHowplay4] = useState();
  const [howplay5, setHowplay5] = useState();

  const [mental1, setMental1] = useState();
  const [mental2, setMental2] = useState();
  const [mental3, setMental3] = useState();
  const [mental4, setMental4] = useState();
  const [mental5, setMental5] = useState();
  const [mental6, setMental6] = useState();
  const [mental7, setMental7] = useState();
  const [mental8, setMental8] = useState();
  const [mental9, setMental9] = useState();
  const [mental10, setMental10] = useState();
  const [mental11, setMental11] = useState();
  const [mental12, setMental12] = useState();


  const [remark, setRemark] = useState();
///////////////////
  const [checkboxgroup, setCheckboxgroup] = useState(
    [ { en: 's_serverData', jp: 'サーブ', fc: 's_setServerData', val:true },
      { en: 's_receiveData', jp: 'レシーブ', fc: 's_setReceiveData', val:true},
      { en: 's_strokeData', jp: 'ストローク', fc: 's_setStrokeData', val:true},
      { en: 's_netplayData', jp: 'ネットプレー', fc: 's_setNetplayData', val:true},
      { en: 's_mentalData', jp: 'メンタル', fc: 's_setMentalData', val:true},
      { en: 's_tacticsData', jp: '戦術', fc: 's_setTacticsData', val:true},]
    );

  const [s_serverData, s_setServerData] = useState([]);
  const [s_receiveData, s_setReceiveData] = useState([]);
  const [s_strokeData, s_setStrokeData] = useState([]);
  const [s_netplayData, s_setNetplayData] = useState([]);
  const [s_mentalData, s_setMentalData] = useState([]);
  const [s_tacticsData, s_setTacticsData] = useState([]);
  
  const [checkedState, setCheckedState] = useState(
    new Array(s_serverData.length).fill(false)
  );

  // const analysisOnChange = (e, position, aryName, fcName) => {
    
  //   eval(aryName)[position]['solution'] = e.target.value;
  //   window.sessionStorage.setItem(aryName, JSON.stringify(eval(aryName))); 
  //   eval(fcName)(eval(aryName));

  //   let tmp = [...checkboxgroup];
  //   tmp[0]['val'] = !tmp[0]['val'];
  //   setCheckboxgroup(tmp);

  // };
  

  useEffect(() => {
    
    if(session === null){    
      window.location = '/';
    }

    //戦術
    selTab["lose1"] = 0;
    selTab["win1"] = 0;
    selTab["lose2"] = 0;
    selTab["win2"] = 0;
    selTab["lose3"] = 0;
    selTab["win3"] = 0;

    for(var i = 0 ; i < checkboxgroup.length; i++){

      axios.get(URL_API+"getOtherSelAPI", {
        params: {
          data: checkboxgroup[i]['en'],
          sel: selTables['id'], 
        }
      })
      .then(function (response) {
        
        if(response.data.length > 0 && Array.isArray(response.data)){           
          eval(response.data[0]['fc'])(response.data);  
        }

      });
    }

    //selGame. selTables

    axios.get(URL_API+"getSelTableAPI", {
      params: {       
        sel: selTables['id'], 
      }
    })
    .then(function (response) {
      
      window.sessionStorage.setItem('selTab', JSON.stringify(response.data));

      if(response.data.length > 0 && Array.isArray(response.data)){

        selTab = response.data[0];
    
        setResthink1(selTab['resthink1']);
        setResthink2(selTab['resthink2']);
        setResthink3(selTab['resthink3']);
        setResthink4(selTab['resthink4']);
        setResthink5(selTab['resthink5']);

        setOffort(selTab['offort']);
        setFight(selTab['fight']);

        setg1_1(selTab['g1_1']);
        setg1_2(selTab['g1_2']);
        setg1_3(selTab['g1_3']);
        setg1_4(selTab['g1_4']);
        setg1_5(selTab['g1_5']);
        setg1_6(selTab['g1_6']);
        setg1_7(selTab['g1_7']);
        setg1_8(selTab['g1_8']);
        setg1_9(selTab['g1_9']);
        setg1_10(selTab['g1_10']);
        setg1_11(selTab['g1_11']);
        setg1_12(selTab['g1_12']);
        setg1_13(selTab['g1_13']);
        setg1_14(selTab['g1_14']);
        setg1_15(selTab['g1_15']);
        setg1_16(selTab['g1_16']);
        setg1_17(selTab['g1_17']);

        set_g1_1(selTab['_g1_1']);
        set_g1_2(selTab['_g1_2']);
        set_g1_3(selTab['_g1_3']);
        set_g1_4(selTab['_g1_4']);
        set_g1_5(selTab['_g1_5']);
        set_g1_6(selTab['_g1_6']);
        set_g1_7(selTab['_g1_7']);
        set_g1_8(selTab['_g1_8']);
        set_g1_9(selTab['_g1_9']);
        set_g1_10(selTab['_g1_10']);
        set_g1_11(selTab['_g1_11']);
        set_g1_12(selTab['_g1_12']);
        set_g1_13(selTab['_g1_13']);
        set_g1_14(selTab['_g1_14']);
        set_g1_15(selTab['_g1_15']);
        set_g1_16(selTab['_g1_16']);
        set_g1_17(selTab['_g1_17']);

        setg2_1(selTab['g2_1']);
        setg2_2(selTab['g2_2']);
        setg2_3(selTab['g2_3']);
        setg2_4(selTab['g2_4']);
        setg2_5(selTab['g2_5']);
        setg2_6(selTab['g2_6']);
        setg2_7(selTab['g2_7']);
        setg2_8(selTab['g2_8']);
        setg2_9(selTab['g2_9']);
        setg2_10(selTab['g2_10']);
        setg2_11(selTab['g2_11']);
        setg2_12(selTab['g2_12']);
        setg2_13(selTab['g2_13']);
        setg2_14(selTab['g2_14']);
        setg2_15(selTab['g2_15']);
        setg2_16(selTab['g2_16']);
        setg2_17(selTab['g2_17']);

        set_g2_1(selTab['_g2_1']);
        set_g2_2(selTab['_g2_2']);
        set_g2_3(selTab['_g2_3']);
        set_g2_4(selTab['_g2_4']);
        set_g2_5(selTab['_g2_5']);
        set_g2_6(selTab['_g2_6']);
        set_g2_7(selTab['_g2_7']);
        set_g2_8(selTab['_g2_8']);
        set_g2_9(selTab['_g2_9']);
        set_g2_10(selTab['_g2_10']);
        set_g2_11(selTab['_g2_11']);
        set_g2_12(selTab['_g2_12']);
        set_g2_13(selTab['_g2_13']);
        set_g2_14(selTab['_g2_14']);
        set_g2_15(selTab['_g2_15']);
        set_g2_16(selTab['_g2_16']);
        set_g2_17(selTab['_g2_17']);

        setg3_1(selTab['g3_1']);
        setg3_2(selTab['g3_2']);
        setg3_3(selTab['g3_3']);
        setg3_4(selTab['g3_4']);
        setg3_5(selTab['g3_5']);
        setg3_6(selTab['g3_6']);
        setg3_7(selTab['g3_7']);
        setg3_8(selTab['g3_8']);
        setg3_9(selTab['g3_9']);
        setg3_10(selTab['g3_10']);
        setg3_11(selTab['g3_11']);
        setg3_12(selTab['g3_12']);
        setg3_13(selTab['g3_13']);
        setg3_14(selTab['g3_14']);
        setg3_15(selTab['g3_15']);
        setg3_16(selTab['g3_16']);
        setg3_17(selTab['g3_17']);

        set_g3_1(selTab['_g3_1']);
        set_g3_2(selTab['_g3_2']);
        set_g3_3(selTab['_g3_3']);
        set_g3_4(selTab['_g3_4']);
        set_g3_5(selTab['_g3_5']);
        set_g3_6(selTab['_g3_6']);
        set_g3_7(selTab['_g3_7']);
        set_g3_8(selTab['_g3_8']);
        set_g3_9(selTab['_g3_9']);
        set_g3_10(selTab['_g3_10']);
        set_g3_11(selTab['_g3_11']);
        set_g3_12(selTab['_g3_12']);
        set_g3_13(selTab['_g3_13']);
        set_g3_14(selTab['_g3_14']);
        set_g3_15(selTab['_g3_15']);
        set_g3_16(selTab['_g3_16']);
        set_g3_17(selTab['_g3_17']);

        setImproved1(selTab['improved1']);
        setImproved2(selTab['improved2']);
        setImproved3(selTab['improved3']);
        setImproved4(selTab['improved4']);
        setImproved5(selTab['improved5']);

        setHowplay1(selTab['howplay1']);
        setHowplay2(selTab['howplay2']);
        setHowplay3(selTab['howplay3']);
        setHowplay4(selTab['howplay4']);
        setHowplay5(selTab['howplay5']);

        setMental1(selTab['mental1']);
        setMental2(selTab['mental2']);
        setMental3(selTab['mental3']);
        setMental4(selTab['mental4']);
        setMental5(selTab['mental5']);
        setMental6(selTab['mental6']);
        setMental7(selTab['mental7']);
        setMental8(selTab['mental8']);
        setMental9(selTab['mental9']);
        setMental10(selTab['mental10']);
        setMental11(selTab['mental11']);
        setMental12(selTab['mental12']);

        setRemark(selTab['remark']);

        setwin3(selTab['win3']);
        setwin1(selTab['win1']);
        setwin2(selTab['win2']);

        setlose1(selTab['lose1']);
        setlose2(selTab['lose2']);
        setlose3(selTab['lose3']);
      }
      
    });
    

  }, []);
  return (

    <>
      <div className="content px-0 pb-0 coach-user-content">
        <NotificationAlert ref={notificationAlert}  className="col-12"/>
        <Row className="px-0 mx-0">          
          <Col className="col-12 px-0">
            <Card className="px-2 py-2 mb-1 border-bottom">
              <h6>{selTables.tournament}</h6>
            </Card>
            <Card className="card-user mb-1 border-bottom py-2">
              <div className="d-flex col-12 px-0">
                <Col className="px-2 col-4">
                  <FormGroup>
                    <label>カテゴリー</label>
                    <Input type="text" value={category} className="" readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </FormGroup>
                </Col>
                <Col className="px-2 col-8">
                  <FormGroup>
                    <label>トーナメント日にち</label>
                    <Input type="text" value={tourdate} className="" readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </FormGroup>
                </Col>
              </div>
              <div className="d-flex col-12 px-0">
                <Col className="px-2 col-4">
                  <FormGroup>
                    <label>対戦相手名前</label>
                    <Input type="text" value={!opponentname ? '' : opponentname} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </FormGroup>
                </Col>                  
                <Col className="px-2 col-8">
                  <FormGroup>
                    <label>対戦相手クラブ名</label>
                    <Input type="text" value={!opponentgroup ? '' : opponentgroup} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </FormGroup>
                </Col>   
              </div>
              <div className="d-flex col-12 px-0">
                <Col className="px-2 col-4">
                  <FormGroup>
                    <label>サーフェス</label>
                    <Input type="text" value={!surface ? '' : surface} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </FormGroup>
                </Col>                  
                <Col className="px-2 col-8">
                  <FormGroup>
                    <label>試合形式</label>
                    <Input type="text" value={!match ? '' : match} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </FormGroup>
                </Col>   
              </div>
              <div className="d-flex col-12 px-0">
                <Col className="px-2 col-4">
                  <FormGroup>
                    <label>サーフェス</label>
                    <Input type="text" value={!mainround ? '' : mainround} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </FormGroup>
                </Col>                  
                <Col className="px-2 col-8">
                  <FormGroup>
                    <label>試合形式</label>
                    <Input type="text" value={!subround ? '' : subround} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </FormGroup>
                </Col>   
              </div>
              <div className=" col-12 px-0">
                <Col className="px-2 col-12">
                  <FormGroup>
                    <label>天気</label>
                    <div className="row col-12 p2-0 py-0 mx-0 d-flex justify-content-between align-items-center">
                      <img src={w1} className={weather == 1 ? 'w_selected' : 'no_selected'}/>
                      <img src={w2} className={weather == 2 ? 'w_selected' : 'no_selected'}/>
                      <img src={w3} className={weather == 3 ? 'w_selected' : 'no_selected'}/>
                      <img src={w4} className={weather == 4 ? 'w_selected' : 'no_selected'}/>
                      <img src={w5} className={weather == 5 ? 'w_selected' : 'no_selected'}/>
                      <img src={w6} className={weather == 6 ? 'w_selected' : 'no_selected'}/>
                    </div>
                  </FormGroup>
                </Col>                  
                <Col className="px-2 col-12">
                  <FormGroup>
                    <label>起きた時の体調や気分</label>
                    <div className="row col-12 p2-0 py-2 mx-0 d-flex justify-content-between align-items-center">
                      <img src={face1} className={condition == 1 ? 'c_selected' : 'cno_selected'}/>
                      <img src={face2} className={condition == 2 ? 'c_selected' : 'cno_selected'}/>
                      <img src={face3} className={condition == 3 ? 'c_selected' : 'cno_selected'}/>
                      <img src={face4} className={condition == 4 ? 'c_selected' : 'cno_selected'}/>
                      <img src={face5} className={condition == 5 ? 'c_selected' : 'cno_selected'}/>
                    </div>
                  </FormGroup>
                </Col>   
              </div>
            </Card>
            <Card className="card-user mb-1 border-bottom py-2">              
              <CardBody>
                <Form>
                  <Row>                    
                    <Col className="col-8">                      
                        <h6>{selTables.think1}</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <Input type="text" value={!resthink1 ? '' : resthink1+'/10'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                    </Col>   
                  </Row>
                
                  <Row>                    
                    <Col className="col-8">                      
                        <h6>{selTables.think2}</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <Input type="text" value={!resthink2 ? '' : resthink2+'/10'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                    </Col>     
                  </Row>
                  <Row>                    
                    <Col className="col-8">                      
                        <h6>{selTables.think3}</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <Input type="text" value={!resthink3 ? '' : resthink3+'/10'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                    </Col>      
                  </Row>
                  <Row>                    
                    <Col className="col-8">                      
                        <h6>{selTables.think4}</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <Input type="text" value={!resthink4 ? '' : resthink4+'/10'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                    </Col>     
                  </Row>
                  <Row>                    
                    <Col className="col-8">                      
                        <h6>{selTables.think5}</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <Input type="text" value={!resthink5 ? '' : resthink5+'/10'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                    </Col>     
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card className="card-user mb-1 border-bottom py-2">              
              <CardBody>
                <Form>
                  <Row>                    
                    <Col className="col-8" style={{paddingTop:'10px'}}>                         
                        <h6>努力・闘志の自己評価</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <Input type="text" value={!offort ? '' : offort+'/10'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                    </Col>   
                  </Row>
                
                  <Row>                    
                    <Col className="col-8" style={{paddingTop:'10px'}}>                      
                        <h6>プレーの自己評価</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <Input type="text" value={!fight ? '' : fight+'/10'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                    </Col>     
                  </Row>  

                   <Row>                    
                    <Col className="col-3" style={{paddingTop:'15px'}}>                         
                        <h6>試合結果</h6>                                        
                    </Col> 
                    <Col className="col-9" style={{paddingTop:'10px', color:'#FF0000'}}> 
                      <h5><b>{win1} - {lose1}、 {win2} - {lose2}、 {win3} - {lose3}</b></h5>
                    </Col>   
                  </Row>
                  <Row>                    
                    <Col className="col-12" style={{paddingTop:'15px'}}>                         
                        <h6>試合の流れ</h6>                                        
                    </Col>
                  </Row> 
                  <Row className="mx-0 px-0">                    
                    <Col className="col-12 px-0" style={{paddingTop:'15px', overflow:'auto'}}>                         
                      <table border='1' style={{width:'760px'}}>
                        <tbody>
                          <tr height="40px">
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>             
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                            <td>10</td>
                            <td>11</td>
                            <td>12</td>
                            <td>13</td>
                            <td>14</td>
                            <td>15</td>
                            <td>16</td>
                            <td>T</td>
                            <td style={{color:'#FF0000'}}><b>結果</b></td>
                          </tr>                       
                          <tr height="40px">
                            <td width="40px">自分</td>
                            <td width="40px" id="g1_1">{g1_1 == 1? <img src={ball} width="30px"/> : ''}</td>
                            <td width="40px" id="g1_2">{g1_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_3">{g1_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_4">{g1_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_5">{g1_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_6">{g1_6 == 1?<img src={ball} width="30px"/>: ''}</td>                          
                            <td width="40px" id="g1_7">{g1_7 == 1?<img src={ball} width="30px"/>: ''}</td>     
                            <td width="40px" id="g1_8">{g1_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_9">{g1_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_10">{g1_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_11">{g1_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_12">{g1_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_13">{g1_13 == 1?<img src={ball} width="30px"/>: ''}</td> 
                            <td width="40px" id="g1_14">{g1_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_15">{g1_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_16">{g1_16 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_17">{g1_17 == 1?<img src={ball} width="30px"/>: ''}</td>     
                            <td style={{color:'#FF0000'}}><b>{win1}</b></td>                    
                          </tr>
                          <tr height="40px">
                            <td width="40px">相手</td>
                            <td width="40px" id="_g1_1">{_g1_1 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_2">{_g1_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_3">{_g1_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_4">{_g1_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_5">{_g1_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_6">{_g1_6 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_7">{_g1_7 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_8">{_g1_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_9">{_g1_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_10">{_g1_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_11">{_g1_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_12">{_g1_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_13">{_g1_13 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_14">{_g1_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_15">{_g1_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_16">{_g1_16 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_17">{_g1_17 == 1?<img src={ball} width="30px"/>: ''}</td>  
                            <td style={{color:'#FF0000'}}><b>{lose1}</b></td>   
                          </tr>
                        </tbody>
                      </table>                             
                    </Col>
                  </Row> 
                  <Row className="mx-0 px-0">                    
                    <Col className="col-12 px-0" style={{paddingTop:'15px', overflow:'auto'}}>                         
                      <table border='1' style={{width:'760px'}}>
                        <tbody>
                          <tr height="40px">
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>                            
                            <td>7</td>
                            <td>8</td>
                            <td>9</td> 
                            <td>10</td>
                            <td>11</td>
                            <td>12</td>
                            <td>13</td>
                            <td>14</td>
                            <td>15</td>
                            <td>16</td>
                            <td>T</td>
                            <td style={{color:'#FF0000'}}><b>結果</b></td>
                          </tr>                       
                          <tr height="40px">
                            <td width="40px">自分</td>
                            <td width="40px" id="g2_1">{g2_1 == 1?<img src={ball} width="30px"/>:''}</td>
                            <td width="40px" id="g2_2">{g2_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_3">{g2_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_4">{g2_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_5">{g2_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_6">{g2_6 == 1?<img src={ball} width="30px"/>: ''}</td>                       
                            <td width="40px" id="g2_7">{g2_7 == 1?<img src={ball} width="30px"/>: ''}</td>                      
                            <td width="40px" id="g2_8">{g2_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_9">{g2_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_10">{g2_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_11">{g2_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_12">{g2_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_13">{g2_13 == 1?<img src={ball} width="30px"/>: ''}</td> 
                            <td width="40px" id="g2_14">{g2_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_15">{g2_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_16">{g2_16 == 1?<img src={ball} width="30px"/>: ''}</td> 
                            <td width="40px" id="g2_17">{g2_17 == 1?<img src={ball} width="30px"/>: ''}</td>                         
                            <td style={{color:'#FF0000'}}><b>{win2}</b></td> 

                          </tr>
                          <tr height="40px">
                            <td width="40px">相手</td>
                            <td width="40px" id="_g2_1">{_g2_1 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_2">{_g2_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_3">{_g2_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_4">{_g2_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_5">{_g2_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_6">{_g2_6 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_7">{_g2_7 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_8">{_g2_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_9">{_g2_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_10">{_g2_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_11">{_g2_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_12">{_g2_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_13">{_g2_13 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_14">{_g2_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_15">{_g2_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_16">{_g2_16 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_17">{_g2_17 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td style={{color:'#FF0000'}}><b>{lose2}</b></td>
                          </tr>
                        </tbody>
                      </table>                             
                    </Col>
                  </Row> 

                  <Row className="mx-0 px-0">                    
                    <Col className="col-12 px-0" style={{paddingTop:'15px', overflow:'auto'}}>                         
                      <table border='1' style={{width:'760px'}}>
                        <tbody>
                          <tr height="40px">
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>                            
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                            <td>10</td>
                            <td>11</td>
                            <td>12</td>                            
                            <td>13</td>
                            <td>14</td>
                            <td>15</td>                            
                            <td>16</td>                           
                            <td>T</td>
                            <td style={{color:'#FF0000'}}><b>結果</b></td>
                          </tr>                       
                          <tr height="40px">
                            <td width="40px">自分</td>
                            <td width="40px" id="g3_1">{g3_1 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_2">{g3_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_3">{g3_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_4">{g3_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_5">{g3_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_6">{g3_6 == 1?<img src={ball} width="30px"/>: ''}</td>                          
                            <td width="40px" id="g3_7">{g3_7 == 1?<img src={ball} width="30px"/>: ''}</td>                     
                            <td width="40px" id="g3_8">{g3_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_9">{g3_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_10">{g3_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_11">{g3_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_12">{g3_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_13">{g3_13 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_14">{g3_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_15">{g3_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_16">{g3_16 == 1?<img src={ball} width="30px"/>: ''}</td> 
                            <td width="40px" id="g3_17">{g3_17 == 1?<img src={ball} width="30px"/>: ''}</td>                       
                            <td style={{color:'#FF0000'}}><b>{win3}</b></td>                         
                          </tr>
                          <tr height="40px">
                            <td width="40px">相手</td>
                            <td width="40px" id="_g3_1">{_g3_1 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_2">{_g3_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_3">{_g3_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_4">{_g3_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_5">{_g3_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_6">{_g3_6 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_7">{_g3_7 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_8">{_g3_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_9">{_g3_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_10">{_g3_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_11">{_g3_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_12">{_g3_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_13">{_g3_13 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_14">{_g3_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_15">{_g3_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_16">{_g3_16 == 1?<img src={ball} width="30px"/>: ''}</td> 
                            <td width="40px" id="_g3_17">{_g3_17 == 1?<img src={ball} width="30px"/>: ''}</td>                           
                            <td style={{color:'#FF0000'}}><b>{lose3}</b></td>                        
                          </tr>
                        </tbody>
                      </table>                             
                    </Col>
                  </Row>             
                </Form>
                <Row className="pt-2" style={{color:'#FF0000'}}>
                  <Col md="12"><h6>自由に記入欄</h6></Col>
                </Row>
                <Row>
                  <Col md="12">
                    <textarea className="form-control" id="remark" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={!remark ? '' : remark}>{remark}</textarea>                          
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="card-user mb-1 border-bottom py-2">
              <CardBody>
                <Row style={{color:'#FF0000'}}>
                  <Col md="12">
                    <h6>{selTables.proposergroup} {selTables.proposername}の相手分析情報</h6>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {
                      checkboxgroup.map((group, num) =>
                      <Card className="demo-icons" key={num}>
                        <CardTitle tag="h6" style={{marginTop:'0.75rem'}}>{group.jp}</CardTitle>  
                        
                          {
                            eval(group.en).map((tmp, index) => 
                            
                            <Row key={index }>
                              <Col className="col-12" style={{color:'rgb(3, 115, 202)', fontSize:'14px'}}>
                                {/*<img src={questionImg}  width="20px"/>*/} {tmp.item}
                              </Col>
                              <Col className="col-12" style={{color:'#ff3d02', fontSize:'14px', paddingLeft:'40px'}}>
                               
                                <Input type="text" 
                                            style={{color:'#ff3d02', marginBottom:'5px', background:'white'}}
                                            id={"an_"+num+"_" + index}
                                            name={"an_"+num}
                                            value={tmp.solution}                          
                                             readOnly="readonly"
                                          />    
                              </Col>
                                                        
                            </Row>
                            
                            )
                          }                
                          
                      </Card>
                    )}
                    
                  </Col>
                </Row>
              </CardBody>
            </Card>   
            <Card className="card-user mb-1 border-bottom py-2">
              <CardBody>
                <Row style={{color:'#FF0000'}}>
                  <Col md="12"><h6>改善すべき内容</h6></Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input type="text" className="form-control" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={improved1 == null ? '' : improved1}/>
                    <Input type="text" className="form-control" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={improved2 == null ? '' : improved2}/>
                    <Input type="text" className="form-control" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={improved3 == null ? '' : improved3}/>
                    <Input type="text" className="form-control" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={improved4 == null ? '' : improved4}/>
                    <Input type="text" className="form-control" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={improved5 == null ? '' : improved5}/>                           
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="card-user mb-1 border-bottom py-2">
              <CardBody>
                <Row style={{color:'#FF0000'}}>
                  <Col md="12"><h6>再度同じ相手にあたるとしたら、どのようにプレーしますか？</h6></Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input type="text" className="form-control" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={howplay1 == null ? '' : howplay1}/>
                    <Input type="text" className="form-control" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={howplay2 == null ? '' : howplay2}/>
                    <Input type="text" className="form-control" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={howplay3 == null ? '' : howplay3}/>
                    <Input type="text" className="form-control" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={howplay4 == null ? '' : howplay4}/>
                    <Input type="text" className="form-control" readOnly="readonly" style={{marginTop:'5px', background:'white'}} value={howplay5 == null ? '' : howplay5}/>                           
                  </Col>
                </Row>
              </CardBody>
            </Card>  
            <Card className="card-user mb-0 border-bottom py-2">
              <CardBody className="pb-0">
                <Row style={{color:'#FF0000'}}>
                  <Col md="12"><h6>試合後のメンタルチェック</h6></Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>1. 最高のプレーができた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental1 ? '' : mental1+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>最悪のプレーだった  </Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>2. 体（筋肉）はリラックスしていた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental2 ? '' : mental2+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>体（筋肉）は緊張していた</Col>  
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>3. 不安はなかった</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental3 ? '' : mental3+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>とても不安だった</Col>  
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>4. 落ち着いて冷静だった</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental4 ? '' : mental4+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>混乱し動揺していた</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>5. 積極的、楽観的な考え方をしていた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental5 ? '' : mental5+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>消極的、悲観的な考えをしていた</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>6. 挑戦する事を楽しんでいた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental6 ? '' : mental6+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col> 
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>意欲やエネルギーが不足していた</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>7. 自動的、本能的にプレーしていた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental7 ? '' : mental7+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>意識が先走って指図しすぎていた</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>8. プレーに集中できた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental8 ? '' : mental8+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>集中していなかった</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>9. 注意力が鋭かった</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental9 ? '' : mental9+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col> 
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>注意力が散漫だった</Col> 
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>10. ポジティブなエネルギーに満ちていた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental10 ? '' : mental10+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>ネガティブなエネルギーだらけだった</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>11. 自信満々だった</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental11 ? '' : mental11+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col> 
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>自信がなかった</Col> 
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>12. 自己コントロールができていた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <Input type="text" value={!mental12 ? '' : mental12+'/5'} readOnly="readonly" style={{background:'white', color:'rgb(3, 115, 202)'}}/>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>自分をコントロールできなかった</Col>
                </Row>
                <Col className="px-1">
                  <input type="button" name="user" value="戻る" className="btn-round btn btn-primary" onClick={preparationPage}/>
                </Col> 
              </CardBody>
            </Card>   
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Tablesedit;
