/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";

import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

// add new codes
import { useHistory } from 'react-router-dom';

// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function SelectDays() {

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }

  const history = useHistory();
  const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

  const [sDay, setSDay] = useState(window.sessionStorage.getItem('s_day').split(',') || [7]);
  const [sDayName, setSDayName] = useState(window.sessionStorage.getItem('s_day_name') || 'Every day');

  const editFlag = window.sessionStorage.getItem('editFlag') || 0;

  const cancelAddNewItem = (event) => {   
    if(editFlag == 0)
      toPage(event, 'newItem');
    else
      toPage(event, 'editItem');
  }

  const doneAddNewItem = (event) => {
    window.sessionStorage.setItem('s_day', sDay.join(','));
    window.sessionStorage.setItem('s_day_name', sDayName);
    if(editFlag == 0)
      toPage(event, 'newItem');
    else
      toPage(event, 'editItem');
  }

  const toPage = (e, pname) => {
    history.push('../admin/'+pname);
  }

  const onSelectDays = (event, item, idx, sf) =>{
    let sdays = [];
    if(idx == 7){
      setSDay(['7']);
      setSDayName('Every day');
      sdays = ['7'];
      //window.sessionStorage.setItem('s_day', '7');
      //window.sessionStorage.setItem('s_day_name', 'Every day');
    }else{
      if(sDay == '7'){
        setSDay([''+idx]);
        setSDayName(weekday[idx]);
        sdays = [''+idx];
        //window.sessionStorage.setItem('s_day', ''+idx);
        //window.sessionStorage.setItem('s_day_name', weekday[idx]);
      }else{
        let s = sDay;
        if(sf == 'd_selected'){
          let na = [];
          for(let i=0;i<s.length;i++){
            if(s[i]!=idx)
              na.push(s[i]);
          }
          s = na;
        }else{
          s.push(''+idx);
        }
        s.sort();
        //window.sessionStorage.setItem('s_day', s.join(','));
        let s_name = [];
        for(let i=0;i<s.length;i++){
          s_name.push(weekday[s[i]]);
        }
        setSDay(s);
        setSDayName(s_name.join(','));
        //window.sessionStorage.setItem('s_day_name', s_name.join(','));
        sdays = s;
      }
    }
    // console.log(sDay);
    // let d = sdays;
    if(sdays.length == 7){
      setSDay(['7']);
      setSDayName('Every day');
      //window.sessionStorage.setItem('s_day', '7');
      //window.sessionStorage.setItem('s_day_name', 'Every day');
    }
  }
  return (
    <>      
      <div className="content px-0 coach-user-content pb-0" style={{background:'white'}}>
        <div className="titleDiv">
          <Card className="mb-0" style={{boxShadow:'unset'}}>
            <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
              <div className="d-flex col-12 px-2" style={{fontSize:'16px'}}>
                <div className="col-4 col-lg-2 d-flex align-items-center justify-content-start px-0">
                  <span className="p-1" onClick={(event) => cancelAddNewItem(event)}>キャンセル</span>
                </div>
                <div className="col-4 col-lg-8 text-center px-0 d-flex align-items-center justify-content-center head_title">
                    日を選択
                </div>
                <div className="col-4 col-lg-2 d-flex align-items-center justify-content-end px-0">
                  <span className="p-1" onClick={(event) => doneAddNewItem(event)}>完了</span>
                </div>
              </div>
            </CardBody>
            <CardBody className="px-1">
              <div className="itemRow select_days col-12 d-flex px-0 py-3">
                {
                  weekday.map((item, idx) => 
                      <div key={idx} className="px-0 py-0 d-flex justify-content-center align-items-center">
                          <span className={"p-1 " + idx + (sDay.indexOf(''+idx) >= 0 ? ' d_selected' : ' nod_selected')} id={'day_'+idx} onClick={(event) => onSelectDays(event, item, idx, (sDay.indexOf(''+idx) >= 0 ? 'd_selected' : ''))} style={{fontSize:'17px',fontWeight:'bold'}}>{item}</span>
                      </div>
                  )
                }                
              </div>

              <div className="itemRow select_days col-12 d-flex px-0 py-3">
                <Col className="col-12 d-flex justify-content-center align-items-center px-0">
                  <span className={"p-1 " + sDay.indexOf('7') + (sDay.indexOf('7') >= 0 ? ' d_selected' : ' nod_selected')} onClick={(event) => onSelectDays(event, 'all', 7, '')} style={{fontSize:'18px',fontWeight:'bold'}}>Every day</span>
                </Col>
              </div>

            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default SelectDays;
