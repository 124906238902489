/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import ukindbg from "assets/img/ukindbg.jpg"
import coach from "assets/img/coach.png"
import player from "assets/img/player.png"
import parent from "assets/img/parent.png"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useHistory } from 'react-router-dom';
// react plugin used to create charts
let name, email, password, password_confirmation;
let axios = require('axios');
let userkind = "coach";

let URL_API = "https://xs458763.xsrv.jp/api/";

function Register1() {
  
  const history = useHistory();

  const register = (event)=>{    
    axios.get(URL_API+"register", {
      params: {
        name: name,
        userkind : userkind,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
      }
    })
    .then(function (response) {
      
      if(response.data["created_at"] && response.data[""] !== ""){
  
        response.data["sex"] = 0;
        response.data["birthday"] = "";
        response.data["hight"] = 180;
        response.data["weight"] = 60;
        response.data["school"] = "";
        response.data["grade"] = 1;
        response.data["post_code"] = "";
        response.data["address"] = "";
        response.data["number"] = 0;
        response.data["portfolio"] = "";
        response.data["profile_photo_path"] = "";
        
        window.sessionStorage.setItem("sessions", JSON.stringify(response.data));
       
        history.push('../admin/user-pag');
  
      }else{
  
        if(response.data.email && response.data.email[0] !== "")
          NotificationManager.error(response.data.email[0], null, 10000, null, true)
        
        if(response.data.name && response.data.name[0] !== "")
          NotificationManager.error(response.data.name[0], null, 10000, null, true)
              
        if(response.data.password && response.data.password[0] !== "")
          NotificationManager.error(response.data.password[0], null, 10000, null, true)
      }
  
    })
  }

  const seluserkind = (sel)=>{ 
    document.getElementById("coach").className = "uKindDiv";
    document.getElementById("player").className = "uKindDiv";
    document.getElementById("parent").className = "uKindDiv";
    document.getElementById(sel).className = "uKindSelDiv";
    userkind = sel;
  };
  
  const getInputNameValue = (event)=>{    
    name = event.target.value;  
  };
  
  const getInputEmailValue = (event)=>{    
    email = event.target.value;  
  };

  const getInputPasswordValue = (event)=>{    
    password = event.target.value;  
  };

  const getInputPconfirmationValue = (event)=>{ 

    password_confirmation = event.target.value;  

  };

  return (
    <>      
      <NotificationContainer/>
      <div>      
        <div className="uHeadLabel">
          TennisLab
        </div>
        <div style={{display:'flex'}}>
          <div className="uKindSelDiv" id="coach" onClick={() => seluserkind('coach')}>
            <img src={coach} />
            <a className="uKindLabel">コーチ</a>
          </div>
          <div className="uKindDiv" id="player" onClick={() => seluserkind('player')}>
            <img src={player} />
            <a className="uKindLabel">選手</a>
          </div>
          <div className="uKindDiv" id="parent" onClick={() => seluserkind('parent')}>
            <img src={parent} />
            <a className="uKindLabel">親</a>
          </div>
        </div>
     
        <div style={{marginTop : "50px"}}>
          <input placeholder="名前" type="text" name="name" id="name" className="user-user" onChange={getInputNameValue}/>
        </div>    
        <div style={{marginTop : "20px"}}>
          <input placeholder="メールアドレス" type="email" name="email" id="email" className="user-user" onChange={getInputEmailValue}/>
        </div>     
        <div style={{marginTop : "20px"}}>
          <input placeholder="パスワード" type="password" name="password" id="password" className="user-user" onChange={getInputPasswordValue}/>
        </div>
        <div style={{marginTop : "20px"}}>
          <input placeholder="パスワード（確認用）" type="password" name="password_confirmation" id="password_confirmation" className="user-user"  onChange={getInputPconfirmationValue}/>
        </div>
        <div style={{marginTop : "70px"}}>
          <input type="button" name="user" value="アカウントを作成" className="user-user" onClick={register}/>
        </div> 
        <div style={{marginTop : "40px"}}>
          <a className="user-sign" href="login">戻る</a>
        </div>        
      </div>
    </>
  );
}


export default Register1;
