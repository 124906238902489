/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import DemoNavbar from "components/Navbars/SubNavbar.js";
import Footer from "components/Footer/Footer.js";
import { useHistory } from 'react-router-dom';

//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routesSub.js";

var ps;
let Pusher = require('pusher-js');
let channel3 = null;
let notificationAlert;

function Dashboard(props) {

  const mainPanel = React.useRef();
  const location = useLocation();
  const history = useHistory();
  const [coach, setCoach] = React.useState({});
  notificationAlert = React.useRef();
  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }
  const onShowMsg = (e) => {
    if(coach){
      window.sessionStorage.setItem('coach', JSON.stringify(coach));
      history.push('/admin/chatRoom');
    }else{
      history.push('/admin/members');
    }
    // history.push('/admin/chatRoom');
  }
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if(channel3){
      channel3.unbind();
      channel3 = null;
    }
    Pusher.logToConsole = true;
    var pusher = new Pusher('6ecccbbacc37f7103bdb', {
        cluster: 'eu'
    });
    // console.log('my-receive-msg-'+se['id']);
    channel3 = pusher.subscribe('my-receive-msg-'+se['id']);
    channel3.bind('my-event', function(data) {
      // console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
      let coachs = JSON.parse(window.sessionStorage.getItem('coachs'));
      for(let i=0;i<coachs.length;i++){
        if(coachs[i].id == data['sender_id'])
          setCoach(coachs[i]);
      }
      if(window.location.href.indexOf('admin/chatRoom') == -1){
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>
                <b>メッセージが来ました。</b>
              </div>
            </div>
          ),
          type: 'warning',
          icon: "nc-icon nc-bell-55",
          autoDismiss: 7
        };
        // console.log(notificationAlert, notificationAlert.current.notificationAlert);
        console.log('aaaaaaaaaaaaaaaaaaa');
        if(notificationAlert.current)
          notificationAlert.current.notificationAlert(options);
      }
    });
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    return () => {
      // channel3.unbind();
    }
  }, [location]);
  
  return (
    <div className="wrapper">     
      <NotificationAlert ref={notificationAlert}  className="col-12" onClick={(e) => onShowMsg(e)}/> 
      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar {...props} />
        <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
        </Switch>
        {/*<Footer fluid />*/}
      </div>
      
    </div>
  );
}

export default Dashboard;
