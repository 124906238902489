/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import gameImg from "assets/img/biggoal.png";
import { useHistory } from 'react-router-dom';
import PlayerList from './PlayerList.js';
import { Input } from "reactstrap";
import winImg from "assets/img/win.png";
import loseImg from "assets/img/lose.png";

// reactstrap components
import NotificationAlert from "react-notification-alert";
import {  
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
// let session = window.sessionStorage.getItem('sessions'); 
// let se = JSON.parse(session);

let notificationAlert;
var aTimeout = null;

function OtherSearch() {

  notificationAlert = React.useRef();  
  
  const [goalsetting, s_setGoalsetting] = useState([]);
  const [player, setPlayer] = useState(JSON.parse(window.sessionStorage.getItem('players'))[0]);
  const [playerIndex, setPlayerIndex] = useState(0);
  const [uFlag, setUFlag] = useState(true);
  const [enableSearchP, setEnableSearchP] = useState('hide');
  const [a_proposer, s_setProposerData] = useState([]);
  
  const history = useHistory();
  
  // const preparationUpdateFunc = (e) => {

  //   window.sessionStorage.setItem('target', '{}');   
  //   history.push('../coach/biggoalsettingedit');

  // };
  const getViewpageUrl = (tmp) => {
    window.sessionStorage.setItem('selTables', JSON.stringify(tmp));
    history.push('../coach/tablesShow');
  }

  const onSelectPlayer = (idx, player) => {
    setPlayerIndex(idx);
    setUFlag(!uFlag);
    setEnableSearchP('hide');
    setPlayer(player);
    // console.log(idx);
  }
  
  const [searchName, setSearchName] = useState('');
  const [players, setPlayers] = useState(JSON.parse(window.sessionStorage.getItem('players')) || []);

  const onSearchPlayer = (e) => {
    setSearchName(e.target.value);
    if(aTimeout)
      clearTimeout(aTimeout);

    aTimeout = setTimeout(function(){
      let players = JSON.parse(window.sessionStorage.getItem('players'));
      let result = [];
      for(let i=0;i<players.length;i++){
        if(players[i].name.indexOf(e.target.value) >= 0){
          result.push(players[i]);
        }
      }
      if(result.length > 0){
        window.sessionStorage.setItem('player', JSON.stringify(result[0]));   
        setPlayer(result[0]);
      }
      else
        window.sessionStorage.setItem('player', JSON.stringify({}));   
      setPlayers(result);
      setPlayerIndex(0);
      setUFlag(!uFlag);
    }, 500);
  }
  
  useEffect(() => {    

    // let session = JSON.parse(window.sessionStorage.getItem('player')); 
    // if(!session){
    //   session = JSON.parse(window.sessionStorage.getItem('players'))[0];
    // }
    // if(!session){
    //   // history.push('../coach/userMg');
    //   return;
    // }

    s_setProposerData(JSON.parse(window.sessionStorage.getItem("a_proposer")));

    axios.get(URL_API+"getPreparaAfterListAPI", {
      params: {
        sel: player['id'], 
      }
    })
    .then(function (response) {
    
      // if(response.data != ""){
        s_setProposerData(response.data);
        window.sessionStorage.setItem('a_proposer', JSON.stringify(response.data));
      // }

    }); 

  }, [uFlag]);

  return (

    <>
      <div className="content px-0 pb-0">
        <NotificationAlert ref={notificationAlert} />
        <div className="selected_player">
          <Row className="py-2 px-0 mx-0" onClick={(e) => setEnableSearchP(enableSearchP == 'hide' ? 'a_show' : 'hide')}>
            <div className="text-center col-2 px-0">
              <img className="s_player_icon" src={(player.profile_photo_path ? "https://xs458763.xsrv.jp/uploads/"+player.profile_photo_path : require("assets/img/mike.jpg").default)}/>
            </div>
            <div className="d-flex align-items-center col-10 px-0">
              <span style={{width:'100%', whiteSpace:'pre-wrap'}}>{player.name}</span>
            </div>  
          </Row>
        </div>
        <Card className={"mb-0 search_panel " + enableSearchP}>
          <Row className="mx-0 px-0">
            <Col className="biggoalcol">
              <label className="text-center m-1">
                <i className="fa fa-search" aria-hidden="true" style={{color:'rgb(3, 115, 202)'}}></i>
              </label>
              <Input className="mx-1" type="text" style={{border:'none', background:'white'}} placeholder="選手名" value={searchName} onChange={onSearchPlayer} id="technical3"/>                                            
            </Col>
          </Row>
          <Row className="mx-0 px-1">  
            <div style={{overflow:'auto'}} className="col-12 row mx-0 px-0">
              <PlayerList playerIndex={playerIndex} players={players} onSelectPlayer={onSelectPlayer}/>
            </div>
          </Row>
        </Card>   
        <Card className="mb-0">
          <CardBody className="analy_content">
            <Row>
              <CardHeader>
                <CardTitle tag="h6">トーナメントリスト</CardTitle>                
              </CardHeader>
              <Col md="12">                                         
                {
                  a_proposer?.map((tmp, index) => 

                  <Row key={index} style={{borderBottom:'dotted 1px #ccc', padding:'5px'}}  onClick={() => getViewpageUrl(tmp)} val={tmp.id}> 
                    <Col className="col-1" style={{color:'rgb(3, 115, 202)', fontSize:'16px', padding:'0px'}}>
                      <img src={tmp.winflag == 1? winImg : loseImg}  width="25px"/>
                    </Col>                        
                    <Col className="col-3" style={{color:'rgb(3, 115, 202)', fontSize:'16px', padding:'0px'}}>
                      <b>{tmp.tournament}</b>
                    </Col>
                    <Col className="col-3" style={{color:'rgb(3, 115, 202)', fontSize:'14px', padding:'0px'}}>
                      {tmp.tourdate}   
                    </Col>
                    <Col className="col-3" style={{color:'rgb(3, 115, 202)', fontSize:'14px', padding:'0px'}}>
                    {tmp.proposergroup} {tmp.proposername}
                    </Col>
                    <Col className="col-2" style={{color:'#FF0000', fontSize:'18px', padding:'0px'}}>
                      <b>{tmp.mark}</b>
                    </Col>
                  </Row>
                  )
                } 
              </Col>
            </Row>
          </CardBody>     
        </Card>
      </div>
    </>
  );
}


export default OtherSearch;