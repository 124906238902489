/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";

import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

// add new codes
import { useHistory } from 'react-router-dom';
import dataTypes from "views/diary/dataType.js";

import "assets/css/coach.css";

// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function PlayerList(props) {

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }

  const history = useHistory();

  // const [players, setPlayers] = useState([]);

  const toPage = (e, pname) => {
    history.push('../coach/'+pname);
  }

  const onSelectPlayer = (e, player, idx) => {
    window.sessionStorage.setItem('player', JSON.stringify(player));   
    props.onSelectPlayer(idx, player);    
  }

  useEffect(() => {
    let session = window.sessionStorage.getItem('sessions');
    let se = JSON.parse(session);
    if(!se){
      window.location = '/';
    }
  }, []);
  // console.log(props.playerIndex);
  return (
    <ScrollMenu>
      {
        props.players.map((player, idx) => 
          <div key={idx} className={'m_player p-1 mb-2 mr-2' + (idx == props.playerIndex ? ' active' : '')} onClick={(event) => onSelectPlayer(event, player, idx)}>
            <div className="text-center">
              <img src={(player.profile_photo_path ? "https://xs458763.xsrv.jp/uploads/"+player.profile_photo_path : require("assets/img/mike.jpg").default)}/>
            </div>
            <div className="text-center mt-2">
              <span style={{width:'100%', whiteSpace:'pre-wrap'}}>{player.name}</span>
            </div>                
          </div>
        )
      }
    </ScrollMenu>
  );
}

export default PlayerList;
