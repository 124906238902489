import React, { Component } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { render } from 'react-dom';
import {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import Stat from './Stat.js';


import "assets/css/chart.css";


let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function AttendStat(){

  let session = JSON.parse(window.sessionStorage.getItem('sessions'));
  if(!session)
    window.location = '/';
  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  const players = JSON.parse(window.sessionStorage.getItem('players'));
  
  const [flag, setFlag]             = useState(0);
  const [today, setToday]           = useState(new Date(sessionStorage.getItem('today')) || new Date());
  const [modalIsOpen, setIsOpen]    = React.useState(false);
  const [todayVDate, setTodayVDate] = useState(month[today.getMonth()]+" "+today.getFullYear());

  const [dataItems, setDataItems] = useState([]);

  const [dateConfig, setDateConfig] = useState({
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: 'MM',
        caption: 'Mon',
        step: 1,
    }
  });

  const history = useHistory();

  const openModal = (f) => {
    setIsOpen(true);
  } 

  const selectDate = (time) => {
      setToday(time);
      setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
      setIsOpen(false);
      window.sessionStorage.setItem('today', time);
  }

  const handleCancel = () => {
      setIsOpen(false);
  }

  const toPage = (e, pname) => {
    history.push('../coach/'+pname);
  }

  const renderItemData = (player, idx) => {
    return (
      <Stat player = {player} today = {today} flag = {flag}/>
    );
  }

  const onShowChart = (f) => {
    setFlag(f);
  }

  useEffect(() => {
    if(!session){
        window.location = '/';
    }

    // if(JSON.parse(window.sessionStorage.getItem('attends')) != null){
    //   setAttends(JSON.parse(window.sessionStorage.getItem('attends')));
    // }
  }, [flag, today]);
  return (
    <>
      <DatePicker
            dateConfig={dateConfig}
            value={today}
            isOpen={modalIsOpen}
            onSelect={selectDate}
            onCancel={handleCancel} />  

          <div className="content px-0 pb-0">
            <Row className="px-0 mx-0">
              <Col md="12" className="px-0">
                <Card className="mb-0">
                  <CardBody className="seldate" id='seldate' style={{color:'white'}}>
                    <Row>
                      <Col className="col-2 d-flex justify-content-center align-items-center">
                        <i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toPage(event, 'attend')}></i>
                      </Col>
                        <Col className="col-8 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ymd')} >
                          {todayVDate}
                      </Col>
                      <Col className="col-2 d-flex justify-content-center">
                      </Col>
                    </Row>
                  </CardBody> 
                  <div className="stat_list">
                  {
                    players.map((player, idx) => 
                      <div key={idx} style={{fontWeight:'bold'}}>
                        {
                          renderItemData(player, idx)
                        }
                      </div>
                    )
                  }    
                  </div>          
                  <div style={{height:'40px'}}>
                    <Row className="mx-0">
                      <Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==0?'active':'')} onClick={() => onShowChart(0)}>
                        月
                      </Col>
                      <Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==1?'active':'')} onClick={() => onShowChart(1)}>
                        3か月
                      </Col>
                      <Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==2?'active':'')} onClick={() => onShowChart(2)}>
                        年
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    )
}

export default AttendStat;