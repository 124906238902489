/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
sujuki@gmail.com
asdasdasd
nonak3@gmail.com

*/
import React, { useState } from "react";
import coach from "assets/img/coach.png"
import player from "assets/img/player.png"
import parent from "assets/img/parent.png"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useHistory } from 'react-router-dom';
// react plugin used to create charts
import { 
  Row,
  Col,
  Input,
  CardBody,
  Card,
  FormGroup,
} from "reactstrap";
import loginImg from "assets/img/logo.png";

let name, email, password, password_confirmation;
let axios = require('axios');
let userkind = "coach";


let URL_API = "https://xs458763.xsrv.jp/api/";

function Register() {
  
  const history = useHistory();

  const register = (event)=>{    
    axios.get(URL_API+"register", {
      params: {
        name: name,
        userkind : userkind,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
      }
    })
    .then(function (response) {
      
      if(response.data["created_at"] && response.data[""] !== ""){
  
        response.data["sex"] = 0;
        // response.data["birthday"] = "";
        response.data["hight"] = 180;
        response.data["weight"] = 60;
        response.data["school"] = "";
        response.data["grade"] = 1;
        response.data["post_code"] = "";
        response.data["address"] = "";
        response.data["number"] = '';
        response.data["portfolio"] = "";
        response.data["profile_photo_path"] = "";
        response.data["userkind"] = userkind;
        
        window.sessionStorage.setItem("sessions", JSON.stringify(response.data));
        if(response.data['userkind'] == "player"){
          history.push('../admin/user-page');
          // window.location = "/"; 
        }else if(response.data['userkind'] == "coach"){
          history.push('../coach/user');
          // window.location = "/"; 
        }  
      }else{
  
        if(response.data.email && response.data.email[0] !== "")
          NotificationManager.error(response.data.email[0], null, 10000, null, true)
        
        if(response.data.name && response.data.name[0] !== "")
          NotificationManager.error(response.data.name[0], null, 10000, null, true)
              
        if(response.data.password && response.data.password[0] !== "")
          NotificationManager.error(response.data.password[0], null, 10000, null, true)
      }
  
    })
  }

  const login = (event)=>{
    history.push('../user/login');
  }

  let kindTextAry = {
    "coach":"コーチ 選手の情報を把握して、指導に繋げましょう。",
    "player":"選手 あなたの成長を記録していきましょう。",
    "parent":"親 お子様の考えや成長を共有していきましょう。",
  }

  const[kindtxt, setKindTxt] = useState(kindTextAry['coach']);

  const seluserkind = (sel)=>{ 
    document.getElementById("coach").className = "uKindDiv col";
    document.getElementById("player").className = "uKindDiv col";
    document.getElementById("parent").className = "uKindDiv col";
    document.getElementById(sel).className = "uKindSelDiv col";
    userkind = sel;
    setKindTxt(kindTextAry[sel]);
  };
  
  const getInputNameValue = (event)=>{    
    name = event.target.value;  
  };
  
  const getInputEmailValue = (event)=>{    
    email = event.target.value;  
  };

  const getInputPasswordValue = (event)=>{    
    password = event.target.value;  
  };

  const getInputPconfirmationValue = (event)=>{ 

    password_confirmation = event.target.value;  

  };

  return (
    <>      
      <NotificationContainer/>
      <Card className="card-user">              
        <CardBody>
          <Row>    
            <Col style={{paddingLeft:'0px', paddingTop:'30px'}}>
              <img src={loginImg} style={{width:'200px'}}/>
            </Col>
          </Row>
          <Row> 
            <Col className="px-1" style={{width:'330px', paddingTop:'10px', color:'rgb(0 137 255)'}}>
              <b>TennisLab</b>は選手の為の管理アプリです。目標管理、課題管理、試合管理、日々の成長記録など、全てお任せ下さい。
            </Col>
          </Row>
          <Row style={{paddingTop:'15px', paddingBottom:'15px'}}>
            <Col className="uKindSelDiv" id="coach" onClick={() => seluserkind('coach')}>
              <img src={coach} />
              <p className="uKindLabel">コーチ</p>
            </Col>
            <Col className="uKindDiv" id="player" onClick={() => seluserkind('player')}>
              <img src={player} />
              <p className="uKindLabel">選手</p>
            </Col>
            <Col className="uKindDiv" id="parent" onClick={() => seluserkind('parent')}>
              <img src={parent} />
              <p className="uKindLabel">親</p>
            </Col>
          </Row>
          
          <Row style={{height:'85px'}}> 
            <Col className="px-1" style={{width:'330px', paddingTop:'10px', color:'rgb(199 25 25)'}}>
              {kindtxt}
            </Col>
          </Row>
          <Row> 
            <Col className="px-1">
              <FormGroup>
                <Input placeholder="名前" type="text" name="name" id="name" className="form-control" onChange={getInputNameValue}/>
              </FormGroup>
            </Col>  
          </Row>  
          <Row> 
            <Col className="px-1">
              <FormGroup>
                <Input placeholder="メールアドレス" type="email" name="email" id="email" className="form-control" onChange={getInputEmailValue}/>
              </FormGroup>
            </Col>  
          </Row>     
          <Row> 
            <Col className="px-1"> 
              <FormGroup> 
                <Input placeholder="パスワード" type="password" name="password" id="password" className="form-control" onChange={getInputPasswordValue}/>
              </FormGroup>
            </Col>  
          </Row>
          <Row> 
            <Col className="px-1"> 
              <FormGroup> 
                <Input placeholder="パスワード（確認用）" type="password" name="password_confirmation" id="password_confirmation" className="form-control"  onChange={getInputPconfirmationValue}/>
              </FormGroup>
            </Col>  
          </Row>
          <Row>                    
            <Col className="px-1">
              <input type="button" name="user" value="アカウントを作成" className="btn-round btn btn-danger" onClick={register}/>
            </Col>    
            <Col className="px-1">
              <input type="button" name="user" value="戻る" className="btn-round btn btn-primary" onClick={login}/>
            </Col>                                  
          </Row>        
        </CardBody>
      </Card>
    </>
  );
}


export default Register;
