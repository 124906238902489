/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";

// reactstrap components //https://xs458763.xsrv.jp/api/
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Input } from "reactstrap";
import { useHistory } from 'react-router-dom';
import down from "assets/img/down.png";
import up from "assets/img/up.png";
import { Radar, RadarChart, PolarGrid, 
  PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

let session = {}; 
let se;

function Analysis() {
  
  const history = useHistory();
  const [checkboxgroup, setCheckboxgroup] = useState(
    [ { en: 's_serverData', jp: 'サーブ', fc: 's_setServerData', val:true },
      { en: 's_receiveData', jp: 'レシーブ', fc: 's_setReceiveData', val:true},
      { en: 's_strokeData', jp: 'ストローク', fc: 's_setStrokeData', val:true},
      { en: 's_netplayData', jp: 'ネットプレー', fc: 's_setNetplayData', val:true},
      { en: 's_mentalData', jp: 'メンタル', fc: 's_setMentalData', val:true},
      { en: 's_tacticsData', jp: '戦術', fc: 's_setTacticsData', val:true},]
    );
  
  const [s_serverData, s_setServerData] = useState([]);
  const [s_receiveData, s_setReceiveData] = useState([]);
  const [s_strokeData, s_setStrokeData] = useState([]);
  const [s_netplayData, s_setNetplayData] = useState([]);
  const [s_mentalData, s_setMentalData] = useState([]);
  const [s_tacticsData, s_setTacticsData] = useState([]);
  const [dragPadding, setDragPadding] = useState({paddingBottom:'15px'});
  
  const orderUpChange = (e, position, aryName, fcName) => {
    //alert(position);
    console.log(eval(aryName));
    let objAry = eval(aryName);
    let newAry = [];

    for(let i = 0; i < objAry.length; i++){
      if(i == (position-1) && position > 0){
        newAry[i] = objAry[position];
        i++;
        newAry[i] = objAry[position-1];
      }else{
        newAry[i] = objAry[i]; 
      }
    }
    eval(fcName)(newAry);

    window.sessionStorage.setItem(aryName, JSON.stringify(newAry)); 

    let tmp = [...checkboxgroup];
    tmp[0]['val'] = !tmp[0]['val'];
    setCheckboxgroup(tmp);

  }

  const orderDownChange = (e, position, aryName, fcName) => {
    //alert(position);
    console.log(eval(aryName));
    let objAry = eval(aryName);
    let newAry = [];

    for(let i = 0; i < objAry.length; i++){
      if(i == position && position < (objAry.length-1)){
        newAry[i] = objAry[position+1];
        i++;
        newAry[i] = objAry[position];
      }else{
        newAry[i] = objAry[i]; 
      }
    }
    eval(fcName)(newAry);

    window.sessionStorage.setItem(aryName, JSON.stringify(newAry)); 

    let tmp = [...checkboxgroup];
    tmp[0]['val'] = !tmp[0]['val'];
    setCheckboxgroup(tmp);

  }

  const handleOnChange = (e, position, aryName, fcName) => {
    
    eval(aryName)[position]['solution'] = e.target.value;
    window.sessionStorage.setItem(aryName, JSON.stringify(eval(aryName))); 
    eval(fcName)(eval(aryName));

    let tmp = [...checkboxgroup];
    tmp[0]['val'] = !tmp[0]['val'];
    setCheckboxgroup(tmp);

  };
  
  const profileUpdateFunc = (e) => {

    history.push('../sub/icons');
  }

  const [powermark, setpowermark] = useState(0);
  const [techmark, settechmark] = useState(0);
  const [physicalmark, setphysicalmark] = useState(0);
  const [titlemark, settitlemark] = useState(0);

  useEffect(() => {
    session = window.sessionStorage.getItem('sessions'); 
    se = JSON.parse(session);

    for(var i = 0 ; i < checkboxgroup.length; i++){

      if(JSON.parse(window.sessionStorage.getItem(checkboxgroup[i]['en'])) != null)
        eval(checkboxgroup[i]['fc'])(JSON.parse(window.sessionStorage.getItem(checkboxgroup[i]['en'])));
      
      axios.get(URL_API+"getSelfSelAPI", {
        params: {
          data: checkboxgroup[i]['en'],
          user: se['id'], 
        }
      })
      .then(function (response) {
        
        if(response.data !== null && response.data.length > 0){           
          
          window.sessionStorage.setItem(response.data[0]['group_id'], JSON.stringify(response.data));

          eval(response.data[0]['fc'])(JSON.parse(window.sessionStorage.getItem(response.data[0]['group_id'])));

        }

      });
    }

    let p = JSON.parse(window.sessionStorage.getItem('power'));
    if(Array.isArray(p)){
      if(p.length > 0){
        setPower(p); 
        let t = p[0]['val1'] + p[0]['val2'] + p[0]['val3'] +p[0]['val4'] +p[0]['val5']+p[0]['val6'];        
        //setTotal(t);
        setpowermark(Math.round(t / 6));
      }
    }

    session = window.sessionStorage.getItem('sessions'); 
    se = JSON.parse(session);
    
    axios.get(URL_API+"rederchart", {
      params: {
        kind: 1,
        user: se['id'], 
      }
    })
    .then(function (response) {
     
      if(Array.isArray(response.data)){
        if(response.data.length > 0){

          power[0]['x'] = response.data[0]['val1'];
          power[1]['x'] = response.data[0]['val2'];
          power[2]['x'] = response.data[0]['val3'];
          power[3]['x'] = response.data[0]['val4'];
          power[4]['x'] = response.data[0]['val5'];
          power[5]['x'] = response.data[0]['val6'];

          let t = response.data[0]['val1'] + response.data[0]['val2'] + response.data[0]['val3'] +response.data[0]['val4'] +response.data[0]['val5']+response.data[0]['val6'];
          setPower(power);
          //setTotal(t);
          setpowermark(Math.round(t / 6));
        }
      }

    });

    p = JSON.parse(window.sessionStorage.getItem('tech'));
    if(Array.isArray(p)){
      if(p.length > 0){
        setTech(p); 
        let t = p[0]['val1'] + p[0]['val2'] + p[0]['val3'] +p[0]['val4'] +p[0]['val5']+p[0]['val6']+p[0]['val7']+p[0]['val8'];        
        //setTotal(t);
        settechmark(Math.round(t / 8));
      }
    }
    
    axios.get(URL_API+"rederchart", {
      params: {
        kind: 2,
        user: se['id'], 
      }
    })
    .then(function (response) {
     
      if(Array.isArray(response.data)){
        if(response.data.length > 0){

          tech[0]['x'] = response.data[0]['val1'];
          tech[1]['x'] = response.data[0]['val2'];
          tech[2]['x'] = response.data[0]['val3'];
          tech[3]['x'] = response.data[0]['val4'];
          tech[4]['x'] = response.data[0]['val5'];
          tech[5]['x'] = response.data[0]['val6'];
          tech[6]['x'] = response.data[0]['val7'];
          tech[7]['x'] = response.data[0]['val8'];

          let t = response.data[0]['val1'] + response.data[0]['val2'] + response.data[0]['val3'] +response.data[0]['val4'] +response.data[0]['val5']+response.data[0]['val6']+response.data[0]['val7']+response.data[0]['val8'];
          setTech(tech);
          //setTotal(t);
          settechmark(Math.round(t / 8));
        }
      }

    });
    
    p = JSON.parse(window.sessionStorage.getItem('physical'));
    if(Array.isArray(p)){
      if(p.length > 0){
        setPhysical(p); 
        let t = p[0]['val1'] + p[0]['val2'] + p[0]['val3'] +p[0]['val4'] +p[0]['val5']+p[0]['val6']+p[0]['val7']+p[0]['val8'];        
        //setTotal(t);
        setphysicalmark(Math.round(t / 8));
      }
    }
    
    axios.get(URL_API+"rederchart", {
      params: {
        kind: 3,
        user: se['id'], 
      }
    })
    .then(function (response) {
     
      if(Array.isArray(response.data)){
        if(response.data.length > 0){

          physical[0]['x'] = response.data[0]['val1'];
          physical[1]['x'] = response.data[0]['val2'];
          physical[2]['x'] = response.data[0]['val3'];
          physical[3]['x'] = response.data[0]['val4'];
          physical[4]['x'] = response.data[0]['val5'];
          physical[5]['x'] = response.data[0]['val6'];
          physical[6]['x'] = response.data[0]['val7'];
          physical[7]['x'] = response.data[0]['val8'];

          let t = response.data[0]['val1'] + response.data[0]['val2'] + response.data[0]['val3'] +response.data[0]['val4'] +response.data[0]['val5']+response.data[0]['val6']+response.data[0]['val7']+response.data[0]['val8'];
          setPhysical(physical);
          //setTotal(t);
          setphysicalmark(Math.round(t / 8));
        }
      }
    });

    p = JSON.parse(window.sessionStorage.getItem('title'));
    if(Array.isArray(p)){
      if(p.length > 0){
        setTitle(p); 
        let t = p[0]['val1'] + p[0]['val2'] + p[0]['val3'] +p[0]['val4'] +p[0]['val5']+p[0]['val6']+p[0]['val7'];        
        //setTotal(t);
        settitlemark(Math.round(t / 7));
      }
    }

    axios.get(URL_API+"rederchart", {
      params: {
        kind: 4,
        user: se['id'], 
      }
    })
    .then(function (response) {
      
      if(Array.isArray(response.data)){
        if(response.data.length > 0){
          console.log(response.data[0]);

          title[0]['x'] = response.data[0]['val1'];
          title[1]['x'] = response.data[0]['val2'];
          title[2]['x'] = response.data[0]['val3'];
          title[3]['x'] = response.data[0]['val4'];
          title[4]['x'] = response.data[0]['val5'];
          title[5]['x'] = response.data[0]['val6'];
          title[6]['x'] = response.data[0]['val7'];         

          let t = response.data[0]['val1'] + response.data[0]['val2'] + response.data[0]['val3'] +response.data[0]['val4'] +response.data[0]['val5']+response.data[0]['val6']+response.data[0]['val7'];
          setTitle(title);
          //setTotal(t);
          settitlemark(Math.round(t / 7));
        }
      }    
    });

  }, []);

  let data = [
    { name: '技術', x: 3 },
    { name: 'メンタル', x: 4 },
    { name: 'フィジカル', x: 4 },
    { name: '戦術', x: 5 },
    { name: '攻撃力', x: 3 },
    { name: '守備カ', x: 4 },
  ];
  const [power, setPower] = useState(data); 

  data = [
    { name: '1stサーブ', x: 3 },
    { name: '2ndサーブ', x: 4 },
    { name: 'フォアハンド', x: 4 },
    { name: 'バックハンド', x: 5 },
    { name: 'リターン', x: 3 },
    { name: 'ボレー', x: 4 },
    { name: 'スマッシュ', x: 4 },
    { name: 'ロブ', x: 4 },
  ];
  const [tech, setTech] = useState(data); 

  data = [
    { name: 'バワー', x: 3 },
    { name: 'スビード', x: 4 },
    { name: '持久力', x: 4 },
    { name: 'バランス', x: 5 },
    // { name: '瞬発力', x: 3 },
    { name: '発力', x: 3 },
    { name: '反応', x: 4 },
    { name: '筋力', x: 4 },
    { name: 'フットワーク', x: 4 },
  ];
  const [physical, setPhysical] = useState(data);

  // data = [
  //   { name: '集中力', x: 3 },
  //   { name: '冷静さ', x: 4 },
  //   { name: '闘志（空回りしない強気さ）', x: 4 },
  //   { name: 'ポジティブ思考', x: 5 },
  //   { name: 'ボールを最後まで追う', x: 3 },
  //   { name: '声を出せる（打つ時やカモン！）', x: 4 },
  //   { name: '最後まであきらめないポイントへの執着心', x: 4 },   
  // ];
  data = [
    { name: '集中力', x: 3 },
    { name: '冷静さ', x: 4 },
    { name: '闘志（空回りしない強気さ）', x: 4 },
    { name: 'ポジティブ思考', x: 5 },
    { name: 'ボールを最後まで追う', x: 3 },
    { name: '声を出せる（打つ時やカモン！）', x: 4 },
    { name: '最後まであきらめないポイントへの執着心', x: 4 },   
  ];
  const [title, setTitle] = useState(data);

  const redarchart = (e, kind) => {
    console.log(kind);
    if(kind * 1 > 0){

      history.push('../sub/redarchart'+kind);

    }else{

      history.push('../sub/redarchart');

    }

  }

  const onDragStart = (a, group, fcName) => {
    // return;
    setDragPadding({paddingBottom:'70px'});
  }

  const onDragEnd = (result, group, fcName) => {
    // return;
    setDragPadding({paddingBottom:'15px'});
    if(!result.source || !result.destination)
      return;
    const newItems = Array.from(eval(group));
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    for(let i=0;i<newItems.length;i++){
      newItems[i].ordernum = i+1;
    }
    eval(fcName)(newItems);

    window.sessionStorage.setItem(group, JSON.stringify(newItems)); 

    let tmp = [...checkboxgroup];
    tmp[0]['val'] = !tmp[0]['val'];
    setCheckboxgroup(tmp);
  }

  return (
    <>
      <div className="content coach-user-content" style={{color:'rgb(3, 115, 202)', background:'white'}}>
       
        <Row style={{padding:'18px'}}>
          <Col onClick={(e) => redarchart(e, 0)} style={{textAlign:'center', border:'1px solid #CCC', padding:'5px', margin:'5px', borderRadius:'20px'}}>
            <h6>総合力</h6>
            <div className="d-flex justify-content-center">
              <RadarChart height={150} width={150} style={{fontSize:'10px'}} 
                outerRadius="60%" data={power}>
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <PolarRadiusAxis domain={[0, 5]}/>
                <Radar dataKey="x" stroke="green" angle={1}
                    fill="green" fillOpacity={0.5} max={5} />
              </RadarChart>
            </div>
            <h6 style={{textAlign:'right', paddingRight:'10px'}}>評価 : {powermark}</h6>
          </Col>
          <Col onClick={(e) => redarchart(e, 1)} style={{textAlign:'center', border:'1px solid #CCC', padding:'5px', margin:'5px', borderRadius:'20px'}}>
            <h6>技術</h6>
            <div className="d-flex justify-content-center">
            <RadarChart height={150} width={150}  style={{fontSize:'10px'}} 
              outerRadius="60%" data={tech}>
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis domain={[0, 5]}/>
              <Radar dataKey="x" stroke="green" angle={1}
                  fill="green" fillOpacity={0.5} max={5} />
            </RadarChart>
            </div>
            <h6 style={{textAlign:'right', paddingRight:'10px'}}>評価 : {techmark}</h6>
          </Col>
          <Col onClick={(e) => redarchart(e, 2)} style={{textAlign:'center', border:'1px solid #CCC', padding:'5px', margin:'5px', borderRadius:'20px'}}>
            <h6>フィジカル</h6>
            <div className="d-flex justify-content-center">
            <RadarChart height={150} width={150}  style={{fontSize:'10px'}} 
              outerRadius="60%" data={physical}>
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis domain={[0, 5]}/>
              <Radar dataKey="x" stroke="green" angle={1}
                  fill="green" fillOpacity={0.5} max={5} />
            </RadarChart>
            </div>
            <h6 style={{textAlign:'right', paddingRight:'10px'}}>評価 : {physicalmark}</h6>
          </Col>
          <Col onClick={(e) => redarchart(e, 3)} style={{textAlign:'center', border:'1px solid #CCC', padding:'5px', margin:'5px', borderRadius:'20px'}}>
            <h6>メンタル</h6>
            <div className="d-flex justify-content-center">
            <RadarChart height={150} width={150}  style={{fontSize:'10px'}} 
              outerRadius="60%" data={title}>
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis domain={[0, 5]}/>
              <Radar dataKey="x" stroke="green" angle={1}
                  fill="green" fillOpacity={0.5} max={5} />
            </RadarChart>
            </div>
            <h6 style={{textAlign:'right', paddingRight:'10px'}}>評価 : {titlemark}</h6>
          </Col>
        </Row>

        <Row>
          <Col style={{color: "#FF0000"}}>
            【空欄に改善策や戦術を記入して下さい。自己分析の中で優先順位の高い項目を矢印ボタンで上に上げていきましょう。】
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {
              checkboxgroup.map((group, num) =>
              <Card className="demo-icons" key={num}>
                <CardHeader style={{height:'50px'}}>
                  <CardTitle tag="h5">{group.jp}</CardTitle>                
                </CardHeader>
                <CardBody className="all-icons" style={{paddingTop:'0px'}} style={dragPadding}> 
                  {
                    eval(group.en).length > 0 &&
                  (<DragDropContext onDragEnd={(a) => onDragEnd(a, group.en, group.fc)} onDragStart={(r) => onDragStart(r, group.en, group.fc)}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {                    
                            eval(group.en).map((tmp, index) => (
                              <Draggable key={tmp.id} draggableId={"dnd_"+tmp.id} index={index}>
                                {(provided, snapshot) => (
                                  <div className="row" ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <div className="col-12 px-0">
                                      <div className="row" style={{marginTop:'10px'}}>
                                        <div className="col-10"> 
                                          <h6> 
                                            {tmp.item}       
                                          </h6>
                                        </div> 
                                        {/*<div className="col-1" style={{padding:'0px'}}> 
                                          <img src={up} style={{width:'20px'}} onClick={(e) => orderUpChange(e, index, group.en, group.fc)}></img>
                                        </div> 
                                        <div className="col-1" style={{padding:'0px'}}> 
                                          <img src={down} style={{width:'20px'}} onClick={(e) => orderDownChange(e, index, group.en, group.fc)}></img>
                                        </div>*/}
                                      </div>
                                      <div>   
                                        <div>                 
                                          <Input type="text" 
                                            style={{color:'#ff3d02'}}
                                            id={"an_"+num+"_" + index}
                                            name={"an_"+num}
                                            value={tmp.solution}                          
                                            onChange={(e) => handleOnChange(e, index, group.en, group.fc)}
                                          />    
                                        </div>                           
                                      </div>
                                    </div>                                           
                                  </div>
                                )}
                              </Draggable>)
                            )
                          }
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>)
                  }
                </CardBody>
              </Card>
            )}

          </Col>
        </Row>
        <Row>                    
          <Col className="col-6">
            <input type="button" name="user" value="保存" className="btn-round btn btn-danger" onClick={save}/>
          </Col>  
          <Col className="col-6">
            <input type="button" name="user" value="自己分析画面" className="btn-round btn btn-primary" onClick={profileUpdateFunc}/>
          </Col>                                     
        </Row>
      </div>
    </>
  );
}
function save(){

  let server = JSON.parse(window.sessionStorage.getItem(['s_serverData']));
  let serAry = [];
  for(var i = 0; i < server.length; i++){
    serAry[i] = {};
    serAry[i].id = server[i].id;
    serAry[i].solution = server[i].solution;
  }

  let receive = JSON.parse(window.sessionStorage.getItem(['s_receiveData']));
  let recAry = [];
  for(var i = 0; i < receive.length; i++){
    recAry[i] = {};
    recAry[i].id = receive[i].id;
    recAry[i].solution = receive[i].solution;
  }

  let stroke = JSON.parse(window.sessionStorage.getItem(['s_strokeData']));
  let strAry = [];
  for(var i = 0; i < stroke.length; i++){
    strAry[i] = {};
    strAry[i].id = stroke[i].id;
    strAry[i].solution = stroke[i].solution;
  }

  let netplay = JSON.parse(window.sessionStorage.getItem(['s_netplayData']));
  let netAry = [];
  for(var i = 0; i < netplay.length; i++){
    netAry[i] = {};
    netAry[i].id = netplay[i].id;
    netAry[i].solution = netplay[i].solution;
  }

  let mental = JSON.parse(window.sessionStorage.getItem(['s_mentalData']));
  let menAry = [];
  for(var i = 0; i < mental.length; i++){
    menAry[i] = {};
    menAry[i].id = mental[i].id;
    menAry[i].solution = mental[i].solution;
  }

  let tactics = JSON.parse(window.sessionStorage.getItem(['s_tacticsData']));
  let tacAry = [];
  for(var i = 0; i < tactics.length; i++){
    tacAry[i] = {};
    tacAry[i].id = tactics[i].id;
    tacAry[i].solution = tactics[i].solution;
  }

  axios.post(URL_API+"saveSelfSelAPI", {
    params: {
      s_serverData: JSON.stringify(serAry),
      s_receiveData: JSON.stringify(recAry), 
      s_strokeData: JSON.stringify(strAry),
      s_netplayData: JSON.stringify(netAry), 
      s_mentalData: JSON.stringify(menAry),
      s_tacticsData: JSON.stringify(tacAry), 
    }
  },{ 
    xsrfHeaderName: 'X-CSRF-Token',
    withCredentials: true
  })
  .then(function (response) {

  });
}

export default Analysis;
