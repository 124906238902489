import React, { Component } from 'react';
import { render } from 'react-dom';

import {useState, useEffect } from "react";

import "assets/css/list.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import technicalImg from "assets/img/self/technical.png";
import graphImg from "assets/img/self/graph.png";
import listImg from "assets/img/self/list.png";


import { useHistory } from 'react-router-dom';
import DatePicker from 'react-mobile-datepicker';



let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}

function initDiaryStorage(tDate){
  // var date = new Date();
  // console.log(tDate);
  let key = tDate.getFullYear()+("0"+(tDate.getMonth()+1)).slice(-2)+("0" + tDate.getDate()).slice(-2);
  if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
    let diaryData = JSON.parse(window.sessionStorage.getItem('diaryData'));
    if(!diaryData[key]){
      diaryData[key] = {
        abdominal: 0,
        eat1: 0,
        eat2: 0,
        eat3: 0,
        mental1: 0,
        mental2: 0,
        mental3: 0,
        physical1: 0,
        physical2: 0,
        physical3: 0,
        pushup: 0,
        sleepend: "00:00",
        sleepstart: "00:00",
        spine: 0,
        stretch: 0,
        studyend: "00:00",
        studystart: "00:00",
        tactical1: 0,
        tactical2: 0,
        tactical3: 0,
        technical1: 0,
        technical2: 0,
        technical3: 0,
        todayDate: ""
      };
      window.sessionStorage.setItem('diaryData', JSON.stringify(diaryData));
    }
    return diaryData;
  }else{
    let diaryData = {};
    diaryData[key] = {
      abdominal: 0,
      eat1: 0,
      eat2: 0,
      eat3: 0,
      mental1: 0,
      mental2: 0,
      mental3: 0,
      physical1: 0,
      physical2: 0,
      physical3: 0,
      pushup: 0,
      sleepend: "00:00",
      sleepstart: "00:00",
      spine: 0,
      stretch: 0,
      studyend: "00:00",
      studystart: "00:00",
      tactical1: 0,
      tactical2: 0,
      tactical3: 0,
      technical1: 0,
      technical2: 0,
      technical3: 0,
      todayDate: ""
    };
    window.sessionStorage.setItem('diaryData', JSON.stringify(diaryData));
    return diaryData;
  }
}

function TimeList(){

	const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const history = useHistory();

	var date = new Date(); 
	const [flag, setFlag] 						= useState(0);
	// const [categories, setCategories] 			= useState([]);
	// const [data, setData] 						= useState([]);
	// var date = new Date();  
	const [todayDate, setTodayDate] 			= useState((new Date(sessionStorage.getItem('today'))).getDate());

	const [seriesName, setSeriesName] 			= useState('');
	const [fname, setFName] 					= useState(sessionStorage.getItem('chart_field_name') || 'technical1');
	const [fname2, setFName2] 					= useState(sessionStorage.getItem('chart_field2_name') || 'technical1');
	const [timeFname, setTimeFname] 			= useState('studystart');

	const [chart_logo, setChart_logo] 			= useState(sessionStorage.getItem('chart_intro_img') ||technicalImg);

	const [showDate, setShowDate] 				= useState();
	const [keyData, setKeyData] 				= useState(1);
	const [vData, setVData] 					= useState('NO DATA');

	const [vData1, setVData1] 					= useState('00:00');
	const [vData2, setVData2] 					= useState('00:00');

	const [showInputV, setShowInputV] 			= useState('hide');
	const [showDataV, setShowDataV] 			= useState('show');

	const [showInputBtnf, setShowInputBtnf] 	= useState(false);
	const [isModal, setIsModal] 				= useState('hide');
	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	const [sToday, setSToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	
	const [getKey, setGetKey] = useState((new Date(sessionStorage.getItem('today'))).getFullYear()+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));

	const [data_list, setDataList] = useState([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [dateType, setDateType] = useState('ymd');
	const [dateConfig, setDateConfig] = useState({
                    'year': {
                        format: 'YYYY',
                        caption: 'Year',
                        step: 1,
                    },
                    'month': {
                        format: 'MM',
                        caption: 'Mon',
                        step: 1,
                    },
                    'date': {
                        format: 'DD',
                        caption: 'Day',
                        step: 1,
                    }
                });

	const setStateDatas = (d_datas) => {
		// setDiaryData(d_datas);
		let datas = [];
		let days = lastday(today.getFullYear(), today.getMonth());
		// for (let i in diaryData) {
		// let keys = [];
		// console.log(today.getFullYear(), today.getMonth());
		for(var i=1;i<=days;i++){
			let key = today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + i).slice(-2);
			let d = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+i);
			// console.log(d.getDay());
			if(d_datas[key]){
				datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:d_datas[key][fname], v2:d_datas[key][fname2], y:today.getFullYear(), m:(today.getMonth()+1), d:i});
			}else
				datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:'00:00', v2:'00:00', y:today.getFullYear(), m:(today.getMonth()+1), d:i});
		}
		// console.log(datas);
		setDataList(datas);
	}	


	function openModal(f, fname, da) {
	    setIsOpen(true);
	    setDateType(f);
	    if(f == 'ymd'){
	      setDateConfig({
	                    'year': {
	                        format: 'YYYY',
	                        caption: 'Year',
	                        step: 1,
	                    },
	                    'month': {
	                        format: 'MM',
	                        caption: 'Mon',
	                        step: 1,
	                    },
	                    'date': {
	                        format: 'DD',
	                        caption: 'Day',
	                        step: 1,
	                    }
	                });
	    }else{
	      setTimeFname(fname);
	      var t = da.split(':');
	      // console.log(eval(fname), t);
	      var tod = sToday;
	      tod.setHours(t[0]);
	      tod.setMinutes(t[1]);
	      var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
	      // console.log(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t[0]+":"+t[1]);
	      setSToday(tods);
	      setDateConfig({
	        'hour': {
	          format: 'hh',
	          caption: 'Hour',
	          step: 1,
	        },
	        'minute': {
	          format: 'mm',
	          caption: 'Min',
	          step: 1,
	        },
	      });
	    }
	  }

	const showChart = (title, fname, img, e) => {
	    sessionStorage.setItem("chart_title", title);
	    sessionStorage.setItem("chart_field_name", fname);
	    sessionStorage.setItem("chart_intro_img", img);
	    sessionStorage.setItem("today", today);
	    history.push('../admin/timeChart');
	};

	const showList = (title, fname, img, e) => {
	    sessionStorage.setItem("chart_title", title);
	    sessionStorage.setItem("chart_field_name", fname);
	    sessionStorage.setItem("chart_intro_img", img);
	    sessionStorage.setItem("today", today);
	    history.push('../admin/timeList');
	};

	const setDataInput = (e, item) => {
		var v = item.v + ' - '+item.v2;
		var key = item.key;
		setTodayDate(item.d);
		setVData(v == '00:00 - 00:00' ? 'NO DATA' : v);
		setVData1(item.v);
		setVData2(item.v2);
		setKeyData(key);
		setIsModal('_show');
		setShowDate(item.m+' / '+ item.d + ' ' + weekday[item.w]);
	};

	const noHandleClick = (event) => {
		setIsModal('_hide');		
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
	}

	const onInputData = (e, f) => {
		if(f){
			setShowInputV('show');
			setShowDataV('hide');
		}else{
			setShowInputV('hide');
			setShowDataV('show');
		}
		setShowInputBtnf(f);
	}

	const onChangeData = (e, f_name, n) => {
		// const d = new Date();
		if(n==1)
			setVData1(e.target.value);
		else
			setVData2(e.target.value);
		// setVData(e.target.value);
		let session = window.sessionStorage.getItem('sessions'); 
		let setDataDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-' + todayDate;
		let se = JSON.parse(session);
		axios.get(URL_API+"setChartData", {
	      	params: {
	        	fname: f_name, 
	        	v:e.target.value,
	        	seldate: setDataDate,
	        	flag:flag,
	        	user: se['id'],
	      	}
	    })
	    .then(function (response) {
	    	// setCategories(response.data.categories);
	    	// setData(response.data.data);	   
	    	let ds = JSON.parse(window.sessionStorage.getItem('diaryData'));
	    	let key = today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + todayDate).slice(-2);
	    	// console.log(key, fname);
	    	// ds[key][fname] = e.target.value;
	    	if(!ds[key])
	    		ds = initDiaryStorage(new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-' + todayDate));
	    	ds[key][f_name] = e.target.value;
	    	window.sessionStorage.setItem('diaryData', JSON.stringify(ds));
	   		setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   		setVData(vData1+' - '+vData2);
	    }); 
	}

	const onBeforeData = (e) => {
		onGetData(todayDate-1);
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
	}

	const onNextData = (e) => {
		onGetData(todayDate+1);
		setShowInputV('hide');
		setShowDataV('show');
		setShowInputBtnf(false);
	}

	const setDiaryDataStorage = (f_name, v, setFuncName) => {
		// console.log(f_name, v, setFuncName);
		let session = window.sessionStorage.getItem('sessions'); 
		let setDataDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-' + todayDate;
		let se = JSON.parse(session);
		axios.get(URL_API+"setChartData", {
	      	params: {
	        	fname: f_name, 
	        	v:v,
	        	seldate: setDataDate,
	        	flag:flag,
	        	user: se['id'],
	      	}
	    })
	    .then(function (response) {
	    	// setCategories(response.data.categories);
	    	// setData(response.data.data);	   
	    	let ds = JSON.parse(window.sessionStorage.getItem('diaryData'));
	    	let key = today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + todayDate).slice(-2);
	    	// console.log(key, f_name);
	    	// ds[key][fname] = e.target.value;
	    	if(!ds[key])
	    		ds = initDiaryStorage(new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-' + todayDate));
	    	ds[key][f_name] = v;
	    	window.sessionStorage.setItem('diaryData', JSON.stringify(ds));
	   		setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	    }); 
    }
   //  const sendToServerData = (fname, v) => {
	  //   let session = window.sessionStorage.getItem('sessions');
	  //   let se = JSON.parse(session);
	  //   axios.get(URL_API+"setChartData", {
	  //       params: {
	  //         fname: fname, 
	  //         v:v,
	  //         seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+todayDate,
	  //         flag:0,
	  //         user: se['id'],
	  //       }
	  //   })
	  //   .then(function (response) {
	      
	  //   }); 
	  // }

	const onGetData = (i) => {		
		let data = JSON.parse(window.sessionStorage.getItem('diaryData'));
		let key = today.getFullYear()+("0"+(today.getMonth()+1)).slice(-2)+("0" + i).slice(-2);

		let d = new Date(today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2));
		// console.log(key, today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2));
		let days = lastday(today.getFullYear(), today.getMonth());
		if(i>days)
			return;
		if(data[key] != undefined){
			setVData(data[key][fname]+' - '+data[key][fname2]);
			setVData1(data[key][fname]);
			setVData2(data[key][fname2]);
		}else{
			setVData('NO DATA');
			setVData1('00:00');
			setVData2('00:00');
		}
		setShowDate((today.getMonth()+1)+' / '+ i + ' ' + weekday[d.getDay()]);
		setTodayDate(i);
	}
	const selectDate = (time) => {
	    if(dateType == 'ymd'){
	      setToday(time);
	      setSToday(time);
	      setTodayDate(time.getDate());
	      setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	      setGetKey(time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	    }else{
	      var h = time.getHours();
	      var m = time.getMinutes();
	      // console.log(timeFname, h, m);
	      var t = ("0"+h).slice(-2)+":"+("0" + m).slice(-2);
	      var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t);
	      // console.log(dateConfig);
	      setSToday(tods);
	      if(timeFname == 'studystart'){
	        setDiaryDataStorage(timeFname, t, 'setStudystart');
	        setVData1(t);
	      }else if(timeFname == 'studyend'){
	        setDiaryDataStorage(timeFname, t, 'setStudyend');
	        setVData2(t);
	      }else if(timeFname == 'sleepstart'){
	        setDiaryDataStorage(timeFname, t, 'setSleepstart');
	        setVData1(t);
	      }else if(timeFname == 'sleepend'){
	        setDiaryDataStorage(timeFname, t, 'setSleepend');
	        setVData2(t);
	      }
	      setVData(vData1+' - '+vData2);
	    }
	    setIsOpen(false);
	    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	  }

	const handleCancel = () => {
	    setIsOpen(false);
	}

	useEffect(() => {
		let session = window.sessionStorage.getItem('sessions'); 
	    let se = JSON.parse(session);
	    if(!se){
	      	window.location = '/';
	    }

	   	var chart_title = sessionStorage.getItem('chart_title');
	   	if(chart_title != null)
	   		setSeriesName(chart_title);
	   	else
	   		setSeriesName('');

	   	var chart_field_name = sessionStorage.getItem('chart_field_name');
	   	if(chart_field_name != null)
	   		setFName(chart_field_name);
	   	else
	   		setFName('technical1');

	   	var chart_intro_img = sessionStorage.getItem('chart_intro_img');
	   	if(chart_intro_img != null)
	   		setChart_logo(chart_intro_img);
	   	else
	   		setChart_logo(technicalImg);

	   	// console.log(chart_title, chart_field_name, chart_intro_img);
	   	if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
	   		// setDiaryData(JSON.parse(window.sessionStorage.getItem('diaryData')));
			setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   	}
   		axios.get(URL_API+"getDiaryAPI", {
			params: {
				seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
				user: se['id'],
			}
	    })
	    .then(function (response) {		      
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data));
	      	// setDiaryData(response.data);
	      	// let data = response.data[getKey];
	      	setStateDatas(response.data);
	    }).catch(
	      	error => {
	        	if(error.response){
	          		console.log(error.response.data);
	        	};
	      	}
	    );
  	}, [today]);
	return (
		<>		
			<DatePicker
		        dateConfig={dateConfig}
		        value={sToday}
		        isOpen={modalIsOpen}
		        onSelect={selectDate}
		        onCancel={handleCancel} />	


			<div className={isModal + ' py-3 px-2'} id="showDateDiv">
				<div className={'show_body'}>						
					<div className={'m-header mb-1'}>
						<div>{showDate}</div>
						<div className={'pos_abs pointer'} onClick={(event) => onInputData(event, !showInputBtnf)}>input</div>
					</div>
					<div className={'m-body'}>
						<span><i className={"fas fa-caret-left fa-fw fa-2x pointer"} onClick={(event) => onBeforeData(event)}></i></span>
						<div className={showDataV}>{vData}</div>
						<div className={showInputV}>
							<input type="text" style={{width:'120px', textAlign:'center'}} onClick={() => openModal('hm', fname, vData1)} readOnly="readonly" value={vData1} />     
                    		<input type="text" style={{width:'120px', textAlign:'center'}} onClick={() => openModal('hm', fname2, vData2)} readOnly="readonly" value={vData2} />
						</div>
						<span><i className={"fas fa-caret-right fa-fw fa-2x pointer"} onClick={(event) => onNextData(event)}></i></span>
					</div>
					<div className="d-flex justify-content-center">
						<span><i className="fas fa-angle-down fa-fw fa-2x pointer" onClick={(event) => noHandleClick(event)}></i></span>
					</div>
				</div>		
			</div>
			<div className="content">
        		<Row>
          			<Col md="12">
            			<Card>
							<CardBody className="seldate" id='seldate' onClick={() => openModal('ymd','', '')} >
				                <Row>
				                  	<Col className="col-12 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}}>
				                    	{todayVDate}
				                 	</Col>
				                </Row>
				            </CardBody>   
							<div className="d-flex justify-content-between align-items-center">
								<div className="ml-2 d-flex align-items-center">
									<img src={chart_logo} className="mr-2"/> {seriesName}
								</div>
								<div className="d-flex mr-2">
									<img src={graphImg} width="35px" style={{marginRight:'5px'}} onClick={(event) => showChart(seriesName, fname, chart_logo, event)}/>
									<img src={listImg} width="35px" onClick={(event) => showList(seriesName, fname, chart_logo, event)}/>
								</div>
							</div>
							<div className="content_body">					
							{
								data_list.map((item, i) => 
									<div key={i} className="d-flex justify-content-between px-3 border-bottom py-2 font-weight-bolder pointer fa-lg" onClick={(event) => setDataInput(event, item)}>
										<div className = {(item.w==0 ? 'sun' : (item.w == 6 ? 'sat' : '')) + ' d-flex align-items-center'}>{item.dtitle}</div>
										<div className="d-flex align-items-center" style={{color:'rgb(65, 150, 23)'}}>{item.v+' - '+item.v2}</div>
									</div>
								)
							}
							</div>
						</Card>
					</Col>
				</Row>
			</div>
      	</>
    )
}

export default TimeList;