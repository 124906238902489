/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Icons from "views/Icons.js";
import Other from "views/Other.js";
import Preparationedit from "views/Preparationedit.js";
import Preparationview from "views/Preparationview.js";
import Tablesedit from "views/Tablesedit.js";
// import Aphorismsdit from "views/Aphorismsedit.js"
import ApList from "views/ApList.js";
import Goalsettingedit from "views/Goalsettingedit.js"
import BigGoalsettingedit from "views/BigGoalsettingedit.js"
import Redarchart from "views/Redarchart.js"
import Redarchart1 from "views/Redarchart1.js"
import Redarchart2 from "views/Redarchart2.js"
import Redarchart3 from "views/Redarchart3.js"
// import TablesShow from "views/coach/TablesShow.js";

var routes = [ 
  {
    path: "/redarchart",
    name: "編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Redarchart,
    layout: "/sub",
  },
  {
    path: "/redarchart1",
    name: "編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Redarchart1,
    layout: "/sub",
  },
  {
    path: "/redarchart2",
    name: "編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Redarchart2,
    layout: "/sub",
  }, 
  {
    path: "/redarchart3",
    name: "編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Redarchart3,
    layout: "/sub",
  },  
  {
    path: "/icons",
    name: "編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Icons,
    layout: "/sub",
  },  
  {
    path: "/other",
    name: "編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Other,
    layout: "/sub",
  }, 
  {
    path: "/apList",
    name: "格言集",
    icon: "nc-icon nc-bullet-list-67",
    component: ApList,
    layout: "/sub",
  }, 
  {
    path: "/biggoalsettingedit",
    name: "大会目標編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: BigGoalsettingedit,
    layout: "/sub",
  }, 
  {
    path: "/goalsettingedit",
    name: "課題目標設定編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Goalsettingedit,
    layout: "/sub",
  }, 
  {
    path: "/preparationedit",
    name: "試合準備編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Preparationedit,
    layout: "/sub",
  },  
  {
    path: "/preparationview",
    name: "編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Preparationview,
    layout: "/sub",
  }, 
  {
    path: "/tablesedit",
    name: "試合後リフレクション編集画面",
    icon: "nc-icon nc-bullet-list-67",
    component: Tablesedit,
    layout: "/sub",
  },
  // {
  //   path: "/tablesShow",
  //   name: "対戦相手検索",
  //   icon: "nc-icon nc-bullet-list-67",
  //   component: TablesShow,
  //   layout: "/sub",
  // },
  
];
export default routes;
