var dataTypes = {
    basic:[ 
      {/////****
        id:1,
        name: "整数(～100)",
        comment:"100までの整数値を1つ記録します。パーセンテジーや点数評価(100点満点)など、MAX100の整数データにお使いください。",
        iType:1,
        max:100,
        min:0
      }, 
      {///////*****
        id:2,
        name: "少数(～100)",
        comment:"100までの少数値を1つ記録します。体脂肪率、筋肉率、体水分率などのパーセンテジーデータにお使いください。",
        iType:1,
        max:100,
        min:0
      }, 
      {//////*****
        id:3,
        name: "整数ダブル",
        comment:"2つの整数値(MAX5ケタ)を記録します。血圧など高低2つの値を扱う整数データにお使いください。",
        iType:2,
        max:99999,
        min:0
      }, 
      {//////*****
        id:4,
        name: "累計(整数)",
        comment:"入力する度に整数値を加算します。累計値だけでなく個々の入力データも記録として残ります。各入力データにはラベル名を付けることができます。入力画面内の時計ボタンを押すと、ラベル名の末尾に入力時刻をスタンプします。",
        iType:3
      }, 
      {//////*******
        id:5,
        name: "時間(h:m)",
        comment:"1つの時間(時分)を記録します。",
        iType:4
      }, 
      {//////******
        id:6,
        name: "時間(h:m`s)",
        comment:"1つの時間(時分秒)をきろくします。",
        iType:5
      }, 
      {////////******
        id:7,
        name: "時間帯(h:m)",
        comment:"2つの時間(時分)を記録します。開始・終了の2つの時間を扱うデータにお使いください。",
        iType:6
      }, 
      {///////*********
        id:8,
        name: "累計時間(h:m`s)",
        comment:"入力する度に時間を加算します。累計時間だけでなく個々の入力データも記録として残ります。各入力データにはラベル名を付けることができます。入力画面内の時計ボタンを押すと、ラベル名の末尾に入力時刻をスタンプします。",
        iType:7
      }, 
      {//////******
        id:9,
        name: "チェックBOX",
        comment:"日々のタスク管理にお使いください。",
        iType:8
      }, 
      {////*****
        id:10,
        name: "5段階評価",
        comment:"5段階評価データを記録します。お通じや気分など感覚的な記録にお使いください。",
        iType:9
      }, 
      {
        id:11,
        name: "グラフビューア",
        comment:"グラフ(またはチャート)を並べて表示します。※MAX100個",
        iType:10,
        max:100
      },
      {
        id:12,
        name: "コメントテキスト",
        comment:"コメントテキスト",
        iType:11
      }
    ],
  extra:[]
};
export default dataTypes;
