/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import gameImg from "assets/img/biggoal.png";
import { useHistory } from 'react-router-dom';

// reactstrap components
import NotificationAlert from "react-notification-alert";
import {  
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let session = window.sessionStorage.getItem('sessions'); 
let se = JSON.parse(session);

let notificationAlert;

function Goalsetting() {

  notificationAlert = React.useRef();  
  
  const [goalsetting, s_setGoalsetting] = useState([]);
  //const [p_opponent, s_setOpponentData] = useState([]);
  
  const history = useHistory();
  
  const preparationUpdateFunc = (e) => {

    window.sessionStorage.setItem('target', '{}');   
    history.push('../sub/biggoalsettingedit');

  };
  const getViewpageUrl = (tmp) => {

    window.sessionStorage.setItem('target', JSON.stringify(tmp)); 
    history.push('../sub/biggoalsettingedit');

  };
  
  useEffect(() => {    
    session = window.sessionStorage.getItem('sessions'); 
    se = JSON.parse(session);

    if(session === null){    
      window.location = '/';
    }        
    //サーブ
    s_setGoalsetting(JSON.parse(window.sessionStorage.getItem("goalsetting")));
    axios.get(URL_API+"getBigSettingAPI", {
      params: {
        sel: se['id'], 
      }
    })
    .then(function (response) {

      if(Array.isArray(response.data)){
        s_setGoalsetting(response.data);
        window.sessionStorage.setItem('goalsetting', JSON.stringify(response.data));
      }

    }); 
       

  }, []);
 

  return (

    <>
      <div className="content px-0 coach-user-content pb-0" style={{background:'white'}}>
        <NotificationAlert ref={notificationAlert} />
        <Row className="px-0 mx-0">          
          <Col md="12" className="px-0">
            <div className="card-user">              
              <CardBody>
                <Form>
                  <Row>
                    <div>
                      <div className="pl-1" style={{color:'rgb(3, 115, 202)'}}><h6>大会目標画面</h6></div>                
                    </div>
                    <Col md="12">                                         
                      {
                        goalsetting?.map((tmp, index) => 

                        <Row key={index} onClick={() => getViewpageUrl(tmp)} val={tmp.id} style={{height:'35px'}}> 
                          <Col className="col-2 px-1 d-flex align-items-center " style={{color:'rgb(3, 115, 202)', fontSize:'16px', paddingRight:'0px', textAlign: 'center'}}>
                            <img src={gameImg}  width="30px"/>
                          </Col>                        
                          <Col className="col-7 px-2 d-flex align-items-center" style={{color:'rgb(3, 115, 202)', fontSize:'16px', display: 'flex', alignItems: 'center'}}>
                            <b>{tmp.target1}</b>
                          </Col>
                          <Col className="col-3 px-1 d-flex align-items-center" style={{color:'rgb(3, 115, 202)', fontSize:'16px', display: 'flex', alignItems: 'center'}}>                          
                            {tmp.edit_date}
                          </Col>
                        </Row>
                        )
                      } 
                    </Col>
                  </Row>
                  
                  <Row>                    
                    <Col className="px-1">
                      <input type="button" name="user" value="追加" className="btn-round btn btn-primary" onClick={preparationUpdateFunc}/>
                    </Col>                                     
                  </Row>                  
                </Form>
              </CardBody>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}


export default Goalsetting;
