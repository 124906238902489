/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/coach/Dashboard.js";
import User from "views/coach/User.js";
import UserMg from "views/coach/UserMg.js";
import Analysis from "views/coach/Analysis.js";
import BigGoalSetting from "views/coach/BigGoalSetting.js";
import GoalSetting from "views/coach/GoalSetting.js";
import Evaluation from "views/coach/Evaluation.js";
import Preparation from "views/coach/Preparation.js";
import TableList from "views/coach/Tables.js";
import Aphorisms from "views/coach/Aphorisms.js";
import Attend from "views/coach/Attend.js";
import Members from "views/coach/Members.js";
import OtherSearch from "views/coach/OtherSearch.js";
import Logout from "views/Logout.js";

// end
var routesCoach = [  
  // {
  //   path: "/dashboard",
  //   name: "ダッシュボード",
  //   icon: "nc-icon nc-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/coach",
  // },
  {
    path: "/user",
    name: "プロフィール編集",
    icon: "nc-icon nc-single-02",
    component: User,
    layout: "/coach",
  },
  {
    path: "/userMg",
    name: "選手情報",
    icon: "nc-icon nc-circle-10",
    component: UserMg,
    layout: "/coach",
  },
  {
    path: "/attend",
    name: "出席入力",
    icon: "nc-icon nc-check-2",
    component: Attend,
    layout: "/coach",
  }, 
  {
    path: "/evaluation",
    name: "選手評価",
    icon: "nc-icon nc-user-run",
    component: Evaluation,
    layout: "/coach",
  }, 
  {
    path: "/otherS",
    name: "対戦相手検索",
    icon: "nc-icon nc-air-baloon",
    component: OtherSearch,
    layout: "/coach",
  },
  {
    path: "/chatMembers",
    name: "チャット",
    icon: "nc-icon nc-chat-33",
    component: Members,
    layout: "/coach",
  },
  {
    path: "/analysis",
    name: "自己分析編集",
    icon: "nc-icon nc-chart-bar-32",
    component: Analysis,
    layout: "/coach",
  },
  // {
  //   path: "/biggoalsetting",
  //   name: "大会目標画面",
  //   icon: "nc-icon nc-air-baloon",
  //   component: BigGoalSetting,
  //   layout: "/coach",
  // },
  // {
  //   path: "/goalsetting",
  //   name: "課題目標設定画面",
  //   icon: "nc-icon nc-vector",
  //   component: GoalSetting,
  //   layout: "/coach",
  // },
  // {
  //   path: "/preparation",
  //   name: "試合準備画面",
  //   icon: "nc-icon nc-bullet-list-67",
  //   component: Preparation,
  //   layout: "/coach",
  // },  
  // {
  //   path: "/tables",
  //   name: "試合後リフレクション画面",
  //   icon: "nc-icon nc-trophy",
  //   component: TableList,
  //   layout: "/coach",
  // },
  {
    path: "/aphorisms",
    name: "格言集編集",
    icon: "nc-icon nc-atom",
    component: Aphorisms,
    layout: "/coach",
  },
  {
    path: "/logout",
    name: "ログアウト",
    icon: "nc-icon nc-share-66",
    component: Logout,
    layout: "/admin",
  },
];

export default routesCoach;
