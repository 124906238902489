/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useHistory } from 'react-router-dom';
import React, {useState, useEffect} from "react";
function Logout() {

  const [a, seta] = useState(0);
  const history = useHistory();

  useEffect(() => {

    sessionStorage.clear();  
    history.push('../user/login');

  }, []);

  
  //window.location = '/';
  return (<><div>{a}</div></>);
}

export default Logout;
