/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";
import DatePicker from 'react-mobile-datepicker';
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import Modal from 'react-modal';
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

// add new codes
import { useHistory } from 'react-router-dom';
import dataTypes from "views/diary/dataType.js";

import "assets/css/diary.css";
// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y,m){
  return  new Date(y, m +1, 0).getDate();
}

function Attend() {

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }  
  
  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

  var date = new Date();  
  const [attends, setAttends] =useState({});
  if(!window.sessionStorage.getItem('today'))
    window.sessionStorage.setItem('today', date);
  const [todayDate, setTodayDate] =  useState(date.getDate());
  const [todayDay, setTodayDay] = useState(weekday[date.getDay()]);
  const [todayVDate, setTodayVDate] =  useState(month[date.getMonth()]+" "+date.getFullYear());
  const [today, setToday] =  useState(date);
  const [sToday, setSToday] =  useState(date);
  const [uFlag, setUFlag] =  useState(true);

  const [getKey, setGetKey] = useState(date.getFullYear()+"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2));
  const [dragPadding, setDragPadding] = useState({paddingBottom:'15px'});
  const [dateConfig, setDateConfig] = useState({
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: 'MM',
        caption: 'Mon',
        step: 1,
    },
    'date': {
        format: 'DD',
        caption: 'Day',
        step: 1,
    }
  });

  const [modalIsOpen, setIsOpen] = useState(false);
  const [players, setPlayers] = useState(JSON.parse(window.sessionStorage.getItem('players')));
  const history = useHistory();

  function openModal(f, fname) {
    setIsOpen(true);
  }  

  const showChart = (player, e) => {
    window.sessionStorage.setItem('selectedPlayer', JSON.stringify(player));
    history.push('../coach/attendChart');
  };


  const showList = (player, e) => { 
    window.sessionStorage.setItem('selectedPlayer', JSON.stringify(player));
    history.push('../coach/attendList');
  };

  const selectDate = (time) => {
    setToday(time);
    setSToday(time);
    setTodayDate(time.getDate());
    setTodayDay(weekday[time.getDay()]);
    setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
    setGetKey(time.getFullYear()+"-"+("0"+(time.getMonth()+1)).slice(-2)+"-"+("0" + time.getDate()).slice(-2));
    window.sessionStorage.setItem('today', time);
    setIsOpen(false);
    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  const sendServerData = (v) => {    
    axios.get(URL_API+"setPlayerAttendData", {
      params: {
        todayDate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
        c_id: se['id'],
        content:v
      }
    })
    .then(function (response) {
      // window.sessionStorage.setItem('attends', JSON.stringify(response.data.data));
      // setAttends(response.data.data);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
  }

  const getValueEffClass = (player, txt) => {
    let atts = attends;
    let att_data = atts[getKey];
    let v = [];
    if(att_data)
      v = att_data.split(',');

    // console.log(att_data, getKey, attends, player.id, v.indexOf(player.id));

    let idx = v.indexOf(""+player.id);
    if( idx > -1){
      return 'o-'+txt;
    }else
      return 'n-'+txt;
  }

  const loadUserImg = (player) => {
    if(player.profile_photo_path){
      return "https://xs458763.xsrv.jp/uploads/" + player.profile_photo_path;
    }else{
      return require("assets/img/mike.jpg").default;
    }
  }

  const onSelectPlayer = (e, player) => {
    let atts = attends;
    let att_data = atts[getKey];
    let v = [];
    if(att_data)
      v = att_data.split(',');
    let idx = v.indexOf(""+player.id);
    if( idx > -1){
      v.splice(idx, 1);
    }else{
      v.push(player.id);
    }
    atts[getKey] = v.join(',');
    setAttends(atts);
    // console.log(uFlag);
    setUFlag(!uFlag);
    window.sessionStorage.setItem('attends', JSON.stringify(atts));
    sendServerData(v.join(','));
  }

  const showStatics = (e) => {
    history.push('../coach/attendStat');
  }

  const showEvaluation = (e) => {
    history.push('../coach/evaluation');
  }

  const formatCheckData1 = (player) => {
    let atts = attends;
    let att_data = atts[getKey];
    let v = [];
    if(att_data)
      v = att_data.split(',');
    let idx = v.indexOf(""+player.id);
    if( idx > -1){
      return (<span className="s_1"></span>);
    }else
      return '';
  }

  const getAttendCount = (player) => {
    let atts = attends;
    let lastDate = lastday(sToday.getFullYear(), sToday.getMonth());
    let cnt = 0;
    for(let i=1;i<=lastDate;i++){
      let key = sToday.getFullYear()+"-"+("0"+(sToday.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
      let att_data = atts[key];
      let v = [];
      if(att_data)
        v = att_data.split(',');
      let idx = v.indexOf(""+player.id);
      if( idx > -1){
        cnt++;
      }
    }
    return cnt;
  }

  const renderItemData_1 = (player, idx, provided, snapshot) => {
    return (
      <div key={idx} className="py-1 col-12 d-flex px-0 border-bottom">
        <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center" onClick={(e) => onSelectPlayer(e, player)}>
          <img className={"player_icon " + getValueEffClass(player, 'img')} src={loadUserImg(player)}/>
        </Col>
        <Col className="col-7 justify-content-between px-2 d-flex align-items-center" onClick={(e) => onSelectPlayer(e, player)}>
          <Col className={"px-0 " + getValueEffClass(player, 'value')}>
            {player.name}({getAttendCount(player)})
          </Col> 
            {
              formatCheckData1(player)
            }             
        </Col>
        <Col className="col-3 chartList px-0">
          <i className="fas fa-calendar-alt" onClick={(event) => showChart(player, event)} style={{marginRight:'5px',paddingLeft: '7px', paddingRight: '7px'}}></i>
          <i className="fas fa-list" onClick={(event) => showList(player, event)}></i>   
        </Col>
      </div>
    )
  }

  const renderItemData1 = (player, idx) => {
    return renderItemData_1(player, idx);
  }

  const onDragStart = (a) => {
    setDragPadding({paddingBottom:'70px'});
  }

  const onDragEnd = (result) => {
    if(!result.source || !result.destination)
      return;
    setDragPadding({paddingBottom:'15px'});
    const newPlayers = Array.from(players);
    const [removed] = newPlayers.splice(result.source.index, 1);
    newPlayers.splice(result.destination.index, 0, removed);
    for(let i=0;i<newPlayers.length;i++){
      newPlayers[i].ord = i+1;
    }
    setPlayers(newPlayers);
    axios.post(URL_API+"UpdatePlayersOrd", {
      params: {
        newPlayers:newPlayers,
        // seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
        user: se['id'],
      }
    })
    .then(function (response) {
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
    // console.log(newPlayers);
  };

  useEffect(() => {    
    // add new codes diaryData202201
    if(window.sessionStorage.getItem('attends') && window.sessionStorage.getItem('attends') != 'undefined')
      setAttends(JSON.parse(window.sessionStorage.getItem('attends'))); 

    axios.get(URL_API+"getMyPlayersAttAPI", {
      params: {
        seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
        user: se['id'],
      }
    })
    .then(function (response) {      
      window.sessionStorage.setItem('attends', JSON.stringify(response.data));

      setAttends(response.data);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 

  }, [today]);
  
  // console.log(renderData);
  return (
    <>
      <DatePicker
        dateConfig={dateConfig}
        value={sToday}
        isOpen={modalIsOpen}
        onSelect={selectDate}
        onCancel={handleCancel} />

      <div className="content px-0 pb-0">
        <Row className="px-0 mx-0">
          <Col md="12" className="px-0">
            <Card className="mb-0">
            <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
              <div className="d-flex col-12 px-0">
                <Col className="col-2 px-0 d-flex justify-content-center align-items-center" style={{cursor:'pointer'}} onClick={() => openModal('ymd', '')}>
                  <b style={{fontSize:'40px'}}>{todayDate}</b>
                </Col>
                <Col className="col-5 d-flex align-items-center justify-content-start px-0" style={{fontSize:'16px', fontWeight:'bold'}} onClick={() => openModal('ymd', '')} >
                    <div style={{fontSize:'0.8rem'}}>
                        <Col className="col-12 px-0">
                          {todayVDate}
                        </Col>
                        <Col className="col-12 px-0">
                          {todayDay}
                        </Col>
                    </div>                    
                </Col>
                <div className="col-5 justify-content-end d-flex align-items-center pr-3">
                </div>
              </div>
            </CardBody>

            <CardBody className="px-1 attend_list" style={dragPadding}>              
              <div className="py-1 col-12 d-flex px-0 border-bottom" onClick={(event) => showEvaluation(event)}>
                <Col className="col-9 d-flex align-items-center justify-content-start px-0 pl-3 fill-value" style={{fontSize:'18px', fontWeight:'bold'}}>
                    選手評価      
                </Col>
                <Col className="col-3 d-flex align-items-center justify-content-end pl-0 pr-3">
                  <i className="fas fa-chevron-right" style={{fontSize:'20px', color:'rgb(3, 115, 202)'}}></i>
                </Col>
              </div>
              <div className="py-1 col-12 d-flex px-0 border-bottom" onClick={(event) => showStatics(event)}>
                <Col className="col-9 d-flex align-items-center justify-content-start px-0 pl-3 fill-value" style={{fontSize:'18px', fontWeight:'bold'}}>
                    統計      
                </Col>
                <Col className="col-3 d-flex align-items-center justify-content-end pl-0 pr-3">
                  <i className="fas fa-chevron-right" style={{fontSize:'20px', color:'rgb(3, 115, 202)'}}></i>
                </Col>
              </div>
              {
                players.map((player, index) => (
                    renderItemData1(player, index)
              ))}
            </CardBody>
            </Card>
          </Col>          
        </Row>
      </div>
    </>
  );
}

export default Attend;