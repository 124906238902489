import React, { Component } from 'react';
import { render } from 'react-dom';

import {useState, useEffect } from "react";

import "assets/css/list.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import { useHistory } from 'react-router-dom';
import DatePicker from 'react-mobile-datepicker';



let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}
function checkTimeNum(t, f){
    if(Number(t) > f)
      return f;
    else
      return Number(t);
}

function DiaryList6(){

	let session = JSON.parse(window.sessionStorage.getItem('sessions'));
	if(!session)
		window.location = '/';
	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const history = useHistory();

	var date = new Date(); 
	const [flag, setFlag] 						= useState(0);
	const [todayDate, setTodayDate] 			= useState((new Date(sessionStorage.getItem('today'))).getDate());

	const [seriesName, setSeriesName] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['title'] + "("+JSON.parse(sessionStorage.getItem('selectedItem'))['i_unit']+")" || '');
	const [fname, setFName] 					= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['id'] || 0);

	const [chart_logo, setChart_logo] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['i_icon'] || '');

	const [showDate, setShowDate] 				= useState();
	const [keyData, setKeyData] 				= useState(1);

	const [isModal, setIsModal] 				= useState('hide');
	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	const [sToday, setSToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	
	const [getKey, setGetKey] = useState((new Date(sessionStorage.getItem('today'))).getFullYear()+"-"+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+"-"+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));

	const [data_list, setDataList] = useState([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [dateType, setDateType] = useState('ymd');
	const [dateConfig, setDateConfig] = useState({
                'year': {
                    format: 'YYYY',
                    caption: 'Year',
                    step: 1,
                },
                'month': {
                    format: 'MM',
                    caption: 'Mon',
                    step: 1,
                }
            });

	const setStateDatas = (d_datas) => {
		// setDiaryData(d_datas);
		let datas = [];
		let days = lastday(today.getFullYear(), today.getMonth());
		// for (let i in diaryData) {
		// let keys = [];
		// console.log(today.getFullYear(), today.getMonth());
		for(var i=1;i<=days;i++){
			let key = today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2);
			let d = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+i);
			// console.log(d.getDay());
			if(d_datas[key] && d_datas[key][fname]){
				// let ts = d_datas[key][fname].start.split(':');
				let ts = [];
				if(d_datas[key][fname].start){
					ts = d_datas[key][fname].start.split(':');
				}
				if(isNaN(Number(ts[0])))
					ts[0] = 0;
				if(isNaN(Number(ts[1])))
					ts[1] = 0;
				if(isNaN(Number(ts[2])))
					ts[2] = 0;

				ts[0] = checkTimeNum(ts[0], 23);
			    ts[1] = checkTimeNum(ts[1], 59);
			    ts[2] = checkTimeNum(ts[2], 59);
				let v = ("0"+ts[0]).slice(- 2)+":"+("0" + ts[1]).slice(-2)+"`"+("0" + ts[2]).slice(-2);

				datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:v, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
			}else
				datas.push({'dtitle':i+' '+ weekday[d.getDay()], key:key, w:d.getDay(), v:'00:00`00', y:today.getFullYear(), m:(today.getMonth()+1), d:i});
		}
		// console.log(datas);
		setDataList(datas);
	}	


	function openModal(f) {
	    setIsOpen(true);
	    setDateType(f);
	    if(f == 'ymd'){
	      	setDateConfig({
                'year': {
                    format: 'YYYY',
                    caption: 'Year',
                    step: 1,
                },
                'month': {
                    format: 'MM',
                    caption: 'Mon',
                    step: 1,
                }
            });
	    }
	  }

	const showChart = (e) => {
	    // sessionStorage.setItem("today", today);
	    if(session.userkind == 'coach')
	    	history.push('../coach/diaryChart6');
	    else
	    	history.push('../admin/diaryChart6');
	};

	const toPage = (e, pname) => {
		if(session.userkind == 'coach' && pname == 'diary'){
			history.push('../coach/'+pname);
			return;
		}
		history.push('../admin/'+pname);
	}

	const setDataInput = (e, item) => {
		if(session.userkind == 'coach')
			return;
		var v = item.v.replace('`',':');
		var key = item.key;
		setTodayDate(item.d);
		var tods = new Date(item.y+"-"+item.m+"-"+item.d+" "+v);
		console.log(item.y+"-"+item.m+"-"+item.d+" "+v);
		setDateConfig({
	        'hour': {
	          	format: 'hh',
	          	caption: 'Hour',
	          	step: 1,
	        },
	        'minute': {
	          	format: 'mm',
	          	caption: 'Min',
	          	step: 1,
	        },
        	'second': {
          		format: 'ss',
          		caption: 'Sec',
          		step: 1,
        	}
	    });
	    setDateType('hm');
		setSToday(tods);
		setKeyData(key);
		setIsOpen(true);
	};

	const selectDate = (time) => {
	    if(dateType == 'ymd'){
	      setToday(time);
	      setSToday(time);
	      setTodayDate(time.getDate());
	      setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	      setGetKey(time.getFullYear()+'-'+("0"+(time.getMonth()+1)).slice(-2)+'-'+("0" + time.getDate()).slice(-2));
	    }else{
	      	var h = time.getHours();
	      	var m = time.getMinutes();
	      	var s = time.getSeconds();
	      	// console.log(timeFname, h, m);
	      	var t = ("0"+h).slice(-2)+":"+("0" + m).slice(-2)+":"+("0" + s).slice(-2);
	      	var tods = new Date(sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate()+" "+t);
	      	// console.log(dateConfig);
	      	setSToday(tods);
	      	// setVData(vData1);
	     //  	let session = window.sessionStorage.getItem('sessions'); 
	    	// let se = JSON.parse(session);

	      	axios.get(URL_API+"setDiaryItemData", {
		      	params: {
		        	seldate: sToday.getFullYear()+"-"+(sToday.getMonth()+1)+"-"+sToday.getDate(), 
		        	user: session['id'],
		        	item_id: fname,
		        	v:{start:t}
		      	}
		    })
		    .then(function (response) {
		      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
		  		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
		      	setStateDatas(response.data.data);
		    }).catch(
		      	error => {
		        	if(error.response){
		          		console.log(error.response.data);
		        	};
		      	}
		    ); 
	    }
	    setIsOpen(false);
	    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	  }

	const handleCancel = () => {
	    setIsOpen(false);
	}

	useEffect(() => {
		if(!session){
	      	window.location = '/';
	    }

	   	// console.log(chart_title, chart_field_name, chart_intro_img);
	   	if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
	   		// setDiaryData(JSON.parse(window.sessionStorage.getItem('diaryData')));
			setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   	}

	   	let p = session;
	   	if(session.userkind == 'coach'){
			p = JSON.parse(window.sessionStorage.getItem('player')); 
			if(!p){
			  p = JSON.parse(window.sessionStorage.getItem('players'))[0];
			}
		}
   		axios.get(URL_API+"getDiaryAPI", {
			params: {
				seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
				user: p['id'],
			}
	    })
	    .then(function (response) {
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
      		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
	      	setStateDatas(response.data.data);
	    }).catch(
	      	error => {
	        	if(error.response){
	          		console.log(error.response.data);
	        	};
	      	}
	    );
  	}, [today]);
	return (
		<>		
			<DatePicker
		        dateConfig={dateConfig}
		        value={sToday}
		        isOpen={modalIsOpen}
		        onSelect={selectDate}
		        onCancel={handleCancel} />	

			<div className="content px-0">
        		<Row className="px-0 mx-0">
          			<Col md="12" className="px-0">
            			<Card>
							<CardBody className="seldate" id='seldate' style={{color:'white'}}>
				                <Row>
					            	<Col className="col-2 d-flex justify-content-center align-items-center">
					            		<i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toPage(event, 'diary')}></i>
					            	</Col>
				                  	<Col className="col-8 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ymd','', '')} >
				                    	{todayVDate}
				                 	</Col>
					             	<Col className="col-2 d-flex justify-content-center">
					             	</Col>
				                </Row>
				            </CardBody>   
				            <div className="chart_body">
				            	<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center">
									<div className="col-9 px-0 d-flex align-items-center">
										<div className="d-flex col-3 px-0 justify-content-center align-items-center">
											<img src={chart_logo} className="chart_list_logo"/>
										</div>
										<div className="col-9 px-0 justify-content-start align-items-center">
											{seriesName}
										</div>
									</div>
									<div className="d-flex col-3 px-0 justify-content-center align-items-center chartList">
										<i className="fas fa-chart-line" style={{marginRight:'5px'}} onClick={(event) => showChart(event)}></i>
          								<i className="fas fa-list"></i>
									</div>
								</div>
							</div>
							<div className="content_body">					
							{
								data_list.map((item, i) => 
									<div key={i} className="d-flex justify-content-between px-3 border-bottom py-2 font-weight-bolder pointer fa-lg" onClick={(event) => setDataInput(event, item)}>
										<div className = {(item.w==0 ? 'sun' : (item.w == 6 ? 'sat' : '')) + ' d-flex align-items-center'}>{item.dtitle}</div>
										<div className="d-flex align-items-center" style={{color:'rgb(65, 150, 23)'}}>{item.v}</div>
									</div>
								)
							}
							</div>
						</Card>
					</Col>
				</Row>
			</div>
      	</>
    )
}

export default DiaryList6;