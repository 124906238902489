/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import winImg from "assets/img/win.png";
import loseImg from "assets/img/lose.png";
import { useHistory } from 'react-router-dom';
// reactstrap components
import NotificationAlert from "react-notification-alert";
import {  
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
} from "reactstrap";


let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let session;
let notificationAlert;
let se;
function Tables() {
  
  notificationAlert = React.useRef();  
  
  const history = useHistory();

  const [a_proposer, s_setProposerData] = useState([]);
  //const [a_opponent, s_setOpponentData] = useState([]);

  const getViewpageUrl = (tmp) => {
    window.sessionStorage.setItem('selTables', JSON.stringify(tmp));
    history.push('../sub/tablesedit');
  }

  useEffect(() => {

    session = window.sessionStorage.getItem('sessions'); 
    if(session === null){
      window.location = '/';
    }
    se = JSON.parse(session);
    
    s_setProposerData(JSON.parse(window.sessionStorage.getItem("a_proposer")));
    axios.get(URL_API+"getPreparaAfterListAPI", {
      params: {
        sel: se['id'], 
      }
    })
    .then(function (response) {
    
      if(response.data != ""){
        s_setProposerData(response.data);
        window.sessionStorage.setItem('a_proposer', JSON.stringify(response.data));
      }

    }); 
    
    // s_setOpponentData(JSON.parse(window.sessionStorage.getItem("a_opponent")));
    // axios.get(URL_API+"getOpponentAfterListAPI", {
    //   params: {
    //     sel: se['id'], 
    //   }
    // })
    // .then(function (response) {
      
    //   if(response.data != ""){
    //     s_setOpponentData(response.data);
    //     window.sessionStorage.setItem('a_opponent', JSON.stringify(response.data));
    //   }
    // }); 

  }, []);
 

  return (

    <>
      <div className="content px-0">
        <NotificationAlert ref={notificationAlert} />
        <Row className="px-0 mx-0">          
          <Col className="col-12 px-0">
            <Card className="card-user">              
              <CardBody>
                <Form>
                  <Row>
                    <CardHeader>
                      <CardTitle tag="h6">トーナメントリスト</CardTitle>                
                    </CardHeader>
                    <Col md="12">                                         
                      {
                        a_proposer?.map((tmp, index) => 

                        <Row key={index} style={{borderBottom:'dotted 1px #ccc', padding:'5px'}}  onClick={() => getViewpageUrl(tmp)} val={tmp.id}> 
                          <Col className="col-1" style={{color:'rgb(3, 115, 202)', fontSize:'16px', padding:'0px'}}>
                            <img src={tmp.winflag == 1? winImg : loseImg}  width="25px"/>
                          </Col>                        
                          <Col className="col-3" style={{color:'rgb(3, 115, 202)', fontSize:'16px', padding:'0px'}}>
                            <b>{tmp.tournament}</b>
                          </Col>
                          <Col className="col-3" style={{color:'rgb(3, 115, 202)', fontSize:'14px', padding:'0px'}}>
                            {tmp.tourdate}   
                          </Col>
                          <Col className="col-3" style={{color:'rgb(3, 115, 202)', fontSize:'14px', padding:'0px'}}>
                          {tmp.proposergroup} {tmp.proposername}
                          </Col>
                          <Col className="col-2" style={{color:'#FF0000', fontSize:'18px', padding:'0px'}}>
                            <b>{tmp.mark}</b>
                          </Col>
                        </Row>
                        )
                      } 
                    </Col>
                  </Row>
                  {/* <Row>
                    <CardHeader>
                      <CardTitle tag="h6">トーナメントリスト</CardTitle>                
                    </CardHeader>
                    <Col md="12">                                         
                      {
                        a_opponent?.map((tmp, index) => 

                        <Row key={index} style={{borderBottom:'dotted 1px #ccc', padding:'3px'}}  onClick={() => getViewpageUrl(tmp)} val={tmp.id}> 
                          <Col className="col-2" style={{color:'#18258c', fontSize:'16px', paddingRight:'0px'}}>
                            <img src={tmp.winflag == 1? winImg : loseImg}  width="25px"/>
                          </Col>                        
                          <Col className="col-3" style={{color:'#18258c', fontSize:'16px', padding:'0px'}}>
                            <b>{tmp.tournament}</b>
                          </Col>
                          <Col className="col-3" style={{color:'#419617', fontSize:'14px', padding:'0px'}}>
                            {tmp.tourdate}   
                          </Col>
                          <Col className="col-3" style={{color:'#419617', fontSize:'14px', padding:'0px'}}>
                          {tmp.proposergroup} {tmp.proposername}
                          </Col>
                          <Col className="col-1" style={{color:'#FF0000', fontSize:'14px', padding:'0px'}}>
                            {tmp.mark}
                          </Col>
                        </Row>
                        )
                      } 
                    </Col>
                  </Row>                                  */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
