/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


list

*/
import React, {useState, useEffect } from "react";
import DatePicker from 'react-mobile-datepicker';
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from 'react-modal';
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

// add new codes
import { useHistory } from 'react-router-dom';
import dataTypes from "views/diary/dataType.js";

import "assets/css/diary.css";
// end

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
var aTimeout = null;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0.25rem',
    width:'85%',
    borderRadius:'15px',
    fontWeight:'bold'
  },
};
function Evaluation() {

  let session = window.sessionStorage.getItem('sessions');
  let se = JSON.parse(session);
  if(!se){
    window.location = '/';
  }  
  
  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

  var date = new Date();  
  const [aPlayers, setAPlayers] =useState([]);
  if(!window.sessionStorage.getItem('today'))
    window.sessionStorage.setItem('today', date);
  const [todayDate, setTodayDate] =  useState(date.getDate());
  const [todayDay, setTodayDay] = useState(weekday[date.getDay()]);
  const [todayVDate, setTodayVDate] =  useState(month[date.getMonth()]+" "+date.getFullYear());
  const [today, setToday] =  useState(date);
  const [sToday, setSToday] =  useState(date);
  const [uFlag, setUFlag] =  useState(true);

  const [getKey, setGetKey] = useState(date.getFullYear()+"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2));
  const [dragPadding, setDragPadding] = useState({paddingBottom:'15px'});
  const [player, setPlayer] = useState({});
  const [pname, setPname] = useState('');
  const [dateConfig, setDateConfig] = useState({
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: 'MM',
        caption: 'Mon',
        step: 1,
    },
    'date': {
        format: 'DD',
        caption: 'Day',
        step: 1,
    }
  });

  const [modalIsOpen, setIsOpen] = useState(false);
  const [cmt, setComment] = useState('');
  const [isShowCmt, setIsShowCmt] = useState('');
  const [isEditCmt, setIsEditCmt] = useState(false);
  const [items, setItems] = useState([]);
  // const [players, setPlayers] = useState(JSON.parse(window.sessionStorage.getItem('players')));
  const history = useHistory();

  function openModal(f, fname) {
    setIsOpen(true);
  }

  const selectDate = (time) => {
    setToday(time);
    setSToday(time);
    setTodayDate(time.getDate());
    setTodayDay(weekday[time.getDay()]);
    setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
    setGetKey(time.getFullYear()+"-"+("0"+(time.getMonth()+1)).slice(-2)+"-"+("0" + time.getDate()).slice(-2));
    window.sessionStorage.setItem('today', time);
    setIsOpen(false);
    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  const sendServerData = (v) => {    
    axios.get(URL_API+"setPlayerAttendData", {
      params: {
        todayDate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
        c_id: se['id'],
        content:v
      }
    })
    .then(function (response) {
      // window.sessionStorage.setItem('attends', JSON.stringify(response.data.data));
      // setAttends(response.data.data);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
  }

  const loadUserImg = (player) => {
    if(player.profile_photo_path){
      return "https://xs458763.xsrv.jp/uploads/" + player.profile_photo_path;
    }else{
      return require("assets/img/mike.jpg").default;
    }
  }

  const renderStarData = (item, num) => {
    
    // console.log(item);
    let dArr = [];
    for(let i=1;i<=num;i++){
      dArr.push({n:i, sel:(i<=item ? 'gold':'noGold')});
    }
    // setRenderData(rd);
    return dArr;
  }

  const onReSetStar = (e, player, item, n) => {
    let result = {};
    if(player.item && player.item['item_value']){
      result = JSON.parse(player.item['item_value']);
      if(typeof result == 'object' && result != {}){
        if(result[item.id] && result[item.id] == 1 && result[item.id] == n)
          result[item.id] = 0;
        else
          result[item.id] = n;

      }else{
        result = {};
        result[item.id] = n;
      }
    }else{
      result[item.id] = n;
    }

    axios.get(URL_API+"setMyPlayersOAPI", {
      params: {
        todayDate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
        c_id :se['id'], 
        user: player['id'],
        key:'item_value',
        v:result        
      }
    })
    .then(function (response) {  
      window.sessionStorage.setItem('aPlayers', JSON.stringify(response.data));
      setUFlag(!uFlag);
      setAPlayers(response.data);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
  }

  const renderItem = (player, item) => {
    // console.log(p, k);
    // let item = JSON.parse(player.item['item_value']);
    // if(item_value && item_value[item.id])
    if(player.item && player.item['item_value']){
      let item_value = JSON.parse(player.item['item_value']);
      if(item_value && (typeof item_value == 'object') && item_value != {}){
        item_value[item.id] = isNaN(Number(item_value[item.id])) ? 0 : item_value[item.id];
        return (
          renderStarData(item_value[item.id], 5).map((sItem, i) => 
            <i key={i} className={'fa fa-star p-0 '+ sItem.sel} onClick={(event) => onReSetStar(event, player, item, sItem.n)}></i>
          )
        )
      }
    }else{
      return (
      <>
        <i className={'fa fa-star p-0 noGold'} onClick={(event) => onReSetStar(event, player, item, 1)}></i>
        <i className={'fa fa-star p-0 noGold'} onClick={(event) => onReSetStar(event, player, item, 2)}></i>
        <i className={'fa fa-star p-0 noGold'} onClick={(event) => onReSetStar(event, player, item, 3)}></i>
        <i className={'fa fa-star p-0 noGold'} onClick={(event) => onReSetStar(event, player, item, 4)}></i>
        <i className={'fa fa-star p-0 noGold'} onClick={(event) => onReSetStar(event, player, item, 5)}></i>
      </>
      )
    }
  }

  const onSaveCmt = (e) => {
    setIsEditCmt(false);
    axios.get(URL_API+"setMyPlayersOAPI", {
      params: {
        todayDate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
        c_id : se['id'], 
        user : player['id'],
        key  : 'comment',
        v    : cmt        
      }
    })
    .then(function (response) {  
      window.sessionStorage.setItem('aPlayers', JSON.stringify(response.data));
      setUFlag(!uFlag);
      setAPlayers(response.data);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
  }

  const onChangeComment = (e) => {
    setComment(e.target.value);
  }

  const onEnableEdit = (e, p) => {
    let comment = "";
    if(p.item && p.item['comment']){
      comment = p.item['comment'];
    }
    setPlayer(p);
    setIsEditCmt(true);
    setComment(comment);
    setPname(p.name);
  }

  const formatCheckData2 = (p) => {
    let comment = "";
    if(p.item && p.item['comment']){
      comment = p.item['comment'];
    }
    return (
      <>
        <div className=' col-12 cmtText' onClick={(e) => onEnableEdit(e, p)}>{comment}</div>        
      </>
    )
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsEditCmt(false);
  }

  const formatCheckData1 = (player) => {
      // console.log(items);
      return items.map((item, index) => (
        <div key={index} className="d-flex py-1">
          <div className="col-7 pl-0 pr-1">{item.title}</div>
          <div className="col-5 px-0 d-flex justify-content-between">
            {
              renderItem(player, item)
            }
          </div>
        </div>
      ))
  }

  const renderItemData1 = (player, idx) => {
    return (
      <div key={idx} className="border-bottom my-3">
        <div className="py-1 col-12 d-flex px-0">
          <Col className="col-2 px-0 py-0 d-flex justify-content-center align-items-center">
            <img className="player_icon" src={loadUserImg(player)}/>
          </Col>
          <Col className="col-3 justify-content-start pl-2 pr-0 d-flex align-items-center">
              {player.name}
          </Col>
          <Col className="col-7 px-0 evalItems">          
            {
              formatCheckData1(player)
            }             
          </Col>
        </div>
          {
            formatCheckData2(player)
          }  
      </div>
    )
  }

  const goToPage = (pname) =>{
    history.push('../coach/evaluationItem');
  }

  useEffect(() => {    
    // add new codes diaryData202201
    if(window.sessionStorage.getItem('evalItems') && window.sessionStorage.getItem('evalItems') != 'undefined')
      setItems(JSON.parse(window.sessionStorage.getItem('evalItems'))); 

    if(window.sessionStorage.getItem('aPlayers') && window.sessionStorage.getItem('aPlayers') != 'undefined')
      setAPlayers(JSON.parse(window.sessionStorage.getItem('aPlayers'))); 

    axios.get(URL_API+"getEvalItemAPI", {
      params: {
        c_id: se['id'],
      }
    })
    .then(function (response) {  
      window.sessionStorage.setItem('evalItems', JSON.stringify(response.data));

      setItems(response.data);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 

    axios.get(URL_API+"getMyPlayersOAPI", {
      params: {
        todayDate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
        c_id: se['id'],
      }
    })
    .then(function (response) {  
      window.sessionStorage.setItem('aPlayers', JSON.stringify(response.data));

      setAPlayers(response.data);
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 

  }, [today]);
  
  // console.log(renderData);
  return (
    <>
      <DatePicker
        dateConfig={dateConfig}
        value={sToday}
        isOpen={modalIsOpen}
        onSelect={selectDate}
        onCancel={handleCancel} />
      <Modal
            isOpen={isEditCmt}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">

        <div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center border-bottom">
          <div className="col-12 px-0 d-flex align-items-center justify-content-center">
            {pname}
          </div>
        </div>
        <div className="col-12 px-1">
          <textarea className='col-12 cmtInput' value={cmt} onChange={(e) => onChangeComment(e)}></textarea>
        </div>
        <div className="col-12 px-0 mx-0 d-flex justify-content-between align-items-center">
          <span className="col-6 py-2 px-0 d-flex justify-content-center align-items-center" onClick={(event) => setIsEditCmt(false)}>キャンセル</span>
          <span className="col-6 py-2 px-0 d-flex justify-content-center align-items-center border-left" onClick={(event) => onSaveCmt(event)}>保存</span>
        </div>
      </Modal>

      <div className="content px-0 pb-0">
        <Row className="px-0 mx-0">
          <Col md="12" className="px-0">
            <Card className="mb-0">
            <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
              <div className="d-flex col-12 px-0">
                <Col className="col-2 px-0 d-flex justify-content-center align-items-center" style={{cursor:'pointer'}} onClick={() => openModal('ymd', '')}>
                  <b style={{fontSize:'40px'}}>{todayDate}</b>
                </Col>
                <Col className="col-5 d-flex align-items-center justify-content-start px-0" style={{fontSize:'16px', fontWeight:'bold'}} onClick={() => openModal('ymd', '')} >
                    <div style={{fontSize:'0.8rem'}}>
                        <Col className="col-12 px-0">
                          {todayVDate}
                        </Col>
                        <Col className="col-12 px-0">
                          {todayDay}
                        </Col>
                    </div>                    
                </Col>
                <div className="col-5 justify-content-end d-flex align-items-center pr-3">
                  <i className='fa fa-tools p-1' style={{fontSize:'26px', cursor:'pointer', color:'white'}} onClick={() => goToPage('evaluationItem')}></i>
                </div>
              </div>
            </CardBody>

            <CardBody className="px-2 attend_list" style={dragPadding}>              
              {
                aPlayers.map((player, index) => (
                    renderItemData1(player, index)
              ))}
            </CardBody>
            </Card>
          </Col>          
        </Row>
      </div>
    </>
  );
}

export default Evaluation;