/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Row, Col} from "reactstrap";

// reactstrap components
import NotificationAlert from "react-notification-alert";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let notificationAlert;
let se;
var aTimeout = null;
function ApList() {

  let session = JSON.parse(window.sessionStorage.getItem('sessions')); 
  notificationAlert = React.useRef();  
  const history = useHistory();  
  const [p_aphorism, setPAhorism] = useState(JSON.parse(window.sessionStorage.getItem('p_aphorism')) || null);
  const [uFlag, setUFlag] = useState(0);

  const [aphorisms, setAphorisms] = useState([]);

  const addAph = (e) => {
    window.sessionStorage.setItem('newFlag', 1);
    // window.sessionStorage.setItem('pId', p_aphorism.id);
    history.push('../coach/aphorismsEdit1');
  }

  const onSelectItem = (e, aphorism) => {
    if(aTimeout)
      clearTimeout(aTimeout);
    window.sessionStorage.setItem('p_aphorism', JSON.stringify(aphorism));
    setPAhorism(aphorism);
    setUFlag((c) => c + 1);
    let d = window.sessionStorage.getItem('catg_deep');
    if(d < 2)
      window.sessionStorage.setItem('catg_deep', d+1);
    // history.push('../coach/apList');
  }
  const onEditSelectedItem = (e, item) => {
    aTimeout = setTimeout(function(){
      window.sessionStorage.setItem('s_aphorism', JSON.stringify(item));
      window.sessionStorage.setItem('newFlag', 0);
      history.push('../coach/aphorismsEdit1');
    }, 1000);
  }
  const onEditSelectedItem1 = (e, item) => {
    if(window.mobileCheck())
      return;
    onEditSelectedItem(e, item);
  }

  const onUpPage = (e) => {
    if(p_aphorism.p_id == 0){
      history.push('../coach/aphorisms');
      return;
    }
    axios.get(URL_API+"getParentAPI", {
        params: {
          sel: session['id'], 
          p_id:p_aphorism.p_id,
          f:1
        }
      })
      .then(function (response) {     
        let data = response.data;
        window.sessionStorage.setItem('p_aphorism', JSON.stringify(response.data));     
        setPAhorism(response.data);
        setUFlag((c) => c + 1);
      }); 
    // history.push('../coach/aphorisms');
  }
  
  useEffect(() => {    
    if(session === null){
      window.location = '/';
    }
    if(p_aphorism){
      axios.get(URL_API+"getAphorismsAPI", {
        params: {
          sel: session['id'], 
          p_id:p_aphorism.id,
          f:1
        }
      })
      .then(function (response) {
        // console.log(response.data, response.data != "");
        if(Array.isArray(response.data)){
          setAphorisms(response.data);
        }
      }); 
    }
  }, [uFlag]);
  

  return (
    <>
      <div className="content px-0 pb-0">
        <NotificationAlert ref={notificationAlert} />
        <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
          <div className="d-flex col-12 px-2">
            <div className="col-2 col-lg-1 d-flex align-items-center px-0">
              <i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => onUpPage(event)}></i>
            </div>
          </div>
        </CardBody>
        <Card className="mb-0">
            <div className="text-right d-flex align-items-center pr-2 justify-content-end" style={{height:'50px'}}>
              <span className="p-2" onClick={(event) => addAph(event)}><i className="fas fa-plus add_s_items"></i></span>
            </div>   
            <CardBody className="px-1 aph_list">
              <div className="col-12">
              {
                aphorisms?.map((tmp, index) =>
                                
                <div key={index} className="col-12 border-bottom py-2" onClick={(e) => onSelectItem(e, tmp)} onTouchStart = {(event) => onEditSelectedItem(event, tmp)} onMouseDown = {(event) => onEditSelectedItem1(event, tmp)}>                                         
                  {tmp.content}
                </div>
                    
              )}
              </div>
            </CardBody>
          </Card>
      </div>
    </>
  );
}

export default ApList;
