import React, { Component } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { render } from 'react-dom';
// import HighchartsReact from 'highcharts-react-official';
// import Highcharts from 'highcharts';
import {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import "assets/css/diary.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function AttendChart(){

	let session = JSON.parse(window.sessionStorage.getItem('sessions'));
	if(!session)
		window.location = '/';
	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const [flag, setFlag] 						= useState(0);
	const [attends, setAttends] = useState({});
	const [data, setData] 						= useState([]);
	const [todayDate, setTodayDate] 			= useState();
	const player = JSON.parse(sessionStorage.getItem('selectedPlayer'));

	// const [seriesName, setSeriesName] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['title'] || '');
	// const [fname, setFName] 					= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['id'] || 0);
	const [chartType, setChartType] 			= useState('line');
	// const [chart_logo, setChart_logo] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['i_icon'] || '');

	const [xData, setXData] 					= useState(1);
	const [yData, setYData] 					= useState(0);

	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [modalIsOpen, setIsOpen] 				= React.useState(false);
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	const [total, setTotal] = useState(0);
	// const [getKey, setGetKey] = useState((new Date(sessionStorage.getItem('today'))).getFullYear()+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));
	const [dateConfig, setDateConfig] = useState({
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        },
        'month': {
            format: 'MM',
            caption: 'Mon',
            step: 1,
        }
    });

	const history = useHistory();

	const loadUserImg = () => {
	    if(player.profile_photo_path){
	      return "https://xs458763.xsrv.jp/uploads/" + player.profile_photo_path;
	    }else{
	      return require("assets/img/mike.jpg").default;
	    }
	}

	const openModal = (f) => {
	    setIsOpen(true);
	}	

	const selectDate = (time) => {
	    setToday(time);
	    setTodayDate(time.getDate());
	    setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	    window.sessionStorage.setItem('today', time);
	    setIsOpen(false);
	}

	const handleCancel = () => {
	    setIsOpen(false);
	}

	const onShowChart = (f) => {
		setFlag(f);
	}

	const showList = (e) => {
		history.push('../coach/attendList');
	};

	const toPage = (e, pname) => {
		history.push('../coach/'+pname);
	}

	const renderData = (d) => {
		let dlen = Object.keys(d).length;
		let colCss = 'col-12 px-3 mx-0 ';
		if(dlen != 1)
			colCss = 'col-6 px-2 mx-0 ';

		// console.log(d);
		let dhtml = '';
		for (let m in d) {
			let df = 0;

			dhtml += "<div class='"+colCss+" my-2 d-flex text-center' style='flex-wrap:wrap'>";
			if(dlen > 1){
				dhtml += "<div class='col-12 d-flex align-items-center justify-content-center' style='flex-wrap:wrap;font-weight:bold'>";
				dhtml += month[m-1];
				dhtml += "</div>";
			}
			for(let i=0;i<d[m].length;i++){
				let dt = new Date(today.getFullYear()+'-'+m+'-'+(i+1));
				let day = dt.getDay();
				if(df == 0){
					if(day != 0){
						df = 1;
						for(let j=0;j<day;j++){
							dhtml += '<div class="w-7 d-flex align-items-center justify-content-center" style="aspect-ratio:1">';
							dhtml += '<div class="w-90">';
							dhtml += '';
							dhtml += '</div>';
							dhtml += '</div>';
						}
					}else{
						df = 1;
					}
				}
				dhtml += '<div class="w-7 d-flex align-items-center justify-content-center" style="aspect-ratio:1">';
				dhtml += '<div class="w-90 d-flex align-items-center justify-content-center';
				if(d[m][i] == 1){
					dhtml += ' doneIt';
				}else if(d[m][i] == 2)
					dhtml += ' notDone';
				else
					dhtml += ' empty';
				dhtml += '" style="aspect-ratio:1;border-radius:50%">';
				dhtml += (i+1);
				dhtml += '</div>';
				dhtml += '</div>';
			}
			dhtml += "</div>";
		}
		// setTotal(tNum);
		return dhtml;
	}

	const setStateDatas = (d_datas) => {
		// var categs = [];
		var dats = {};
		let to_date = today;
		if(!isValidDate(to_date)){
			to_date = new Date();
			setToday(to_date);
			sessionStorage.setItem("today", to_date);
		}
		let y = to_date.getFullYear();
		let m =to_date.getMonth();
		let tNum=0;
		// console.log(flag, fname);
		if(flag == 0){
			let m =to_date.getMonth();
			let days = lastday(y, m);
			dats[m+1] = [];
			if(d_datas == null){
				for(var i=1;i<=days;i++){
					// categs.push((today.getMonth()+1)+'/'+i);
					dats[m+1].push(0);
				}
			}else{
				for(var i=1;i<=days;i++){
					let key = to_date.getFullYear()+"-"+("0"+(to_date.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);

					if(d_datas[key]){
						let content = d_datas[key].split(',');
						let v = content.indexOf(player.id+"") == -1 ? 0 : 1;
						dats[m+1].push(v);
						tNum += v;
					}else{
						dats[m+1].push(0);
					}
				}
			}
		}else if(flag == 1){
			let mon = m + 1;
			let m1 = mon % 3 == 0 ? mon - 2 : ( mon % 3 == 1 ? mon : mon - 1);
			let m2 = m1 + 1;
			let m3 = m1 + 2;
			let ms = [m1, m2, m3];
			if(d_datas == null){
				for(var i = 0; i<ms.length;i++){
					let days = lastday(y, ms[i]-1);
					dats[ms[i]] = [];
					for(var j=1;j<=days;j++){
						// categs.push(ms[i]+'/'+j);
						dats[ms[i]].push(0);
					}
				}
			}else{
				for(var i = 0; i<ms.length;i++){
					let days = lastday(y, ms[i]-1);
					dats[ms[i]] = [];
					for(var j=1;j<=days;j++){
						// categs.push(ms[i]+'/'+j);
						let key = to_date.getFullYear()+"-"+("0"+ms[i]).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							let content = d_datas[key].split(',');
							let v = content.indexOf(player.id+"") == -1 ? 0 : 1;
							dats[ms[i]].push(v);
							tNum += v;
						}else{
							dats[ms[i]].push(0);
						}
					}
				}
			}
		}else{
			if(d_datas == null){
				for(var i = 0; i<12;i++){
					let days = lastday(y, i);
					dats[i+1] = [];
					for(var j=1;j<=days;j++){
						// categs.push((i+1)+'/'+j);
						dats[i+1].push(0);
					}
				}
			}else{
				for(var i=0;i<12;i++){
					let days = lastday(y, i);
					dats[i+1] = [];
					for(var j=1;j<=days;j++){
						// categs.push((i+1)+'/'+j);
						let key = to_date.getFullYear()+"-"+("0"+(i+1)).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							let content = d_datas[key].split(',');
							let v = content.indexOf(player.id+"") == -1 ? 0 : 1;
							dats[i+1].push(v);
							tNum += v;
						}else{
							dats[i+1].push(0);
						}
					}
				}
			}
		}
		// console.log(dats);
		setTotal(tNum);
		setData(dats);
	}

	useEffect(() => {
		// console.log(typeof today);
		if(!session){
	      	window.location = '/';
	    }

	    if(window.sessionStorage.getItem('attends') && window.sessionStorage.getItem('attends') != 'undefined'){
      		setAttends(JSON.parse(window.sessionStorage.getItem('attends'))); 
      		setStateDatas(JSON.parse(window.sessionStorage.getItem('attends')));
	    }
  	}, [flag, today]);
	return (
		<>
			<DatePicker
		        dateConfig={dateConfig}
		        value={today}
		        isOpen={modalIsOpen}
		        onSelect={selectDate}
		        onCancel={handleCancel} />	

			<div className="content px-0 pb-0">
        		<Row className="mx-0 px-0">
          			<Col md="12" className="px-0">
            			<Card className="mb-0">
            				<CardBody className="seldate" id='seldate' style={{color:'white'}}>
					            <Row>
					            	<Col className="col-2 d-flex justify-content-center align-items-center">
					            		<i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toPage(event, 'attend')}></i>
					            	</Col>
					              	<Col className="col-8 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ymd')} >
					                	{todayVDate}
					             	</Col>
					             	<Col className="col-2 d-flex justify-content-center">
					             	</Col>
					            </Row>
					        </CardBody>   
							<div className="chart_body">
								<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center">
									<div className="col-9 px-0 d-flex align-items-center">
										<div className="d-flex col-3 px-0 justify-content-center align-items-center">
											<img src={loadUserImg()} className="chart_list_logo" style={{borderRadius:'50%'}}/>
										</div>
										<div className="col-8 px-0 justify-content-start align-items-center" style={{fontSize:'16px', fontWeight:'bold'}}>
											{player.name}
										</div>
										<div className="col-1" style={{fontSize:'16px'}}>
											{total}
										</div>
									</div>
									<div className="d-flex col-3 px-0 justify-content-center align-items-center chartList">
										<i className="far fa-calendar-alt" style={{marginRight:'5px',paddingLeft: '7px', paddingRight: '7px'}}></i>
          								<i className="fas fa-list" onClick={(event) => showList(event)}></i>
									</div>
								</div>
							</div>
							<div className="content_body1">
								<div className="row col-12 px-0 mx-0 py-1" dangerouslySetInnerHTML={{__html: renderData(data)}} />
							</div>
				      		<div style={{height:'40px'}}>
				      			<Row className="mx-0">
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==0?'active':'')} onClick={() => onShowChart(0)}>
				      					月
				      				</Col>
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==1?'active':'')} onClick={() => onShowChart(1)}>
				      					3か月
				      				</Col>
				      				<Col className={'col-4 col-md-4 py-2 text-center sFlag ' + (flag==2?'active':'')} onClick={() => onShowChart(2)}>
				      					年
				      				</Col>
				      			</Row>
				      		</div>
				      	</Card>
				    </Col>
				</Row>
			</div>
      	</>
    )
}

export default AttendChart;