/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect, useRef} from "react";
import Modal from 'react-modal';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
// reactstrap components
import NotificationAlert from "react-notification-alert";
import {  
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col  
} from "reactstrap";
import { useHistory } from 'react-router-dom';

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
// let URL_API = "https://xs458763.xsrv.jp/api/";

let session = {};
let notificationAlert;
let imgFile = "";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0.25rem',
    width:'85%',
    borderRadius:'15px',
    maxWidth:'1000px',
    fontWeight:'bold'
  },
};

function User() {

  notificationAlert = React.useRef(); 
  const history = useHistory();

  const [image, setImage] = useState(require("assets/img/mike.jpg").default);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const [modalIsOpen, setIsOpen]    = useState(false);

  if(session['name'] == undefined){
    session = JSON.parse(window.sessionStorage.getItem('sessions'));
  }
    

  const [name, setName] = useState(session['name']);  
  const getInputNameValue = (event)=>{    
    setName(event.target.value);     
    session['name'] = event.target.value;
    var d = JSON.parse(window.sessionStorage.getItem('sessions'));
    d['name'] = event.target.value;
    window.sessionStorage.setItem("sessions", JSON.stringify(d));    
  };

  let nowsex = true;
  if(session['sex'] == 0)nowsex = false;     
  const [sex, setSex] = useState(nowsex);

  const getInputSexValue = (event)=>{    
    setSex(event.target.checked);        
    let s = 0;
    if(event.target.checked == true)s = 1;   
    session['sex'] = s;  
  };

  let birthdaAry = session['birthday'] ? session['birthday'].split("-") : ['2002','01','01'];
  let bl = "";
  let old;
  if(birthdaAry.length > 2){
    // bl = birthdaAry[0]+"年"+birthdaAry[1]+"月"+birthdaAry[2]+"日生まれ";
    bl = birthdaAry[1]+"月"+birthdaAry[2]+"日生まれ";
    old = new Date().getFullYear() - birthdaAry[0];
  }
  const [oldLab, setOldLab] = useState(old);
  const [birthLab, setBirthLab] = useState(bl);

  const [birthday, setBirthday] = useState(session['birthday'] ? session['birthday'] : '2002-01-01');
  // setBirthday(session['birthday'] ? session['birthday'] : '2002-01-01');
  session['birthday'] = session['birthday'] ? session['birthday'] : '2002-01-01';
  const getInputBirthValue = (event)=>{
        
    setBirthday(event.target.value);
    session['birthday'] = event.target.value;
    birthdaAry = event.target.value.split("-");
    bl = birthdaAry[0]+"年"+birthdaAry[1]+"月"+birthdaAry[1]+"日生まれ";
    old = new Date().getFullYear() - birthdaAry[0];

    setOldLab(old);
    setBirthLab(bl);
  };

  const [hight, setHight] = useState(session['hight']);
  const getInputHightValue = (event)=>{    
    setHight(event.target.value);
    session['hight'] = event.target.value;  
  };

  const [weight, setWeight] = useState(session['weight']);
  const getInputWeightValue = (event)=>{    
    setWeight(event.target.value);
    session['weight'] = event.target.value;
  };

  const [school, setSchool] = useState(session['school']);
  const getInputSchoolValue = (event)=>{ 
    setSchool(event.target.value);  
    session['school'] = event.target.value;
  };

  const [grade, setGrade] = useState(session['grade']);
  const getInputGradeValue = (event)=>{    
    setGrade(event.target.value); 
    session['grade'] = event.target.value;
  };

  const [post_code, setPost] = useState(session['post_code']);
  const getInputPostValue = (event)=>{    
    setPost(event.target.value);
    session['post_code'] = event.target.value;
  };

  const [address, setAddress] = useState(session['address']);
  const getInputAddressValue = (event)=>{    
    setAddress(event.target.value);
    session['address'] = event.target.value;
  };

  const [number, setNumber] = useState(session['number']);
  const getInputNumberValue = (event)=>{    
    setNumber(event.target.value); 
    session['number'] = event.target.value;
  };

  const [lessonClass, setLessonClass] = useState(session['lessonClass']);
  const getLessonClassValue = (event)=>{    
    setLessonClass(event.target.value); 
    session['lessonClass'] = event.target.value;
  };

  const [portfolio, setPortfolio] = useState(session['portfolio'] || '');
  const portfolioUpdateFunc = (event)=>{    
    
    setPortfolio(event.target.value); 
    session['portfolio'] = event.target.value;
    
  };

  let numberLab = ['4回','8回','12回','16回','フリー'];


  const [p_proposer, s_setProposerData] = useState([]);
  const [p_opponent, s_setOpponentData] = useState([]);
  const [s_preparation, s_setPreparation] =  useState([]);
  const [s_win, s_setWin] = useState([]);
  const [s_battle, s_setBattle] = useState([]);
  const [s_ranking, s_setRanking] = useState([]);
  const [s_winrate, s_setWinrate] = useState([]);
  const [rank, setRank] = useState(0);

  useEffect(() => {
    
    if(session === null){      
      window.location = '/';
    }
    
    if(Array.isArray(JSON.parse(window.sessionStorage.getItem("s_battle"))))
      s_setBattle(JSON.parse(window.sessionStorage.getItem("s_battle")));  

    axios.get(URL_API+"getBattleAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {

      if(response.data.length > 0 && Array.isArray(response.data)){
        s_setBattle(response.data);
        window.sessionStorage.setItem('s_battle', JSON.stringify(response.data));
       
      }

    }); 

    axios.get(URL_API+"getnewbig", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {
      if(response.data && typeof response.data == 'object'){
        setPortfolio(response.data["target1"] +"<br>"+response.data["target2"]+"<br>"+response.data["target3"]);
      }
    }); 

    if(Array.isArray(JSON.parse(window.sessionStorage.getItem("s_winrate"))))
      s_setWinrate(JSON.parse(window.sessionStorage.getItem("s_winrate")));
    
    axios.get(URL_API+"getWinrateAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {

      if(response.data.length > 0 && Array.isArray(response.data)){
        s_setWinrate(response.data);
        window.sessionStorage.setItem('s_winrate', JSON.stringify(response.data));

        let winlose = {};
        winlose["lose"] = response.data[response.data.length - 1]["lose"];
        winlose["win"] = response.data[response.data.length - 1]["win"];
        s_setWin(winlose);
        window.sessionStorage.setItem('s_win', JSON.stringify(winlose));

      }

    }); 

    if(Array.isArray(JSON.parse(window.sessionStorage.getItem("s_ranking")))){
      s_setRanking(JSON.parse(window.sessionStorage.getItem("s_ranking")));

      let rankAry = JSON.parse(window.sessionStorage.getItem("s_ranking"),true);

      for(var i = 0; i < rankAry.length; i++){
        if(rankAry[i].title == "関東U18"){
          setRank(rankAry[i]["ranking"]);
        }
      }

    }
    
    axios.get(URL_API+"getRankingAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {

      if(response.data.length > 0 && Array.isArray(response.data)){
        s_setRanking(response.data);
        window.sessionStorage.setItem('s_ranking', JSON.stringify(response.data));

        let rankAry = JSON.parse(window.sessionStorage.getItem("s_ranking"),true);

        for(var i = 0; i < rankAry.length; i++){
          if(rankAry[i].title == "関東U18"){
            setRank(rankAry[i]["ranking"]);
          }
        }
      }

    }); 


    s_setWin(JSON.parse(window.sessionStorage.getItem("s_win")));

    if(s_win.win == undefined){
      s_setWin({"win":0, "lose":0});
    }

    // axios.get(URL_API+"getWinAPI", {
    //   params: {
    //     sel: session['id'], 
    //   }
    // })
    // .then(function (response) {
    
    //   //if(response.data.win > 0 && Array.isArray(response.data)){
    //     s_setWin(response.data);
    //     window.sessionStorage.setItem('s_win', JSON.stringify(response.data));
    //   //}

    // }); 

    s_setProposerData(JSON.parse(window.sessionStorage.getItem("p_proposer")));
    axios.get(URL_API+"getPreparaListAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {
    
      if(response.data.length > 0 && Array.isArray(response.data)){
        s_setProposerData(response.data);
        window.sessionStorage.setItem('p_proposer', JSON.stringify(response.data));
      }

    }); 
    
    s_setOpponentData(JSON.parse(window.sessionStorage.getItem("p_opponent")));
    axios.get(URL_API+"getOpponentListAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {
      
      if(response.data.length > 0 && Array.isArray(response.data)){
        s_setOpponentData(response.data);
        window.sessionStorage.setItem('p_opponent', JSON.stringify(response.data));
      }
    });  
    
    //サーブ
    s_setPreparation(JSON.parse(window.sessionStorage.getItem("s_preparation")));
    axios.get(URL_API+"getNowPreparaListAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {

      if(response.data.length > 0 && Array.isArray(response.data)){       
        s_setPreparation(response.data);       
        window.sessionStorage.setItem('s_preparation', JSON.stringify(response.data));
      }

    }); 

  }, []);
  
  const handleOnChange = (e, index) => {

    let tmp = [...s_preparation];
    tmp[index]['target'] = e.target.value;
    s_setPreparation(tmp);

    window.sessionStorage.setItem('s_preparation', JSON.stringify(s_preparation));
  };

  const battleAddFunc = (e) => {
    axios.get(URL_API+"addBattleAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {
  
      if(response.data.length > 0 && Array.isArray(response.data)){       
        s_setBattle(response.data);       
        window.sessionStorage.setItem('s_battle', JSON.stringify(response.data));
      }
  
    });
  }

  const battleDelFunc = (e, sel) => {
    axios.get(URL_API+"delBattleAPI", {
      params: {
        sel: session['id'], 
        id : sel,
      }
    })
    .then(function (response) {
  
      if(response.data.length > 0 && Array.isArray(response.data)){       
        s_setBattle(response.data);       
        window.sessionStorage.setItem('s_battle', JSON.stringify(response.data));
      }
  
    });
  }//rankingDelFunc
  const rankingDelFunc = (e, id) => {
    axios.get(URL_API+"delRankingAPI", {
      params: {
        sel: session['id'], 
        id : id,
      }
    })
    .then(function (response) {
  
      if(response.data.length > 0 && Array.isArray(response.data)){       
        s_setRanking(response.data);       
        window.sessionStorage.setItem('s_ranking', JSON.stringify(response.data));
      }
  
    });
  }

  const rankingAddFunc = (e) => {
    axios.get(URL_API+"addRankingAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {
  
      if(response.data.length > 0 && Array.isArray(response.data)){       
        s_setRanking(response.data);       
        window.sessionStorage.setItem('s_ranking', JSON.stringify(response.data));
      }
  
    });
  }
  const battleUpdateFunc = (e, index) =>{
    let battle = JSON.parse(window.sessionStorage.getItem('s_battle'));
    battle[index]["content"] = e.target.value;
    s_setBattle(battle);     
    window.sessionStorage.setItem('s_battle', JSON.stringify(battle));
  }

  const rankingTitleFunc = (e, index) =>{
    let ranking = JSON.parse(window.sessionStorage.getItem('s_ranking'));
    ranking[index]["title"] = e.target.value;
    s_setRanking(ranking);     
    window.sessionStorage.setItem('s_ranking', JSON.stringify(ranking));
  }
  
  const rankingRankingFunc = (e, index) =>{
    let ranking = JSON.parse(window.sessionStorage.getItem('s_ranking'));
    ranking[index]["ranking"] = e.target.value;
    s_setRanking(ranking);
    
    if(ranking[index].title == "関東U18"){
      setRank(e.target.value);
    }

    window.sessionStorage.setItem('s_ranking', JSON.stringify(ranking));

  }

  // const [selectedFile, setSelectedFile] = React.useState(null);
  const inputElement = useRef();
  const [selectedFile, setSelectedFile] = useState(session['profile_photo_path']);
  const [imgFile1, setImgFile1] = useState(''); 

  console.log(selectedFile);
  
  const handleSubmit = (e) => {
    setIsOpen(true);
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setImgFile1(cropper.getCroppedCanvas().toDataURL());
      imgFile = cropper.getCroppedCanvas().toDataURL();
    }
    setIsOpen(false);
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onSelectImage = (event) => {
    document.getElementById("photo").click();
  }

  return (

    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="row col-12 px-0 mx-0 py-2 justify-content-center align-items-center border-bottom">
          クロップ画像
        </div>
        <div className="row col-12 px-0 mx-0 py-4 justify-content-center align-items-center border-bottom">
          <Cropper
            style={{ height: 300, width: "100%" }}
            zoomTo={0.5}
            initialAspectRatio={1}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        </div>
        <div className="col-12 px-0 mx-0 d-flex justify-content-between align-items-center">
          <div
            className="img-preview"
            style={{ width: "80%", float: "left", height: "200px" }}
          />
        </div>
        <div>

          <button className="btn btn-primary" style={{ float: "right" }} onClick={getCropData}>
              クロップ画像
          </button>
        </div>
      </Modal>
      <div className="content px-0 pb-0">
        <NotificationAlert ref={notificationAlert} />
        <Row className="px-0 mx-0 ply-aph">
          <Col md="4" className="colp">
            <Card className="card-user mb-0">
              
              <CardBody>
                <Row>
                  <Col className="col-5 text-right" style={{position:'relative'}} >
                    <img
                      alt="..."
                      className="avatar border-gray my-0"                      
                      src={
                        imgFile1 !='' ? imgFile1 : (selectedFile ? "https://xs458763.xsrv.jp/uploads/"+selectedFile : require("assets/img/mike.jpg").default)}
                      onClick = {onSelectImage}
                    />
                    <i className="fas fa-edit" style={{position: 'absolute',bottom: '0px',right: '10px'}} onClick = {onSelectImage}></i>
                    <input ref={inputElement} type="file" id="photo" style={{display:'none'}} onChange={handleSubmit}/>
                  </Col>
                  <Col className="col-7" >    
                    <div className="pt-2" style={{fontSize: '18px', fontWeight: 'bold', color: 'rgb(3, 115, 202)'}}>
                      大きな目標（夢）は
                    </div>                
                    <div className="pt-2" style={{fontSize: '18px', fontWeight: '700', color: 'rgb(36 160 216)'}} dangerouslySetInnerHTML={{__html: portfolio}}/>           
                  </Col>
                </Row>              
              </CardBody>
              <CardFooter>
                <hr />
                <div className="button-container">
                  <Row>
                    <Col className="col-5" >
                      <h5>
                        <small>{birthLab == null ? '' : birthLab}</small><br />
                        {oldLab == null ? '' : oldLab}才
                      </h5>
                    </Col>
                    <Col className="col-4">
                      <h5>
                        <small>身長 / 体重</small><br />
                        {hight == null ? '' : hight}cm {weight == null ? '' : weight}kg                        
                      </h5>
                    </Col>
                    <Col className="col-3">
                      <h5>
                        <small>受講回数</small><br />
                        {number == null ? '' : numberLab[number]}                       
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-5" >
                      <h5>
                        <small>関東U18 Rank</small><br />
                        {rank}                       
                      </h5>
                    </Col>
                    <Col className="col-4" >
                      <h5>
                        <small>2022　W-L </small><br />
                        {s_win.win} : {s_win.lose}                        
                      </h5>
                    </Col>
                    <Col className="col-3" >
                      <h5>
                        <small>勝率</small><br />
                        {s_win.win + s_win.lose === 0? 0 : Math.round(s_win.win / (s_win.win + s_win.lose) * 100)}％                       
                      </h5>
                    </Col>
                  </Row>
                </div>
              </CardFooter>
            </Card>
            <Card className="mb-0">
              <CardHeader>
                <CardTitle tag="h6">近日予定の試合</CardTitle>
              </CardHeader>
              <CardBody>
              {
                s_preparation?.map((tmp, index) =>                    
                  <Row key={index} style={{alignItems: 'center'}}>
                    <Col className="col-3">{tmp['tourdate']}</Col>
                    <Col className="col-3" style={{padding:'0px'}}>{tmp['tournament']}</Col>
                    <Col className="col-2" style={{padding:'0px'}}>目標</Col>
                    <Col className="col-4" style={{padding:'5px'}}>
              
                      <select className="form-control"
                        value={tmp['target']}                
                        id={"target_"+ index}
                        name={"target_"+index}                                              
                        onChange={(e) => handleOnChange(e, index)}
                      >
                        <option value="0">優勝</option>
                        <option value="1">準優勝</option>
                        <option value="2">ベスト4</option>
                        <option value="3">ベスト8</option>
                        <option value="4">ベスト16</option>
                      </select>
                    </Col>
                  </Row>
                )
              } 
                                 
              </CardBody>
            </Card>
          </Col>
          <Col md="8" className="colp">
            <Card className="card-user mb-0">
              <CardHeader>
                <CardTitle tag="h6">プロフィール編集</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>                    
                    <Col className="col-12">
                      <FormGroup>
                        <label>名前 </label>
                        <Input type="text" onChange={getInputNameValue} value={name == null ? '' : name}/>
                      </FormGroup>
                    </Col>                  
                    <Col className="col-12">
                      <FormGroup>
                        <label>性別</label><br/>                        
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggle"
                            id="toggle"
                            checked={sex}
                            onChange={getInputSexValue}
                          />
                          <label className="toggle-switch-label" htmlFor="toggle">
                            <span className="toggle-switch-inner" />
                            <span className="toggle-switch-switch" />
                          </label>
                        </div>                        
                      </FormGroup>
                    </Col>                                      
                    <Col className="col-12">
                      <FormGroup>
                        <label>生年月日</label>
                        <Input type="date" onChange={getInputBirthValue} value={birthday == null ? '' : birthday}/>
                      </FormGroup>
                    </Col>  
                  </Row>
                  <Row>                
                    <Col className="col-12">
                      <FormGroup>
                        <label>身長</label>
                        <Input type="number" onChange={getInputHightValue} value={hight == null ? '' : hight}/>
                      </FormGroup>
                    </Col>                                   
                    <Col className="col-12">
                      <FormGroup>
                        <label>体重</label>
                        <Input type="number" onChange={getInputWeightValue} value={weight == null ? '' : weight}/>
                      </FormGroup>
                    </Col>                  
                    <Col className="col-12">
                      <FormGroup>
                        <label>学校</label>
                        <Input type="text" onChange={getInputSchoolValue} value={school == null ? '' : school}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>                    
                    <Col className="col-12">
                      <FormGroup>
                        <label>学年</label>
                        <Input type="number" onChange={getInputGradeValue} value={grade == null ? '' : grade}/>
                      </FormGroup>
                    </Col>                  
                    <Col className="col-12">
                      <FormGroup>
                        <label>郵便番号</label>
                        <Input type="text" onChange={getInputPostValue} value={post_code == null ? '' : post_code}/>
                      </FormGroup>
                    </Col>
                                    
                    <Col className="col-12">
                      <FormGroup>
                        <label>住所</label>
                        <Input type="text" onChange={getInputAddressValue} value={address == null ? '' : address}/>
                      </FormGroup>
                    </Col> 
                  </Row>
                  <Row>                   
                    <Col className="col-12">
                      <FormGroup>
                        <label>受講回数</label>                        
                        <select className="form-control"
                          value={number == null ? '' : number} 
                          onChange={getInputNumberValue} 
                        >
                          <option value=""></option>
                          <option value="0">4回</option>
                          <option value="1">8回</option>
                          <option value="2">12回</option>
                          <option value="3">16回</option>
                          <option value="4">フリー</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>                   
                    <Col className="col-12">
                      <FormGroup>
                        <label>クラス</label>
                        <select className="form-control"
                          value={lessonClass == null ? '' : lessonClass} 
                          onChange={getLessonClassValue} 
                        >
                          <option value="0">Aプレイヤーズ</option>
                          <option value="1">Bプレイヤーズ</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form> 
              </CardBody>   
            </Card>
            <Card className="card-user mb-0">
              <CardBody>
                <Form>
                  <Row>                    
                    <Col  className="col-12">
                      <FormGroup>  
                        <Row>
                          <Col className="col-12">
                            <input type="button" name="user" value="主な戦績追加" className="btn-round btn btn-danger"  onClick={battleAddFunc}/>
                          </Col>
                        </Row>    
                        {
                        s_battle.map((battle, index) =>
                          <Row key={index } style={{marginBottom:'3px'}}>
                            <Col className="col-11" style={{paddingRight:'0px'}}>
                              <Input placeholder="ex : 2019年関東ジュニアベスト４" type="text" value={battle.content == null ? '' : battle.content} onChange={(event) => battleUpdateFunc(event, index) }/>
                            </Col>
                            <Col className="col-1" style={{padding:'0px', display:'flex', justifyContent:'center', alignItems:'center'}} >
                              <i className="fa fa-trash-alt pointer" style={{fontSize:'21px','color':'#ccc'}}  onClick={(event) => battleDelFunc(event, battle.id) }></i>   
                            </Col>
                          </Row>
                        )}
                      </FormGroup>
                    </Col>                                     
                  </Row>
                  <Row>                    
                    <Col  className="col-12">
                      <FormGroup>  
                        <Row>
                          <Col className="col-12">
                            <input type="button" name="user" value="ランキング追加" className="btn-round btn btn-danger"  onClick={rankingAddFunc}/>
                          </Col>
                        </Row>    
                        {
                        s_ranking.map((ranking, index) =>
                          <Row key={index }>
                            <Col className="col-7" style={{paddingRight:'0px'}}>
                              <Input placeholder="ex : 埼玉U〇〇" type="text" value={ranking.title == null ? '' : ranking.title} onChange={(event) => rankingTitleFunc(event, index) }/>
                            </Col>
                            <Col className="col-4" style={{paddingLeft:'0px', paddingRight:'0px'}}>
                              <Input placeholder="" type="text" value={ranking.ranking == null ? '' : ranking.ranking} onChange={(event) => rankingRankingFunc(event, index) }/>
                            </Col>
                            {
                              index !=0 && (
                              <Col className="col-1" style={{padding:'0px', display:'flex', justifyContent:'center', alignItems:'center'}} >
                                <i className="fa fa-trash-alt pointer" style={{fontSize:'21px','color':'#ccc'}}  onClick={(event) => rankingDelFunc(event, ranking.id) }></i>                              
                              </Col>)
                            }
                          </Row>
                        )}
                      </FormGroup>
                    </Col>                                     
                  </Row>
                  <Row>                    
                    <Col  className="col-12">
                      <FormGroup>  
                        <Row>
                          <Col className="col-12">                           
                            <input type="button" name="user" value="勝率" className="btn-round btn btn-danger" />
                          </Col>
                        </Row>  
                        <Row>
                          <Col className="col-6">
                            <b>カテゴリー</b>
                          </Col>
                          <Col className="col-3">
                            <b>W-L</b>
                          </Col>
                          <Col className="col-3">
                            <b>%</b>
                          </Col>                              
                        </Row>    
                        {
                        s_winrate.map((winrate, index) =>
                          <Row key={index } style={{marginTop:'5px'}}>
                            <Col className="col-6">
                              {winrate["surface"]}
                            </Col>
                            <Col className="col-3">
                              {winrate["win"]} - {winrate["lose"]}
                            </Col>
                            <Col className="col-3">
                              {(winrate["win"] + winrate["lose"]) === 0? 0 : Math.round(winrate["win"] / (winrate["win"] + winrate["lose"]) * 100)}%
                            </Col>
                          </Row>
                        )}
                      </FormGroup>
                    </Col>                                     
                  </Row>
                  <Row style={{display:'none'}}>                    
                    <Col className="px-1">
                      <FormGroup>                         
                        <textarea className="form-control" id="remark" onChange={portfolioUpdateFunc} value={portfolio} type='text'></textarea>                        
                      </FormGroup>
                    </Col>                                     
                  </Row>
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup className="mb-0">      
                        
                        <input type="button" name="user" value="プロフィール保存" className="btn-round btn btn-primary" onClick={profileUpdateFunc}/>
                        
                      </FormGroup>
                    </Col>                                     
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

function profileUpdateFunc() {  
  
  var registerSession = JSON.stringify(session);
  window.sessionStorage.setItem("sessions", registerSession);
  let preparation = JSON.parse(window.sessionStorage.getItem("s_preparation"));
 
  let preparaAry = [];

  if(Array.isArray(preparation)){
    for(var i = 0; i < preparation.length; i++){
      preparaAry[i] = {};
      preparaAry[i].id = preparation[i].id;
      preparaAry[i].target = preparation[i].target;

    }
  }

  axios.post(URL_API+"profileUpdateAPI", {
    params: {
      data: registerSession, 
      img:imgFile,
      preparation : JSON.stringify(preparaAry),
      battle : window.sessionStorage.getItem("s_battle"),
      ranking : window.sessionStorage.getItem("s_ranking")
    }
  })
  .then(function (response) {
    var d = JSON.parse(window.sessionStorage.getItem('sessions'));
    d['profile_photo_path'] = response.data.profile_photo_path;
    d['profile_photo_url'] = response.data.profile_photo_url;
    window.sessionStorage.setItem("sessions", JSON.stringify(d));    
    session['profile_photo_path'] = response.data.profile_photo_path;
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b>「プロフィール編集」</b>が 完了しました。
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
    // if(imgFile)
    //   document.getElementById('userLogo').src = imgFile;
    // else
      document.getElementById('userLogo').src = "https://xs458763.xsrv.jp/uploads/" + response.data.profile_photo_path;
      document.getElementById('userName').text = session['name'];
  })
}
export default User;
