import React, { Component } from 'react';
import { render } from 'react-dom';

import {useState, useEffect } from "react";

import "assets/css/list.css";
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import { useHistory } from 'react-router-dom';
import DatePicker from 'react-mobile-datepicker';

import dataTypes from "views/diary/dataType.js";


let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}
function getMaxV(item){
	for(let i=0;i<dataTypes.basic.length;i++){
		if(dataTypes.basic[i].id == item.data_type)
			return dataTypes.basic[i].max;
	} 
	return 5;
}

function DiaryList4(){
	let session = JSON.parse(window.sessionStorage.getItem('sessions'));
	if(!session)
		window.location = "/";
	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const history = useHistory();
	var date = new Date();  
	const [flag, setFlag] 						= useState(0);
	const [todayDate, setTodayDate] 			= useState((new Date(sessionStorage.getItem('today'))).getDate());

	const [seriesName, setSeriesName] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['title'] + "("+JSON.parse(sessionStorage.getItem('selectedItem'))['i_unit']+")" || '');
	const [fname, setFName] 					= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['id'] || 0);

	const [chart_logo, setChart_logo] 			= useState(JSON.parse(sessionStorage.getItem('selectedItem'))['i_icon'] || '');
	const [maxNum, setMaxNum] 					= useState(getMaxV(JSON.parse(sessionStorage.getItem('selectedItem'))) || 5);

	const [showDate, setShowDate] 				= useState();
	const [keyData, setKeyData] 				= useState(1);
	// const [vData, setVData] 					= useState(0);

	const [isModal, setIsModal] 				= useState('hide');
	const [today, setToday] 					= useState(new Date(sessionStorage.getItem('today')) || new Date());
	const [todayVDate, setTodayVDate] 			= useState(month[today.getMonth()]+" "+today.getFullYear());
	const [getKey, setGetKey] = useState((new Date(sessionStorage.getItem('today'))).getFullYear()+("0"+((new Date(sessionStorage.getItem('today'))).getMonth()+1)).slice(-2)+("0" + (new Date(sessionStorage.getItem('today'))).getDate()).slice(-2));
	const [numberLabel, setNumberLabel]             = useState('');
    const [labelNumber, setLabelNumber]             = useState('');
    const [isNumbersModal, setIsNumbersModal]       = useState('hide');
    const [isDeleteBtn, setIsDeleteBtn]				= useState('hide');
    const [updateLabel, setUpdateLabel]				= useState('');

	const [data_list, setDataList] = useState([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [dateConfig, setDateConfig] = useState({
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        },
        'month': {
            format: 'MM',
            caption: 'Mon',
            step: 1,
        }
    });

	const sumNumber = (ds) => {
		let sum = 0;
		let cnt = 0;
	    for (let key in ds) {
	      sum += isNaN(Number(ds[key])) ? 0 : Number(ds[key]);
	      if(!isNaN(Number(ds[key])))
	      	cnt++;
	    }
	    return {sum:sum, cnt:cnt, txt:"Total("+sum+") "+cnt};
	}

	const setStateDatas = (d_datas) => {
		// setDiaryData(d_datas);
		let datas = [];
		let days = lastday(today.getFullYear(), today.getMonth());
		// for (let i in diaryData) {
		// let keys = [];
		// console.log(today.getFullYear(), today.getMonth());
		for(var i=1;i<=days;i++){
			let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
			let d = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+i);
			// console.log(d.getDay());
			if(d_datas[key]){
				if(d_datas[key][fname] && (typeof d_datas[key][fname] == 'object') && !Array.isArray(d_datas[key][fname])){
					console.log(d_datas[key][fname]);
					let v = sumNumber(d_datas[key][fname]);
					datas.push({'dtitle':i+' '+ weekday[d.getDay()], pCss:'', p:1, key:key, w:d.getDay(), vv:v, v:v.txt, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
					for (let lKey in d_datas[key][fname]) {
						let vs = isNaN(Number(d_datas[key][fname][lKey])) ? 0 : Number(d_datas[key][fname][lKey]);
						datas.push({'dtitle':' - '+ lKey, pCss:'pl-3', p:0, key:key, w:d.getDay(), lv:lKey, v:vs, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
					}
				}else{
					datas.push({'dtitle':i+' '+ weekday[d.getDay()], pCss:'', p:1, key:key, w:d.getDay(), v:0, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
				}
			}else
				datas.push({'dtitle':i+' '+ weekday[d.getDay()], pCss:'', p:1, key:key, w:d.getDay(), v:0, y:today.getFullYear(), m:(today.getMonth()+1), d:i});
		}
		// console.log(datas);
		setDataList(datas);
	}	
	const openModal = (f) => {
	    setIsOpen(true);
	    setIsModal('_hide');
	    if(f == 'ymd'){
	      	setDateConfig({
	            'year': {
	                format: 'YYYY',
	                caption: 'Year',
	                step: 1,
	            },
	            'month': {
	                format: 'MM',
	                caption: 'Mon',
	                step: 1,
	            }
	        });
	    }
	}

	const showChart = (e) => {
	    sessionStorage.setItem("today", today);
	    if(session.userkind == 'coach')
	    	history.push('../coach/diaryChart4');
	    else
	    	history.push('../admin/diaryChart4');
	};

	const setDataInput = (e, item) => {
		if(session.userkind == 'coach')
			return;
		var v = item.v;
		var key = item.key;
		setTodayDate(item.d);		
		setKeyData(key);
		setIsNumbersModal('');
		setShowDate(item.m+' / '+ item.d + ' ' + weekday[item.w]);

		if(item.p == 1){
			setIsDeleteBtn('hide');
			setLabelNumber('');
			setNumberLabel('');
			setUpdateLabel('');
		}else{
			setLabelNumber(item.v);
			setNumberLabel(item.lv);
			setUpdateLabel(item.lv);
			setIsDeleteBtn('');
		}
	};

	const noHandleClick = (event) => {
		setIsModal('_hide');
	}

	const noHandleLClick = (event) => {
		setLabelNumber('');
		setNumberLabel('');
		setUpdateLabel('');
	    setIsNumbersModal('_hide');
	}

	const deleteHandleLClick = (event) => {
	  	let session = window.sessionStorage.getItem('sessions'); 
		// setVData(e.target.value);
		let setDataDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-' + todayDate;
		let se = JSON.parse(session);
		if(updateLabel != ''){
			let ds = JSON.parse(window.sessionStorage.getItem('diaryData'));
			let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + todayDate).slice(-2);
			if(ds[key] && ds[key][fname]){
				let cd = ds[key][fname];
				let new_cd = {};
				for (let lk in cd) {
					if(lk != updateLabel){
						new_cd[lk] = cd[lk]
					}
			    }
				axios.get(URL_API+"setDiaryItemData", {
			      params: {
			        seldate: setDataDate, 
			        user: se['id'],
			        item_id: fname,
			        v:new_cd
			      }
			    })
			    .then(function (response) {
			      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
			  		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
			      	setStateDatas(response.data.data);
			    }).catch(
			      error => {
			        if(error.response){
			          console.log(error.response.data);
			        };
			      }
			    ); 
			}
		}
	    setIsNumbersModal('_hide');
	}

	const okHandleLClick = (event) => {		
		let session = window.sessionStorage.getItem('sessions'); 
		// setVData(e.target.value);
		let setDataDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-' + todayDate;
		let se = JSON.parse(session);

		let ds = JSON.parse(window.sessionStorage.getItem('diaryData'));
		let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + todayDate).slice(-2);
		let newd = {};
		if(ds[key] && ds[key][fname]){
			if(!Array.isArray(ds[key][fname]))
				newd = ds[key][fname];
			// console.log(newd);
		}

		if(updateLabel == ''){
			newd[numberLabel] = labelNumber;			
		}else{
			let nw = {};
			for (let lk in newd) {
				if(lk == updateLabel){
					nw[numberLabel] = labelNumber;
				}else{						
					nw[lk] = newd[lk]
				}
		    }
		    newd = nw;
		    // console.log(newd);
		}
		// console.log(newd);
		axios.get(URL_API+"setDiaryItemData", {
	      params: {
	        seldate: setDataDate, 
	        user: se['id'],
	        item_id: fname,
	        v:newd
	      }
	    })
	    .then(function (response) {
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
	  		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
	      	setStateDatas(response.data.data);
	    }).catch(
	      error => {
	        if(error.response){
	          console.log(error.response.data);
	        };
	      }
	    ); 
	    setIsNumbersModal('_hide');
	}

	const onBeforeData = (e) => {
		onGetData(todayDate-1);
	}

	const onNextData = (e) => {
		onGetData(todayDate+1);
	}

	const onGetData = (i) => {	
		if(i < 1)
			return;	
		let data = JSON.parse(window.sessionStorage.getItem('diaryData'));
		let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);

		let d = new Date(today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2));
		// console.log(key, today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2));
		let days = lastday(today.getFullYear(), today.getMonth());
		if(i > days)
			return;
		if(data[key] != undefined){
			if(data[key][fname]){
				// setVData(data[key][fname]);
			}else{
				// setVData(0);
			}
		}else{
			// setVData(0);
		}
		setShowDate((today.getMonth()+1)+' / '+ i + ' ' + weekday[d.getDay()]);
		setTodayDate(i);
	}

	const selectDate = (time) => {
	    setToday(time);
	    setTodayDate(time.getDate());
	    setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
	    setIsOpen(false);
	    setGetKey(time.getFullYear()+"-"+("0"+(time.getMonth()+1)).slice(-2)+"-"+("0" + time.getDate()).slice(-2));
	    // console.log(time, time.getFullYear()+("0"+(time.getMonth()+1)).slice(-2)+("0" + time.getDate()).slice(-2));
	}

	const handleCancel = () => {
	    setIsOpen(false);
	}

	const toPage = (e, pname) => {
		if(session.userkind == 'coach' && pname == 'diary'){
			history.push('../coach/'+pname);
			return;
		}
		history.push('../admin/'+pname);
	}

	useEffect(() => {
		if(!session){
	      	window.location = '/';
	    }

	   	// console.log(chart_title, chart_field_name, chart_intro_img);
	   	if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
			setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   	}
	   	let p = session;
	   	if(session.userkind == 'coach'){
			p = JSON.parse(window.sessionStorage.getItem('player')); 
			if(!p){
			  p = JSON.parse(window.sessionStorage.getItem('players'))[0];
			}
		}
   		axios.get(URL_API+"getDiaryAPI", {
			params: {
				seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
				user: p['id'],
			}
	    })
	    .then(function (response) {		      
	      	window.sessionStorage.setItem('diaryData', JSON.stringify(response.data.data));
      		// window.sessionStorage.setItem('dataItems', JSON.stringify(response.data.items));
	      	setStateDatas(response.data.data);
	    }).catch(
	      	error => {
	        	if(error.response){
	          		console.log(error.response.data);
	        	};
	      	}
	    );
  	}, [today]);
	return (
		<>		
			<DatePicker
		        dateConfig={dateConfig}
		        value={today}
		        isOpen={modalIsOpen}
		        onSelect={selectDate}
		        onCancel={handleCancel} />	

			<div className={isNumbersModal + ' pt-3 pb-2 px-2 showDataDiv'} id="showNumbersDataDiv">
		        <div className={'show_body'}> 
		        	<div className={'m-header mb-1'}>
						<div>{showDate}</div>
					</div>
		          <div className={'m-body'}>
		            <div className="col-12 row px-0 mx-0">
		              <div className="col-12 d-flex px-1">
		                <label className="col-4 px-1 m-0 d-flex align-items-center">label : </label>
		                <input className="col-8 px-1" type="text" id="sNValue" value={numberLabel} onChange={(event) => setNumberLabel(event.target.value)}/>
		              </div>
		              <div className="col-12 d-flex px-1 mt-1">
		                <label className="col-4 px-1 m-0 d-flex align-items-center">VALUE : </label>
		                <input className="col-8 px-1" type="number" id="eNValue" value={labelNumber} onChange={(event) => setLabelNumber(event.target.value)}/>
		              </div>
		            </div>
		          </div>
		          <div className="d-flex justify-content-between px-3 mt-1" style={{fontSize:'16px'}}>
		            <span className={"p-2 " + isDeleteBtn}><i className="fa fa-trash-alt pointer" onClick={(event) => deleteHandleLClick(event)}></i></span>
		            <span className="p-2" onClick={(event) => noHandleLClick(event)}>キャンセル</span>
		            <span className="p-2" onClick={(event) => okHandleLClick(event)}>OK</span>
		          </div>
		        </div>    
		    </div>
			<div className="content px-0">
        		<Row className="px-0 mx-0">
          			<Col md="12" className="px-0">
						<Card>
							<CardBody className="seldate" id='seldate' style={{color:'white'}}>
					            <Row>					            	
					            	<Col className="col-2 d-flex justify-content-center align-items-center">
					            		<i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toPage(event, 'diary')}></i>
					            	</Col>
					              	<Col className="col-8 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ymd')} >
					                	{todayVDate}
					             	</Col>
					             	<Col className="col-2 d-flex justify-content-center">
					             	</Col>
					            </Row>
					        </CardBody>  
					        <div className="chart_body">
								<div className="row col-12 px-0 mx-0 py-1 justify-content-between align-items-center">
									<div className="col-9 px-0 d-flex align-items-center">
										<div className="d-flex col-3 px-0 justify-content-center align-items-center">
											<img src={chart_logo} className="chart_list_logo"/>
										</div>
										<div className="col-9 px-0 justify-content-start align-items-center">
											{seriesName}
										</div>
									</div>
									<div className="d-flex col-3 px-0 justify-content-center align-items-center chartList">
										<i className="fas fa-chart-line" style={{marginRight:'5px'}} onClick={(event) => showChart(event)}></i>
          								<i className="fas fa-list"></i>
									</div>
								</div>
							</div>
							<div className="content_body">					
							{
								data_list.map((item, i) => 
									<div key={i} className="d-flex justify-content-between px-3 border-bottom py-2 font-weight-bolder pointer fa-lg" onClick={(event) => setDataInput(event, item)}>
										<div className = {(item.w==0 ? 'sun' : (item.w == 6 ? 'sat' : '')) + ' d-flex align-items-center ' + item.pCss}>{item.dtitle}</div>
										<div className="d-flex align-items-center" style={{color:'rgb(65, 150, 23)'}}>{item.v}</div>
									</div>
								)
							}
							</div>
						</Card>
					</Col>
				</Row>
			</div>
      	</>
    )
}

export default DiaryList4;