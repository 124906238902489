/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import questionImg from "assets/img/self/question.png";
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import { useParams } from 'react-router-dom';
import "assets/css/p.css";

import face1 from "assets/img/face/1.png";
import face2 from "assets/img/face/2.png";
import face3 from "assets/img/face/3.png";
import face4 from "assets/img/face/4.png";
import face5 from "assets/img/face/5.png";


import w1 from "assets/img/face/11.png";
import w2 from "assets/img/face/12.png";
import w3 from "assets/img/face/13.png";
import w4 from "assets/img/face/14.png";
import w5 from "assets/img/face/15.png";
import w6 from "assets/img/face/16.png";

import {  
  Card,  
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
// let presession = {};

let notificationAlert;
let se;
let plyer={};
function convetDateToIso(d){
  return d.getFullYear()+'-'
       + (("0"+(d.getMonth() + 1)).slice(-2))+'-'
       + ("0"+d.getDate()).slice(-2)+'T'
       + ("0"+d.getHours()).slice(-2)+':'
       + ("0"+d.getMinutes()).slice(-2)+':'
       + ("0"+d.getSeconds()).slice(-2);
}

const Preparationview = (e) => {    

  let session = JSON.parse(window.sessionStorage.getItem('sessions')); 
  const { view_id } = useParams();

  notificationAlert = React.useRef(); 
  
  const history = useHistory();

  if(session === null){    
    window.location = '/';
  }
  
  const preparationPage = (e) => {    
    if(sendingText != ''){
      if(window.confirm("コーチ様に送信しますか？")){
        axios.get(URL_API+"setMessages", {
          params: {
            coach_id : session['id'],
            player_id : plyer['id'],
            content : '<p>"'+presession['tournament']+' '+presession['tourdate']+' '+presession['opponentgroup']+' '+presession['opponentname']+'"</p>'+sendingText,
            pp : window.sessionStorage.getItem('msg_id'),
            f:1
          }
        })
        .then(function (response) {
          // setUFlag(uFlag+1);
          // setMsg('');
        }).catch(
          error => {
            if(error.response){
              console.log(error.response.data);
            };
          }
        ); 
        axios.get(URL_API+"updatePreparationComment", {
          params: {
            id:view_id,
            comment:sendingText
          }
        })
        .then(function (response) {
          // setUFlag(uFlag+1);
          // setMsg('');
        }).catch(
          error => {
            if(error.response){
              console.log(error.response.data);
            };
          }
        ); 
        history.push('/coach/chatRoom');
      }
    }    
  }
  
///////////////////

  const [presession, setPresession] = useState({});
  

  const [tournament, setTournament] = useState(presession['tournament'] || '');
  const [category, setCategory] = useState(presession['category'] || '');
  const [tourdate, setTourdate] = useState(presession['tourdate'] || '');
  const [opponentname, setOpponentname] = useState(presession['opponentname'] || '');
  const [opponentgroup, setOpponentgroup] = useState(presession['opponentgroup'] || '');
  const [surface, setSurfaceNumber] = useState(presession['surface'] || '');
  const [match, setMatch] = useState(presession['match'] || '');
  const [mainround, setMainround] = useState( presession['mainround'] || '');
  const [subround, setSubround] = useState(presession['subround'] || '');
  const [weather, setWeather] = useState(presession['weather'] || '');
  const [condition, setCondition] = useState(presession['condition'] || '');
  const [think1, setThink1] = useState(presession['think1'] || '');
  const [think2, setThink2] = useState(presession['think2'] || '');
  const [think3, setThink3] = useState(presession['think3'] || '');
  const [think4, setThink4] = useState(presession['think4'] || '');
  const [think5, setThink5] = useState(presession['think5'] || '');
  
  const [checkboxgroup, setCheckboxgroup] = useState(
    [ { en: 's_serverData', jp: 'サーブ', fc: 's_setServerData', val:true },
      { en: 's_receiveData', jp: 'レシーブ', fc: 's_setReceiveData', val:true},
      { en: 's_strokeData', jp: 'ストローク', fc: 's_setStrokeData', val:true},
      { en: 's_netplayData', jp: 'ネットプレー', fc: 's_setNetplayData', val:true},
      { en: 's_mentalData', jp: 'メンタル', fc: 's_setMentalData', val:true},
      { en: 's_tacticsData', jp: '戦術', fc: 's_setTacticsData', val:true},]
    );

  const [s_serverData, s_setServerData] = useState([]);
  const [s_receiveData, s_setReceiveData] = useState([]);
  const [s_strokeData, s_setStrokeData] = useState([]);
  const [s_netplayData, s_setNetplayData] = useState([]);
  const [s_mentalData, s_setMentalData] = useState([]);
  const [s_tacticsData, s_setTacticsData] = useState([]);
   
  const [checkedState, setCheckedState] = useState(
    new Array(s_serverData.length).fill(false)
  );

  const [sendingText, setSendingText] = useState(presession['comment'] || '');

  const getSendTextValue = (e) => {
    setSendingText(e.target.value);
  }

  
  useEffect(() => {

    axios.get(URL_API+"getPreparaAPI", {
      params: {
        id: view_id, 
      }
    })
    .then(function (response) {
      setPresession(response.data);
      // plyer = response.data.proposer;
      let plys = JSON.parse(window.sessionStorage.getItem('players'));

      for(let i=0;i<plys.length;i++){
        if(plys[i].id == response.data.proposer)
          plyer = plys[i];
      }

      setCategory(response.data['category']);
      setCondition(response.data['condition']);
      setMainround(response.data['mainround']);
      setMatch(response.data['match']);
      setOpponentgroup(response.data['opponentgroup']);
      setOpponentname(response.data['opponentname']);
      setSubround(response.data['subround']);
      setSurfaceNumber(response.data['surface']);
      setThink1(response.data['think1']);
      setThink2(response.data['think2']);
      setThink3(response.data['think3']);
      setThink4(response.data['think4']);
      setThink5(response.data['think5']);
 
      setTourdate(response.data['tourdate']);
      setTournament(response.data['tournament']);
      setWeather(response.data['weather']);
      setSendingText(response.data['comment']);

      for(var i = 0 ; i < checkboxgroup.length; i++){

        if(JSON.parse(window.sessionStorage.getItem(checkboxgroup[i]['en'])) != null)
          eval(checkboxgroup[i]['fc'])(JSON.parse(window.sessionStorage.getItem(checkboxgroup[i]['en'])));
        
        axios.get(URL_API+"getSelfSelAPI", {
          params: {
            data: checkboxgroup[i]['en'],
            user: plyer.id, 
          }
        })
        .then(function (response) {
          
          if(response.data !== null && response.data.length > 0){           
            
            window.sessionStorage.setItem(response.data[0]['group_id'], JSON.stringify(response.data));

            eval(response.data[0]['fc'])(JSON.parse(window.sessionStorage.getItem(response.data[0]['group_id'])));

          }

        });
      }

    });   

  }, []);
  return (

    <>
      <div className="content px-0 pb-0">
        <NotificationAlert ref={notificationAlert}  className="col-12"/>
        <div className="selected_player">
          <Row className="py-2 px-0 mx-0">
            <div className="text-center col-2 px-0">
              <img className="s_player_icon" src={(plyer && plyer.profile_photo_path ? "https://xs458763.xsrv.jp/uploads/"+plyer.profile_photo_path : require("assets/img/mike.jpg").default)}/>
            </div>
            <div className="d-flex align-items-center col-5 px-0">
              <span style={{width:'100%', whiteSpace:'pre-wrap'}}>{plyer.name}</span>
            </div>  
            <div className="col-5 pl-0 pr-3 justify-content-end d-flex align-items-center">
              試合準備
            </div>
          </Row>
        </div>
        <Row className="px-0 mx-0">          
          <Col md="12" className="px-0">
            <Card className="card-user">              
              <CardBody>
                <Form>
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup>
                        <label>大会名</label>
                        <Input type="text" readOnly="readonly" style={{background:'white'}} id="tournament" value={tournament == null ? '' : tournament}/>
                      </FormGroup>
                    </Col>  
                    
                  </Row>
                 
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup>
                        <label>カテゴリー</label>
                        
                        <select className="form-control" id="category"
                          value={category == null ? '' : category} 
                           readOnly="readonly" style={{background:'white'}}
                        >
                          <option value=""></option>
                          <option value="U8">U8</option>
                          <option value="U9">U9</option>
                          <option value="U10">U10</option>
                          <option value="U11">U11</option>
                          <option value="U12">U12</option>
                          <option value="U13">U13</option>
                          <option value="U14">U14</option>
                          <option value="U15">U15</option>
                          <option value="U16">U16</option>
                          <option value="U17">U17</option>
                          <option value="U18">U18</option>
                          <option value="一般">一般</option>
                        </select>
                      </FormGroup>
                    </Col>

                    <Col className="px-1">
                      <FormGroup>
                        <label>トーナメント日にち</label>
                        <Input type="datetime-local" readOnly="readonly" style={{background:'white'}} id="tourdate" value={tourdate == null ? '' : convetDateToIso(new Date(tourdate))}  step="1"/>
                      </FormGroup>
                    </Col>                  
                    
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup>
                        <label>対戦相手名前</label>
                        <Input type="text" readOnly="readonly" style={{background:'white'}} id="opponentname" value={opponentname == null ? '' : opponentname}/>
                      </FormGroup>
                    </Col>                  
                    <Col className="px-1">
                      <FormGroup>
                        <label>対戦相手クラブ名</label>
                        <Input type="text" readOnly="readonly" style={{background:'white'}} id="opponentgroup" value={opponentgroup == null ? '' : opponentgroup}/>
                      </FormGroup>
                    </Col>                  
                    
                  </Row>
                  <Row>                  
                    <Col className="px-1">
                      <FormGroup>
                        <label>サーフェス</label>
                        <select className="form-control" id="surface"
                          value={surface == null ? '' : surface} 
                           readOnly="readonly" style={{background:'white'}}
                        >
                          <option value="0"></option>
                          {/* <option value="1set">1set</option>
                          <option value="3set">3set</option> */}
                          <option value="オムニコート">オムニコート</option>
                          <option value="ハードコート">ハードコート</option>
                          <option value="クレーコート">クレーコート</option>
                        </select>
                      </FormGroup>
                    </Col>  
                    <Col className="px-1">
                      <FormGroup>
                        <label>試合形式</label>
                        <select className="form-control" id="match"
                          value={match == null ? '' : match} 
                           readOnly="readonly" style={{background:'white'}}
                        >
                          <option value="0"></option>
                          <option value="1セットマッチ">1セットマッチ</option>
                          <option value="3セットマッチ">3セットマッチ</option>
                          <option value="8ゲームマッチ">8ゲームマッチ</option>
                          <option value="その他">その他</option>
                        </select>
                      </FormGroup>
                    </Col>                  
                    
                  </Row>
                  <Row>   
                    <Col className="px-1">
                      <FormGroup>
                        <label>本戦ラウンド</label>
                        
                        <select className="form-control" id="mainround"
                          value={mainround == null ? '' : mainround} 
                           readOnly="readonly" style={{background:'white'}}
                        >
                          <option value=""></option>
                          <option value="1R">1R</option>
                          <option value="2R">2R</option>
                          <option value="3R">3R</option>
                          <option value="4R">4R</option>
                          <option value="5R">5R</option>
                          <option value="QF">QF</option>
                          <option value="SF">SF</option>
                          <option value="F">F</option>
                        </select>
                      </FormGroup>
                    </Col>                 
                    <Col className="px-1">
                      <FormGroup>
                        <label>予選ラウンド</label>
                        
                        <select className="form-control" id="subround"
                          value={subround == null ? '' : subround} 
                           readOnly="readonly" style={{background:'white'}}
                        >
                          <option value=""></option>
                          <option value="1R">1R</option>
                          <option value="2R">2R</option>
                          <option value="3R">3R</option>
                          <option value="4R">4R</option>
                          <option value="5R">5R</option>
                          <option value="QF">QF</option>
                          <option value="SF">SF</option>
                          <option value="F">F</option>
                        </select>
                      </FormGroup>
                    </Col>                  
                    
                  </Row>
                  <Row>  
                    <Col className="px-1">
                      <div><label>天気</label></div>
                      <FormGroup>
                        <div className="row col-12 p2-0 py-0 mx-0 d-flex justify-content-between align-items-center">
                          <img src={w1} className={weather == 1 ? 'w_selected' : 'no_selected'} readOnly="readonly" style={{background:'white'}}/>
                          <img src={w2} className={weather == 2 ? 'w_selected' : 'no_selected'} readOnly="readonly" style={{background:'white'}}/>
                          <img src={w3} className={weather == 3 ? 'w_selected' : 'no_selected'} readOnly="readonly" style={{background:'white'}}/>
                          <img src={w4} className={weather == 4 ? 'w_selected' : 'no_selected'} readOnly="readonly" style={{background:'white'}}/>
                          <img src={w5} className={weather == 5 ? 'w_selected' : 'no_selected'} readOnly="readonly" style={{background:'white'}}/>
                          <img src={w6} className={weather == 6 ? 'w_selected' : 'no_selected'} readOnly="readonly" style={{background:'white'}}/>
                        </div>
                      </FormGroup>
                      <FormGroup style={{display:'none'}}>
                        <label>天気</label>                        
                        <select className="form-control" id="weather"
                          value={weather == null ? '' : weather} 
                           readOnly="readonly" style={{background:'white'}}
                        >
                          <option value="0"></option>
                          <option value="1">☀️</option>
                          <option value="2">⛅</option>
                          <option value="3">☁️</option>
                          <option value="4">🌧️</option>
                          <option value="5">☔</option>
                          <option value="6">☃️</option>
                        </select>
                      </FormGroup>
                    </Col>  
                  </Row>
                  <Row>                
                    <Col className="px-1">
                      <div><label>起きた時の体調や気分</label></div>
                      <FormGroup>
                        <div className="row col-12 p2-0 py-2 mx-0 d-flex justify-content-between align-items-center">
                          <img src={face1} className={condition == 1 ? 'c_selected' : 'cno_selected'} readOnly="readonly" style={{background:'white'}}/>
                          <img src={face2} className={condition == 2 ? 'c_selected' : 'cno_selected'} readOnly="readonly" style={{background:'white'}}/>
                          <img src={face3} className={condition == 3 ? 'c_selected' : 'cno_selected'} readOnly="readonly" style={{background:'white'}}/>
                          <img src={face4} className={condition == 4 ? 'c_selected' : 'cno_selected'} readOnly="readonly" style={{background:'white'}}/>
                          <img src={face5} className={condition == 5 ? 'c_selected' : 'cno_selected'} readOnly="readonly" style={{background:'white'}}/>
                        </div>
                      </FormGroup>
                      <FormGroup style={{display:'none'}}>      
                        <label>起きた時の体調や気分</label>                        
                          <select className="form-control" id="condition"
                            value={condition == null ? '' : condition} 
                             readOnly="readonly" style={{background:'white'}}
                          >
                            <option value="0"></option>
                            <option value="1">😆</option>
                            <option value="2">😀</option>
                            <option value="3">🙂</option>
                            <option value="4">😞</option>
                            <option value="5">😣</option>
                          </select>
                      </FormGroup>
                    </Col>                                     
                  </Row>
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup>      
                        <label>試合前に心がける事（下の自己分析結果や相手のタイプを考えて最低3つ書く）</label>
                        <Input type="text" readOnly="readonly" style={{background:'white', marginTop:'5px'}} value={think1 == null ? '' : think1}/>
                        <Input type="text" readOnly="readonly" style={{background:'white', marginTop:'5px'}} value={think2 == null ? '' : think2}/>
                        <Input type="text" readOnly="readonly" style={{background:'white', marginTop:'5px'}} value={think3 == null ? '' : think3}/>
                        <Input type="text" readOnly="readonly" style={{background:'white', marginTop:'5px'}} value={think4 == null ? '' : think4}/>
                        <Input type="text" readOnly="readonly" style={{background:'white', marginTop:'5px'}} value={think5 == null ? '' : think5}/>                        
                      </FormGroup>
                    </Col>                                     
                  </Row>
                  <Row>
                    <Col md="12">
                      {
                        checkboxgroup.map((group, num) =>
                        <Card className="demo-icons" key={num} style={{padding:'10px'}}>
                          <CardTitle tag="h6" style={{marginTop:'0.75rem'}}>{group.jp}</CardTitle>  
                          
                            {
                              eval(group.en).map((tmp, index) => 
                              
                              <Row key={index }>
                                <Col className="col-12" style={{color:'rgb(3, 115, 202)', fontSize:'14px'}}>
                                  <img src={questionImg}  width="20px"/> {tmp.item}
                                </Col>
                                <Col className="col-12" style={{color:'#ff3d02', fontSize:'14px', paddingLeft:'40px'}}>
                                  {tmp.solution}
                                </Col>
                                                          
                              </Row>
                              
                              )
                            }                
                            
                        </Card>
                      )}
                      
                    </Col>
                  </Row>
                  <Row>   
                    <Card className="demo-icons col-12" style={{padding:'10px'}}>
                      <CardTitle tag="h6" style={{marginTop:'0.75rem'}}>コメント</CardTitle>  
                      <textarea className="form-control p-2" id="remark" onChange={getSendTextValue} value={sendingText ? sendingText : ''}>{sendingText}</textarea>
                    </Card>
                    <Col className="px-1">
                      <input type="button" name="user" value="確認" className="btn-round btn btn-primary col-12" onClick={preparationPage}/>
                    </Col>   
                    <Col className="px-1">
                      <input type="button" name="user" value="戻る" className="btn-round btn btn-primary" onClick={(e) => history.push('/coach/chatRoom')}/>
                    </Col>                                
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Preparationview;
