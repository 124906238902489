import React, { Component } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { render } from 'react-dom';
import {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import Diary1 from './Diary1.js';
import Diary3 from './Diary3.js';
import Diary4 from './Diary4.js';
import Diary5 from './Diary5.js';
import Diary6 from './Diary6.js';
import Diary7 from './Diary7.js';
import Diary8 from './Diary8.js';
import Diary9 from './Diary9.js';
import Diary10 from './Diary10.js';


import "assets/css/chart.css";


let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function DiaryChart11(){

  let session = JSON.parse(window.sessionStorage.getItem('sessions'));
  if(!session)
    window.location = '/';
  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  
  const [flag, setFlag]             = useState(0);
  const [today, setToday]           = useState(new Date(sessionStorage.getItem('today')) || new Date());
  const [modalIsOpen, setIsOpen]    = React.useState(false);
  const [todayVDate, setTodayVDate] = useState(month[today.getMonth()]+" "+today.getFullYear());

  const [dataItems, setDataItems] = useState([]);

  const [dateConfig, setDateConfig] = useState({
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: 'MM',
        caption: 'Mon',
        step: 1,
    }
  });

  const history = useHistory();

  const openModal = (f) => {
      setIsOpen(true);
      if(f == 'ymd'){
        setDateConfig({
          'year': {
              format: 'YYYY',
              caption: 'Year',
              step: 1,
          },
          'month': {
              format: 'MM',
              caption: 'Mon',
              step: 1,
          }
        });
      }
  } 

  const selectDate = (time) => {
      setToday(time);
      setTodayVDate(month[time.getMonth()]+" "+time.getFullYear());
      setIsOpen(false);
  }

  const handleCancel = () => {
      setIsOpen(false);
  }

  const toPage = (e, pname) => {
    if(session.userkind == 'coach' && pname == 'diary'){
      history.push('../coach/'+pname);
      return;
    }
    history.push('../admin/'+pname);
  }

  const renderItemData = (item, idx) => {
    // console.log(idx);   
    let to_day = today; 
    if(!isValidDate(to_day)){
      to_day = new Date()
      setToday(to_day);
    }
    if(item.data_type == 1 || item.data_type == 2){
      return (<Diary1 sItem = {item} today = {to_day} flag = {flag}/>);
    }else if(item.data_type == 3){
      return (<Diary3 sItem = {item} today = {to_day} flag = {flag}/>);
    }else if(item.data_type == 4){
      return (<Diary4 sItem = {item} today = {to_day} flag = {flag}/>);
    }else if(item.data_type == 5){
      return (<Diary5 sItem = {item} today = {to_day} flag = {flag}/>);
    }else if(item.data_type == 6){
      return (<Diary6 sItem = {item} today = {to_day} flag = {flag}/>);
    }else if(item.data_type == 7){
      return (<Diary7 sItem = {item} today = {to_day} flag = {flag}/>);
    }else if(item.data_type == 8){
      return (<Diary8 sItem = {item} today = {to_day} flag = {flag}/>);
    }else if(item.data_type == 9){
      return (<Diary9 sItem = {item} today = {to_day} flag = {flag}/>);
    }else if(item.data_type == 10){
      return (<Diary10 sItem = {item} today = {to_day} flag = {flag}/>);
    }
  }

  const onShowChart = (f) => {
    setFlag(f);
  }

  useEffect(() => {
    // console.log(typeof today);
    if(!session){
        window.location = '/';
    }

    if(JSON.parse(window.sessionStorage.getItem('dataItems')) != null){
      setDataItems(JSON.parse(window.sessionStorage.getItem('dataItems')));
    }
  }, [flag, today]);
  return (
    <>
      <DatePicker
            dateConfig={dateConfig}
            value={today}
            isOpen={modalIsOpen}
            onSelect={selectDate}
            onCancel={handleCancel} />  
          <div className="content px-0 pb-0">
            <Row className="px-0 mx-0">
              <Col md="12" className="px-0">
                <Card className="mb-0">
                  <CardBody className="seldate" id='seldate' style={{color:'white'}}>
                    <Row>
                      <Col className="col-2 d-flex justify-content-center align-items-center">
                        <i className="fas fa-chevron-left" style={{fontSize:'24px'}} onClick={(event) => toPage(event, 'diary')}></i>
                      </Col>
                        <Col className="col-8 d-flex justify-content-center" style={{fontSize:'1.5rem', fontWeight:'bold'}} onClick={() => openModal('ymd')} >
                          {todayVDate}
                      </Col>
                      <Col className="col-2 d-flex justify-content-center">
                      </Col>
                    </Row>
                  </CardBody> 
                  <CardBody className=" dataItems px-0">
                  {
                    dataItems.map((item, idx) => 
                      <div key={idx} style={{fontWeight:'bold'}}>
                        {
                          renderItemData(item, idx)
                        }
                      </div>
                    )
                  }         
                  </CardBody>          
                  <div style={{height:'50px'}} className="border-top">
                    <Row className="mx-0">
                      <Col className={'col-4 d-flex align-items-center justify-content-center col-md-4 py-2 text-center sFlag ' + (flag==0?'active':'')} onClick={() => onShowChart(0)}>
                        月
                      </Col>
                      <Col className={'col-4 d-flex align-items-center justify-content-center col-md-4 py-2 text-center sFlag ' + (flag==1?'active':'')} onClick={() => onShowChart(1)}>
                        3か月
                      </Col>
                      <Col className={'col-4 d-flex align-items-center justify-content-center col-md-4 py-2 text-center sFlag ' + (flag==2?'active':'')} onClick={() => onShowChart(2)}>
                        年
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    )
}

export default DiaryChart11;