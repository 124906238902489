/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
// reactstrap components
import NotificationAlert from "react-notification-alert";
import {  
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  textarea,  
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import { isArray } from "highcharts";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let session = {};
let notificationAlert;

function Dashboard() {

  notificationAlert = React.useRef(); 
  const history = useHistory();
  
  // if(session['name'] == undefined){
    session = JSON.parse(window.sessionStorage.getItem('sessions'));
  // }
 
  let birthdaAry = session['birthday'].split("-");
  let bl = "";
  let old;
  let numberLab = ['4回','8回','12回','16回','4回','フリー'];

  if(birthdaAry.length > 2){
    bl = birthdaAry[0]+"年"+birthdaAry[1]+"月"+birthdaAry[2]+"日生まれ";
    old = new Date().getFullYear() - birthdaAry[0];
  }

  const [oldLab, setOldLab] = useState(old);
  const [birthLab, setBirthLab] = useState(bl);
  const [hight, setHight] = useState(session['hight']); 
  const [weight, setWeight] = useState(session['weight']);    
  const [number, setNumber] = useState(session['number']);  
  const [portfolio, setPortfolio] = useState(session['portfolio']); 
  const [p_proposer, s_setProposerData] = useState([]);
  const [p_opponent, s_setOpponentData] = useState([]);
  const [s_preparation, s_setPreparation] =  useState([]);
  const [s_win, s_setWin] = useState([]);
  const [s_battle, s_setBattle] = useState([]);
  const [s_ranking, s_setRanking] = useState([]);
  const [s_winrate, s_setWinrate] = useState([]);
  const [rank, setRank] = useState(0);
  const [selectedFile, setSelectedFile] = useState(session['profile_photo_path']);
  useEffect(() => {
    
    if(session === null){      
      window.location = '/';
    }
    
    if(Array.isArray(JSON.parse(window.sessionStorage.getItem("s_battle"))))
      s_setBattle(JSON.parse(window.sessionStorage.getItem("s_battle")));  

    axios.get(URL_API+"getBattleAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {

      if(response.data.length > 0 && Array.isArray(response.data)){
        s_setBattle(response.data);
        window.sessionStorage.setItem('s_battle', JSON.stringify(response.data));
       
      }

    }); 

    if(Array.isArray(JSON.parse(window.sessionStorage.getItem("s_winrate"))))
      s_setWinrate(JSON.parse(window.sessionStorage.getItem("s_winrate")));
    
    axios.get(URL_API+"getWinrateAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {

      if(response.data.length > 0 && Array.isArray(response.data)){
        s_setWinrate(response.data);
        window.sessionStorage.setItem('s_winrate', JSON.stringify(response.data));
      }

    }); 

    if(Array.isArray(JSON.parse(window.sessionStorage.getItem("s_ranking")))){
      s_setRanking(JSON.parse(window.sessionStorage.getItem("s_ranking")));

      let rankAry = JSON.parse(window.sessionStorage.getItem("s_ranking"),true);

      for(var i = 0; i < rankAry.length; i++){
        if(rankAry[i].title == "関東U18"){
          setRank(rankAry[i]["ranking"]);
        }
      }

    }
    
    axios.get(URL_API+"getRankingAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {

      if(response.data.length > 0 && Array.isArray(response.data)){
        s_setRanking(response.data);
        window.sessionStorage.setItem('s_ranking', JSON.stringify(response.data));

        let rankAry = JSON.parse(window.sessionStorage.getItem("s_ranking"),true);

        for(var i = 0; i < rankAry.length; i++){
          if(rankAry[i].title == "関東U18"){
            setRank(rankAry[i]["ranking"]);
          }
        }
      }

    }); 


    s_setWin(JSON.parse(window.sessionStorage.getItem("s_win")));

    if(s_win.win == undefined){
      s_setWin({"win":0, "lose":0});
    }

    axios.get(URL_API+"getWinAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {
    
      //if(response.data.win > 0 && Array.isArray(response.data)){
        s_setWin(response.data);
        window.sessionStorage.setItem('s_win', JSON.stringify(response.data));
      //}

    }); 

    s_setProposerData(JSON.parse(window.sessionStorage.getItem("p_proposer")));
    axios.get(URL_API+"getPreparaListAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {
    
      if(response.data.length > 0 && Array.isArray(response.data)){
        s_setProposerData(response.data);
        window.sessionStorage.setItem('p_proposer', JSON.stringify(response.data));
      }

    }); 
    
    s_setOpponentData(JSON.parse(window.sessionStorage.getItem("p_opponent")));
    axios.get(URL_API+"getOpponentListAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {
      
      if(response.data.length > 0 && Array.isArray(response.data)){
        s_setOpponentData(response.data);
        window.sessionStorage.setItem('p_opponent', JSON.stringify(response.data));
      }
    });  
    
    //サーブ
    s_setPreparation(JSON.parse(window.sessionStorage.getItem("s_preparation")));
    axios.get(URL_API+"getNowPreparaListAPI", {
      params: {
        sel: session['id'], 
      }
    })
    .then(function (response) {

      if(response.data.length > 0 && Array.isArray(response.data)){       
        s_setPreparation(response.data);       
        window.sessionStorage.setItem('s_preparation', JSON.stringify(response.data));
      }

    }); 

  }, []);

  return (

    <>
      <div className="content">
        <NotificationAlert ref={notificationAlert} />
        <Row>
          <Col md="4">
            <Card className="card-user">
              
              <CardBody>
                <Row>
                  <Col className="col-5" >
                    <img
                      alt="..."
                      className="avatar border-gray"                      
                      src={selectedFile ? "https://xs458763.xsrv.jp/uploads/"+selectedFile : require("assets/img/mike.jpg").default}
                    />
                  </Col>
                  <Col className="col-7" >
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>                    
                      <h5 className="title" style={{marginTop : "10px"}}>{portfolio}</h5>
                    </a>                 
                  </Col>
                </Row>              
              </CardBody>
              <CardFooter>
                <hr />
                <div className="button-container">
                  <Row>
                    <Col className="col-5" >
                      <h5>
                        <small>{birthLab == null ? '' : birthLab}</small><br />
                        {oldLab == null ? '' : oldLab}才
                      </h5>
                    </Col>
                    <Col className="col-4">
                      <h5>
                        <small>身長 / 体重</small><br />
                        {hight == null ? '' : hight}cm {weight == null ? '' : weight}kg                        
                      </h5>
                    </Col>
                    <Col className="col-3">
                      <h5>
                        <small>受講回数</small><br />
                        {number == null ? '' : numberLab[number]}                       
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-5" >
                      <h5>
                        <small>関東U18 Rank</small><br />
                        {rank}                       
                      </h5>
                    </Col>
                    <Col className="col-4" >
                      <h5>
                        <small>2022　W-L </small><br />
                        {s_win.win} : {s_win.lose}                        
                      </h5>
                    </Col>
                    <Col className="col-3" >
                      <h5>
                        <small>勝率</small><br />
                        {Math.round(s_win.win / (s_win.win + s_win.lose) * 100)}％                       
                      </h5>
                    </Col>
                  </Row>
                </div>
              </CardFooter>
            </Card>            
          </Col>          
        </Row>
        <Row>  
          <Col md="12"> 
            <Card className="card-user">  
              <CardBody>                          
                <Col className="col-12">
                  <FormGroup> 
                    <Row>
                      <Col className="col-6">
                        <b>カテゴリー</b>
                      </Col>
                      <Col className="col-3">
                        <b>W-L</b>
                      </Col>
                      <Col className="col-3">
                        <b>%</b>
                      </Col>                              
                    </Row>    
                    {
                    s_winrate.map((winrate, index) =>
                      <Row key={index } style={{marginBottom:'3px'}}>
                        <Col className="col-6">
                          {winrate["surface"]}
                        </Col>
                        <Col className="col-3">
                          {winrate["win"]} - {winrate["lose"]}
                        </Col>
                        <Col className="col-3">
                          {Math.round(winrate["win"] / (winrate["win"] + winrate["lose"]) * 100)}%
                        </Col>
                      </Row>
                    )}
                  </FormGroup>
                </Col>  
              </CardBody>   
            </Card> 
          </Col>                                  
        </Row>
      </div>
    </>
  );
}

function profileUpdateFunc() {  
  
  var registerSession = JSON.stringify(session);
  window.sessionStorage.setItem("sessions", registerSession);
  let preparation = JSON.parse(window.sessionStorage.getItem("s_preparation"));
 
  let preparaAry = [];

  for(var i = 0; i < preparation.length; i++){
    preparaAry[i] = {};
    preparaAry[i].id = preparation[i].id;
    preparaAry[i].target = preparation[i].target;

  }

  axios.get(URL_API+"profileUpdateAPI", {
    params: {
      data: registerSession, 
      preparation : JSON.stringify(preparaAry),
      battle : window.sessionStorage.getItem("s_battle"),
      ranking : window.sessionStorage.getItem("s_ranking")
    }
  })
  .then(function (response) {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b>「プロフィール編集」</b>が 完了しました。
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  })
}
export default Dashboard;
