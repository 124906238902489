/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import gameImg from "assets/img/self/game.png";
import ballImg from "assets/img/self/ball.png";
import { useHistory } from 'react-router-dom';
import { Input } from "reactstrap";
import PlayerList from './PlayerList.js';

// reactstrap components
import NotificationAlert from "react-notification-alert";
import {  
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

let notificationAlert;
var aTimeout = null;

function Preparation() {

  let session = JSON.parse(window.sessionStorage.getItem('sessions')); 
  if(!session){    
    window.location = '/';
  }   

  notificationAlert = React.useRef();  
  
  const [p_proposer, s_setProposerData] = useState([]);
  const [playerIndex, setPlayerIndex] = useState(0);
  const [uFlag, setUFlag] = useState(true);
  //const [p_opponent, s_setOpponentData] = useState([]);
  
  const history = useHistory();
  
  const preparationUpdateFunc = (e) => {

    window.sessionStorage.setItem('presession', '{}');   
    history.push('../coach/preparationedit');

  };
  const getViewpageUrl = (tmp) => {

    window.sessionStorage.setItem('presession', JSON.stringify(tmp)); 
    history.push('../coach/preparationedit');

  };
  
  useEffect(() => { 

    if(!session){    
      window.location = '/';
    }        
    let p = JSON.parse(window.sessionStorage.getItem('player'));
    if(!p){
      p = JSON.parse(window.sessionStorage.getItem('players'))[0];
    }
    if(!p){
      // history.push('../coach/userMg');
      return;
    }
    //サーブ
    s_setProposerData(JSON.parse(window.sessionStorage.getItem("p_proposer")));
    axios.get(URL_API+"getPreparaListAPI", {
      params: {
        sel: p['id'], 
      }
    })
    .then(function (response) {
      if(Array.isArray(response.data)){
        s_setProposerData(response.data);
        window.sessionStorage.setItem('p_proposer', JSON.stringify(response.data));
      }
    }); 
  }, [uFlag]);

  const onSelectPlayer = (idx) => {
    setPlayerIndex(idx);
    setUFlag(!uFlag);
    // console.log(idx);
  }
  
  const [searchName, setSearchName] = useState('');
  const [players, setPlayers] = useState(JSON.parse(window.sessionStorage.getItem('players')) || []);

  const onSearchPlayer = (e) => {
    setSearchName(e.target.value);
    if(aTimeout)
      clearTimeout(aTimeout);

    aTimeout = setTimeout(function(){
      let players = JSON.parse(window.sessionStorage.getItem('players'));
      let result = [];
      for(let i=0;i<players.length;i++){
        if(players[i].name.indexOf(e.target.value) >= 0){
          result.push(players[i]);
        }
      }
      if(result.length > 0)
        window.sessionStorage.setItem('player', JSON.stringify(result[0]));   
      else
        window.sessionStorage.setItem('player', JSON.stringify({}));   
      setPlayers(result);
      setPlayerIndex(0);
      setUFlag(!uFlag);
    }, 500);
  }
 

  return (

    <>
      <div className="content px-0">
        <NotificationAlert ref={notificationAlert} />
        <Row className="px-0 mx-0">
          <Col className="biggoalcol">
            <label className="text-center m-1">
              <i className="fa fa-search" aria-hidden="true" style={{color:'rgb(3, 115, 202)'}}></i>
            </label>
            <Input className="mx-1" type="text" style={{border:'none', background:'white'}} placeholder="選手名" value={searchName} onChange={onSearchPlayer} id="technical3"/>                                            
          </Col>
        </Row>  
        <PlayerList playerIndex={playerIndex} players={players} onSelectPlayer={onSelectPlayer}/>
        <Row className="px-0 mx-0">          
          <Col md="12" className="px-0">
            <Card className="card-user">              
              <CardBody>
                <Form>
                  <Row>
                    <CardHeader>
                      <CardTitle tag="h6">トーナメントリスト</CardTitle>                
                    </CardHeader>
                    <Col md="12">                                         
                      {
                        p_proposer?.map((tmp, index) => 

                        <Row key={index} onClick={() => getViewpageUrl(tmp)} val={tmp.id}> 
                          <Col className="col-2" style={{color:'rgb(3, 115, 202)', fontSize:'16px', paddingRight:'0px', textAlign: 'center'}}>
                            <img src={gameImg}  width="25px"/>
                          </Col>                        
                          <Col className="col-3" style={{color:'rgb(3, 115, 202)', fontSize:'16px', padding:'0px'}}>
                            <b>{tmp.tournament}</b>
                          </Col>
                          <Col className="col-3" style={{color:'rgb(3, 115, 202)', fontSize:'14px', padding:'0px'}}>
                            {tmp.tourdate_}   
                          </Col>
                          <Col className="col-4" style={{color:'rgb(3, 115, 202)', fontSize:'14px', padding:'0px'}}>
                          {tmp.opponentgroup}<br/> {tmp.opponentname}
                          </Col>
                        </Row>
                        )
                      } 
                    </Col>
                  </Row>               
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}


export default Preparation;
