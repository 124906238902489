/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState, useEffect } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useHistory } from 'react-router-dom';

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
function AnalysisDataList() {
  let session = JSON.parse(window.sessionStorage.getItem('sessions'));
  if(!session)
    window.location = '/';

  const catg = JSON.parse(window.sessionStorage.getItem('a_category')) || {};
  // console.log(catg);
  // const [checkboxgroup, setCheckboxgroup] = useState(
  //   [{ en: 'serverData', jp: 'サーブ', fc: 'setServerData', val:true },
  //     { en: 'receiveData', jp: 'レシーブ', fc: 'setReceiveData', val:true},
  //     { en: 'strokeData', jp: 'ストローク', fc: 'setStrokeData', val:true},
  //     { en: 'netplayData', jp: 'ネットプレー', fc: 'setNetplayData', val:true},
  //     { en: 'mentalData', jp: 'メンタル', fc: 'setMentalData', val:true},
  //     { en: 'tacticsData', jp: '戦術', fc: 'setTacticsData', val:true},]
  // );
   
  const [serverData, setServerData] = useState([]);
  const [receiveData, setReceiveData] = useState([]);
  const [strokeData, setStrokeData] = useState([]);
  const [netplayData, setNetplayData] = useState([]);
  const [mentalData, setMentalData] = useState([]);
  const [tacticsData, setTacticsData] = useState([]);
  const [dragPadding, setDragPadding] = useState({paddingBottom:'15px'});
  // const [, setServerData] = useState([]);

  const history = useHistory();

  const analyserPage = (e) => {
    history.push('../coach/analysis');
  };
 
  const onAdd = (e) => {
    window.sessionStorage.setItem('newFlag', 1);
    history.push('../coach/analysisEdit');
  }

  const onSelectA = (e, tmp) => {
    window.sessionStorage.setItem('newFlag', 0);
    window.sessionStorage.setItem('a_data', JSON.stringify(tmp));
    history.push('../coach/analysisEdit');
  }

  const onDragStart = (a) => {
    setDragPadding({paddingBottom:'70px'});
  }

  const onDragEnd = (result) => {
    if(!result.source || !result.destination)
      return;
    setDragPadding({paddingBottom:'15px'});
    const newItems = Array.from(eval(catg.en));
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    for(let i=0;i<newItems.length;i++){
      newItems[i].ord = i+1;
    }
    eval(catg.fc)(newItems);
    axios.post(URL_API+"UpdateAnalysisOrd", {
      params: {
        newItems:newItems,
        // seldate: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), 
        user: session['id'],
      }
    })
    .then(function (response) {
    }).catch(
      error => {
        if(error.response){
          console.log(error.response.data);
        };
      }
    ); 
    // console.log(newItems);
  };

  useEffect(() => { 
    if(catg && catg != {}){
      if(JSON.parse(window.sessionStorage.getItem(catg['en'])) != null)
        eval(catg['fc'])(JSON.parse(window.sessionStorage.getItem(catg['en'])));

      axios.get(URL_API+"getSelfAnaAPI", {
        params: {
          data: catg['en'],
          user: session['id'], 
        }
      })
      .then(function (response) {
        
        if(response.data !== null && response.data.length > 0){           
          
          window.sessionStorage.setItem(response.data[0]['group_id'], JSON.stringify(response.data));

          eval(response.data[0]['fc'])(JSON.parse(window.sessionStorage.getItem(response.data[0]['group_id'])));

        }

      });
    }
  }, []);

  return (
    <>
      <div className="content px-0 pb-0">
        <CardBody className="seldate p-1 d-flex" id='seldate' style={{color:'white'}}>
          <div className="d-flex col-12 px-2">
            <div className="col-3 col-lg-2 d-flex align-items-center justify-content-start px-0">
              <span className="p-1" style={{fontSize:'14px'}} onClick={(e) => history.push('../coach/analysis')}>
                <i className="fas fa-chevron-left" style={{fontSize: '24px'}}></i>
              </span>
            </div>
            <div className="col-6 col-lg-8 text-center px-0 d-flex align-items-center justify-content-center head_title">
                
            </div>
            <div className="col-3 col-lg-2 d-flex align-items-center justify-content-end px-0">
              <span className="p-1" style={{fontSize:'14px'}} onClick={(e) => onAdd(e)}><i className="fas fa-plus" style={{fontSize: '24px', color:'white'}}></i></span>
            </div>
          </div>
        </CardBody>
        <Card className="mb-0">
        <Row className="px-0 mx-0 an_list">
          <Col md="12" className="px-0">            
            {
              (catg && catg != {}) && ( 
                <div className="demo-icons">
                  <CardHeader>
                    <CardTitle tag="h6">{catg.jp}</CardTitle>                
                  </CardHeader>
                  <CardBody className="all-icons" style={dragPadding}> 
                    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {
                              eval(catg.en).map((tmp, index) => 
                                <Draggable key={tmp.id} draggableId={"dnd_"+tmp.id} index={index}>
                                  {(provided, snapshot) => (
                                    <div className="col-12 border-bottom py-1" key={index} onClick={(event) => onSelectA(event, tmp) } ref={provided.innerRef} snapshot={snapshot}  {...provided.draggableProps} {...provided.dragHandleProps}>
                                      <h6> 
                                        {tmp.item} <br/>    
                                        <small> </small>                 
                                      </h6>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            }                
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </CardBody>
                </div>
              )
            }
          </Col>
        </Row>
        </Card>
      </div>
    </>
  );
}
export default AnalysisDataList;
