/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
// import questionImg from "assets/img/self/question.png";
import ball from "assets/img/ball.jpg";

import { useHistory } from 'react-router-dom';
// reactstrap components

import NotificationAlert from "react-notification-alert";
import {  
  Card,  
  CardBody,
  CardTitle,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let selTables = {};
let session;
let notificationAlert;
let selTab = {};
let se;

function Tablesedit() {

  session = window.sessionStorage.getItem('sessions'); 
    
  if(session === null){    
    window.location = '/';
  }

  se = JSON.parse(session);

  notificationAlert = React.useRef(); 

  selTables = JSON.parse(window.sessionStorage.getItem('selTables'));

  const history = useHistory();
  
  const preparationPage = (e) => {   
    history.push('../admin/tables');
  }
///////////////////
  const [resthink1, setResthink1] = useState();
  const [resthink2, setResthink2] = useState();
  const [resthink3, setResthink3] = useState();
  const [resthink4, setResthink4] = useState();
  const [resthink5, setResthink5] = useState();

  const getInputResthink1Value = (event)=>{    
    setResthink1(event.target.value);     
    selTab['resthink1'] = event.target.value;
  };
  const getInputResthink2Value = (event)=>{    
    setResthink2(event.target.value);     
    selTab['resthink2'] = event.target.value;
  };
  const getInputResthink3Value = (event)=>{    
    setResthink3(event.target.value);     
    selTab['resthink3'] = event.target.value;
  };
  const getInputResthink4Value = (event)=>{    
    setResthink4(event.target.value);     
    selTab['resthink4'] = event.target.value;
  };
  const getInputResthink5Value = (event)=>{    
    setResthink5(event.target.value);     
    selTab['resthink5'] = event.target.value;
  };

  const [offort, setOffort] = useState();
  const [fight, setFight] = useState();
  const getInputOffortValue = (event)=>{    
    setOffort(event.target.value);     
    selTab['offort'] = event.target.value;
  };
  const getInputFightValue = (event)=>{    
    setFight(event.target.value);     
    selTab['fight'] = event.target.value;
  };
  /////////////////////// start //////////////////////
  const [g1_1, setg1_1] = useState(0);
  const [g1_2, setg1_2] = useState(0);
  const [g1_3, setg1_3] = useState(0);
  const [g1_4, setg1_4] = useState(0);
  const [g1_5, setg1_5] = useState(0);
  const [g1_6, setg1_6] = useState(0);
  const [g1_7, setg1_7] = useState(0);
  const [g1_8, setg1_8] = useState(0);
  const [g1_9, setg1_9] = useState(0);
  const [g1_10, setg1_10] = useState(0);
  const [g1_11, setg1_11] = useState(0);
  const [g1_12, setg1_12] = useState(0);
  const [g1_13, setg1_13] = useState(0);
  const [g1_14, setg1_14] = useState(0);
  const [g1_15, setg1_15] = useState(0);
  const [g1_16, setg1_16] = useState(0);
  const [g1_17, setg1_17] = useState(0);

  const [_g1_1, set_g1_1] = useState(0);
  const [_g1_2, set_g1_2] = useState(0);
  const [_g1_3, set_g1_3] = useState(0);
  const [_g1_4, set_g1_4] = useState(0);
  const [_g1_5, set_g1_5] = useState(0);
  const [_g1_6, set_g1_6] = useState(0);
  const [_g1_7, set_g1_7] = useState(0);
  const [_g1_8, set_g1_8] = useState(0);
  const [_g1_9, set_g1_9] = useState(0);
  const [_g1_10, set_g1_10] = useState(0);
  const [_g1_11, set_g1_11] = useState(0);
  const [_g1_12, set_g1_12] = useState(0);
  const [_g1_13, set_g1_13] = useState(0);
  const [_g1_14, set_g1_14] = useState(0);
  const [_g1_15, set_g1_15] = useState(0);
  const [_g1_16, set_g1_16] = useState(0);
  const [_g1_17, set_g1_17] = useState(0);

  const [g2_1, setg2_1] = useState(0);
  const [g2_2, setg2_2] = useState(0);
  const [g2_3, setg2_3] = useState(0);
  const [g2_4, setg2_4] = useState(0);
  const [g2_5, setg2_5] = useState(0);
  const [g2_6, setg2_6] = useState(0);
  const [g2_7, setg2_7] = useState(0);
  const [g2_8, setg2_8] = useState(0);
  const [g2_9, setg2_9] = useState(0);
  const [g2_10, setg2_10] = useState(0);
  const [g2_11, setg2_11] = useState(0);
  const [g2_12, setg2_12] = useState(0);
  const [g2_13, setg2_13] = useState(0);
  const [g2_14, setg2_14] = useState(0);
  const [g2_15, setg2_15] = useState(0);
  const [g2_16, setg2_16] = useState(0);
  const [g2_17, setg2_17] = useState(0);

  const [_g2_1, set_g2_1] = useState(0);
  const [_g2_2, set_g2_2] = useState(0);
  const [_g2_3, set_g2_3] = useState(0);
  const [_g2_4, set_g2_4] = useState(0);
  const [_g2_5, set_g2_5] = useState(0);
  const [_g2_6, set_g2_6] = useState(0);
  const [_g2_7, set_g2_7] = useState(0);
  const [_g2_8, set_g2_8] = useState(0);
  const [_g2_9, set_g2_9] = useState(0);
  const [_g2_10, set_g2_10] = useState(0);
  const [_g2_11, set_g2_11] = useState(0);
  const [_g2_12, set_g2_12] = useState(0);
  const [_g2_13, set_g2_13] = useState(0);
  const [_g2_14, set_g2_14] = useState(0);
  const [_g2_15, set_g2_15] = useState(0);
  const [_g2_16, set_g2_16] = useState(0);
  const [_g2_17, set_g2_17] = useState(0);

  const [g3_1, setg3_1] = useState(0);
  const [g3_2, setg3_2] = useState(0);
  const [g3_3, setg3_3] = useState(0);
  const [g3_4, setg3_4] = useState(0);
  const [g3_5, setg3_5] = useState(0);
  const [g3_6, setg3_6] = useState(0);
  const [g3_7, setg3_7] = useState(0);
  const [g3_8, setg3_8] = useState(0);
  const [g3_9, setg3_9] = useState(0);
  const [g3_10, setg3_10] = useState(0);
  const [g3_11, setg3_11] = useState(0);
  const [g3_12, setg3_12] = useState(0);
  const [g3_13, setg3_13] = useState(0);
  const [g3_14, setg3_14] = useState(0);
  const [g3_15, setg3_15] = useState(0);
  const [g3_16, setg3_16] = useState(0);
  const [g3_17, setg3_17] = useState(0);


  const [_g3_1, set_g3_1] = useState(0);
  const [_g3_2, set_g3_2] = useState(0);
  const [_g3_3, set_g3_3] = useState(0);
  const [_g3_4, set_g3_4] = useState(0);
  const [_g3_5, set_g3_5] = useState(0);
  const [_g3_6, set_g3_6] = useState(0);
  const [_g3_7, set_g3_7] = useState(0);
  const [_g3_8, set_g3_8] = useState(0);
  const [_g3_9, set_g3_9] = useState(0);
  const [_g3_10, set_g3_10] = useState(0);
  const [_g3_11, set_g3_11] = useState(0);
  const [_g3_12, set_g3_12] = useState(0);
  const [_g3_13, set_g3_13] = useState(0);
  const [_g3_14, set_g3_14] = useState(0);
  const [_g3_15, set_g3_15] = useState(0);
  const [_g3_16, set_g3_16] = useState(0);
  const [_g3_17, set_g3_17] = useState(0);

  const[win1, setwin1] = useState(0);
  const[lose1, setlose1] = useState(0);
  const[win2, setwin2] = useState(0);
  const[lose2, setlose2] = useState(0);
  const[win3, setwin3] = useState(0);
  const[lose3, setlose3] = useState(0);

  const handleOnChange = (e, position, r)=>{    
    let f = eval(position);
    let l = eval("_"+position);

    f = Number(!f);
    l = f == 1 ? 0 : l;

    eval("set"+position)(f);
    eval("set_"+position)(l);
    selTab[position] = f;
    selTab["_"+position] = l;

    selTab["win"+r] = 0;
    selTab["lose"+r] = 0;

    eval("setwin"+r)(0);
    eval("setlose"+r)(0);

    for(var i = 1; i < 18; i++){

      if(eval(selTab["g"+r+"_"+i]) == 1){
        
        selTab["win"+r] = selTab["win"+r] + 1;
      }
      if(eval(selTab["_g"+r+"_"+i]) == 1){
        selTab["lose"+r] = selTab["lose"+r] + 1;
      }
    }
    eval("setwin"+r)(selTab["win"+r]);
    eval("setlose"+r)(selTab["lose"+r]);

  };

  const _handleOnChange = (e, position, r)=>{

    let f = eval(position);
    let l = eval("_"+position);

    l = Number(!l);
    f = l == 1 ? 0 : f;

    eval("setwin"+r)(0);
    eval("setlose"+r)(0);
    
    eval("set_"+position)(l);
    eval("set"+position)(f);
    selTab[position] = f;
    selTab["_"+position] = l;

    selTab["win"+r] = 0;
    selTab["lose"+r] = 0;

    for(var i = 1; i < 18; i++){
      if(eval(selTab["g"+r+"_"+i]) == 1){
        selTab["win"+r] = selTab["win"+r] + 1;
      }
      if(eval(selTab["_g"+r+"_"+i]) == 1){
        selTab["lose"+r] = selTab["lose"+r] + 1;
      }
    }
    eval("setwin"+r)(selTab["win"+r]);
    eval("setlose"+r)(selTab["lose"+r]);
  };

  ////////////////////////////////////////////////////
  const [improved1, setImproved1] = useState();
  const getInputImproved1Value = (event)=>{    
    setImproved1(event.target.value);     
    selTab['improved1'] = event.target.value;
  };
  const [improved2, setImproved2] = useState();
  const getInputImproved2Value = (event)=>{    
    setImproved2(event.target.value);     
    selTab['improved2'] = event.target.value;
  };
  const [improved3, setImproved3] = useState();
  const getInputImproved3Value = (event)=>{    
    setImproved3(event.target.value);     
    selTab['improved3'] = event.target.value;
  };
  const [improved4, setImproved4] = useState();
  const getInputImproved4Value = (event)=>{    
    setImproved4(event.target.value);     
    selTab['improved4'] = event.target.value;
  };
  const [improved5, setImproved5] = useState();
  const getInputImproved5Value = (event)=>{    
    setImproved5(event.target.value);     
    selTab['improved5'] = event.target.value;
  };
  //HowPlay
  const [howplay1, setHowplay1] = useState();
  const getInputHowplay1Value = (event)=>{    
    setHowplay1(event.target.value);     
    selTab['howplay1'] = event.target.value;
  };
  const [howplay2, setHowplay2] = useState();
  const getInputHowplay2Value = (event)=>{    
    setHowplay2(event.target.value);     
    selTab['howplay2'] = event.target.value;
  };
  const [howplay3, setHowplay3] = useState();
  const getInputHowplay3Value = (event)=>{    
    setHowplay3(event.target.value);     
    selTab['howplay3'] = event.target.value;
  };
  const [howplay4, setHowplay4] = useState();
  const getInputHowplay4Value = (event)=>{    
    setHowplay4(event.target.value);     
    selTab['howplay4'] = event.target.value;
  };
  const [howplay5, setHowplay5] = useState();
  const getInputHowplay5Value = (event)=>{    
    setHowplay5(event.target.value);     
    selTab['howplay5'] = event.target.value;
  };

  const [mental1, setMental1] = useState();
  const [mental2, setMental2] = useState();
  const [mental3, setMental3] = useState();
  const [mental4, setMental4] = useState();
  const [mental5, setMental5] = useState();
  const [mental6, setMental6] = useState();
  const [mental7, setMental7] = useState();
  const [mental8, setMental8] = useState();
  const [mental9, setMental9] = useState();
  const [mental10, setMental10] = useState();
  const [mental11, setMental11] = useState();
  const [mental12, setMental12] = useState();

  const getInputMental1Value = (event)=>{    
    setMental1(event.target.value);     
    selTab['mental1'] = event.target.value;
  };
  const getInputMental2Value = (event)=>{    
    setMental2(event.target.value);     
    selTab['mental2'] = event.target.value;
  };
  const getInputMental3Value = (event)=>{    
    setMental3(event.target.value);     
    selTab['mental3'] = event.target.value;
  };
  const getInputMental4Value = (event)=>{    
    setMental4(event.target.value);     
    selTab['mental4'] = event.target.value;
  };
  const getInputMental5Value = (event)=>{    
    setMental5(event.target.value);     
    selTab['mental5'] = event.target.value;
  };
  const getInputMental6Value = (event)=>{    
    setMental6(event.target.value);     
    selTab['mental6'] = event.target.value;
  };
  const getInputMental7Value = (event)=>{    
    setMental7(event.target.value);     
    selTab['mental7'] = event.target.value;
  };
  const getInputMental8Value = (event)=>{    
    setMental8(event.target.value);     
    selTab['mental8'] = event.target.value;
  };
  const getInputMental9Value = (event)=>{    
    setMental9(event.target.value);     
    selTab['mental9'] = event.target.value;
  };
  const getInputMental10Value = (event)=>{    
    setMental10(event.target.value);     
    selTab['mental10'] = event.target.value;
  };
  const getInputMental11Value = (event)=>{    
    setMental11(event.target.value);     
    selTab['mental11'] = event.target.value;
  };
  const getInputMental12Value = (event)=>{    
    setMental12(event.target.value);     
    selTab['mental12'] = event.target.value;
  };

  const [remark, setRemark] = useState();
  const getInputRemarkValue = (event)=>{    
    setRemark(event.target.value);     
    selTab['remark'] = event.target.value;
  };
///////////////////
  const [checkboxgroup, setCheckboxgroup] = useState(
    [ { en: 's_serverData', jp: 'サーブ', fc: 's_setServerData', val:true },
      { en: 's_receiveData', jp: 'レシーブ', fc: 's_setReceiveData', val:true},
      { en: 's_strokeData', jp: 'ストローク', fc: 's_setStrokeData', val:true},
      { en: 's_netplayData', jp: 'ネットプレー', fc: 's_setNetplayData', val:true},
      { en: 's_mentalData', jp: 'メンタル', fc: 's_setMentalData', val:true},
      { en: 's_tacticsData', jp: '戦術', fc: 's_setTacticsData', val:true},]
    );

  const [s_serverData, s_setServerData] = useState([]);
  const [s_receiveData, s_setReceiveData] = useState([]);
  const [s_strokeData, s_setStrokeData] = useState([]);
  const [s_netplayData, s_setNetplayData] = useState([]);
  const [s_mentalData, s_setMentalData] = useState([]);
  const [s_tacticsData, s_setTacticsData] = useState([]);
  
  const [checkedState, setCheckedState] = useState(
    new Array(s_serverData.length).fill(false)
  );
  
  const profileUpdateFunc = (e) => {

    window.sessionStorage.setItem("other", selTables['id']);
    history.push('../sub/other');
  }

  const analysisOnChange = (e, position, aryName, fcName) => {
    
    eval(aryName)[position]['solution'] = e.target.value;
    window.sessionStorage.setItem(aryName, JSON.stringify(eval(aryName))); 
    eval(fcName)(eval(aryName));

    let tmp = [...checkboxgroup];
    tmp[0]['val'] = !tmp[0]['val'];
    setCheckboxgroup(tmp);

  };
  const [coachs, setCoachs] = useState(JSON.parse(window.sessionStorage.getItem('coachs')));
  const onSendMsg = (e, pid) => {
    // console.log(pid);
    if(window.confirm("選手様に送信しますか？")){
      for(let i=0;i<coachs.length;i++){
        axios.get(URL_API+"setMessages", {
          params: {
            coach_id : coachs[i]['id'],
            player_id : se['id'],
            content : '<p>'+se['name']+'選手様からメッセージが来ました。</p><p>"'+selTables['tournament']+' '+selTables['tourdate']+' '+selTables['opponentgroup']+' '+selTables['opponentname']+'"</p>',
            t : pid,
            f : 0
          }
        })
        .then(function (response) {
          // setUFlag(uFlag+1);
          // setMsg('');
        }).catch(
          error => {
            if(error.response){
              console.log(error.response.data);
            };
          }
        ); 
      }
    }
  }

  const sendPreparationData = (e, f) => {
    tablesUpdateFunc(e, f);
  }

  const tablesUpdateFunc = (e, f) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
     
    var data = JSON.stringify(selTab);
    
    axios.get(URL_API+"tablesUpdateAPI", {
      params: {
        data: data, 
        user_id: selTables["proposer"],
        sel: selTables["id"],
      }
    })
    .then(function (response) {    
      if(f == 1){
        // console.log(response.data.id);
        onSendMsg(e, selTables['id']);
        return;
      }
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>
                <b>「試合後リフレクション画面編集」</b>が 完了しました。
              </div>
            </div>
          ),
          type: type,
          icon: "nc-icon nc-bell-55",
          autoDismiss: 7,
        };
          
      notificationAlert.current.notificationAlert(options);
    })
  }
  

  useEffect(() => {  
    

    //戦術
    selTab["lose1"] = 0;
    selTab["win1"] = 0;
    selTab["lose2"] = 0;
    selTab["win2"] = 0;
    selTab["lose3"] = 0;
    selTab["win3"] = 0;

    for(var i = 0 ; i < checkboxgroup.length; i++){

      axios.get(URL_API+"getOtherSelAPI", {
        params: {
          data: checkboxgroup[i]['en'],
          sel: selTables['id'], 
        }
      })
      .then(function (response) {
        
        if(response.data.length > 0 && Array.isArray(response.data)){           
          eval(response.data[0]['fc'])(response.data);  
        }

      });
    }

    //selGame. selTables

    axios.get(URL_API+"getSelTableAPI", {
      params: {       
        sel: selTables['id'], 
      }
    })
    .then(function (response) {
      
      window.sessionStorage.setItem('selTab', JSON.stringify(response.data));

      if(response.data.length > 0 && Array.isArray(response.data)){

        selTab = response.data[0];
    
        setResthink1(selTab['resthink1']);
        setResthink2(selTab['resthink2']);
        setResthink3(selTab['resthink3']);
        setResthink4(selTab['resthink4']);
        setResthink5(selTab['resthink5']);

        setOffort(selTab['offort']);
        setFight(selTab['fight']);

        setg1_1(selTab['g1_1']);
        setg1_2(selTab['g1_2']);
        setg1_3(selTab['g1_3']);
        setg1_4(selTab['g1_4']);
        setg1_5(selTab['g1_5']);
        setg1_6(selTab['g1_6']);
        setg1_7(selTab['g1_7']);
        setg1_8(selTab['g1_8']);
        setg1_9(selTab['g1_9']);
        setg1_10(selTab['g1_10']);
        setg1_11(selTab['g1_11']);
        setg1_12(selTab['g1_12']);
        setg1_13(selTab['g1_13']);
        setg1_14(selTab['g1_14']);
        setg1_15(selTab['g1_15']);
        setg1_16(selTab['g1_16']);
        setg1_17(selTab['g1_17']);

        set_g1_1(selTab['_g1_1']);
        set_g1_2(selTab['_g1_2']);
        set_g1_3(selTab['_g1_3']);
        set_g1_4(selTab['_g1_4']);
        set_g1_5(selTab['_g1_5']);
        set_g1_6(selTab['_g1_6']);
        set_g1_7(selTab['_g1_7']);
        set_g1_8(selTab['_g1_8']);
        set_g1_9(selTab['_g1_9']);
        set_g1_10(selTab['_g1_10']);
        set_g1_11(selTab['_g1_11']);
        set_g1_12(selTab['_g1_12']);
        set_g1_13(selTab['_g1_13']);
        set_g1_14(selTab['_g1_14']);
        set_g1_15(selTab['_g1_15']);
        set_g1_16(selTab['_g1_16']);
        set_g1_17(selTab['_g1_17']);

        setg2_1(selTab['g2_1']);
        setg2_2(selTab['g2_2']);
        setg2_3(selTab['g2_3']);
        setg2_4(selTab['g2_4']);
        setg2_5(selTab['g2_5']);
        setg2_6(selTab['g2_6']);
        setg2_7(selTab['g2_7']);
        setg2_8(selTab['g2_8']);
        setg2_9(selTab['g2_9']);
        setg2_10(selTab['g2_10']);
        setg2_11(selTab['g2_11']);
        setg2_12(selTab['g2_12']);
        setg2_13(selTab['g2_13']);
        setg2_14(selTab['g2_14']);
        setg2_15(selTab['g2_15']);
        setg2_16(selTab['g2_16']);
        setg2_17(selTab['g2_17']);

        set_g2_1(selTab['_g2_1']);
        set_g2_2(selTab['_g2_2']);
        set_g2_3(selTab['_g2_3']);
        set_g2_4(selTab['_g2_4']);
        set_g2_5(selTab['_g2_5']);
        set_g2_6(selTab['_g2_6']);
        set_g2_7(selTab['_g2_7']);
        set_g2_8(selTab['_g2_8']);
        set_g2_9(selTab['_g2_9']);
        set_g2_10(selTab['_g2_10']);
        set_g2_11(selTab['_g2_11']);
        set_g2_12(selTab['_g2_12']);
        set_g2_13(selTab['_g2_13']);
        set_g2_14(selTab['_g2_14']);
        set_g2_15(selTab['_g2_15']);
        set_g2_16(selTab['_g2_16']);
        set_g2_17(selTab['_g2_17']);

        setg3_1(selTab['g3_1']);
        setg3_2(selTab['g3_2']);
        setg3_3(selTab['g3_3']);
        setg3_4(selTab['g3_4']);
        setg3_5(selTab['g3_5']);
        setg3_6(selTab['g3_6']);
        setg3_7(selTab['g3_7']);
        setg3_8(selTab['g3_8']);
        setg3_9(selTab['g3_9']);
        setg3_10(selTab['g3_10']);
        setg3_11(selTab['g3_11']);
        setg3_12(selTab['g3_12']);
        setg3_13(selTab['g3_13']);
        setg3_14(selTab['g3_14']);
        setg3_15(selTab['g3_15']);
        setg3_16(selTab['g3_16']);
        setg3_17(selTab['g3_17']);

        set_g3_1(selTab['_g3_1']);
        set_g3_2(selTab['_g3_2']);
        set_g3_3(selTab['_g3_3']);
        set_g3_4(selTab['_g3_4']);
        set_g3_5(selTab['_g3_5']);
        set_g3_6(selTab['_g3_6']);
        set_g3_7(selTab['_g3_7']);
        set_g3_8(selTab['_g3_8']);
        set_g3_9(selTab['_g3_9']);
        set_g3_10(selTab['_g3_10']);
        set_g3_11(selTab['_g3_11']);
        set_g3_12(selTab['_g3_12']);
        set_g3_13(selTab['_g3_13']);
        set_g3_14(selTab['_g3_14']);
        set_g3_15(selTab['_g3_15']);
        set_g3_16(selTab['_g3_16']);
        set_g3_17(selTab['_g3_17']);

        setImproved1(selTab['improved1']);
        setImproved2(selTab['improved2']);
        setImproved3(selTab['improved3']);
        setImproved4(selTab['improved4']);
        setImproved5(selTab['improved5']);

        setHowplay1(selTab['howplay1']);
        setHowplay2(selTab['howplay2']);
        setHowplay3(selTab['howplay3']);
        setHowplay4(selTab['howplay4']);
        setHowplay5(selTab['howplay5']);

        setMental1(selTab['mental1']);
        setMental2(selTab['mental2']);
        setMental3(selTab['mental3']);
        setMental4(selTab['mental4']);
        setMental5(selTab['mental5']);
        setMental6(selTab['mental6']);
        setMental7(selTab['mental7']);
        setMental8(selTab['mental8']);
        setMental9(selTab['mental9']);
        setMental10(selTab['mental10']);
        setMental11(selTab['mental11']);
        setMental12(selTab['mental12']);

        setRemark(selTab['remark']);

        setwin3(selTab['win3']);
        setwin1(selTab['win1']);
        setwin2(selTab['win2']);

        setlose1(selTab['lose1']);
        setlose2(selTab['lose2']);
        setlose3(selTab['lose3']);
      }
      
    });
    

  }, []);
  return (

    <>
      <div className="content pt-2">
        <NotificationAlert ref={notificationAlert}  className="col-12"/>
        <Row>          
          <Col className="col-12">
            <h6>{selTables.tournament}</h6>
            <Card className="card-user">              
              <CardBody>
                <Form>
                  <Row>                    
                    <Col className="col-8">                      
                        <h6>{selTables.think1}</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <select className="form-control" id="resthink1"
                          value={resthink1 == null ? '' : resthink1} 
                          onChange={getInputResthink1Value} 
                        >
                          <option value=""></option>
                          <option value="1">1/10</option>
                          <option value="2">2/10</option>
                          <option value="3">3/10</option>
                          <option value="4">4/10</option>
                          <option value="5">5/10</option>
                          <option value="6">6/10</option>
                          <option value="7">7/10</option>
                          <option value="8">8/10</option>
                          <option value="9">9/10</option>
                          <option value="10">10/10</option>
                        </select>
                    </Col>   
                  </Row>
                
                  <Row>                    
                    <Col className="col-8">                      
                        <h6>{selTables.think2}</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <select className="form-control" id="resthink2"
                          value={resthink2 == null ? '' : resthink2} 
                          onChange={getInputResthink2Value} 
                        >
                          <option value=""></option>
                          <option value="1">1/10</option>
                          <option value="2">2/10</option>
                          <option value="3">3/10</option>
                          <option value="4">4/10</option>
                          <option value="5">5/10</option>
                          <option value="6">6/10</option>
                          <option value="7">7/10</option>
                          <option value="8">8/10</option>
                          <option value="9">9/10</option>
                          <option value="10">10/10</option>
                        </select>
                    </Col>     
                  </Row>
                  <Row>                    
                    <Col className="col-8">                      
                        <h6>{selTables.think3}</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <select className="form-control" id="resthink3"
                          value={resthink3 == null ? '' : resthink3} 
                          onChange={getInputResthink3Value} 
                        >
                          <option value=""></option>
                          <option value="1">1/10</option>
                          <option value="2">2/10</option>
                          <option value="3">3/10</option>
                          <option value="4">4/10</option>
                          <option value="5">5/10</option>
                          <option value="6">6/10</option>
                          <option value="7">7/10</option>
                          <option value="8">8/10</option>
                          <option value="9">9/10</option>
                          <option value="10">10/10</option>
                        </select>
                    </Col>      
                  </Row>
                  <Row>                    
                    <Col className="col-8">                      
                        <h6>{selTables.think4}</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <select className="form-control" id="resthink4"
                          value={resthink4 == null ? '' : resthink4} 
                          onChange={getInputResthink4Value} 
                        >
                          <option value=""></option>
                          <option value="1">1/10</option>
                          <option value="2">2/10</option>
                          <option value="3">3/10</option>
                          <option value="4">4/10</option>
                          <option value="5">5/10</option>
                          <option value="6">6/10</option>
                          <option value="7">7/10</option>
                          <option value="8">8/10</option>
                          <option value="9">9/10</option>
                          <option value="10">10/10</option>
                        </select>
                    </Col>     
                  </Row>
                  <Row>                    
                    <Col className="col-8">                      
                        <h6>{selTables.think5}</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <select className="form-control" id="resthink5"
                           value={resthink5 == null ? '' : resthink5} 
                           onChange={getInputResthink5Value} 
                        >
                          <option value=""></option>
                          <option value="1">1/10</option>
                          <option value="2">2/10</option>
                          <option value="3">3/10</option>
                          <option value="4">4/10</option>
                          <option value="5">5/10</option>
                          <option value="6">6/10</option>
                          <option value="7">7/10</option>
                          <option value="8">8/10</option>
                          <option value="9">9/10</option>
                          <option value="10">10/10</option>
                        </select>
                    </Col>     
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card className="card-user">              
              <CardBody>
                <Form>
                  <Row>                    
                    <Col className="col-8" style={{paddingTop:'10px'}}>                         
                        <h6>努力・闘志の自己評価</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <select className="form-control" id="offort"
                          value={offort == null ? '' : offort} 
                          onChange={getInputOffortValue} 
                        >
                          <option value=""></option>
                          <option value="1">1/10</option>
                          <option value="2">2/10</option>
                          <option value="3">3/10</option>
                          <option value="4">4/10</option>
                          <option value="5">5/10</option>
                          <option value="6">6/10</option>
                          <option value="7">7/10</option>
                          <option value="8">8/10</option>
                          <option value="9">9/10</option>
                          <option value="10">10/10</option>
                        </select>
                    </Col>   
                  </Row>
                
                  <Row>                    
                    <Col className="col-8" style={{paddingTop:'10px'}}>                      
                        <h6>プレーの自己評価</h6>                                        
                    </Col> 
                    <Col className="col-4"> 
                      <select className="form-control" id="fight"
                          value={fight == null ? '' : fight} 
                          onChange={getInputFightValue} 
                        >
                          <option value=""></option>
                          <option value="1">1/10</option>
                          <option value="2">2/10</option>
                          <option value="3">3/10</option>
                          <option value="4">4/10</option>
                          <option value="5">5/10</option>
                          <option value="6">6/10</option>
                          <option value="7">7/10</option>
                          <option value="8">8/10</option>
                          <option value="9">9/10</option>
                          <option value="10">10/10</option>
                        </select>
                    </Col>     
                  </Row>  

                   <Row>                    
                    <Col className="col-3" style={{paddingTop:'15px'}}>                         
                        <h6>試合結果</h6>                                        
                    </Col> 
                    <Col className="col-9" style={{paddingTop:'10px', color:'#FF0000'}}> 
                      <h5><b>{win1} - {lose1}、 {win2} - {lose2}、 {win3} - {lose3}</b></h5>
                    </Col>   
                  </Row>
                  <Row>                    
                    <Col className="col-12" style={{paddingTop:'15px'}}>                         
                        <h6>試合の流れ</h6>                                        
                    </Col>
                  </Row> 
                  <Row className="mx-0 px-0">                    
                    <Col className="col-12 px-0" style={{paddingTop:'15px', overflow:'auto'}}>                         
                      <table border='1' style={{width:'760px'}}>
                        <tbody>
                          <tr height="40px">
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>             
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                            <td>10</td>
                            <td>11</td>
                            <td>12</td>
                            <td>13</td>
                            <td>14</td>
                            <td>15</td>
                            <td>16</td>
                            <td>T</td>
                            <td style={{color:'#FF0000'}}><b>結果</b></td>
                          </tr>                       
                          <tr height="40px">
                            <td width="40px">自分</td>
                            <td width="40px" id="g1_1" onClick={(e) => handleOnChange(e, 'g1_1', 1)}>{g1_1 == 1? <img src={ball} width="30px"/> : ''}</td>
                            <td width="40px" id="g1_2" onClick={(e) => handleOnChange(e, 'g1_2', 1)}>{g1_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_3" onClick={(e) => handleOnChange(e, 'g1_3', 1)}>{g1_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_4" onClick={(e) => handleOnChange(e, 'g1_4', 1)}>{g1_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_5" onClick={(e) => handleOnChange(e, 'g1_5', 1)}>{g1_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_6" onClick={(e) => handleOnChange(e, 'g1_6', 1)}>{g1_6 == 1?<img src={ball} width="30px"/>: ''}</td>                          
                            <td width="40px" id="g1_7" onClick={(e) => handleOnChange(e, 'g1_7', 1)}>{g1_7 == 1?<img src={ball} width="30px"/>: ''}</td>     
                            <td width="40px" id="g1_8" onClick={(e) => handleOnChange(e, 'g1_8', 1)}>{g1_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_9" onClick={(e) => handleOnChange(e, 'g1_9', 1)}>{g1_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_10" onClick={(e) => handleOnChange(e, 'g1_10', 1)}>{g1_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_11" onClick={(e) => handleOnChange(e, 'g1_11', 1)}>{g1_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_12" onClick={(e) => handleOnChange(e, 'g1_12', 1)}>{g1_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_13" onClick={(e) => handleOnChange(e, 'g1_13', 1)}>{g1_13 == 1?<img src={ball} width="30px"/>: ''}</td> 
                            <td width="40px" id="g1_14" onClick={(e) => handleOnChange(e, 'g1_14', 1)}>{g1_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_15" onClick={(e) => handleOnChange(e, 'g1_15', 1)}>{g1_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_16" onClick={(e) => handleOnChange(e, 'g1_16', 1)}>{g1_16 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g1_17" onClick={(e) => handleOnChange(e, 'g1_17', 1)}>{g1_17 == 1?<img src={ball} width="30px"/>: ''}</td>     
                            <td style={{color:'#FF0000'}}><b>{win1}</b></td>                    
                          </tr>
                          <tr height="40px">
                            <td width="40px">相手</td>
                            <td width="40px" id="_g1_1" onClick={(e) => _handleOnChange(e, 'g1_1', 1)}>{_g1_1 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_2" onClick={(e) => _handleOnChange(e, 'g1_2', 1)}>{_g1_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_3" onClick={(e) => _handleOnChange(e, 'g1_3', 1)}>{_g1_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_4" onClick={(e) => _handleOnChange(e, 'g1_4', 1)}>{_g1_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_5" onClick={(e) => _handleOnChange(e, 'g1_5', 1)}>{_g1_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_6" onClick={(e) => _handleOnChange(e, 'g1_6', 1)}>{_g1_6 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_7" onClick={(e) => _handleOnChange(e, 'g1_7', 1)}>{_g1_7 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_8" onClick={(e) => _handleOnChange(e, 'g1_8', 1)}>{_g1_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_9" onClick={(e) => _handleOnChange(e, 'g1_9', 1)}>{_g1_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_10" onClick={(e) => _handleOnChange(e, 'g1_10', 1)}>{_g1_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_11" onClick={(e) => _handleOnChange(e, 'g1_11', 1)}>{_g1_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_12" onClick={(e) => _handleOnChange(e, 'g1_12', 1)}>{_g1_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_13" onClick={(e) => _handleOnChange(e, 'g1_13', 1)}>{_g1_13 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_14" onClick={(e) => _handleOnChange(e, 'g1_14', 1)}>{_g1_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_15" onClick={(e) => _handleOnChange(e, 'g1_15', 1)}>{_g1_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_16" onClick={(e) => _handleOnChange(e, 'g1_16', 1)}>{_g1_16 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g1_17" onClick={(e) => _handleOnChange(e, 'g1_17', 1)}>{_g1_17 == 1?<img src={ball} width="30px"/>: ''}</td>  
                            <td style={{color:'#FF0000'}}><b>{lose1}</b></td>   
                          </tr>
                        </tbody>
                      </table>                             
                    </Col>
                  </Row> 
                  <Row className="mx-0 px-0">                    
                    <Col className="col-12 px-0" style={{paddingTop:'15px', overflow:'auto'}}>                         
                      <table border='1' style={{width:'760px'}}>
                        <tbody>
                          <tr height="40px">
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>                            
                            <td>7</td>
                            <td>8</td>
                            <td>9</td> 
                            <td>10</td>
                            <td>11</td>
                            <td>12</td>
                            <td>13</td>
                            <td>14</td>
                            <td>15</td>
                            <td>16</td>
                            <td>T</td>
                            <td style={{color:'#FF0000'}}><b>結果</b></td>
                          </tr>                       
                          <tr height="40px">
                            <td width="40px">自分</td>
                            <td width="40px" id="g2_1" onClick={(e) => handleOnChange(e, 'g2_1', 2)}>{g2_1 == 1?<img src={ball} width="30px"/>:''}</td>
                            <td width="40px" id="g2_2" onClick={(e) => handleOnChange(e, 'g2_2', 2)}>{g2_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_3" onClick={(e) => handleOnChange(e, 'g2_3', 2)}>{g2_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_4" onClick={(e) => handleOnChange(e, 'g2_4', 2)}>{g2_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_5" onClick={(e) => handleOnChange(e, 'g2_5', 2)}>{g2_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_6" onClick={(e) => handleOnChange(e, 'g2_6', 2)}>{g2_6 == 1?<img src={ball} width="30px"/>: ''}</td>                       
                            <td width="40px" id="g2_7" onClick={(e) => handleOnChange(e, 'g2_7', 2)}>{g2_7 == 1?<img src={ball} width="30px"/>: ''}</td>                      
                            <td width="40px" id="g2_8" onClick={(e) => handleOnChange(e, 'g2_8', 2)}>{g2_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_9" onClick={(e) => handleOnChange(e, 'g2_9', 2)}>{g2_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_10" onClick={(e) => handleOnChange(e, 'g2_10', 2)}>{g2_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_11" onClick={(e) => handleOnChange(e, 'g2_11', 2)}>{g2_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_12" onClick={(e) => handleOnChange(e, 'g2_12', 2)}>{g2_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_13" onClick={(e) => handleOnChange(e, 'g2_13', 2)}>{g2_13 == 1?<img src={ball} width="30px"/>: ''}</td> 
                            <td width="40px" id="g2_14" onClick={(e) => handleOnChange(e, 'g2_14', 2)}>{g2_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_15" onClick={(e) => handleOnChange(e, 'g2_15', 2)}>{g2_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g2_16" onClick={(e) => handleOnChange(e, 'g2_16', 2)}>{g2_16 == 1?<img src={ball} width="30px"/>: ''}</td> 
                            <td width="40px" id="g2_17" onClick={(e) => handleOnChange(e, 'g2_17', 2)}>{g2_17 == 1?<img src={ball} width="30px"/>: ''}</td>                         
                            <td style={{color:'#FF0000'}}><b>{win2}</b></td> 

                          </tr>
                          <tr height="40px">
                            <td width="40px">相手</td>
                            <td width="40px" id="_g2_1" onClick={(e) => _handleOnChange(e, 'g2_1', 2)}>{_g2_1 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_2" onClick={(e) => _handleOnChange(e, 'g2_2', 2)}>{_g2_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_3" onClick={(e) => _handleOnChange(e, 'g2_3', 2)}>{_g2_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_4" onClick={(e) => _handleOnChange(e, 'g2_4', 2)}>{_g2_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_5" onClick={(e) => _handleOnChange(e, 'g2_5', 2)}>{_g2_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_6" onClick={(e) => _handleOnChange(e, 'g2_6', 2)}>{_g2_6 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_7" onClick={(e) => _handleOnChange(e, 'g2_7', 2)}>{_g2_7 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_8" onClick={(e) => _handleOnChange(e, 'g2_8', 2)}>{_g2_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_9" onClick={(e) => _handleOnChange(e, 'g2_9', 2)}>{_g2_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_10" onClick={(e) => _handleOnChange(e, 'g2_10', 2)}>{_g2_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_11" onClick={(e) => _handleOnChange(e, 'g2_11', 2)}>{_g2_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_12" onClick={(e) => _handleOnChange(e, 'g2_12', 2)}>{_g2_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_13" onClick={(e) => _handleOnChange(e, 'g2_13', 2)}>{_g2_13 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_14" onClick={(e) => _handleOnChange(e, 'g2_14', 2)}>{_g2_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_15" onClick={(e) => _handleOnChange(e, 'g2_15', 2)}>{_g2_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_16" onClick={(e) => _handleOnChange(e, 'g2_16', 2)}>{_g2_16 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g2_17" onClick={(e) => _handleOnChange(e, 'g2_17', 2)}>{_g2_17 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td style={{color:'#FF0000'}}><b>{lose2}</b></td>
                          </tr>
                        </tbody>
                      </table>                             
                    </Col>
                  </Row> 

                  <Row className="mx-0 px-0">                    
                    <Col className="col-12 px-0" style={{paddingTop:'15px', overflow:'auto'}}>                         
                      <table border='1' style={{width:'760px'}}>
                        <tbody>
                          <tr height="40px">
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>                            
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                            <td>10</td>
                            <td>11</td>
                            <td>12</td>                            
                            <td>13</td>
                            <td>14</td>
                            <td>15</td>                            
                            <td>16</td>                           
                            <td>T</td>
                            <td style={{color:'#FF0000'}}><b>結果</b></td>
                          </tr>                       
                          <tr height="40px">
                            <td width="40px">自分</td>
                            <td width="40px" id="g3_1" onClick={(e) => handleOnChange(e, 'g3_1', 3)}>{g3_1 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_2" onClick={(e) => handleOnChange(e, 'g3_2', 3)}>{g3_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_3" onClick={(e) => handleOnChange(e, 'g3_3', 3)}>{g3_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_4" onClick={(e) => handleOnChange(e, 'g3_4', 3)}>{g3_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_5" onClick={(e) => handleOnChange(e, 'g3_5', 3)}>{g3_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_6" onClick={(e) => handleOnChange(e, 'g3_6', 3)}>{g3_6 == 1?<img src={ball} width="30px"/>: ''}</td>                          
                            <td width="40px" id="g3_7" onClick={(e) => handleOnChange(e, 'g3_7', 3)}>{g3_7 == 1?<img src={ball} width="30px"/>: ''}</td>                     
                            <td width="40px" id="g3_8" onClick={(e) => handleOnChange(e, 'g3_8', 3)}>{g3_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_9" onClick={(e) => handleOnChange(e, 'g3_9', 3)}>{g3_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_10" onClick={(e) => handleOnChange(e, 'g3_10', 3)}>{g3_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_11" onClick={(e) => handleOnChange(e, 'g3_11', 3)}>{g3_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_12" onClick={(e) => handleOnChange(e, 'g3_12', 3)}>{g3_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_13" onClick={(e) => handleOnChange(e, 'g3_13', 3)}>{g3_13 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_14" onClick={(e) => handleOnChange(e, 'g3_14', 3)}>{g3_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_15" onClick={(e) => handleOnChange(e, 'g3_15', 3)}>{g3_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="g3_16" onClick={(e) => handleOnChange(e, 'g3_16', 3)}>{g3_16 == 1?<img src={ball} width="30px"/>: ''}</td> 
                            <td width="40px" id="g3_17" onClick={(e) => handleOnChange(e, 'g3_17', 3)}>{g3_17 == 1?<img src={ball} width="30px"/>: ''}</td>                       
                            <td style={{color:'#FF0000'}}><b>{win3}</b></td>                         
                          </tr>
                          <tr height="40px">
                            <td width="40px">相手</td>
                            <td width="40px" id="_g3_1" onClick={(e) => _handleOnChange(e, 'g3_1', 3)}>{_g3_1 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_2" onClick={(e) => _handleOnChange(e, 'g3_2', 3)}>{_g3_2 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_3" onClick={(e) => _handleOnChange(e, 'g3_3', 3)}>{_g3_3 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_4" onClick={(e) => _handleOnChange(e, 'g3_4', 3)}>{_g3_4 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_5" onClick={(e) => _handleOnChange(e, 'g3_5', 3)}>{_g3_5 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_6" onClick={(e) => _handleOnChange(e, 'g3_6', 3)}>{_g3_6 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_7" onClick={(e) => _handleOnChange(e, 'g3_7', 3)}>{_g3_7 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_8" onClick={(e) => _handleOnChange(e, 'g3_8', 3)}>{_g3_8 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_9" onClick={(e) => _handleOnChange(e, 'g3_9', 3)}>{_g3_9 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_10" onClick={(e) => _handleOnChange(e, 'g3_10', 3)}>{_g3_10 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_11" onClick={(e) => _handleOnChange(e, 'g3_11', 3)}>{_g3_11 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_12" onClick={(e) => _handleOnChange(e, 'g3_12', 3)}>{_g3_12 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_13" onClick={(e) => _handleOnChange(e, 'g3_13', 3)}>{_g3_13 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_14" onClick={(e) => _handleOnChange(e, 'g3_14', 3)}>{_g3_14 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_15" onClick={(e) => _handleOnChange(e, 'g3_15', 3)}>{_g3_15 == 1?<img src={ball} width="30px"/>: ''}</td>
                            <td width="40px" id="_g3_16" onClick={(e) => _handleOnChange(e, 'g3_16', 3)}>{_g3_16 == 1?<img src={ball} width="30px"/>: ''}</td> 
                            <td width="40px" id="_g3_17" onClick={(e) => _handleOnChange(e, 'g3_17', 3)}>{_g3_17 == 1?<img src={ball} width="30px"/>: ''}</td>                           
                            <td style={{color:'#FF0000'}}><b>{lose3}</b></td>                        
                          </tr>
                        </tbody>
                      </table>                             
                    </Col>
                  </Row>             
                </Form>
                <Row className="pt-2" style={{color:'#FF0000'}}>
                  <Col md="12"><h6>自由に記入欄</h6></Col>
                </Row>
                <Row>
                  <Col md="12">
                    <textarea className="form-control" id="remark" onChange={getInputRemarkValue} value={remark ? remark : ''}>{remark}</textarea>                          
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row style={{color:'#FF0000'}}>
                  <Col md="12">
                    <h6>{selTables.proposergroup} {selTables.proposername}の相手分析情報</h6>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {
                      checkboxgroup.map((group, num) =>
                      <Card className="demo-icons" key={num}>
                        <CardTitle tag="h6" style={{marginTop:'0.75rem'}}>{group.jp}</CardTitle>  
                        
                          {
                            eval(group.en).map((tmp, index) => 
                            
                            <Row key={index }>
                              <Col className="col-12" style={{color:'rgb(3, 115, 202)', fontSize:'14px'}}>
                                {/*<img src={questionImg}  width="20px"/>*/} {tmp.item}
                              </Col>
                              <Col className="col-12" style={{color:'#ff3d02', fontSize:'14px', paddingLeft:'40px'}}>
                               
                                <Input type="text" 
                                            style={{color:'#ff3d02', marginBottom:'5px'}}
                                            id={"an_"+num+"_" + index}
                                            name={"an_"+num}
                                            value={tmp.solution}                          
                                            onChange={(e) => analysisOnChange(e, index, group.en, group.fc)}
                                          />    
                              </Col>
                                                        
                            </Row>
                            
                            )
                          }                
                          
                      </Card>
                    )}
                    
                  </Col>
                </Row>
                <Row>                    
                  <Col className="col-6">
                    <input type="button" name="user" value="相手分析情報保存" className="btn-round btn btn-danger" onClick={save}/>
                  </Col>  
                  <Col className="col-6">
                    <input type="button" name="user" value="相手分析画面" className="btn-round btn btn-primary" onClick={profileUpdateFunc}/>
                  </Col>                                     
                </Row>
              </CardBody>
            </Card>   
            <Card>
              <CardBody>
                <Row style={{color:'#FF0000'}}>
                  <Col md="12"><h6>改善すべき内容</h6></Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input type="text" className="form-control" onChange={getInputImproved1Value} value={improved1 == null ? '' : improved1} style={{marginTop:'5px'}}/>
                    <Input type="text" className="form-control" onChange={getInputImproved2Value} value={improved2 == null ? '' : improved2} style={{marginTop:'5px'}}/>
                    <Input type="text" className="form-control" onChange={getInputImproved3Value} value={improved3 == null ? '' : improved3} style={{marginTop:'5px'}}/>
                    <Input type="text" className="form-control" onChange={getInputImproved4Value} value={improved4 == null ? '' : improved4} style={{marginTop:'5px'}}/>
                    <Input type="text" className="form-control" onChange={getInputImproved5Value} value={improved5 == null ? '' : improved5} style={{marginTop:'5px'}}/>                           
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row style={{color:'#FF0000'}}>
                  <Col md="12"><h6>再度同じ相手にあたるとしたら、どのようにプレーしますか？</h6></Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input type="text" className="form-control" onChange={getInputHowplay1Value} value={howplay1 == null ? '' : howplay1} style={{marginTop:'5px'}}/>
                    <Input type="text" className="form-control" onChange={getInputHowplay2Value} value={howplay2 == null ? '' : howplay2} style={{marginTop:'5px'}}/>
                    <Input type="text" className="form-control" onChange={getInputHowplay3Value} value={howplay3 == null ? '' : howplay3} style={{marginTop:'5px'}}/>
                    <Input type="text" className="form-control" onChange={getInputHowplay4Value} value={howplay4 == null ? '' : howplay4} style={{marginTop:'5px'}}/>
                    <Input type="text" className="form-control" onChange={getInputHowplay5Value} value={howplay5 == null ? '' : howplay5} style={{marginTop:'5px'}}/>                           
                  </Col>
                </Row>
              </CardBody>
            </Card>  
            <Card>
              <CardBody>
                <Row style={{color:'#FF0000'}}>
                  <Col md="12"><h6>試合後のメンタルチェック</h6></Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>1. 最高のプレーができた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental1"
                        value={mental1 == null ? '' : mental1} 
                        onChange={getInputMental1Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>最悪のプレーだった	</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>2. 体（筋肉）はリラックスしていた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental2"
                        value={mental2 == null ? '' : mental2} 
                        onChange={getInputMental2Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>体（筋肉）は緊張していた</Col>  
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>3. 不安はなかった</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental3"
                        value={mental3 == null ? '' : mental3} 
                        onChange={getInputMental3Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>とても不安だった</Col>  
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>4. 落ち着いて冷静だった</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental4"
                        value={mental4 == null ? '' : mental4} 
                        onChange={getInputMental4Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>混乱し動揺していた</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>5. 積極的、楽観的な考え方をしていた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental5"
                        value={mental5 == null ? '' : mental5} 
                        onChange={getInputMental5Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>消極的、悲観的な考えをしていた</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>6. 挑戦する事を楽しんでいた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental6"
                        value={mental6 == null ? '' : mental6} 
                        onChange={getInputMental6Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col> 
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>意欲やエネルギーが不足していた</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>7. 自動的、本能的にプレーしていた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental7"
                        value={mental7 == null ? '' : mental7} 
                        onChange={getInputMental7Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>意識が先走って指図しすぎていた</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>8. プレーに集中できた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental8"
                        value={mental8 == null ? '' : mental8} 
                        onChange={getInputMental8Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>集中していなかった</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>9. 注意力が鋭かった</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental9"
                        value={mental9 == null ? '' : mental9} 
                        onChange={getInputMental9Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col> 
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>注意力が散漫だった</Col> 
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>10. ポジティブなエネルギーに満ちていた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental10"
                        value={mental10 == null ? '' : mental10} 
                        onChange={getInputMental10Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>ネガティブなエネルギーだらけだった</Col>
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>11. 自信満々だった</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental11"
                        value={mental11 == null ? '' : mental11} 
                        onChange={getInputMental11Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col> 
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>自信がなかった</Col> 
                </Row>
                <Row style={{borderBottom:'dotted 1px #ccc', padding:'3px'}} >
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>12. 自己コントロールができていた</Col>
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}> 
                    <select className="form-control" id="mental12"
                        value={mental12 == null ? '' : mental12} 
                        onChange={getInputMental12Value} 
                      >
                      <option value=""></option>
                      <option value="1">1/5</option>
                      <option value="2">2/5</option>
                      <option value="3">3/5</option>
                      <option value="4">4/5</option>
                      <option value="5">5/5</option>
                    </select>
                  </Col>  
                  <Col className="col-4" style={{display:'flex', alignItems:'center'}}>自分をコントロールできなかった</Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="demo-icons col-12" style={{padding:'10px'}}>
              <CardTitle tag="h6" style={{marginTop:'0.75rem'}}>コメント</CardTitle>  
              <textarea className="form-control p-2" id="remark" readOnly="readonly" value={selTab.comment ? selTab.comment : ''} style={{background:'white'}}>{selTab.comment ? selTab.comment : ''}</textarea>
            </Card> 
            <Card>
              <CardBody>                
                <Row>                    
                    <Col className="px-1">
                      <input type="button" name="user" value="保存" className="btn-round btn btn-danger" onClick={(e) => tablesUpdateFunc(e, 0)}/>
                    </Col>    
                    <Col className="px-1">
                      <input type="button" name="user" value="送信" className="btn-round btn btn-primary" onClick={(e) => sendPreparationData(e, 1)}/>
                    </Col>     
                    <Col className="px-1">
                    <input type="button" name="user" value="戻る" className="btn-round btn btn-primary" onClick={preparationPage}/>
                    </Col>                                  
                  </Row>
              </CardBody>
            </Card>       
          </Col>
        </Row>
      </div>
    </>
  );
}
function save(){

  let server = JSON.parse(window.sessionStorage.getItem(['s_serverData']));
  let serAry = [];
  for(var i = 0; i < server.length; i++){
    serAry[i] = {};
    serAry[i].id = server[i].id;
    serAry[i].solution = server[i].solution;
  }

  let receive = JSON.parse(window.sessionStorage.getItem(['s_receiveData']));
  let recAry = [];
  for(var i = 0; i < receive.length; i++){
    recAry[i] = {};
    recAry[i].id = receive[i].id;
    recAry[i].solution = receive[i].solution;
  }

  let stroke = JSON.parse(window.sessionStorage.getItem(['s_strokeData']));
  let strAry = [];
  for(var i = 0; i < stroke.length; i++){
    strAry[i] = {};
    strAry[i].id = stroke[i].id;
    strAry[i].solution = stroke[i].solution;
  }

  let netplay = JSON.parse(window.sessionStorage.getItem(['s_netplayData']));
  let netAry = [];
  for(var i = 0; i < netplay.length; i++){
    netAry[i] = {};
    netAry[i].id = netplay[i].id;
    netAry[i].solution = netplay[i].solution;
  }

  let mental = JSON.parse(window.sessionStorage.getItem(['s_mentalData']));
  let menAry = [];
  for(var i = 0; i < mental.length; i++){
    menAry[i] = {};
    menAry[i].id = mental[i].id;
    menAry[i].solution = mental[i].solution;
  }

  let tactics = JSON.parse(window.sessionStorage.getItem(['s_tacticsData']));
  let tacAry = [];
  for(var i = 0; i < tactics.length; i++){
    tacAry[i] = {};
    tacAry[i].id = tactics[i].id;
    tacAry[i].solution = tactics[i].solution;
  }

  axios.post(URL_API+"saveOtherSelAPI", {
    params: {
      s_serverData: JSON.stringify(serAry),
      s_receiveData: JSON.stringify(recAry), 
      s_strokeData: JSON.stringify(strAry),
      s_netplayData: JSON.stringify(netAry), 
      s_mentalData: JSON.stringify(menAry),
      s_tacticsData: JSON.stringify(tacAry), 
    }
  },{ 
    xsrfHeaderName: 'X-CSRF-Token',
    withCredentials: true
  })
  .then(function (response) {

  });
}

export default Tablesedit;
