import React, { Component } from 'react';
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useState, useEffect } from "react";
import Modal from 'react-modal';
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import "assets/css/chart.css";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0.25rem',
    width:'85%',
    borderRadius:'15px',
    fontWeight:'bold'
  },
};

function lastday(y, m){
	return  new Date(y, m +1, 0).getDate();
}

function Diary4(props){

	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const flag 									= props.flag;
	const [categories, setCategories] 			= useState([]);
	const [datas, setData] 						= useState([]);
	const [tData, setTData] 					= useState([]);
	const [todayDate, setTodayDate] 			= useState();
	const [seriesName, setSeriesName] 			= useState(props.sItem['title'] || '');
	const [fname, setFName] 					= useState(props.sItem['id'] || 0);
	const [chartType, setChartType] 			= useState('line');
	const [chart_logo, setChart_logo] 			= useState(props.sItem['i_icon'] || '');

	const today 								= props.today;
	const [modalIsOpen, setIsOpen] 				= useState(false);
	const [fromTo, setFromTo]		= useState('');

	const [rDays, setRDays]			= useState(0);
	const [cnts, setCnts]			= useState(0);
	const [pCnts, setPCnts]			= useState(0);
	const [sum, setSum]				= useState(0);


	const [aAve, setAAve]			= useState(0);
	const [aMin, setAMin]			= useState(0);
	const [aMax, setAMax]			= useState(0);
	const [aMinDay, setAMinDay]		= useState('');
	const [aMaxDay, setAMaxDay]		= useState('');

	const [pAve, setPAve]			= useState(0);
	const [pMin, setPMin]			= useState(0);
	const [pMax, setPMax]			= useState(0);
	const [pMinDay, setPMinDay]		= useState('');
	const [pMaxDay, setPMaxDay]		= useState('');

	let rday = 0;
	let cnt  = 0;
	let pcnt = 0;
	let tsum = 0;

	let asum = 0;
	let aave = 0;
	let amin = 0;
	let amax = 0;
	let admin = '';
	let admax = '';

	let pave = 0;
	let pmin = 0;
	let pmax = 0;
	let pdmin = '';
	let pdmax = '';

	const setValues = (ymd, v) =>{
		// console.log(ymd, v, props.sItem['id']);
		cnt += Object.keys(v).length;

		let pn = 0;
		for (let key in v) {
			let a = isNaN(Number(v[key])) ? 0 : Number(v[key]);
			tsum += a;
			pn += a;
		}

		rday++;

		let dates = ymd.split('-');

		pcnt = (cnt / rday).toFixed(2);

		aave = (tsum / cnt).toFixed(2);

		let scnt = 1;
		for (let key in v) {
			if(amin == 0){
				amin = v[key];
				admin = Number(dates[1]) + ' / ' + Number(dates[2]) + '...('+scnt+')';
			}else{
				if(amin > v[key]){
					amin = v[key];
					admin = Number(dates[1]) + ' / ' + Number(dates[2]) + '...('+scnt+')';
				}
			}
			if(amax == 0){
				amax = v[key];
				admax = Number(dates[1]) + ' / ' + Number(dates[2]) + '...('+scnt+')';
			}else{
				if(amax < v[key]){
					amax = v[key];
					admax = Number(dates[1]) + ' / ' + Number(dates[2]) + '...('+scnt+')';
				}
			}
			scnt++;
		}

		if(pmin == 0){
			pmin = pn;
			pdmin = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(pmin > pn){
				pmin = pn;
				pdmin = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
		if(pmax == 0){
			pmax = pn;
			pdmax = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(pmax < pn){
				pmax = pn;
				pdmax = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}

		pave = (tsum / rday).toFixed(2);

		
	}

	const handleClick = (chart) => {
		setIsOpen(true);
	};

	const onSwitchChart = (c) => {
		setChartType(c);
	}

	function afterOpenModal() {

  	}

  	function closeModal() {
    	setIsOpen(false);
  	}

	const sumNumber = (ds) => {
		let sum = 0;
		let cnt = 0;
	    for (let key in ds) {
	      sum += isNaN(Number(ds[key])) ? 0 : Number(ds[key]);
	      if(!isNaN(Number(ds[key])))
	      	cnt++;
	    }
	    return {sum:sum, cnt:cnt};
	}

	const setStateDatas = (d_datas) => {
		var categs = [];
		// var dats = {data:[],dataTCnt:[]};
		var dats = [];
		var tDats = [];
		let y = today.getFullYear();
		let m =today.getMonth();
		// console.log(flag, fname);
		if(flag == 0){
			let m =today.getMonth();
			let days = lastday(y, m);
			setFromTo(month[m]+'.01,'+y+' - '+month[m]+'.'+days+','+y+" ("+days+"days)");
			if(d_datas == null){
				for(var i=1;i<=days;i++){
					categs.push((today.getMonth()+1)+'/'+i);
					tDats.push(0);
					dats.push(0);
				}
			}else{
				for(var i=1;i<=days;i++){
					let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
					categs.push((today.getMonth()+1)+'/'+i);
					// console.log(key, d_datas[key]);
					if(d_datas[key]){
						if(d_datas[key][fname]){
							let s = sumNumber(d_datas[key][fname]);
							dats.push(s.sum);
							tDats.push(s.cnt);
							setValues(key, d_datas[key][fname]);
						}else{
							tDats.push(0);
							dats.push(0);
						}
					}else{
						tDats.push(0);
						dats.push(0);
					}
				}
			}
		}else if(flag == 1){
			let mon = m + 1;
			let m1 = mon % 3 == 0 ? mon - 2 : ( mon % 3 == 1 ? mon : mon - 1);
			let m2 = m1 + 1;
			let m3 = m1 + 2;
			let ms = [m1, m2, m3];
			let sdays = 0;
			let days = 0;
			if(d_datas == null){
				for(var i = 0; i<ms.length;i++){
					days = lastday(y, ms[i]-1);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						tDats.push(0);
						dats.push(0);
					}
				}
			}else{
				for(var i = 0; i<ms.length;i++){
					days = lastday(y, ms[i]-1);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						let key = today.getFullYear()+"-"+("0"+ms[i]).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							if(d_datas[key][fname]){
								let s = sumNumber(d_datas[key][fname]);
								dats.push(s.sum);
								tDats.push(s.cnt);
								setValues(key, d_datas[key][fname]);
							}else{
								tDats.push(0);
								dats.push(0);
							}
						}else{
							tDats.push(0);
							dats.push(0);
						}
					}
				}
			}
			setFromTo(month[m1-1]+'.01,'+y+'-'+month[m3-1]+'.'+days+','+y+" ("+sdays+"days)");
		}else{
			let sdays = 0;
			if(d_datas == null){
				for(var i = 0; i<12;i++){
					let days = lastday(y, i);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						tDats.push(0);
						dats.push(0);
					}
				}
			}else{
				for(var i=0;i<12;i++){
					let days = lastday(y, i);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						let key = today.getFullYear()+"-"+("0"+(i+1)).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							if(d_datas[key][fname]){
								let s = sumNumber(d_datas[key][fname]);
								dats.push(s.sum);
								tDats.push(s.cnt);
								setValues(key, d_datas[key][fname]);
							}else{
								tDats.push(0);
								dats.push(0);
							}
						}else{
							tDats.push(0);
							dats.push(0);
						}
					}
				}
			}
			setFromTo(month[0]+'.01,'+y+'-'+month[11]+'.31,'+y+" ("+sdays+"days)");
		}
		// console.log(categs, dats, tDats);
		setCategories(categs);
		setData(dats);
		setTData(tDats);

		setRDays(rday);
		setCnts(cnt);
		setPCnts(pcnt);
		setSum(tsum);

		setAAve(aave);
		setAMin(amin);
		setAMax(amax);
		setAMinDay(admin);
		setAMaxDay(admax);
		
		setPAve(pave);
		setPMin(pmin);
		setPMax(pmax);
		setPMinDay(pdmin);
		setPMaxDay(pdmax);
	}

	const chartOptions = {
		xAxis: {
  			categories: categories,
  			title:'',
  			labels:{
  				enabled:false
  			}
  		},
  		yAxis:{
  			title:""
  		},
  		series: [
      		{ 
      			type: chartType,
      			name: seriesName,
        		data: datas,
	          	color: '#0071ce',        		
      		}
		],
		credits: {
    		enabled: false
  		},
		tooltip: {
	        enabled: false,
	    },
		title:{
			text:''
		},
		plotOptions:{
			series : {
				point: {
		          events: {
		            click: handleClick.bind(this),
		          },
		        },
			},
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},
		},
		chart:{
			events:{
				click:handleClick.bind(this)
			},
			height:120
		}
	}

	useEffect(() => {
	    if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
	        setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	    }
  	}, [flag, today]);
	return (
		<>
			<Modal
		        isOpen={modalIsOpen}
		        onAfterOpen={afterOpenModal}
		        onRequestClose={closeModal}
		        style={customStyles}
		        contentLabel="Example Modal">
	        	<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center border-bottom">
	        		<div className="col-3 px-0 d-flex align-items-center justify-content-center">
	        			<img src={chart_logo} className="chart_list_logo"/>
	        		</div>
					<div className="col-6 px-0 d-flex align-items-center justify-content-center">
						{seriesName}
					</div>
					<div className="col-3 d-flex">
					</div>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-center align-items-center border-bottom">
					{
						fromTo
					}
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Recorded Days</label>
					<span>{rDays}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Count</label>
					<span>{cnts}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Count per Day(Ave)</label>
					<span>{pCnts}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Sum</label>
					<span>{sum}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">

				</div>

				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Ave</label>
					<span>{aAve}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Min : {aMinDay}</label>
					<span>{aMin}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Max : {aMaxDay}</label>
					<span>{aMax}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">

				</div>

				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Ave per Day</label>
					<span>{pAve}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Min per Day : {pMinDay}</label>
					<span>{pMin}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Max per Day : {pMaxDay}</label>
					<span>{pMax}</span>
				</div>
		    </Modal>
			<div className="chart_body">
				<div className="row col-12 px-0 mx-0 py-1 justify-content-between align-items-center">
					<div className="col-8 px-0 d-flex align-items-center">
						<div className="d-flex col-3 px-0 justify-content-center align-items-center">
							<img src={chart_logo} className="chart_list_logo"/>
						</div>
						<div className="col-9 px-0 justify-content-start align-items-center">
							{seriesName}
						</div>
					</div>
					<div className="d-flex col-4">
						<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='line'?'active':'')} onClick={() => onSwitchChart('line')}><i className="fas fa-chart-line fa-fw fa-2x pointer"></i></span>
						<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='column'?'active':'')} onClick={() => onSwitchChart('column')}><i className="fas fa-chart-bar fa-fw fa-2x pointer"></i></span>
					</div>
				</div>
			</div>
      		<div style={{borderBottom:'1px solid #000'}}>
        		<HighchartsReact
          			highcharts={Highcharts}
          			options={chartOptions}
        		/>
      		</div>
      	</>
    )
}

export default Diary4;