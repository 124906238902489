import React, { Component } from 'react';
import { render } from 'react-dom';
import {useState, useEffect } from "react";
import Modal from 'react-modal';
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import "assets/css/diary.css";
let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0.25rem',
    width:'85%',
    borderRadius:'15px',
    fontWeight:'bold'
  },
};

function lastday(y,m){
	return  new Date(y, m +1, 0).getDate();
}

function Diary9(props){

	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const flag 							= props.flag;
	const [data, setData] 				= useState([]);

	const seriesName  					= props.sItem['title'];
	const fname  						= props.sItem['id'];
	const [chartType, setChartType] 	= useState('line');
	const chart_logo 					= props.sItem['i_icon'];

	const [xData, setXData] 			= useState(1);
	const [yData, setYData] 			= useState(0);

	const today 			 			= props.today;
	const [modalIsOpen, setIsOpen] 		= useState(false);
	const [fromTo, setFromTo]			= useState('');
	const [cnts, setCnts]				= useState(0);
	// const [modalIsOpen, setIsOpen] 		= React.useState(false);

	const handleClick = () => {
		setIsOpen(true);
	};

  	function afterOpenModal() {
    	// references are now sync'd and can be accessed.
    	// subtitle.style.color = '#f00';
  	}

  	function closeModal() {
    	setIsOpen(false);
  	}

  	let cnt = 0;

  	const setValues = (v) =>{
  		if(v == 1)
  			cnt++;
	}

	const renderData = (d) => {
		// let dlen = Object.keys(d).length;
		// console.log(d);
		let maxDays = 0;
		for(let n in d){
			maxDays += d[n].length;
		}
		// console.log(maxDays);
		if(maxDays < 50){
			let dhtml = "<div class='col-12 my-2 d-flex text-center' style='flex-wrap:wrap'>";
			for (let m in d) {
				for(let i=0;i<d[m].length;i++){
					let dt = new Date(today.getFullYear()+'-'+m+'-'+(i+1));
					let day = dt.getDay();
					dhtml += '<div class="d-flex align-items-center justify-content-center" style="aspect-ratio:1;width:calc(100% / '+maxDays+')">';
						dhtml += '<div class="w-90 d-flex align-items-center justify-content-center';
						if(d[m][i] == 1)
							dhtml += ' doneIt';
						else if(d[m][i] == 2)
							dhtml += ' notDone';
						else
							dhtml += ' empty';				
						dhtml += '" style="aspect-ratio:1;border-radius:50%">';
						dhtml += '</div>';
					dhtml += '</div>';
				}
			}
			dhtml += "</div>";
			return dhtml;
		}else{
			let dhtml = "<div class='col-12 my-2 d-flex text-center' style='flex-wrap:wrap;height:10px'>";
			for (let m in d) {
				for(let i=0;i<d[m].length;i++){
					let dt = new Date(today.getFullYear()+'-'+m+'-'+(i+1));
					let day = dt.getDay();
					dhtml += '<div class="d-flex align-items-center justify-content-center" style="width:calc(100% / '+maxDays+')">';
						dhtml += '<div class="w-100 d-flex align-items-center justify-content-center';
						if(d[m][i] == 1)
							dhtml += ' doneIt';
						else if(d[m][i] == 2)
							dhtml += ' notDone';
						else
							dhtml += ' _empty';				
						dhtml += '" style="height:100%">';
						dhtml += '</div>';
					dhtml += '</div>';
				}
			}
			dhtml += "</div>";
			return dhtml;
		}
	}

	const setStateDatas = (d_datas) => {
		// var categs = [];
		var dats = {};
		let y = today.getFullYear();
		let m =today.getMonth();
		// console.log(flag, fname);
		if(flag == 0){
			let m =today.getMonth();
			let days = lastday(y, m);
			setFromTo(month[m]+'.01,'+y+' - '+month[m]+'.'+days+','+y+" ("+days+"days)");
			dats[m+1] = [];
			if(d_datas == null){
				for(var i=1;i<=days;i++){
					// categs.push((today.getMonth()+1)+'/'+i);
					dats[m+1].push(0);
				}
			}else{
				for(var i=1;i<=days;i++){
					let key = today.getFullYear()+"-"+("0"+(today.getMonth()+1)).slice(-2)+"-"+("0" + i).slice(-2);
					// categs.push((today.getMonth()+1)+'/'+i);
					// console.log(key, d_datas[key]);
					if(d_datas[key]){
						if(d_datas[key][fname]){
							dats[m+1].push(Number(d_datas[key][fname]));
							setValues(Number(d_datas[key][fname]));
						}
						else
							dats[m+1].push(0);
					}else{
						dats[m+1].push(0);
					}
				}
			}
		}else if(flag == 1){
			let mon = m + 1;
			let m1 = mon % 3 == 0 ? mon - 2 : ( mon % 3 == 1 ? mon : mon - 1);
			let m2 = m1 + 1;
			let m3 = m1 + 2;
			let ms = [m1, m2, m3];
			let sdays = 0;
			let days = 0;
			if(d_datas == null){
				for(var i = 0; i<ms.length;i++){
					days = lastday(y, ms[i]-1);
					sdays += days;
					dats[ms[i]] = [];
					for(var j=1;j<=days;j++){
						// categs.push(ms[i]+'/'+j);
						dats[ms[i]].push(0);
					}
				}
			}else{
				for(var i = 0; i<ms.length;i++){
					days = lastday(y, ms[i]-1);
					sdays += days;
					dats[ms[i]] = [];
					for(var j=1;j<=days;j++){
						// categs.push(ms[i]+'/'+j);
						let key = today.getFullYear()+"-"+("0"+ms[i]).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							if(d_datas[key][fname]){
								dats[ms[i]].push(Number(d_datas[key][fname]));
								setValues(Number(d_datas[key][fname]));
							}
							else
								dats[ms[i]].push(0);
						}else{
							dats[ms[i]].push(0);
						}
					}
				}
			}
			setFromTo(month[m1-1]+'.01,'+y+'-'+month[m3-1]+'.'+days+','+y+" ("+sdays+"days)");
		}else{
			let sdays = 0;
			if(d_datas == null){
				for(var i = 0; i<12;i++){
					let days = lastday(y, i);
					sdays += days;
					dats[i+1] = [];
					for(var j=1;j<=days;j++){
						// categs.push((i+1)+'/'+j);
						dats[i+1].push(0);
					}
				}
			}else{
				for(var i=0;i<12;i++){
					let days = lastday(y, i);
					sdays += days;
					dats[i+1] = [];
					for(var j=1;j<=days;j++){
						// categs.push((i+1)+'/'+j);
						let key = today.getFullYear()+"-"+("0"+(i+1)).slice(-2)+"-"+("0" + j).slice(-2);
						if(d_datas[key]){
							if(d_datas[key][fname]){
								dats[i+1].push(Number(d_datas[key][fname]));
								setValues(Number(d_datas[key][fname]));
							}
							else
								dats[i+1].push(0);
						}else{
							dats[i+1].push(0);
						}
					}
				}
			}
			setFromTo(month[0]+'.01,'+y+'-'+month[11]+'.31,'+y+" ("+sdays+"days)");
		}
		setData(dats);
		setCnts(cnt);
	}

	useEffect(() => {
	    if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
	        setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	    }
  	}, [flag, today]);
	return (
		<>
			<Modal
		        isOpen={modalIsOpen}
		        onAfterOpen={afterOpenModal}
		        onRequestClose={closeModal}
		        style={customStyles}
		        contentLabel="Example Modal">
	        	<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center border-bottom">
	        		<div className="col-3 px-0 d-flex align-items-center justify-content-center">
	        			<img src={chart_logo} className="chart_list_logo"/>
	        		</div>
					<div className="col-6 px-0 d-flex align-items-center justify-content-center">
						{seriesName}
					</div>
					<div className="col-3 d-flex">
					</div>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-center align-items-center border-bottom">
					{
						fromTo
					}
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Done</label>
					<span>{cnts}</span>
				</div>
		    </Modal>
			<div onClick={() => handleClick()}>
				<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center">
					<div className="col-8 px-0 d-flex align-items-center">
						<div className="d-flex col-3 px-0 justify-content-center align-items-center">
							<img src={chart_logo} className="chart_list_logo"/>
						</div>
						<div className="col-9 px-0 justify-content-start align-items-center">
							{seriesName}
						</div>
					</div>
				</div>
				<div className="row col-12 px-0 mx-0 py-1" dangerouslySetInnerHTML={{__html: renderData(data)}} style={{borderBottom:'1px solid #000'}}/>
			</div>
      	</>
    )
}

export default Diary9;