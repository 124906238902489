/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import questionImg from "assets/img/self/question.png";
import { useHistory } from 'react-router-dom';
// reactstrap components
import NotificationAlert from "react-notification-alert";
import {  
  Card,  
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let session;

let notificationAlert;
let selpre = {};
function Preparationview() {

  notificationAlert = React.useRef();  

  const history = useHistory();
  
  const [checkboxgroup, setCheckboxgroup] = useState(
    [ { en: 's_serverData', jp: 'サーブ', fc: 's_setServerData', val:true },
      { en: 's_receiveData', jp: 'レシーブ', fc: 's_setReceiveData', val:true},
      { en: 's_strokeData', jp: 'ストローク', fc: 's_setStrokeData', val:true},
      { en: 's_netplayData', jp: 'ネットプレー', fc: 's_setNetplayData', val:true},
      { en: 's_mentalData', jp: 'メンタル', fc: 's_setMentalData', val:true},
      { en: 's_tacticsData', jp: '戦術', fc: 's_setTacticsData', val:true},]
    );

  const [s_serverData, s_setServerData] = useState([]);
  const [s_receiveData, s_setReceiveData] = useState([]);
  const [s_strokeData, s_setStrokeData] = useState([]);
  const [s_netplayData, s_setNetplayData] = useState([]);
  const [s_mentalData, s_setMentalData] = useState([]);
  const [s_tacticsData, s_setTacticsData] = useState([]);
  
  useEffect(() => {
    session = window.sessionStorage.getItem('sessions'); 

    if(session === null){   
      window.location = '/';
    }
    
    axios.get(URL_API+"getPreparationSelAPI", {
      params: {
        data: window.sessionStorage.getItem("preparaSelpageId"),
      }
    })
    .then(function (response) {     
      if(response.data[0] != null){
        selpre = response.data[0];
        console.log(selpre);
      }
    });
    //戦術
    let se = JSON.parse(session);

    for(var i = 0 ; i < checkboxgroup.length; i++){

      if(JSON.parse(window.sessionStorage.getItem(checkboxgroup[i]['en'])) != null)
        eval(checkboxgroup[i]['fc'])(JSON.parse(window.sessionStorage.getItem(checkboxgroup[i]['en'])));
      
      axios.get(URL_API+"getSelfSelAPI", {
        params: {
          data: checkboxgroup[i]['en'],
          user: se['id'], 
        }
      })
      .then(function (response) {
        
        if(response.data !== null && response.data.length > 0){           
          
          window.sessionStorage.setItem(response.data[0]['group_id'], JSON.stringify(response.data));

          eval(response.data[0]['fc'])(JSON.parse(window.sessionStorage.getItem(response.data[0]['group_id'])));

        }

      });
    }

  }, []);

  
  return (

    <>
      <div className="content" style={{padding:'10px'}}>
        <NotificationAlert ref={notificationAlert}  className="col-12"/>
        <Row>          
          <Col md="12">
            <Card className="card-user">              
              <CardBody>
                <Form>
                  <Row>                    
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <h6>大会名 : {selpre.tournament}</h6>                        
                      </FormGroup>
                    </Col>  
                  </Row>
                  <Row>                    
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <h6>カテゴリー : {selpre.category}</h6>
                      </FormGroup>
                    </Col>  
                  </Row>
                  <Row>                    
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <h6>トーナメント日にち : {selpre.tourdate}</h6>
                      </FormGroup>
                    </Col>                  
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <h6>対戦相手名前 : {selpre.opponentname}</h6>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>                    
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <h6>対戦相手クラブ名 : {selpre.opponentgroup}</h6>
                      </FormGroup>
                    </Col>                  
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <h6>サーフェス : {selpre.surface}</h6>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>                    
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <h6>試合形式 : {selpre.match}</h6>
                      </FormGroup>
                    </Col>                  
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <h6>本戦ラウンド : {selpre.mainround}ラウンド</h6>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>                    
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <h6>予選ラウンド : {selpre.subround}ラウンド</h6>
                      </FormGroup>
                    </Col>                  
                    <Col className="px-1" md="3">
                      <FormGroup>                      
                        <h6>天気 : {selpre.weather == 1 ? '☀️' : selpre.weather == 2 ? '⛅' : selpre.weather == 3 ? '☁️' : selpre.weather == 4 ? '🌧️' : selpre.weather == 5 ? '☔' : selpre.weather == 6 ? '☃️' : ''}</h6>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup>  
                        <h6>起きた時の体調や気分 : {selpre.condition == 1 ? '😆' : selpre.condition == 2 ? '😀' : selpre.condition == 3 ? '🙂' : selpre.condition == 4 ? '😞' : selpre.condition == 5 ? '😣' : ''}</h6>
                      </FormGroup>
                    </Col>                                     
                  </Row>
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup>      
                        <label>試合前に心がける事（下の自己分析結果や相手のタイプを考えて最低3つ書く）</label>
                        <h6>{selpre.think1}</h6>
                        <h6>{selpre.think2}</h6>
                        <h6>{selpre.think3}</h6>
                        <h6>{selpre.think4}</h6>
                        <h6>{selpre.think5}</h6>
                      </FormGroup>
                    </Col>                                     
                  </Row>
                  <Row>
                    <Col md="12">
                      {
                        checkboxgroup.map((group, num) =>
                        <Card className="demo-icons" key={num}>
                          <CardTitle tag="h6" style={{marginTop:'0.75rem'}}>{group.jp}</CardTitle>  
                          
                            {
                              eval(group.en).map((tmp, index) => 
                              
                              <Row key={index }>
                                <Col className="col-12" style={{color:'#18258c', fontSize:'14px'}}>
                                  <img src={questionImg}  width="20px"/> {tmp.item}
                                </Col>
                                <Col className="col-12" style={{color:'#ff3d02', fontSize:'14px', paddingLeft:'40px'}}>
                                  {tmp.solution}
                                </Col>
                                                          
                              </Row>
                              
                              )
                            }                
                            
                        </Card>
                      )}
                      
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <input type="button" name="user" value="試合進行" className="btn-round btn btn-danger" onClick={tablesUpdateFunc}/>
                    </Col>    
                    <Col className="px-1">
                      <input type="button" name="user" value="戻る" className="btn-round btn btn-primary" onClick={preparationPage}/>
                    </Col>   
                                                  
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
function tablesUpdateFunc(){
  window.sessionStorage.setItem('selTables', JSON.stringify(selpre)); 
  window.location = "../sub/tablesedit";
}
function preparationPage(){
  window.location = "../admin/preparation";
}

export default Preparationview;
