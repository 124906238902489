import React, { Component } from 'react';
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {useState, useEffect } from "react";
import Modal from 'react-modal';
import { Card, CardHeader, CardBody, Row, Col, Input } from "reactstrap";

import "assets/css/chart.css";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0.25rem',
    width:'85%',
    borderRadius:'15px',
    fontWeight:'bold'
  },
};

function lastday(y, m){
	return  new Date(y, m +1, 0).getDate();
}

function checkTimeNum(t, f){
    if(Number(t) > f)
      return f;
    else
      return Number(t);
}

function Diary7(props){

	// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

	const flag  								= props.flag;
	const [categories, setCategories] 			= useState([]);
	const [data, setData] 						= useState([]);
	const [data2, setData2] 					= useState([]);

	const [seriesName, setSeriesName] 			= useState(props.sItem['title'] || '');
	const [fname, setFName] 					= useState(props.sItem['id'] || 0);

	const [chartType, setChartType] 			= useState('line');
	const [chart_logo, setChart_logo] 			= useState(props.sItem['i_icon'] || '');

	const [modalIsOpen, setIsOpen] 				= useState(false);
	const today 								= props.today;
	const [fromTo, setFromTo]		= useState('');
	const [cnts, setCnts]			= useState(0);
	const [sum, setSum]				= useState(0);

	const [aAve, setAAve]			= useState(0);
	const [aMin, setAMin]			= useState(0);
	const [aMax, setAMax]			= useState(0);
	const [aMinDay, setAMinDay]		= useState('');
	const [aMaxDay, setAMaxDay]		= useState('');

	const [bAve, setBAve]			= useState(0);
	const [bMin, setBMin]			= useState(0);
	const [bMax, setBMax]			= useState(0);
	const [bMinDay, setBMinDay]		= useState('');
	const [bMaxDay, setBMaxDay]		= useState('');

	const [tAve, setTAve]			= useState(0);
	const [tMin, setTMin]			= useState(0);
	const [tMax, setTMax]			= useState(0);
	const [tMinDay, setTMinDay]		= useState('');
	const [tMaxDay, setTMaxDay]		= useState('');

	let cnt = 0;

	let asum = 0;
	let aave = 0;
	let amin = 0;
	let amax = 0;
	let admin = '';
	let admax = '';

	let bsum = 0;
	let bave = 0;
	let bmin = 0;
	let bmax = 0;
	let bdmin = '';
	let bdmax = '';

	let tsum = 0;
	let tave = 0;
	let tmin = 0;
	let tmax = 0;
	let tdmin = '';
	let tdmax = '';

	const setValues = (ymd, v) =>{
		// console.log(ymd, v, props.sItem['id']);
		cnt += 1;
		let st = [0, 0];
		if(v && v.start)
			st = v.start.split(':');
		if(isNaN(Number(st[0])))
			st[0] = 0;
		if(isNaN(Number(st[1])))
			st[1] = 0;

		st[0] = checkTimeNum(st[0], 23);
	    st[1] = checkTimeNum(st[1], 59);
		let start = Number(Number(st[0]) * 60 * 60 *1000 + Number(st[1]) *  60 *1000);	

		let et = [0, 0];
		if(v && v.end)
			et = v.end.split(':');
		if(isNaN(Number(et[0])))
			et[0] = 0;
		if(isNaN(Number(st[1])))
			et[1] = 0;

		et[0] = checkTimeNum(et[0], 23);
	    et[1] = checkTimeNum(et[1], 59);
		let end = Number(Number(et[0]) * 60 * 60 *1000 + Number(et[1]) *  60 *1000);

		// console.log(start, end);

		let dates = ymd.split('-');

		asum += start;
		aave = (asum / cnt).toFixed(2);
		if(amin == 0){
			amin = start;
			admin = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(amin > start){
				amin = start;
				admin = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
		if(amax == 0){
			amax = start;
			admax = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(amax < start){
				amax = start;
				admax = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}

		bsum += end;
		bave = (bsum / cnt).toFixed(2);
		if(bmin == 0){
			bmin = end;
			bdmin = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(bmin > end){
				bmin = end;
				bdmin = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
		if(bmax == 0){
			bmax = end;
			bdmax = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(bmax < end){
				bmax = end;
				bdmax = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}

		let av = end - start;
		tsum += av;
		tave = (tsum / cnt).toFixed(2);
		if(tmin == 0){
			tmin = av;
			tdmin = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(tmin > av){
				tmin = av;
				tdmin = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
		if(tmax == 0){
			tmax = av;
			tdmax = Number(dates[1]) + ' / ' + Number(dates[2]);
		}else{
			if(tmax < av){
				tmax = av;
				tdmax = Number(dates[1]) + ' / ' + Number(dates[2]);
			}
		}
	}

	const getDateTime = (d) => {
		var h = Math.floor(d / 3600000);
		var m = Math.round((d / 3600000 - h) * 60);
		// console.log(("0"+(h)).slice(-2)+":"+("0" + m).slice(-2), m);
		return ("0"+(h)).slice(-2)+":"+("0" + m).slice(-2);
	}

	const setStateDatas = (d_datas) => {

		var categs = [];
		var datas = [];
		var datas2 = [];
		let y = today.getFullYear();
		let m = today.getMonth();
		if(flag == 0){
			let days = lastday(y, m);
			setFromTo(month[m]+'.01,'+y+' - '+month[m]+'.'+days+','+y+" ("+days+"days)");
			if(d_datas == null){
				for(var i=1;i<=days;i++){
					categs.push(i);
					datas.push(0);
					datas2.push(0);
				}
			}else{
				for(var i=1;i<=days;i++){
					let key = today.getFullYear()+'-'+("0"+(today.getMonth()+1)).slice(-2)+'-'+("0" + i).slice(-2);
					categs.push((today.getMonth()+1)+'/'+i);
					if(d_datas[key] && d_datas[key][fname]){
						// let d = d_datas[key][fname].start.split(':');
						let d = [];
						if(d_datas[key][fname].start){
							d = d_datas[key][fname].start.split(':');
						}
						if(isNaN(Number(d[0])))
							d[0] = 0;
						if(isNaN(Number(d[1])))
							d[1] = 0;

						d[0] = checkTimeNum(d[0], 23);
					    d[1] = checkTimeNum(d[1], 59);
						datas.push(Number(d[0]) * 60 * 60 *1000 + Number(d[1]) *  60 *1000);

						let d2 = [];
						if(d_datas[key][fname].end){
							d2 = d_datas[key][fname].end.split(':');
						}
						if(isNaN(Number(d2[0])))
							d2[0] = 0;
						if(isNaN(Number(d2[1])))
							d2[1] = 0;

						d2[0] = checkTimeNum(d2[0], 23);
					    d2[1] = checkTimeNum(d2[1], 59);
						datas2.push(Number(d2[0]) * 60 * 60 *1000 + Number(d2[1]) *  60 *1000);

						setValues(key, d_datas[key][fname]);
					}else{
						datas.push(0);
						datas2.push(0);
					}
				}
			}
		}else if(flag == 1){
			let mon = m + 1;
			let m1 = mon % 3 == 0 ? mon - 2 : ( mon % 3 == 1 ? mon : mon - 1);
			let m2 = m1 + 1;
			let m3 = m1 + 2;
			let ms = [m1, m2, m3];
			let sdays = 0;
			let days = 0;
			if(d_datas == null){
				for(var i = 0; i<ms.length;i++){
					days = lastday(y, ms[i]-1);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						datas.push(0);
						datas2.push(0);
					}
				}
			}else{
				for(var i = 0; i<ms.length;i++){
					days = lastday(y, ms[i]-1);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push(ms[i]+'/'+j);
						let key = today.getFullYear()+'-'+("0"+ms[i]).slice(-2)+'-'+("0" + j).slice(-2);
						if(d_datas[key] && d_datas[key][fname]){
							let d = [];
							if(d_datas[key][fname].start){
								d = d_datas[key][fname].start.split(':');
							}
							if(isNaN(Number(d[0])))
								d[0] = 0;
							if(isNaN(Number(d[1])))
								d[1] = 0;

							d[0] = checkTimeNum(d[0], 23);
					    	d[1] = checkTimeNum(d[1], 59);
							datas.push(Number(d[0]) * 60 * 60 *1000+ Number(d[1]) *  60 *1000);

							let d2 = [];
							if(d_datas[key][fname].end){
								d2 = d_datas[key][fname].end.split(':');
							}
							if(isNaN(Number(d2[0])))
								d2[0] = 0;
							if(isNaN(Number(d2[1])))
								d2[1] = 0;

							d2[0] = checkTimeNum(d2[0], 23);
					    	d2[1] = checkTimeNum(d2[1], 59);
							datas2.push(Number(d2[0]) * 60 * 60 *1000+ Number(d2[1]) *  60 *1000);

							setValues(key, d_datas[key][fname]);
						}else{
							datas.push(0);
							datas2.push(0);
						}
					}
				}
			}
			setFromTo(month[m1-1]+'.01,'+y+'-'+month[m3-1]+'.'+days+','+y+" ("+sdays+"days)");
		}else{
			let sdays = 0;
			if(d_datas == null){
				for(var i = 0; i<12;i++){
					let days = lastday(y, i);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						datas.push(0);
						datas2.push(0);
					}
				}
			}else{
				for(var i=0;i<12;i++){
					let days = lastday(y, i);
					sdays += days;
					for(var j=1;j<=days;j++){
						categs.push((i+1)+'/'+j);
						let key = today.getFullYear()+'-'+("0"+(i+1)).slice(-2)+'-'+("0" + j).slice(-2);
						if(d_datas[key] && d_datas[key][fname]){
							// let d = d_datas[key][fname].start.split(':');
							let d = [];
							if(d_datas[key][fname].start){
								d = d_datas[key][fname].start.split(':');
							}
							if(isNaN(Number(d[0])))
								d[0] = 0;
							if(isNaN(Number(d[1])))
								d[1] = 0;

							d[0] = checkTimeNum(d[0], 23);
					    	d[1] = checkTimeNum(d[1], 59);
							datas.push(Number(d[0]) * 60 * 60 *1000+ Number(d[1]) *  60 *1000);

							let d2 = [];
							if(d_datas[key][fname].end){
								d2 = d_datas[key][fname].end.split(':');
							}
							if(isNaN(Number(d2[0])))
								d2[0] = 0;
							if(isNaN(Number(d2[1])))
								d2[1] = 0;

							d2[0] = checkTimeNum(d2[0], 23);
					    	d2[1] = checkTimeNum(d2[1], 59);
							datas2.push(Number(d2[0]) * 60 * 60 *1000+ Number(d2[1]) *  60 *1000);

							setValues(key, d_datas[key][fname]);
						}else{
							datas.push(0);
							datas2.push(0);
						}
					}
				}
			}
			setFromTo(month[0]+'.01,'+y+'-'+month[11]+'.31,'+y+" ("+sdays+"days)");
		}

		setCategories(categs);
		setData(datas);
		setData2(datas2);

		setCnts(cnt);
		setSum(tsum);

		setAAve(aave);
		setAMin(amin);
		setAMax(amax);
		setAMinDay(admin);
		setAMaxDay(admax);

		setBAve(bave);
		setBMin(bmin);
		setBMax(bmax);
		setBMinDay(bdmin);
		setBMaxDay(bdmax);
		
		setTAve(tave);
		setTMin(tmin);
		setTMax(tmax);
		setTMinDay(tdmin);
		setTMaxDay(tdmax);
	}	

	const handleClick = (chart) => {
		setIsOpen(true);
	};

	const onSwitchChart = (c) => {
		setChartType(c);
	}

	function afterOpenModal() {

  	}

  	function closeModal() {
    	setIsOpen(false);
  	}

	const chartOptions = {
		xAxis: {
  			categories: categories,
  			title:'',
  			labels:{
  				enabled:false
  			}
  		},
  		yAxis: {
  			title:"",
		    type: 'datetime',
		    dateTimeLabelFormats: {
		      minute: '%H:%M',
		    }
		  },
  		series: [
  			{
	          	type: chartType,
	          	name: '始める',
	          	color: '#0071ce',
	          	data: data,
	        },
	        {
	          	type: chartType,
	          	name: '終わり',
	          	color: '#ff671b',
	          	data: data2,
	        },
		],
		credits: {
    		enabled: false
  		},
		tooltip: {
	        enabled: false
	    },
		title:{
			text:''
		},
		plotOptions:{
			series : {
				marker: {
		            symbol: 'circle',
		          },
				point: {
		          events: {
		            click: handleClick.bind(this),
		          },
		        },
			},
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},

		},
		chart:{
			events:{
				click:handleClick.bind(this)
			},
			height:120
		}
	}

	useEffect(() => {
	   	if(JSON.parse(window.sessionStorage.getItem('diaryData')) != null){
			setStateDatas(JSON.parse(window.sessionStorage.getItem('diaryData')));
	   	}
  	}, [flag, today]);
	return (
		<>  
			<Modal
		        isOpen={modalIsOpen}
		        onAfterOpen={afterOpenModal}
		        onRequestClose={closeModal}
		        style={customStyles}
		        contentLabel="Example Modal">
	        	<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center border-bottom">
	        		<div className="col-3 px-0 d-flex align-items-center justify-content-center">
	        			<img src={chart_logo} className="chart_list_logo"/>
	        		</div>
					<div className="col-6 px-0 d-flex align-items-center justify-content-center">
						{seriesName}
					</div>
					<div className="col-3 d-flex">
					</div>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-center align-items-center border-bottom">
					{
						fromTo
					}
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Count</label>
					<span>{cnts}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Sum</label>
					<span>{getDateTime(sum)}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Ave</label>
					<span>{getDateTime(tAve)}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Min : {tMinDay}</label>
					<span>{getDateTime(tMin)}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">Max : {tMaxDay}</label>
					<span>{getDateTime(tMax)}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">

				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">START Ave</label>
					<span>{getDateTime(aAve)}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">START Min : {aMinDay}</label>
					<span>{getDateTime(aMin)}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">START Max : {aMaxDay}</label>
					<span>{getDateTime(aMax)}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">

				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">END Ave</label>
					<span>{getDateTime(bAve)}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">END Min : {bMinDay}</label>
					<span>{getDateTime(bMin)}</span>
				</div>
				<div className="d-flex px-0 mx-3 py-2 justify-content-between align-items-center border-bottom">
					<label className="m-0">END Max : {bMaxDay}</label>
					<span>{getDateTime(bMax)}</span>
				</div>
		    </Modal>
			<div className="row col-12 px-0 mx-0 py-2 justify-content-between align-items-center">
				<div className="col-8 px-0 d-flex align-items-center">
					<div className="d-flex col-3 px-0 justify-content-center align-items-center">
						<img src={chart_logo} className="chart_list_logo"/>
					</div>
					<div className="col-9 px-0 justify-content-start align-items-center">
						{seriesName}
					</div>
				</div>
				<div className="d-flex col-4">
					<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='line'?'active':'')} onClick={() => onSwitchChart('line')}><i className="fas fa-chart-line fa-fw fa-2x pointer"></i></span>
					<span className={'pl-2 pr-1 py-2 sel_chart_type ' + (chartType=='column'?'active':'')} onClick={() => onSwitchChart('column')}><i className="fas fa-chart-bar fa-fw fa-2x pointer"></i></span>
				</div>
			</div>
      		<div style={{borderBottom:'1px solid #000'}}>
        		<HighchartsReact
          			highcharts={Highcharts}
          			options={chartOptions}
          			handleClick={handleClick}
        		/>		      			
      		</div>
      	</>
    )
}

export default Diary7;