/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

import {  
  Card,  
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

let axios = require('axios');
let URL_API = "https://xs458763.xsrv.jp/api/";
let goal = {}; 
let session; 
let notificationAlert;
let se;

const Goalsettingedit = (e) => {    

  notificationAlert = React.useRef(); 
  
  const history = useHistory();

  if(session === null){    
    window.location = '/';
  }
    
  const preparationPage = (e) => {    
    history.push('../admin/goalsetting');
  }
  
///////////////////

  const [edit_date, setEditdate] = useState();
  const getInputEditdateValue = (event)=>{    
    setEditdate(event.target.value);     
    goal['edit_date'] = event.target.value;
    
  };

  const [technical1, setTechnical1] = useState();
  const getInputTechnical1Value = (event)=>{    
    setTechnical1(event.target.value);     
    goal['technical1'] = event.target.value;
  };
  const [technical2, setTechnical2] = useState();
  const getInputTechnical2Value = (event)=>{    
    setTechnical2(event.target.value);     
    goal['technical2'] = event.target.value;
  };
  const [technical3, setTechnical3] = useState();
  const getInputTechnical3Value = (event)=>{    
    setTechnical3(event.target.value);     
    goal['technical3'] = event.target.value;
  };
  const [technical4, setTechnical4] = useState();
  const getInputTechnical4Value = (event)=>{    
    setTechnical4(event.target.value);     
    goal['technical4'] = event.target.value;
  };//

  const [tactics1, setTactics1] = useState();
  const getInputTactics1Value = (event)=>{    
    setTactics1(event.target.value);     
    goal['tactics1'] = event.target.value;
  };
  const [tactics2, setTactics2] = useState();
  const getInputTactics2Value = (event)=>{    
    setTactics2(event.target.value);     
    goal['tactics2'] = event.target.value;
  };
  const [tactics3, setTactics3] = useState();
  const getInputTactics3Value = (event)=>{    
    setTactics3(event.target.value);     
    goal['tactics3'] = event.target.value;
  };
  const [tactics4, setTactics4] = useState();
  const getInputTactics4Value = (event)=>{    
    setTactics4(event.target.value);     
    goal['tactics4'] = event.target.value;
  };

  const [body1, setBody1] = useState();
  const getInputBody1Value = (event)=>{    
    setBody1(event.target.value);     
    goal['body1'] = event.target.value;
  };
  const [body2, setBody2] = useState();
  const getInputBody2Value = (event)=>{    
    setBody2(event.target.value);     
    goal['body2'] = event.target.value;
  };
  const [body3, setBody3] = useState();
  const getInputBody3Value = (event)=>{    
    setBody3(event.target.value);     
    goal['body3'] = event.target.value;
  };
  const [body4, setBody4] = useState();
  const getInputBody4Value = (event)=>{    
    setBody4(event.target.value);     
    goal['body4'] = event.target.value;
  };

  const [mental1, setMental1] = useState();
  const getInputMental1Value = (event)=>{    
    setMental1(event.target.value);     
    goal['mental1'] = event.target.value;
  };
  const [mental2, setMental2] = useState();
  const getInputMental2Value = (event)=>{    
    setMental2(event.target.value);     
    goal['mental2'] = event.target.value;
  };
  const [mental3, setMental3] = useState();
  const getInputMental3Value = (event)=>{    
    setMental3(event.target.value);     
    goal['mental3'] = event.target.value;
  };
  const [mental4, setMental4] = useState();
  const getInputMental4Value = (event)=>{    
    setMental4(event.target.value);     
    goal['mental4'] = event.target.value;
  };

  const [match1, setMatch1] = useState();
  const getInputMatch1Value = (event)=>{    
    setMatch1(event.target.value);     
    goal['match1'] = event.target.value;
  };
  const [match2, setMatch2] = useState();
  const getInputMatch2Value = (event)=>{    
    setMatch2(event.target.value);     
    goal['match2'] = event.target.value;
  };
  const [match3, setMatch3] = useState();
  const getInputMatch3Value = (event)=>{    
    setMatch3(event.target.value);     
    goal['match3'] = event.target.value;
  };
  const [match4, setMatch4] = useState();
  const getInputMatch4Value = (event)=>{    
    setMatch4(event.target.value);     
    goal['match4'] = event.target.value;
  };

  const [responsee, setResponsee] = useState();
  const getInputResponseeValue = (event)=>{    
    setResponsee(event.target.value);     
    goal['responsee'] = event.target.value;
  };
  const [hindrance, setHindrance] = useState();
  const getInputHindranceValue = (event)=>{    
    setHindrance(event.target.value);     
    goal['hindrance'] = event.target.value;
  };

  useEffect(() => {
    
    session = window.sessionStorage.getItem('sessions'); 
    
    if(window.sessionStorage.getItem('goal') !== null ){

      goal = window.sessionStorage.getItem('goal'); 
      goal = JSON.parse(goal); 

      setEditdate(goal['edit_date']);

      setTechnical1(goal['technical1']);
      setTechnical2(goal['technical2']);
      setTechnical3(goal['technical3']);
      setTechnical4(goal['technical4']);

      setTactics1(goal['tactics1']);
      setTactics2(goal['tactics2']);
      setTactics3(goal['tactics3']);
      setTactics4(goal['tactics4']);

      setBody1(goal['body1']);
      setBody2(goal['body2']);
      setBody3(goal['body3']);
      setBody4(goal['body4']);

      setMental1(goal['mental1']);
      setMental2(goal['mental2']);
      setMental3(goal['mental3']);
      setMental4(goal['mental4']);

      setMatch1(goal['match1']);
      setMatch2(goal['match2']);
      setMatch3(goal['match3']);
      setMatch4(goal['match4']);

      setHindrance(goal['hindrance']);
      setResponsee(goal['responsee']);

    }else{
      
    }

    console.log(goal);
    // //戦術
    se = JSON.parse(session);

    goal['user_id'] = se["id"];
  }, []);
  return (

    <>
      <div className="content coach-user-content px-0 pb-0">
        <NotificationAlert ref={notificationAlert}  className="col-12"/>
        <Row className="mx-0 px-0">          
          <Col md="12" className="px-0">
            <Card className="card-user mb-0">              
              <CardBody>
                <Form>                  
                  <Row>                    
                    <Col className="px-1">
                      <FormGroup>
                        <label><h6>私は、次の３ヶ月間の目標に向かってベストを尽くします。</h6></label>                        
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>  
                    <Col className="px-1">
                      <FormGroup>
                        <label>記入日</label>
                        <Input type="date" onChange={getInputEditdateValue} id="edit_date" value={edit_date == null ? '' : edit_date}  step="1"/>
                      </FormGroup>
                    </Col>                                      
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <label>テクニカルな目標</label>
                        <Input type="text" onChange={getInputTechnical1Value} id="technical1" value={technical1 == null ? '' : technical1}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <Input type="text" onChange={getInputTechnical2Value} id="technical2" value={technical2 == null ? '' : technical2}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <Input type="text" onChange={getInputTechnical3Value} id="technical3" value={technical3 == null ? '' : technical3}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup>
                        <Input type="text" onChange={getInputTechnical4Value} id="technical4" value={technical4 == null ? '' : technical4}/>
                      </FormGroup>
                    </Col>
                  </Row>  

                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <label>戦術の目標</label>
                        <Input type="text" onChange={getInputTactics1Value} id="tactics1" value={tactics1 == null ? '' : tactics1}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <Input type="text" onChange={getInputTactics2Value} id="tactics2" value={tactics2 == null ? '' : tactics2}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <Input type="text" onChange={getInputTactics3Value} id="tactics3" value={tactics3 == null ? '' : tactics3}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup>
                        <Input type="text" onChange={getInputTactics4Value} id="tactics4" value={tactics4 == null ? '' : tactics4}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <label>身体面の目標</label>
                        <Input type="text" onChange={getInputBody1Value} id="body1" value={body1 == null ? '' : body1}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <Input type="text" onChange={getInputBody2Value} id="body2" value={body2 == null ? '' : body2}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <Input type="text" onChange={getInputBody3Value} id="body3" value={body3 == null ? '' : body3}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup>
                        <Input type="text" onChange={getInputBody4Value} id="body4" value={body4 == null ? '' : body4}/>
                      </FormGroup>
                    </Col>
                  </Row> 

                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <label>メンタルの目標	</label>
                        <Input type="text" onChange={getInputMental1Value} id="mental1" value={mental1 == null ? '' : mental1}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <Input type="text" onChange={getInputMental2Value} id="mental2" value={mental2 == null ? '' : mental2}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <Input type="text" onChange={getInputMental3Value} id="mental3" value={mental3 == null ? '' : mental3}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup>
                        <Input type="text" onChange={getInputMental4Value} id="mental4" value={mental4 == null ? '' : mental4}/>
                      </FormGroup>
                    </Col>
                  </Row> 

                  {/*<Row>
                                       <Col className="px-1">
                                         <FormGroup style={{marginBottom:'3px'}}>
                                           <label>試合/大会の目標</label>
                                           <Input type="text" onChange={getInputMatch1Value} id="match1" value={match1 == null ? '' : match1}/>
                                         </FormGroup>
                                       </Col>
                                     </Row>
                                     <Row>
                                       <Col className="px-1">
                                         <FormGroup style={{marginBottom:'3px'}}>
                                           <Input type="text" onChange={getInputMatch2Value} id="match2" value={match2 == null ? '' : match2}/>
                                         </FormGroup>
                                       </Col>
                                     </Row>
                                     <Row>
                                       <Col className="px-1">
                                         <FormGroup style={{marginBottom:'3px'}}>
                                           <Input type="text" onChange={getInputMatch3Value} id="match3" value={match3 == null ? '' : match3}/>
                                         </FormGroup>
                                       </Col>
                                     </Row>
                                     <Row>
                                       <Col className="px-1">
                                         <FormGroup>
                                           <Input type="text" onChange={getInputMatch4Value} id="match4" value={match4 == null ? '' : match4}/>
                                         </FormGroup>
                                       </Col>
                                     </Row>*/} 

                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <label>以上の私の目標の障害になる事	</label>                        
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{textAlign: 'center'}}>
                        <Input type="text" onChange={getInputHindranceValue} id="hindrance" value={hindrance == null ? '' : hindrance}/>
                        <label>スマホをいじってしまう	</label>
                      </FormGroup>
                    </Col>
                  </Row> 

                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{marginBottom:'3px'}}>
                        <label>それらについて私は次のように打ち勝つように努力します。</label>                        
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1">
                      <FormGroup style={{textAlign: 'center'}}>
                        <Input type="text" onChange={getInputResponseeValue} id="responsee" value={responsee == null ? '' : responsee}/>
                        <label>時間を決めてスマホを使う	</label>
                      </FormGroup>
                    </Col>
                  </Row> 
                  <Row>                    
                    <Col className="px-1">
                      <input type="button" name="user" value="保存" className="btn-round btn btn-danger" onClick={goalUpdateFunc}/>
                    </Col>    
                    <Col className="px-1">
                      <input type="button" name="user" value="戻る" className="btn-round btn btn-primary" onClick={preparationPage}/>
                    </Col>                                  
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

function goalUpdateFunc(){
  console.log(goal);

  var color = Math.floor(Math.random() * 5 + 1);
  var type;
  switch (color) {
    case 1:
      type = "primary";
      break;
    case 2:
      type = "success";
      break;
    case 3:
      type = "danger";
      break;
    case 4:
      type = "warning";
      break;
    case 5:
      type = "info";
      break;
    default:
      break;
  }
  
  let erroMsg = "";

  if(goal== undefined || !goal["edit_date"] || goal["edit_date"] == ""){
    erroMsg += "「記入日」";
  }
  if(erroMsg != ""){
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            <b>{erroMsg}</b>が必須項目です。
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
    return false;
  }

  var data = JSON.stringify(goal);
  
  axios.get(URL_API+"goalUpdateAPI", {
    params: {
      data: data, 
    }
  })
  .then(function (response) {    
      
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            <b>「課題目標設定画面編集」</b>が 完了しました。
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
  
    if(response.data != "y"){
        
      window.sessionStorage.setItem("goal", JSON.stringify(response.data));
      goal = window.sessionStorage.getItem('goal');
      goal = JSON.parse(goal);
    }      
    
    notificationAlert.current.notificationAlert(options);
  })
}
export default Goalsettingedit;
